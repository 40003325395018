<ng-container *ngIf="questionGroupStatistics.length > 0; else noStatistics">
  <p-table
    #table
    name="Statistics"
    [value]="questionGroupStatistics"
    styleClass="p-datatable-sm"
    dataKey="exam.name"
    rowExpandMode="single"
    [expandedRowKeys]="expandedRows"
    [rowHover]="true"
    [style]="{ margin: ' 0.5rem' }"
  >
    <ng-template pTemplate="caption">
      {{ i18n('overview.title') | translate }}
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th [translate]="i18n('overview.exam')"></th>
        <th [translate]="i18n('overview.exam_date')"></th>
        <th [translate]="i18n('table.n')"></th>
        <th
          [pTooltip]="
            'pool.question_group_details.statistics.overview.hint.p_partial'
              | translate
          "
          tooltipPosition="top"
        >
          {{
            'pool.question_group_details.statistics.table.p_partial' | translate
          }}
          <i class="fa-solid fa-square-info"></i>
        </th>
        <th
          [pTooltip]="
            'pool.question_group_details.statistics.overview.hint.p_dichotomous'
              | translate
          "
          tooltipPosition="top"
        >
          {{
            'pool.question_group_details.statistics.table.p_dichotomous'
              | translate
          }}
          <i class="fa-solid fa-square-info"></i>
        </th>
        <th [translate]="i18n('table.r')"></th>
        <th [translate]="i18n('table.lne')"></th>
        <th [translate]="i18n('table.xi')"></th>
        <th [translate]="i18n('table.eliminated')"></th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-questionGroupStatistics
      let-expanded="expanded"
      let-index="rowIndex"
    >
      <tr
        (click)="selectQuestionGroup(questionGroupStatistics, index)"
        interactiveRow
      >
        <td>
          <button
            class="btn btn-icon"
            type="button"
            (click)="selectQuestionGroup(questionGroupStatistics, index)"
          >
            <i
              aria-hidden="true"
              class="fa-xs fa-regular"
              [ngClass]="{
                'fa-chevron-up': expanded,
                'fa-chevron-down': !expanded
              }"
            ></i>
          </button>
        </td>
        <td>
          {{ questionGroupStatistics.exam.name }}
        </td>
        <td>{{ questionGroupStatistics.exam.date | date : 'dd.MM.yyyy' }}</td>
        <td>{{ questionGroupStatistics.n | fixedNumberRange : 0 }}</td>
        <td>
          {{ questionGroupStatistics.p | percentages | fixedNumberRange : 1 }}
        </td>
        <td>
          {{
            questionGroupStatistics.pDichotomous
              | percentages
              | fixedNumberRange : 1
          }}
        </td>
        <td>{{ questionGroupStatistics.r | fixedNumberRange : 2 }}</td>
        <td>{{ questionGroupStatistics.lne | fixedNumberRange : 3 }}</td>
        <td>
          [{{ questionGroupStatistics.xi1 | fixedNumberRange : 3 }},
          {{ questionGroupStatistics.xi2 | fixedNumberRange : 3 }}]
        </td>
        <td>{{ questionGroupStatistics.eliminated | yesOrBlank }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-questionGroupStatistics>
      <tr>
        <td colspan="11">
          <p-table
            name="QuestionStatistic"
            [value]="questionGroupStatistics.questions"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem"></th>
                <th [translate]="i18n('questions')"></th>
                <th style="width: 4rem"></th>
                <th [translate]="i18n('table.n')"></th>
                <th [translate]="i18n('table.p_partial')"></th>
                <th [translate]="i18n('table.p_dichotomous')"></th>
                <th [translate]="i18n('table.r')"></th>
                <th [translate]="i18n('table.lne')"></th>
                <th [translate]="i18n('table.xi')"></th>
                <th [translate]="i18n('table.eliminated')"></th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-questionStatistic
              let-index="rowIndex"
            >
              <tr
                (click)="selectQuestion(index)"
                [ngClass]="{
                  'p-highlight': selectedQuestion === index
                }"
              >
                <td style="width: 4rem"></td>
                <td>
                  {{
                    'pool.question_group_details.statistics.table.question'
                      | translate : { number: index + 1 }
                  }}
                </td>
                <td style="width: 4rem"></td>
                <td>
                  {{
                    questionStatistic.total.n !== null &&
                    questionStatistic.total.n !== undefined
                      ? (questionStatistic.total.n | fixedNumber : 0)
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    questionStatistic.total.p !== null &&
                    questionStatistic.total.p !== undefined
                      ? (questionStatistic.total.p
                        | percentage
                        | fixedNumber : 1)
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    questionStatistic.total.pDichotomous !== null &&
                    questionStatistic.total.pDichotomous !== undefined
                      ? (questionStatistic.total.pDichotomous
                        | percentage
                        | fixedNumber : 1)
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    questionStatistic.total.r !== null &&
                    questionStatistic.total.r !== undefined
                      ? (questionStatistic.total.r | fixedNumber : 2)
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    questionStatistic.total.lne !== null &&
                    questionStatistic.total.lne !== undefined
                      ? (questionStatistic.total.lne | fixedNumber : 3)
                      : '-'
                  }}
                </td>
                <td>
                  [{{
                    questionStatistic.total.xi1 !== null &&
                    questionStatistic.total.xi1 !== undefined
                      ? (questionStatistic.total.xi1 | fixedNumber : 3)
                      : '-'
                  }},
                  {{
                    questionStatistic.total.xi2 !== null &&
                    questionStatistic.total.xi2 !== undefined
                      ? (questionStatistic.total.xi2 | fixedNumber : 3)
                      : '-'
                  }}]
                </td>
                <td>{{ questionStatistic.total.eliminated | yesOrBlank }}</td>
              </tr>
            </ng-template>
          </p-table>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <ng-container *ngIf="currentQuestionStatistics !== undefined">
    <subgroup-statistics
      [statistics]="currentQuestionStatistics"
    ></subgroup-statistics>
  </ng-container>
  <ng-container [ngSwitch]="currentQuestionStatistics?.total?.__typename">
    <ng-container *ngSwitchCase="'TotalStatisticTypeA'">
      <type-a-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></type-a-statistics>
    </ng-container>
    <ng-container *ngSwitchCase="'TotalStatisticTypeAMinus'">
      <type-a-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></type-a-statistics>
    </ng-container>
    <ng-container *ngSwitchCase="'TotalStatisticTypeKprime'">
      <type-kprime-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></type-kprime-statistics>
    </ng-container>
    <ng-container *ngSwitchCase="'TotalStatisticTypeE'">
      <type-e-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></type-e-statistics>
    </ng-container>
    <ng-container *ngSwitchCase="'TotalStatisticTypeFreeText'">
      <type-free-text-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></type-free-text-statistics>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #noStatistics>
  <details-info
    [message]="
      'pool.question_group_details.statistics.no_statistics' | translate
    "
  ></details-info>
</ng-template>
