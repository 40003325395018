import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { Label, Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { PoolLabelFragment } from '../../../services/load-pool.generated';
import { ReloadPool } from '../../../state/pool/pool.actions';
import { PoolState } from '../../../state/pool/pool.state';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../utils/ng-bootstrap-modal';
import { assertIsDefined } from '../../utils/type-guards/is-defined';
import { AssignmentActions } from '../assign-exam-modal/assign-exam-modal.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { AssignLabelsGQL } from './assign-labels.generated';
import { CreateLabelGQL } from './create-label.generated';
import { DeleteLabelGQL } from './delete-label.generated';
import { UpdateLabelGQL } from './update-label.generated';

export interface AssignmentActionsProperty {
  [index: string]: AssignmentActions;
}

const generateColor = (): string => {
  return '#' + Math.random().toString(16).slice(-6); // generate random color
};

interface NewLabel {
  name: string;
  color: string;
}

@Component({
  selector: 'co-assign-label-modal',
  templateUrl: './assign-label-modal.component.html',
  styleUrls: ['./assign-label-modal.component.scss']
})
export class AssignLabelModalComponent implements OnInit, OnDestroy {
  @Input()
  public modalInstance: NgbModalRef;
  @Input()
  public currentPoolId: Scalars['ID'];
  @Input()
  public questionGroups: QuestionGroupListElementFragment[];
  @Select(PoolState.labels)
  public labels$: Observable<PoolLabelFragment[]>;

  public actions: { [index: string]: AssignmentActions } = {};
  public assignmentActions = AssignmentActions;
  public newLabel: NewLabel = {
    name: '',
    color: generateColor()
  };
  public labels: Label[];
  private destroy$ = new Subject<void>();

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly translate: TranslateService,
    private readonly store: Store,
    private readonly assignLabelsGQL: AssignLabelsGQL,
    private readonly createLabelGQL: CreateLabelGQL,
    private readonly updateLabelGQL: UpdateLabelGQL,
    private readonly deleteLabelGQL: DeleteLabelGQL
  ) {}

  public ngOnInit(): void {
    this.labels$.subscribe(labels => {
      this.labels = labels;
      this.setLabelStates();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async save(): Promise<void> {
    const result = await this.assignLabelsGQL
      .mutate({
        poolId: this.currentPoolId,
        questionGroupIds: this.questionGroups.map(qg => qg.id),
        labelIdsToAdd: this.filterLabelsByAction(AssignmentActions.ADD),
        labelIdsToRemove: this.filterLabelsByAction(AssignmentActions.REMOVE)
      })
      .toPromise();

    if (result?.data?.assignLabels?.successful !== true) {
      throw `Unable to assign labels for ${this.questionGroups.map(
        qg => qg.id
      )}`;
    }

    this.modalInstance.close();
  }

  public async removeLabel(label: Label): Promise<void> {
    const title = this.translate.instant('labels.delete');
    const message =
      label.usageCount > 0
        ? this.translate.instant('labels.are_you_sure_with_used', {
            count: label.usageCount
          })
        : this.translate.instant('labels.are_you_sure');

    const ngbInstance = this.ngbModal.open(
      ConfirmModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );
    const componentInstance: ConfirmModalComponent =
      ngbInstance.componentInstance;
    componentInstance.modalInstance = ngbInstance;
    componentInstance.title = title;
    componentInstance.message = message;

    try {
      await ngbInstance.result;
      const deleteResult = await this.deleteLabelGQL
        .mutate({
          poolId: this.currentPoolId,
          labelId: label.id
        })
        .toPromise();

      assertIsDefined(deleteResult);

      if (deleteResult.data?.deleteLabel?.successful !== true) {
        throw `Unable to delete label on pool ${this.currentPoolId}`;
      }

      this.labels = this.labels.filter(l => l.id !== label.id);
      const assignResult = await this.assignLabelsGQL
        .mutate({
          poolId: this.currentPoolId,
          questionGroupIds: this.questionGroups.map(qg => qg.id),
          labelIdsToAdd: [],
          labelIdsToRemove: [label.id]
        })
        .toPromise();

      assertIsDefined(assignResult);

      if (assignResult.data?.assignLabels?.successful !== true) {
        throw `Unable to assign labels on pool ${this.currentPoolId}`;
      }

      this.store.dispatch(new ReloadPool());
    } catch (_rejected: unknown) {
      // do nothing
    }
  }

  public async addLabel(): Promise<void> {
    const result = await this.createLabelGQL
      .mutate({
        poolId: this.currentPoolId,
        name: this.newLabel.name,
        color: this.newLabel.color
      })
      .toPromise();

    if (result?.data?.createLabel?.successful !== true) {
      throw `Unable to create label on pool ${this.currentPoolId}`;
    }

    this.newLabel = {
      name: '',
      color: generateColor()
    };
    this.store.dispatch(new ReloadPool());
  }

  public async updateLabel(newColour: string, label: Label): Promise<void> {
    const result = await this.updateLabelGQL
      .mutate({
        poolId: this.currentPoolId,
        labelId: label.id,
        name: label.name,
        color: newColour
      })
      .toPromise();

    if (result?.data?.updateLabel?.successful !== true) {
      throw `Unable to update label ${label.id}`;
    }

    this.store.dispatch(new ReloadPool());
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  private filterLabelsByAction(action: AssignmentActions): string[] {
    return this.labels
      .filter((label: Label) => {
        return this.actions[label.id] === action;
      })
      .map(label => label.id);
  }

  private getQuestionGroupsWithLabel(
    label: Label,
    questionGroups: QuestionGroupListElementFragment[]
  ): QuestionGroupListElementFragment[] {
    return questionGroups.filter(questionGroup =>
      questionGroup.labels.some(l => l.id === label.id)
    );
  }

  private setLabelStates(): void {
    this.actions = this.labels.reduce(
      (action: AssignmentActionsProperty, label: Label) => {
        const labelledQuestionGroups = this.getQuestionGroupsWithLabel(
          label,
          this.questionGroups
        );
        if (label.id === undefined) {
          action[label.id] = AssignmentActions.ADD;
        } else if (
          labelledQuestionGroups === undefined ||
          labelledQuestionGroups.length === 0
        ) {
          action[label.id] = AssignmentActions.REMOVE;
        } else if (
          labelledQuestionGroups.length === this.questionGroups.length
        ) {
          action[label.id] = AssignmentActions.ADD;
        } else {
          action[label.id] = AssignmentActions.IGNORE;
        }

        return action;
      },
      {}
    );
  }
}
