import {
  ChangeDetectionStrategy,
  Component,
  HostListener
} from '@angular/core';
import { requestStateSuccess } from '@common/utils/remote-data-utils';
import { isDefined } from '@common/utils/type-guards/is-defined';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UnsavedChanges } from '../../../common/guard/unsaved-changes.guard';
import { LoadUsersFieldsFragment } from '../../services/load-users.fragments.generated';
import { UpdateUser } from '../../state/user-form.actions';
import { UserFormState } from '../../state/user-form.state';

@Component({
  templateUrl: './user-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailComponent implements UnsavedChanges {
  public readonly user$: Observable<LoadUsersFieldsFragment>;
  public readonly loading$: Observable<boolean>;
  public readonly saving$: Observable<boolean>;
  public readonly disabled$: Observable<boolean>;

  public unsavedChanges = false;

  constructor(private store: Store) {
    this.user$ = this.store.select(UserFormState.user).pipe(
      filter(requestStateSuccess),
      map(({ data }) => data),
      filter(isDefined)
    );
    this.saving$ = this.store.select(UserFormState.mutationLoading);
    this.loading$ = this.store.select(UserFormState.userLoading);
    this.disabled$ = combineLatest([
      this.saving$,
      this.loading$,
      this.store.select(UserFormState.userFailure)
    ]).pipe(
      map(([User, loading, failure]) => User || loading || failure),
      distinctUntilChanged()
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  public onUnloadListener(event: BeforeUnloadEvent): void {
    if (!this.unsavedChanges) return;

    event.preventDefault();
  }

  public onSave({ id, ...User }: LoadUsersFieldsFragment): void {
    this.store.dispatch(new UpdateUser(id, User));
  }

  public onStateChange(unsavedChanges: boolean): void {
    this.unsavedChanges = unsavedChanges;
  }
}
