import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { OAuthApplicationFieldsFragmentDoc } from './load-oauth-applications.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateOauthApplicationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  attributes: Types.OauthApplicationInput;
}>;


export type UpdateOauthApplicationMutation = { __typename?: 'Mutation', updateOauthApplication?: { __typename?: 'UpdateOauthApplicationPayload', successful: boolean, oauthApplication?: { __typename?: 'OAuthApplication', id: string, uid: string, name: string, secret: string, redirectUri: string } | null, validations?: { __typename?: 'ValidationsOauthApplication', name?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, redirectUri?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const UpdateOauthApplicationDocument = gql`
    mutation UpdateOauthApplication($id: ID!, $attributes: OauthApplicationInput!) {
  updateOauthApplication(id: $id, attributes: $attributes) {
    oauthApplication {
      ...OAuthApplicationFields
    }
    successful
    validations {
      name {
        message
        severity
      }
      redirectUri {
        message
        severity
      }
    }
  }
}
    ${OAuthApplicationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOauthApplicationGQL extends Apollo.Mutation<UpdateOauthApplicationMutation, UpdateOauthApplicationMutationVariables> {
    document = UpdateOauthApplicationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }