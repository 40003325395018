import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RemoteData } from '@common/utils/remote-data';
import { Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { FormState } from 'src/app/common/types/form-types';
import {
  getDirectionFromIndices,
  getMoveQuantity
} from 'src/app/common/utils/drag-and-drop';
import { assertIsDefined } from 'src/app/common/utils/type-guards/is-defined';
import { ActionMenuOption } from 'src/app/new/common/action-menu/action-menu.component';
import { FuzzySearchService } from 'src/app/new/common/fuzzy-search.service';
import { ModalService } from 'src/app/new/common/modal/modal.service';
import { RevisionStatus } from 'src/generated/base-types';
import { CreateRevisionStatusMutationVariables } from '../../../services/create-revision-status.generated';
import { UpdateRevisionStatusMutationVariables } from '../../../services/update-revision-status.generated';
import {
  CreateRevisionStatus,
  DeleteRevisionStatus,
  SetRevisionStatusPosition,
  UpdateRevisionStatus
} from '../../../state/pool-details/revision-status/revision-status.actions';
import { RevisionStatusState } from '../../../state/pool-details/revision-status/revision-status.state';

@Component({
  templateUrl: './revision-status-list.component.html',
  host: { class: 'page' },
  changeDetection: ChangeDetectionStrategy.Default
})
export class RevisionStatusListComponent implements OnDestroy {
  public readonly filterFields = ['shortName', 'description'];
  public sortBy = 'position';
  public inSearchMode = false;
  public readonly revisionStatuses$: Observable<RemoteData<RevisionStatus[]>>;
  public readonly formState$: Observable<FormState<Partial<RevisionStatus>>>;
  public readonly menuOptions: ActionMenuOption[] = [
    {
      label: 'Edit',
      callback: (data: RevisionStatus) => this.onEdit(data)
    },
    {
      label: 'Delete',
      danger: true,
      callback: (data: RevisionStatus) => this.onDelete(data)
    }
  ];
  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private fuzzySearchService: FuzzySearchService<RevisionStatus>
  ) {
    this.formState$ = this.formState();
    this.dismissSidebarOnSuccessfulMutation();
    this.revisionStatuses$ = combineLatest([
      this.store.select(RevisionStatusState.revisionStatus),
      this.searchString$
    ]).pipe(
      map(([revisionStatus, searchString]) => {
        this.inSearchMode = searchString.length > 0;
        searchString.trim() === ''
          ? (this.sortBy = 'position')
          : (this.sortBy = '');

        const result = this.fuzzySearchService.search(
          revisionStatus.data || [],
          searchString,
          this.filterFields
        );

        return { ...revisionStatus, data: result };
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
  }

  public onSearchClear(): void {
    this.searchStringSource.next('');
  }

  public onNew(): void {
    this.routeToForm('new');
  }

  public onEdit({ id }: RevisionStatus): void {
    this.routeToForm(id);
  }

  public onCreate({
    shortName,
    description
  }: Pick<
    CreateRevisionStatusMutationVariables,
    'attributes'
  >['attributes']): void {
    this.store.dispatch(new CreateRevisionStatus({ shortName, description }));
  }

  public onUpdate({
    id,
    shortName,
    description
  }: Pick<UpdateRevisionStatusMutationVariables, 'id'> &
    Pick<
      UpdateRevisionStatusMutationVariables,
      'attributes'
    >['attributes']): void {
    this.store.dispatch(
      new UpdateRevisionStatus(id, { shortName, description })
    );
  }

  public onDismiss(): void {
    this.routeToForm();
  }

  public onReorder({
    dragIndex,
    dropIndex
  }: {
    dragIndex: number;
    dropIndex: number;
  }): void {
    if (this.inSearchMode) return;
    if (dragIndex === dropIndex) return;
    const { id } =
      this.store.selectSnapshot(RevisionStatusState.findRevisionStatusAtIndex)(
        dragIndex
      ) ?? {};
    assertIsDefined(id);

    const moveDirection = getDirectionFromIndices(dragIndex, dropIndex);
    const moveQuantity = getMoveQuantity(dragIndex, dropIndex);

    this.store.dispatch(
      new SetRevisionStatusPosition(id, moveDirection, moveQuantity)
    );
  }

  public async onDelete(revisionStatus: RevisionStatus): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();
    if (confirmDelete) {
      this.store.dispatch(new DeleteRevisionStatus(revisionStatus.id));
    }
  }

  private formState(): Observable<FormState<Partial<RevisionStatus>>> {
    const editingParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editing') ?? undefined)
    );
    const disabled$ = this.store
      .select(RevisionStatusState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    return combineLatest([editingParam$, disabled$]).pipe(
      switchMap(([editingParam, disabled]) => {
        const show: boolean = editingParam !== undefined;
        const id = show && editingParam !== 'new' ? editingParam : undefined;

        return this.revisionStatuses$.pipe(
          filter(({ requestState }) => requestState === 'success'),
          take(1),
          map(({ data }) =>
            id === undefined ? undefined : data?.find(a => a.id === id)
          ),
          map(data => ({ show, disabled, data }))
        );
      })
    );
  }

  private dismissSidebarOnSuccessfulMutation(): void {
    this.store
      .select(RevisionStatusState.mutationRequestState)
      .pipe(
        pairwise(),
        filter(
          ([lastState, currentState]) =>
            lastState === 'loading' && currentState === 'success'
        ),
        tap({ next: () => this.onDismiss() }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private routeToForm(editing?: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editing },
      queryParamsHandling: 'merge'
    });
  }
}
