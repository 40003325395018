import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteDimensionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteDimensionMutation = { __typename?: 'Mutation', deleteDimension?: { __typename?: 'DeleteDimensionPayload', successful: boolean } | null };

export const DeleteDimensionDocument = gql`
    mutation DeleteDimension($id: ID!) {
  deleteDimension(id: $id) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDimensionGQL extends Apollo.Mutation<DeleteDimensionMutation, DeleteDimensionMutationVariables> {
    document = DeleteDimensionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }