import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadPools, PoolsState } from '@state/pools';

@Injectable({ providedIn: 'root' })
export class PoolsGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    const requestState = this.store.selectSnapshot(
      PoolsState.pools
    ).requestState;

    if (requestState === 'loading' || requestState === 'success') return true;

    this.store.dispatch(new LoadPools());

    return true;
  }
}
