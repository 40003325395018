import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateCategoryMutationVariables = Types.Exact<{
  dimensionId: Types.Scalars['ID'];
  attributes: Types.CategoryInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createCategory?: { __typename?: 'CreateCategoryPayload', successful: boolean, category?: { __typename?: 'Category', id: string, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, position: number, customIdentifier?: string | null } | null } | null };

export const CreateCategoryDocument = gql`
    mutation CreateCategory($dimensionId: ID!, $attributes: CategoryInput!) {
  createCategory(dimensionId: $dimensionId, attributes: $attributes) {
    category {
      id
      name
      nameDe
      nameFr
      nameEn
      nameIt
      position
      customIdentifier
    }
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCategoryGQL extends Apollo.Mutation<CreateCategoryMutation, CreateCategoryMutationVariables> {
    document = CreateCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }