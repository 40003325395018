import { Injectable } from '@angular/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { ContextState } from '@state/context/context.state';
import { assertIsDefined } from 'src/app/common/utils/type-guards/is-defined';
import { Scalars } from '../../../../generated/base-types';
import {
  LoadQuestionGroupList,
  ResetSelectedQuestionGroups
} from '../question-list.actions';
import {
  QuestionFilterReset,
  QuestionFilterUpdate,
  ToggleFilterPanel
} from './question-filter.actions';
import {
  QuestionFilters,
  QuestionFilterStateModel,
  SimpleFilterCategory
} from './question-filter.state.model';

const DEFAULT_FILTERS: Readonly<QuestionFilters> = {
  query: '',
  advancedFilter: { options: [], selection: [] },
  simpleFilters: Object.values(SimpleFilterCategory).map(category => ({
    category,
    selection: [],
    options: []
  })),
  hidden: false
};

@State<QuestionFilterStateModel>({ name: 'questionFilter' })
@Injectable()
export class QuestionFilterState {
  constructor(private readonly store: Store) {}

  @Selector([ContextState.currentPoolId])
  public static currentPoolFilters(
    state: QuestionFilterStateModel,
    poolId: Scalars['ID'] | undefined
  ): QuestionFilters {
    assertIsDefined(poolId, 'Cannot get pool filters without a pool id');

    return state[poolId] ?? DEFAULT_FILTERS;
  }

  @Selector([ContextState.currentPoolId])
  public static hidden(
    state: QuestionFilterStateModel,
    poolId: Scalars['ID'] | undefined
  ): boolean {
    assertIsDefined(poolId, 'Cannot get hidden state without a pool id');

    return state[poolId]?.hidden ?? false;
  }

  @Action(QuestionFilterUpdate)
  public update(
    ctx: StateContext<QuestionFilterStateModel>,
    { filter }: QuestionFilterUpdate
  ): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'Cannot update question filter without a pool id');
    ctx.patchState({ [poolId]: filter });
    ctx.dispatch(new ResetSelectedQuestionGroups());
    ctx.dispatch(new LoadQuestionGroupList(0));
  }

  @Action(QuestionFilterReset)
  public reset(ctx: StateContext<QuestionFilterStateModel>): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'Cannot reset question filter without a pool id');
    // In the case where the filter _is_ undefined, but we still want to reset
    // the UI, we need to update the filter in order to trigger DOM updates.
    // This is a little hacky, but it cuts the need to listen to disaptched
    // actions or work with BehaviourSubjects, etc. in the component.
    ctx.patchState({ [poolId]: { ...DEFAULT_FILTERS } });
    ctx.patchState({ [poolId]: undefined });
    ctx.dispatch(new ResetSelectedQuestionGroups());
    ctx.dispatch(new LoadQuestionGroupList(0));
  }

  @Action(ToggleFilterPanel)
  public toggleFilterPanel(ctx: StateContext<QuestionFilterStateModel>): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'Cannot toggle filter panel without a pool id');
    const currentState = ctx.getState()[poolId];
    ctx.patchState({
      [poolId]: {
        ...(currentState ?? DEFAULT_FILTERS),
        hidden: !currentState?.hidden
      }
    });
  }
}
