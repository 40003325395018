import { CommonModule as AngularCommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RemoteData } from 'src/app/common/utils/remote-data';
import { PanelLayoutWrapperComponent } from '../common/panel-layout-wrapper/panel-layout-wrapper.component';
import { QuestionGroupDetailsComponent } from '../common/question-group-details/question-group-details.component';
import { TaskSelectors as Selectors } from '../state/tasks/task.selectors';
import { Task, TaskQuestionGroup } from '../types/task';
import { TaskQuestionGroupsComponent } from './task-question-groups.component';
import { TasksListComponent } from './tasks-list/tasks-list.component';

@Component({
  templateUrl: './tasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AngularCommonModule,
    TasksListComponent,
    TaskQuestionGroupsComponent,
    PanelLayoutWrapperComponent,
    QuestionGroupDetailsComponent,
    RouterModule,
    TranslateModule
  ],
  host: { style: 'flex: 1 1 0; min-width: 0;' }
})
export class TasksComponent implements OnInit {
  public tasks$: Observable<RemoteData<Task[]>>;
  public taskQuestionGroups$: Observable<RemoteData<TaskQuestionGroup[]>>;
  public selectedTaskQuestionGroupIds: Observable<
    TaskQuestionGroup['questionGroup']['id'][]
  >;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.tasks$ = this.store.select(Selectors.tasks);
    this.taskQuestionGroups$ = this.store.select(Selectors.taskQuestionGroups);
    this.selectedTaskQuestionGroupIds = this.store.select(
      Selectors.selectedTaskQuestionGroupIds
    );
  }
}
