import * as Types from '../../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteTasksMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  taskIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type DeleteTasksMutation = { __typename?: 'Mutation', deleteTasks?: { __typename?: 'DeleteTasksPayload', successful: boolean } | null };

export const DeleteTasksDocument = gql`
    mutation DeleteTasks($poolId: ID!, $taskIds: [ID!] = []) {
  deleteTasks(poolId: $poolId, taskIds: $taskIds) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTasksGQL extends Apollo.Mutation<DeleteTasksMutation, DeleteTasksMutationVariables> {
    document = DeleteTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }