import { Injectable } from '@angular/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import type { MonoTypeOperatorFunction } from 'rxjs';
import { Observable, defer, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mapTo,
  retryWhen,
  switchMap,
  takeWhile,
  tap
} from 'rxjs/operators';
import {
  PreviewGenerationStatus,
  QuestionGroupPreview,
  QuestionGroupStatistics,
  Scalars
} from 'src/generated/base-types';
import { takeGraphQLResult } from '../../common/operators/take-graphql-response';
import { parseError } from '../../common/utils/error-parser';
import {
  RemoteData,
  RemoteListData,
  RequestState
} from '../../common/utils/remote-data';
import {
  deleteListItem,
  patchListItem
} from '../../common/utils/state-operators/remote-list-data';
import { assertIsDefined } from '../../common/utils/type-guards/is-defined';
import { ContextState } from '../../state/context/context.state';
import { DeleteCommentGQL } from '../services/delete-comment.generated';
import {
  QuestionGroupMessageFragment,
  QuestionGroupMessagesGQL
} from '../services/question-group-messages.generated';
import { QuestionGroupPreviewGQL } from '../services/question-group-preview.generated';
import {
  QuestionGroupStatisticsFragment,
  QuestionGroupStatisticsGQL
} from '../services/question-group-statistics.generated';
import { UpdateCommentReadStatusGQL } from '../services/update-comment-read-status.generated';
import {
  DeleteComment,
  DeleteCommentFailure,
  DeleteCommentSuccess,
  LoadQuestionGroupMessages,
  LoadQuestionGroupMessagesFailure,
  LoadQuestionGroupMessagesSuccess,
  LoadQuestionGroupPreview,
  LoadQuestionGroupPreviewFailure,
  LoadQuestionGroupPreviewSuccess,
  LoadQuestionGroupStatistics,
  LoadQuestionGroupStatisticsFailure,
  LoadQuestionGroupStatisticsSuccess,
  ReloadAllQuestionDetails,
  SetDetailsWidth,
  ToggleDetailsPanel,
  UpdateCommentReadState,
  UpdateCommentReadStateFailure,
  UpdateCommentReadStateSuccess,
  UpdateTabSelection
} from './question-details.actions';

// webpack has a loading issue when it is in component
// that's why it is here
export enum QuestionDetailsTabs {
  Preview = 'preview',
  Comments = 'comments',
  Statistics = 'statistics'
}

export interface QuestionDetailsStateModel {
  questionGroupIdToLoad: Scalars['ID'] | undefined;
  preview: RemoteData<QuestionGroupPreview>;
  messages: RemoteListData<QuestionGroupMessageFragment>;
  statistics: RemoteData<QuestionGroupStatisticsFragment[]>;
  activeTab: QuestionDetailsTabs;
  hidden: boolean;
  width: number;
}

/*
 * -------------------------------
 * CUSTOM OPERATORS FOR POLLING
 * -------------------------------
 */
function isPreviewGenerationInProgress(
  action: LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure
): boolean {
  return (
    action instanceof LoadQuestionGroupPreviewSuccess &&
    action.preview.status === PreviewGenerationStatus.InProgress
  );
}

function ignoreNonUpToDateQuestionGroups<T>(
  action: LoadQuestionGroupPreview,
  ctx: StateContext<QuestionDetailsStateModel>
): MonoTypeOperatorFunction<T> {
  return takeWhile<T>(
    // eslint-disable-next-line rxjs/no-ignored-takewhile-value
    _value => action.questionGroupId === ctx.getState().questionGroupIdToLoad
  );
}

function retryIfPreviewGenerationIsInProgress(): [
  MonoTypeOperatorFunction<
    LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure
  >,
  MonoTypeOperatorFunction<
    LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure
  >
] {
  return [
    tap<LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure>(
      action => {
        if (isPreviewGenerationInProgress(action)) {
          throw new PreviewGenerationInProgressError();
        }
      }
    ),
    retryWhen<
      LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure
    >(errors =>
      errors.pipe(
        filter(error => error instanceof PreviewGenerationInProgressError),
        delay(2000)
      )
    )
  ];
}

class PreviewGenerationInProgressError extends Error {}

/*
 * -------------------------------
 * STATE
 * -------------------------------
 */

@State<QuestionDetailsStateModel>({
  name: 'details',
  defaults: {
    questionGroupIdToLoad: undefined,
    preview: {
      requestState: 'initial'
    },
    messages: {
      requestState: 'initial',
      list: []
    },
    statistics: {
      requestState: 'initial'
    },
    activeTab: QuestionDetailsTabs.Preview,
    hidden: false,
    width: 500
  }
})
@Injectable()
export class QuestionDetailsState {
  constructor(
    private readonly questionGroupPreviewGQL: QuestionGroupPreviewGQL,
    private readonly questionGroupMessagesGQL: QuestionGroupMessagesGQL,
    private readonly updateCommentReadStateGQL: UpdateCommentReadStatusGQL,
    private readonly deleteCommentGQL: DeleteCommentGQL,
    private readonly questionGroupStatisticsGQL: QuestionGroupStatisticsGQL,
    private readonly store: Store
  ) {}

  @Selector()
  public static isLoading(state: QuestionDetailsStateModel): boolean {
    return (
      state.preview.requestState === 'loading' ||
      state.messages.requestState === 'loading' ||
      state.statistics.requestState === 'loading'
    );
  }

  @Selector()
  public static statisticsLoadingState(
    state: QuestionDetailsStateModel
  ): RequestState {
    return state.statistics.requestState;
  }

  @Selector()
  public static statistics(
    state: QuestionDetailsStateModel
  ): QuestionGroupStatisticsFragment[] {
    return state.statistics.data || [];
  }

  @Selector()
  public static previewLoadingState(
    state: QuestionDetailsStateModel
  ): RequestState {
    return state.preview.requestState;
  }

  @Selector()
  public static preview(
    state: QuestionDetailsStateModel
  ): QuestionGroupPreview | undefined {
    return state.preview.data;
  }

  @Selector()
  public static messagesLoadingState(
    state: QuestionDetailsStateModel
  ): RequestState {
    return state.messages.requestState;
  }

  @Selector()
  public static messages(
    state: QuestionDetailsStateModel
  ): QuestionGroupMessageFragment[] {
    return state.messages.list.map(item => item.data);
  }

  @Selector()
  public static activeTab(
    state: QuestionDetailsStateModel
  ): QuestionDetailsTabs {
    return state.activeTab;
  }

  @Selector()
  public static hidden(state: QuestionDetailsStateModel): boolean {
    return state.hidden;
  }

  @Selector()
  public static width(state: QuestionDetailsStateModel): number {
    return state.width;
  }

  @Action(LoadQuestionGroupPreview)
  public loadQuestionGroupPreview(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupPreview
  ): Observable<void> {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);

    assertIsDefined(
      poolId,
      'Cannot load question group preview without a pool id'
    );

    ctx.patchState({
      questionGroupIdToLoad: action.questionGroupId,
      preview: { requestState: 'loading', data: undefined }
    });

    return defer(() => {
      return this.questionGroupPreviewGQL.fetch({
        poolId: poolId,
        questionGroupId: action.questionGroupId
      });
    }).pipe(
      ignoreNonUpToDateQuestionGroups(action, ctx),
      takeGraphQLResult(),
      map(
        ({ pool }) =>
          // NOTE: questionGroup should never be undefined because the takeGraphQLResult operator throws an error if there is an error in the response
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          new LoadQuestionGroupPreviewSuccess(pool.questionGroup!.preview)
      ),
      catchError(() => of(new LoadQuestionGroupPreviewFailure())),
      tap(action => ctx.dispatch(action)),
      ...retryIfPreviewGenerationIsInProgress(),
      mapTo(void 0)
    );
  }

  @Action(LoadQuestionGroupPreviewSuccess)
  public loadQuestionGroupPreviewSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupPreviewSuccess
  ): void {
    ctx.patchState({
      preview: {
        requestState: 'success',
        data: action.preview
      }
    });
  }

  @Action(LoadQuestionGroupPreviewFailure)
  public loadQuestionGroupPreviewFailure(
    ctx: StateContext<QuestionDetailsStateModel>
  ): void {
    ctx.patchState({
      preview: {
        requestState: 'failure',
        data: undefined
      }
    });
  }

  @Action(LoadQuestionGroupMessages)
  public loadQuestionGroupMessages(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupMessages
  ): Observable<void> {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);

    assertIsDefined(
      poolId,
      'Cannot load question group preview without a pool id'
    );

    ctx.patchState({
      questionGroupIdToLoad: action.questionGroupId,
      messages: { requestState: 'loading', list: [] }
    });

    return this.questionGroupMessagesGQL
      .fetch({
        poolId: poolId,
        questionGroupId: action.questionGroupId
      })
      .pipe(
        takeGraphQLResult(),
        switchMap(({ pool }) =>
          ctx.dispatch(
            new LoadQuestionGroupMessagesSuccess(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              pool.questionGroup!.messages
            )
          )
        ),
        catchError((error: unknown) =>
          ctx.dispatch(new LoadQuestionGroupMessagesFailure(parseError(error)))
        )
      );
  }

  @Action(LoadQuestionGroupMessagesSuccess)
  public loadQuestionGroupMessagesSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupMessagesSuccess
  ): void {
    ctx.patchState({
      messages: {
        requestState: 'success',
        list: action.messages.map(message => {
          return {
            requestState: 'initial',
            data: message
          };
        })
      }
    });
  }

  @Action(LoadQuestionGroupStatistics)
  public loadQuestionGroupStatistics(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupStatistics
  ): Observable<void> {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);

    assertIsDefined(
      poolId,
      'Cannot load question group preview without a pool id'
    );

    ctx.patchState({
      questionGroupIdToLoad: action.questionGroupId,
      statistics: { requestState: 'loading', data: undefined }
    });

    return defer(() => {
      return this.questionGroupStatisticsGQL.fetch({
        poolId: poolId,
        questionGroupId: action.questionGroupId
      });
    }).pipe(
      takeGraphQLResult(),
      switchMap(({ pool }) =>
        ctx.dispatch(
          new LoadQuestionGroupStatisticsSuccess(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pool.questionGroup!.statistics as QuestionGroupStatistics[]
          )
        )
      ),
      catchError((error: unknown) =>
        ctx.dispatch(new LoadQuestionGroupStatisticsFailure(parseError(error)))
      )
    );
  }

  @Action(LoadQuestionGroupStatisticsSuccess)
  public loadQuestionGroupStatisticsSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupStatisticsSuccess
  ): void {
    ctx.patchState({
      statistics: {
        requestState: 'success',
        data: action.statistics
      }
    });
  }

  @Action(LoadQuestionGroupMessagesFailure)
  public loadQuestionGroupMessagesFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupMessagesFailure
  ): void {
    ctx.patchState({
      messages: {
        requestState: 'failure',
        error: action.error,
        list: []
      }
    });
  }

  @Action(UpdateCommentReadState)
  public updateCommentReadState(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: UpdateCommentReadState
  ): Observable<void> {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId);

    ctx.setState(
      patch({
        messages: patchListItem<QuestionGroupMessageFragment>(
          item => item.id === action.commentId && item.__typename === 'Comment',
          'loading',
          { read: action.read }
        )
      })
    );

    return this.updateCommentReadStateGQL
      .mutate({
        poolId,
        commentId: action.commentId,
        attributes: {
          read: action.read
        }
      })
      .pipe(
        takeGraphQLResult(),
        switchMap(() =>
          ctx.dispatch(
            new UpdateCommentReadStateSuccess(action.commentId, action.read)
          )
        ),
        catchError((error: unknown) =>
          ctx.dispatch(
            new UpdateCommentReadStateFailure(
              action.commentId,
              parseError(error)
            )
          )
        )
      );
  }

  @Action(UpdateCommentReadStateSuccess)
  public updateCommentReadStateSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: UpdateCommentReadStateSuccess
  ): void {
    ctx.setState(
      patch({
        messages: patchListItem<QuestionGroupMessageFragment>(
          item => item.id === action.commentId && item.__typename === 'Comment',
          'success',
          {
            read: action.read
          }
        )
      })
    );
  }

  @Action(UpdateCommentReadStateFailure)
  public updateCommentReadStateFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: UpdateCommentReadStateFailure
  ): void {
    ctx.setState(
      patch({
        messages: patchListItem<QuestionGroupMessageFragment>(
          item => item.id === action.commentId && item.__typename === 'Comment',
          'failure',
          {},
          action.error
        )
      })
    );
  }

  @Action(DeleteComment)
  public deleteComment(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: DeleteComment
  ): Observable<void> {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId);

    ctx.setState(
      patch({
        messages: patchListItem<QuestionGroupMessageFragment>(
          item => item.id === action.commentId && item.__typename === 'Comment',
          'loading',
          {}
        )
      })
    );

    return this.deleteCommentGQL
      .mutate({ poolId, commentId: action.commentId })
      .pipe(
        takeGraphQLResult(),
        switchMap(() =>
          ctx.dispatch(new DeleteCommentSuccess(action.commentId))
        ),
        catchError((error: unknown) =>
          ctx.dispatch(
            new DeleteCommentFailure(action.commentId, parseError(error))
          )
        )
      );
  }

  @Action(DeleteCommentSuccess)
  public deleteCommentSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: DeleteCommentSuccess
  ): void {
    ctx.setState(
      patch({
        messages: deleteListItem<QuestionGroupMessageFragment>(
          item => item.id === action.commentId && item.__typename === 'Comment'
        )
      })
    );
  }

  @Action(DeleteCommentFailure)
  public deleteCommentFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: DeleteCommentFailure
  ): void {
    ctx.setState(
      patch({
        messages: patchListItem<QuestionGroupMessageFragment>(
          item => item.id === action.commentId && item.__typename === 'Comment',
          'failure',
          {},
          action.error
        )
      })
    );
  }

  @Action(LoadQuestionGroupStatisticsFailure)
  public loadQuestionGroupStatisticsFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupStatisticsFailure
  ): void {
    ctx.patchState({
      statistics: {
        requestState: 'failure',
        error: action.error,
        data: undefined
      }
    });
  }

  @Action(UpdateTabSelection)
  public updateTabSelection(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: UpdateTabSelection
  ): void {
    ctx.setState(
      patch({
        activeTab: action.activeTab
      })
    );
  }

  @Action(ReloadAllQuestionDetails)
  public reloadAllQuestionDetails(
    ctx: StateContext<QuestionDetailsStateModel>,
    _action: ReloadAllQuestionDetails
  ): void {
    const questionGroupIdToLoad = ctx.getState().questionGroupIdToLoad;

    assertIsDefined(questionGroupIdToLoad);

    ctx.dispatch(new LoadQuestionGroupMessages(questionGroupIdToLoad));
    ctx.dispatch(new LoadQuestionGroupPreview(questionGroupIdToLoad));
    ctx.dispatch(new LoadQuestionGroupStatistics(questionGroupIdToLoad));
  }

  @Action(ToggleDetailsPanel)
  public toggleDetailsPanel(
    ctx: StateContext<QuestionDetailsStateModel>
  ): void {
    ctx.patchState({
      hidden: !ctx.getState().hidden
    });
  }

  @Action(SetDetailsWidth)
  public setDetailsWidth(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: SetDetailsWidth
  ): void {
    ctx.patchState({
      width: action.width
    });
  }
}
