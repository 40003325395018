import { isVoid } from '@common/utils/type-guards/voidable';
import { ContentValidationStatus, ListMetadata } from '@generated/base-types';
import { ColumnConfig, QuestionColumnConfig } from '@state/settings';
import {
  distinctUntilChanged,
  map,
  Observable,
  skip,
  type OperatorFunction
} from 'rxjs';
import { QuestionGroupListElementFragment } from 'src/app/question-form/services/load-question-group-list.generated';
import { type QuestionFilters } from '../../state/question-filter/question-filter.state.model';

export const MAX_SORTING_ATTRIBUTES = 3; // limit sorting attributes used in GraphQL request
export const DEFAULT_COLUMNS_COUNT = 1; // last info column is always present
export const STORAGE_KEY = 'question-group-list';

export const countVisibleColumns = (
  columnConfig: QuestionColumnConfig
): number => {
  const columns = Object.values(columnConfig) as ColumnConfig[];

  return (
    columns.filter(column => column.enabled && column.visible).length +
    DEFAULT_COLUMNS_COUNT
  );
};

const isUnfiltered = (metadata: ListMetadata): boolean => {
  return metadata.totalNumberOfItems === metadata.filteredNumberOfItems;
};

export const countTotalRecords: OperatorFunction<ListMetadata, number> = (
  source: Observable<ListMetadata>
): Observable<number> => {
  return source.pipe(
    map(metadata =>
      isUnfiltered(metadata)
        ? metadata.totalNumberOfItems
        : metadata.filteredNumberOfItems
    )
  );
};

export const pagePositionSummary: OperatorFunction<ListMetadata, string> = (
  source: Observable<ListMetadata>
): Observable<string> => {
  return source.pipe(
    map(metadata =>
      isUnfiltered(metadata)
        ? 'question_management.question_list.footer.unfiltered'
        : 'question_management.question_list.footer.filtered'
    )
  );
};

export const i18nQGKey = (partialKey: string): string => {
  return `activerecord.attributes.question_group.${partialKey}`;
};

export const i18nColKey = (partialKey: string): string => {
  return `common.question_list.columns.${partialKey}`;
};

type SeriesOrSequenceQuestionGroup = QuestionGroupListElementFragment & {
  __typename: 'QuestionSeries' | 'QuestionSequence';
};
export const isSeriesOrSequence = (
  qg: SeriesOrSequenceQuestionGroup
): boolean => {
  return qg.type === 'SERIES' || (qg.type === 'SEQUENCE' && !!qg.questions);
};

type SingleQuestionGroup = QuestionGroupListElementFragment & {
  __typename: 'SingleQuestion';
};
export const isSingle = (qg: SingleQuestionGroup): boolean => {
  return qg.type === 'SINGLE' && !!qg.question;
};

export const validationIcon = (status: ContentValidationStatus): string => {
  return {
    COMPLETE: 'fa fa-check skin-message--success',
    INCOMPLETE: 'fa fa-exclamation skin-message--warning',
    MISSING: 'fa fa-ban skin-message--alert',
    UNDEFINED: 'fa fa-ban skin-message--alert'
  }[status];
};

export const validationText = (status: ContentValidationStatus): string => {
  const baseKey = 'choices.question_groups.content_validation_state.';
  const statusKey = status === 'UNDEFINED' ? 'missing' : status.toLowerCase();

  return `${baseKey}${statusKey}`;
};

export const isColumnVisible = (column: ColumnConfig | undefined): boolean => {
  if (isVoid(column)) return false;

  return column.enabled && column.visible;
};

export type PagingData = { first: number; rows: number; scrollTop: number };

export const writePagingDataToSessionStorage = (
  poolId: string,
  pagingData: Partial<PagingData>
): void => {
  const key = `pool-${poolId}.${STORAGE_KEY}`;

  const currentData = readPagingDataFromSessionStorage(poolId) || {};
  const updatedData = { ...currentData, ...pagingData };

  const payload = JSON.stringify(updatedData);
  sessionStorage.setItem(key, payload);
};

export const readPagingDataFromSessionStorage = (
  poolId: string
): PagingData | undefined => {
  const key = `pool-${poolId}.${STORAGE_KEY}`;
  const payload = sessionStorage.getItem(key);
  if (payload === null) return undefined;

  return JSON.parse(payload);
};

export const resetFirstValueOnFilterChange = (): OperatorFunction<
  QuestionFilters,
  number
> => {
  return source =>
    source.pipe(
      skip(1),
      map(({ hidden, ...rest }) => rest),
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      ),
      map(() => 0)
    );
};
