/* eslint-disable unicorn/no-null */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LanguageVisibility } from '@state/settings';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Affiliation,
  Language,
  QuestionGroupInput,
  QuestionGroupType,
  QuestionInput
} from 'src/generated/base-types';
import { FormDimension } from '../form-types';
import { ValidationsQuestionGroupGraphQL } from '../services/graphql-question-group-types';

@Component({
  selector: 'qf-question-group-form',
  templateUrl: './question-group-form.component.html',
  styleUrls: ['./question-group-form.component.scss']
})
export class QuestionGroupFormComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  // WORKAROUND DUE TO CKEDIOR INITLIZING. AngularJS COMPONETNS WILL BE REFACTORERED ANYWAY
  // IT MAY BE A RACE CONDITION - WORST CASE THERE IS A CHANGE CONFIRMATION IF NO CHANGES HAS
  // BEEN PERFORMED - NO LOSS OF INFORMATION
  public static CHANGE_DETECTION_TIMEOUT = 850;

  @Input()
  public set questionGroup(questionGroupInput: QuestionGroupInput) {
    this.questionGroupInput = questionGroupInput;
  }
  @Input() public questionGroupType: QuestionGroupType;
  @Input() public affiliations: Affiliation[];
  @Input() public supervisors: string[];
  @Input() public authors: string[];
  @Input() public languages: Language[];
  @Input() public sequentialNumber: number;
  @Input() public languageVisibility: LanguageVisibility;
  @Input() public dimensions: FormDimension[];
  @Input() public validations: ValidationsQuestionGroupGraphQL;

  @Output() public questionGroupChanged =
    new EventEmitter<QuestionGroupInput>();
  @Output() public languageVisibilityChanged = new EventEmitter<{
    language: Language;
    visible: boolean;
  }>();
  @Output() public changed = new EventEmitter<void>();

  public questions$ = new BehaviorSubject<QuestionInput[]>([]);

  public sourceLanguage: Language;

  public form: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  private questionGroupInput: QuestionGroupInput;

  constructor(private builder: UntypedFormBuilder) {}

  public ngOnInit(): void {
    this.form = this.builder.group({
      metadata: {},
      questions: []
    });

    this.form.setValue({
      metadata: this.questionGroupInput,
      questions: this.questionGroupInput.questions
    });

    this.questions$.next([...(this.questionGroupInput?.questions || [])]);

    this.sourceLanguage = this.questionGroupInput.sourceLanguage as Language;

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.questions$.next([...changes.questions]);
      this.questionGroupChanged.next({
        ...changes.metadata,
        questions: changes.questions
      });
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.changed.emit();
      });
    }, QuestionGroupFormComponent.CHANGE_DETECTION_TIMEOUT);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public languageVisibilityChange(language: Language, visible: boolean): void {
    this.languageVisibilityChanged.emit({ language, visible });
  }
}
