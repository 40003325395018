<!-- TODO: change anchor tag to button when ng-bootsrap is updated to 14.1.0 see: https://github.com/ng-bootstrap/ng-bootstrap/releases/tag/14.1.0 -->
<div
  *ngIf="questionGroupId.length === 0"
  class="h-100 d-flex flex-column border rounded"
>
  <nav class="d-flex justify-content-between align-items-center error-header">
    <div>
      <span>
        <i class="fa-regular fa-triangle-exclamation"></i>
      </span>
    </div>
    <button
      class="border-0 bg-transparent panel-toggle-btn"
      aria-label="toggle-button"
      (click)="onHidePanel()"
    >
      <i class="fa-regular fa-sidebar-flip"></i>
    </button>
  </nav>
  <details-info
    message="common.question_group_details.no_question_group"
  ></details-info>
</div>

<div
  *ngIf="questionGroupId.length > 1"
  class="h-100 d-flex flex-column border rounded"
>
  <nav class="d-flex justify-content-between align-items-center error-header">
    <div>
      <span>
        <i class="fa-regular fa-triangle-exclamation"></i>
      </span>
    </div>
    <button
      class="border-0 bg-transparent panel-toggle-btn"
      aria-label="toggle-button"
      (click)="onHidePanel()"
    >
      <i class="fa-regular fa-sidebar-flip"></i>
    </button>
  </nav>
  <details-info
    message="pool.question_group_details.not_allowed_multiple"
    [isExclamationMsg]="true"
  ></details-info>
</div>

<div
  *ngIf="questionGroupId.length === 1"
  class="d-flex flex-column border rounded details-container"
>
  <div class="position-sticky top-0 sticky-header">
    <div class="details-header d-flex justify-content-between">
      <nav
        ngbNav
        #nav="ngbNav"
        [activeId]="activeTab$ | async"
        (navChange)="onTabSelectionChanged($event)"
        class="d-flex justify-content-between align-items-center"
      >
        <ng-container [ngbNavItem]="questionDetailsTabs.Preview">
          <a ngbNavLink>
            <span>
              <i class="fa-regular fa-file-invoice"></i>
            </span>
          </a>
          <ng-container *ngIf="previewLoadingState$ | async as previewStatus">
            <ng-template ngbNavContent>
              <loading-details
                *ngIf="previewStatus === 'loading'"
              ></loading-details>
              <question-group-preview
                *ngIf="
                  previewStatus !== 'loading' && (preview$ | async) as preview
                "
                [status]="preview.status"
                [url]="preview.url"
              ></question-group-preview>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container [ngbNavItem]="questionDetailsTabs.Comments">
          <a ngbNavLink>
            <span>
              <i class="fa-regular fa-messages"></i>
            </span>
          </a>
          <ng-container *ngIf="messagesLoadingState$ | async as messagesStatus">
            <ng-template ngbNavContent>
              <loading-details
                *ngIf="messagesStatus === 'loading'"
              ></loading-details>
              <question-group-messages
                *ngIf="
                  messagesStatus !== 'loading' &&
                  (messages$ | async) as messages
                "
                [messages]="messages"
                [showReadMessages]="showReadMessages$ | async : true"
                [showSystemMessages]="showSystemMessages$ | async : true"
                (commentReadStateChanged)="onCommentReadStateChanged($event)"
                (showReadMessageChanged)="onShowReadMessageChanged($event)"
                (showSystemMessageChanged)="onShowSystemMessageChanged($event)"
                (commentDeleted)="onCommentDeleted($event)"
              ></question-group-messages>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container [ngbNavItem]="questionDetailsTabs.Statistics">
          <a ngbNavLink>
            <span>
              <i class="fa-regular fa-chart-pie"></i>
            </span>
          </a>
          <ng-container
            *ngIf="statisticsLoadingState$ | async as statisticsStatus"
          >
            <ng-template ngbNavContent>
              <loading-details
                *ngIf="statisticsStatus === 'loading'"
              ></loading-details>
              <question-group-statistics
                *ngIf="
                  statisticsStatus !== 'loading' &&
                  (statistics$ | async) as statistics
                "
                [statistics]="statistics"
              >
              </question-group-statistics>
            </ng-template>
          </ng-container>
        </ng-container>
      </nav>
      <button
        class="border-0 bg-transparent panel-toggle-btn"
        aria-label="toggle-button"
        (click)="onHidePanel()"
      >
        <i class="fa-regular fa-sidebar-flip"></i>
      </button>
    </div>
  </div>
  <div [ngbNavOutlet]="nav"></div>
</div>
