import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadTasks } from '../../state/tasks/tasks.actions';

@Injectable({ providedIn: 'root' })
export class TasksGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch(new LoadTasks());

    return true;
  }
}
