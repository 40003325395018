import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import Honeybadger from 'honeybadger-js';
import { filter } from 'rxjs/operators';
import { ContextState } from '../../state/context/context.state';
import { isDefined } from '../utils/type-guards/is-defined';

// loads together with the app
// listens user changes in store and sets the user in Honeybadger
@Injectable({ providedIn: 'root' })
export class HoneybadgerHandler {
  constructor(private store: Store) {
    this.store
      .select(ContextState.currentUser)
      .pipe(filter(isDefined))
      .subscribe(user => {
        // eslint-disable-next-line import/namespace
        Honeybadger.setContext({
          userId: user.id,
          userEmail: user.email
        });
      });
  }
}
