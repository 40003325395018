<ng-container *ngIf="roles$ | async as roles">
  <p-dropdown
    [placeholder]="'common.messages.please_select' | translate"
    [options]="roles"
    [(ngModel)]="value"
    (ngModelChange)="onRoleChange($event)"
    [showClear]="true"
    [optionDisabled]="'exists'"
  >
    <ng-template let-role pTemplate="item" disabled="true">
      <div class="role-item">
        <div class="label" [title]="role.label">
          {{ role.label }}
        </div>
        <div
          *ngIf="role.exists"
          class="availability"
          [title]="'admin.pools.permissions.exists' | translate"
        >
          {{ 'admin.pools.permissions.exists' | translate }}
        </div>
      </div>
    </ng-template>
  </p-dropdown>
</ng-container>
