import { ManagerError } from '@common/utils/error-parser';
import { Language, Scalars } from '@generated/base-types';
import { CurrentUserFragment } from '../../services/current-user.generated';

export class SetCurrentPool {
  public static readonly type = '[Context] Set Current Pool';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly languages: Language[]
  ) {}
}

export class SetCurrentLanguage {
  public static readonly type = '[Context] Set Current Language';
  constructor(public readonly language: Language) {}
}

export class LoadCurrentUser {
  public static readonly type = '[Context] Load Current User';
}

export class LoadCurrentUserSuccess {
  public static readonly type = '[Context] Load Current User Success';
  constructor(public readonly currentUser: CurrentUserFragment) {}
}

export class LoadCurrentUserFailure {
  public static readonly type = '[Context] Load Current User Failure';
  constructor(public readonly error: ManagerError) {}
}
