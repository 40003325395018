import { ManagerError } from '@common/utils/error-parser';
import { MoveDirection, Scalars } from '@generated/base-types';
import { AffiliationListElementFragment } from '../../../services/load-affiliations.generated';

export class LoadAffiliations {
  public static readonly type = '[Affiliations] Load';
}

export class LoadAffiliationsSuccess {
  public static readonly type = '[Affiliations] Load Success';
  constructor(public readonly affiliations: AffiliationListElementFragment[]) {}
}

export class LoadAffiliationsFailure {
  public static readonly type = '[Affiliations] Load Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateAffiliation {
  public static readonly type = '[Affiliations] Create';
  constructor(public readonly name: string) {}
}

export class CreateAffiliationSuccess {
  public static readonly type = '[Affiliations] Create Success';
  constructor(public readonly affiliation: AffiliationListElementFragment) {}
}

export class CreateAffiliationFailure {
  public static readonly type = '[Affiliations] Create Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateAffiliation {
  public static readonly type = '[Affiliations] Update';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly name: string
  ) {}
}

export class UpdateAffiliationSuccess {
  public static readonly type = '[Affiliations] Update Success';
  constructor(public readonly affiliation: AffiliationListElementFragment) {}
}

export class UpdateAffiliationFailure {
  public static readonly type = '[Affiliations] Update Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteAffiliation {
  public static readonly type = '[Affiliation] Delete';
  constructor(public readonly id: string) {}
}

export class DeleteAffiliationSuccess {
  public static readonly type = '[Affiliation] Delete Success';
  constructor(public readonly id: string) {}
}

export class DeleteAffiliationFailure {
  public static readonly type = '[Affiliation] Delete Failure';
  constructor(public readonly error: ManagerError) {}
}

export class SetAffiliationPosition {
  public static readonly type = '[Affiliation] Set Affiliation Position';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly direction: MoveDirection,
    public readonly times?: number
  ) {}
}

export class SetAffiliationPositionSuccess {
  public static readonly type =
    '[Affiliation] Set Affiliation Position Success';
  constructor(
    public readonly affiliation: AffiliationListElementFragment,
    public readonly affiliations: AffiliationListElementFragment[]
  ) {}
}

export class SetAffiliationPositionFailure {
  public static readonly type =
    '[Affiliation] Set Affiliation Position Failure';
  constructor(public readonly error: ManagerError) {}
}
