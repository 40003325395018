import * as Types from '../../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteStatisticsMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type DeleteStatisticsMutation = { __typename?: 'Mutation', deleteStatistics?: { __typename?: 'DeleteStatisticsPayload', successful: boolean } | null };

export const DeleteStatisticsDocument = gql`
    mutation DeleteStatistics($poolId: ID!, $questionGroupIds: [ID!]!) {
  deleteStatistics(poolId: $poolId, questionGroupIds: $questionGroupIds) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteStatisticsGQL extends Apollo.Mutation<DeleteStatisticsMutation, DeleteStatisticsMutationVariables> {
    document = DeleteStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }