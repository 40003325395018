import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Scalars } from '../../../../generated/base-types';
import {
  ExportConfig,
  ExportFormats,
  ExportTypes
} from '../../../common/question-group-export/question-group-export.types';
import { isDefined } from '../../../common/utils/type-guards/is-defined';
import { ContextState } from '../../../state/context/context.state';
import { TaskQuestionGroupExporterService } from '../../services/task-question-group-exporter.service';
import { TaskListState } from '../../state/task-list.state';
import { TaskQuestionGroupExportGQL } from './task-question-group-export.generated';

@Component({
  selector: 'tm-question-group-export-modal',
  templateUrl: './task-question-group-export-modal.component.html',
  styleUrls: ['./task-question-group-export-modal.component.scss']
})
export class TaskQuestionGroupExportModalComponent implements OnInit {
  @Input()
  public modalInstance: {
    dismiss(): void;
    close(): void;
  };
  @Select(TaskListState.selectedTaskQuestionGroupIds)
  private selectedTaskQuestionGroupIds$: Observable<number[]>;

  @Select(TaskListState.loadedTaskQuestionGroupIds)
  private loadedTaskQuestionGroupIds$: Observable<number[]>;

  @Select(ContextState.currentPoolId)
  private currentPoolId$: Observable<Scalars['ID'] | undefined>;

  public selectedTaskQuestionGroupIds: number[];
  public taskQuestionGroupIdsIds: number[];
  public poolId: Scalars['ID'];
  public exportConfig: ExportConfig = {
    type: ExportTypes.All,
    format: ExportFormats.Excel
  };
  public isSubmitting = false;

  constructor(
    public taskQuestionGroupExportGQL: TaskQuestionGroupExportGQL,
    public taskQuestionGroupExporterService: TaskQuestionGroupExporterService
  ) {}

  public ngOnInit(): void {
    this.currentPoolId$.pipe(filter(isDefined), first()).subscribe(poolId => {
      this.poolId = poolId;
    });
    this.selectedTaskQuestionGroupIds$.subscribe(ids => {
      this.selectedTaskQuestionGroupIds = ids;
      this.exportConfig.type =
        ids.length > 0 ? ExportTypes.Selected : ExportTypes.All;
    });
    this.loadedTaskQuestionGroupIds$.subscribe(
      ids => (this.taskQuestionGroupIdsIds = ids)
    );
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public async generate(): Promise<void> {
    this.isSubmitting = true;

    const result = await firstValueFrom(
      this.taskQuestionGroupExportGQL.fetch({
        poolId: this.poolId.toString(),
        taskQuestionGroupIds:
          this.exportConfig.type === ExportTypes.All
            ? this.taskQuestionGroupIdsIds.map(id => id.toString())
            : this.selectedTaskQuestionGroupIds.map(id => id.toString())
      })
    );

    const taskQuestionGroups = result.data.pool.taskQuestionGroups;

    if (this.exportConfig.format === ExportFormats.Excel) {
      this.taskQuestionGroupExporterService.saveAsXlsx(taskQuestionGroups);
    } else {
      this.taskQuestionGroupExporterService.saveAsCsv(taskQuestionGroups);
    }

    this.modalInstance.close();
  }
}
