import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteSubjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSubjectMutation = { __typename?: 'Mutation', deleteSubject?: { __typename?: 'DeleteSubjectPayload', successful: boolean } | null };

export const DeleteSubjectDocument = gql`
    mutation DeleteSubject($id: ID!) {
  deleteSubject(id: $id) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSubjectGQL extends Apollo.Mutation<DeleteSubjectMutation, DeleteSubjectMutationVariables> {
    document = DeleteSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }