import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadPoolPermissions } from '../../../state/pool-details/permissions/permissions.actions';
import { LoadUsers } from '../../../state/users.actions';

@Injectable({ providedIn: 'root' })
export class PoolPermissionsGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch([new LoadPoolPermissions(), new LoadUsers()]);

    return true;
  }
}
