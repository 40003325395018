import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { PoolState } from '../../../state/pool/pool.state';
import { LabelValuePair } from '../../ng-select-wrapper/ng-select-wrapper.component';
import { SetSupervisorGQL } from './set-supervisor.generated';

@Component({
  selector: 'co-set-supervisor-modal',
  templateUrl: './set-supervisor-modal.component.html',
  styleUrls: ['./set-supervisor-modal.component.scss']
})
export class SetSupervisorModalComponent implements OnInit, OnDestroy {
  @Input()
  public modalInstance: NgbModalRef;
  @Input()
  public questionGroups: QuestionGroupListElementFragment[];
  @Select(PoolState.supervisors)
  public supervisors$: Observable<string[]>;
  @Input()
  public currentPoolId: Scalars['ID'];

  public isSaving = false;
  public supervisor = '';
  public supervisorOptions: LabelValuePair<string>[] = [];

  private destroy$ = new Subject<void>();

  constructor(private readonly setSupervisorGQL: SetSupervisorGQL) {}

  public ngOnInit(): void {
    this.supervisors$.pipe(takeUntil(this.destroy$)).subscribe(supervisors => {
      this.supervisorOptions = supervisors
        .filter(supervisor => supervisor?.length > 0)
        .map(supervisor => ({
          label: supervisor,
          value: supervisor
        }));

      if (this.supervisorOptions.length > 0) {
        this.supervisor = this.supervisorOptions[0].label; // preselect list element
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public addTag(value: string | null): { label: string; value: string } {
    return {
      label: value ?? '',
      value: value ?? ''
    };
  }

  public async save(): Promise<void> {
    this.isSaving = true;
    const result = await this.setSupervisorGQL
      .mutate({
        poolId: this.currentPoolId,
        questionGroupIds: this.questionGroups.map(qg => qg.id),
        user: this.supervisor
      })
      .toPromise();

    if (result?.data?.setSupervisor?.successful !== true) {
      throw `Unable to set supervisor for ${this.questionGroups.map(
        qg => qg.id
      )}`;
    }

    this.isSaving = false;
    this.modalInstance.close();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }
}
