import { Injectable } from '@angular/core';
import { takeGraphQLResult } from '@common/operators/take-graphql-response';
import { parseError } from '@common/utils/error-parser';
import { RemoteData } from '@common/utils/remote-data';
import { requestStateFailure } from '@common/utils/remote-data-utils';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ModalService } from '../../../common/modal/modal.service';
import {
  ExamDetailsFragment,
  LoadExamDetailsGQL
} from '../../services/exam/load-exam.generated';
import { LoadExam, LoadExamFailure, LoadExamSuccess } from './exam.actions';

export interface ExamStateModel {
  exam: RemoteData<ExamDetailsFragment>;
  mutation: RemoteData<void>;
}

@State<ExamStateModel>({
  name: 'newexam',
  defaults: {
    exam: { requestState: 'initial' },
    mutation: { requestState: 'initial' }
  }
})
@Injectable({
  providedIn: 'root'
})
export class ExamState {
  constructor(
    private readonly loadExamDetailsService: LoadExamDetailsGQL,
    private readonly modalService: ModalService
  ) {}

  @Selector()
  public static exam(state: ExamStateModel): RemoteData<ExamDetailsFragment> {
    return state.exam;
  }

  @Selector()
  public static examFailure(state: ExamStateModel): boolean {
    return requestStateFailure(state.exam);
  }

  @Action(LoadExam, { cancelUncompleted: true })
  public loadExam(
    ctx: StateContext<ExamStateModel>,
    { id }: LoadExam
  ): Observable<void> {
    ctx.patchState({ exam: { requestState: 'loading' } });

    return this.loadExamDetailsService.fetch({ examId: id }).pipe(
      takeGraphQLResult(),
      switchMap(res => ctx.dispatch(new LoadExamSuccess(res.exam))),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadExamFailure(parseError(err), id))
      )
    );
  }

  @Action(LoadExamSuccess)
  public loadExamSuccess(
    ctx: StateContext<ExamStateModel>,
    { exam }: LoadExamSuccess
  ): void {
    ctx.patchState({
      exam: {
        data: exam,
        requestState: 'success'
      }
    });
  }

  @Action(LoadExamFailure)
  public loadExamFailure(
    ctx: StateContext<ExamStateModel>,
    { error, id }: LoadExamFailure
  ): void {
    ctx.patchState({
      exam: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (!retry) return;

      ctx.dispatch(new LoadExam(id));
    });
  }
}
