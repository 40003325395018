import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import {
  TotalStatisticTypeAFragment,
  TotalStatisticTypeAMinusFragment
} from 'src/app/question-management/services/question-group-statistics.generated';
import { DetailsInfoComponent } from '../../details-info/details-info.component';

type Answer = 'A' | 'B' | 'C' | 'D' | 'E' | 'empty';

type Statistic = {
  name: Answer;
  p: number;
  solution: boolean | null;
};

const answerOptions = new Set(['A', 'B', 'C', 'D', 'E', 'empty']);

@Component({
  selector: 'type-a-statistics',
  templateUrl: './type-a-statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ManagerCommonModule, TableModule, DetailsInfoComponent],
  host: { style: 'margin: 0.5rem;' }
})
export class TypeAStatisticsComponent {
  @Input()
  public set statistics(
    value: TotalStatisticTypeAFragment | TotalStatisticTypeAMinusFragment
  ) {
    this.typeAStatistics = Object.entries(value)
      .filter(([key, value]) => answerOptions.has(key) && value !== undefined)
      .map(([key, value]) => {
        return { name: key, ...value };
      });
    this.validStatistics = this.typeAStatistics.some(
      stat => stat.name !== undefined
    );
  }

  public validStatistics = false;
  public typeAStatistics: Statistic[];
  public i18n = (s: string): string =>
    'pool.question_group_details.statistics.' + s;
}
