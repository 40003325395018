import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import { TotalStatisticTypeFreeTextFragment } from 'src/app/question-management/services/question-group-statistics.generated';
import { DetailsInfoComponent } from '../../details-info/details-info.component';

@Component({
  selector: 'type-free-text-statistics',
  templateUrl: './type-free-text-statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ManagerCommonModule, TableModule, DetailsInfoComponent],
  host: { style: 'margin: 0.5rem;' }
})
export class TypeFreeTextStatisticsComponent {
  @Input()
  public statistics: TotalStatisticTypeFreeTextFragment;
}
