<div class="modal-header">
  <h5 class="modal-title">{{ data.titleTranslationKey | translate }}</h5>
</div>
<div class="modal-body d-flex justify-content-between align-items-center">
  <label>{{ data.labelTranslationKey | translate }}</label>
  <div class="col-sm-8">
    <ng-select
      [items]="(data.supervisors | async) || []"
      [(ngModel)]="supervisor"
      [clearable]="false"
      [addTag]="true"
      addTagText="{{ 'common.actions.add' | translate }}"
    ></ng-select>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="onCancel()">
    {{ 'common.actions.cancel' | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()">
    {{ 'common.actions.save' | translate }}
  </button>
</div>
