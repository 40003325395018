import { Injectable } from '@angular/core';
import Fuse from 'fuse.js';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FuzzySearchService<T> {
  public search(
    values: T[],
    searchString: string,
    filterFields: string[],
    sortByField?: keyof T
  ): T[] {
    if (searchString.trim() === '') return values;

    const data = values.map(item => ({
      data: item,
      keywords: filterFields.reduce(
        (acc, key) => acc.concat(' ', get(item, key)),
        ''
      )
    }));

    const fuse = new Fuse(data, {
      keys: ['keywords'],
      threshold: 0.1,
      findAllMatches: true,
      ignoreLocation: true
    });

    const matches = fuse.search(searchString.trim());
    const results = matches.map(match => match.item.data);

    const sortingEnabled = sortByField !== undefined;

    const sortedResult = sortingEnabled
      ? results.sort((a, b) => {
          const aVal = get(a, sortByField);
          const bVal = get(b, sortByField);

          if (aVal < bVal) return -1;
          if (aVal > bVal) return 1;

          return 0;
        })
      : results;

    return sortedResult;
  }
}
