import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateRevisionStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  attributes: Types.RevisionStatusInput;
}>;


export type UpdateRevisionStatusMutation = { __typename?: 'Mutation', updateRevisionStatus?: { __typename?: 'UpdateRevisionStatusPayload', successful: boolean, revisionStatus?: { __typename?: 'RevisionStatus', id: string, description: string, shortName: string, position: number } | null, validations?: { __typename?: 'ValidationsRevisionStatus', description?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, shortName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const UpdateRevisionStatusDocument = gql`
    mutation UpdateRevisionStatus($id: ID!, $attributes: RevisionStatusInput!) {
  updateRevisionStatus(id: $id, attributes: $attributes) {
    revisionStatus {
      id
      description
      shortName
      position
    }
    successful
    validations {
      description {
        message
        severity
      }
      shortName {
        message
        severity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRevisionStatusGQL extends Apollo.Mutation<UpdateRevisionStatusMutation, UpdateRevisionStatusMutationVariables> {
    document = UpdateRevisionStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }