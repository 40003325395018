import { ManagerError } from 'src/app/common/utils/error-parser';
import { PoolPermissionsFragment } from 'src/app/new/admin/services/load-pool-permissions.fragments.generated';
import { PermissionInput, Scalars } from 'src/generated/base-types';

export class LoadPoolPermissions {
  public static readonly type = '[PoolPermissions] Load';
}

export class LoadPoolPermissionsSuccess {
  public static readonly type = '[PoolPermissions] Load Success';
  constructor(public readonly poolPermissions: PoolPermissionsFragment[]) {}
}

export class LoadPoolPermissionsFailure {
  public static readonly type = '[PoolPermissions] Load Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreatePoolPermission {
  public static readonly type = '[PoolPermissions] Create';
  constructor(public readonly attributes: PermissionInput) {}
}

export class CreatePoolPermissionSuccess {
  public static readonly type = '[PoolPermissions] Create Success';
  constructor(public readonly permission: PoolPermissionsFragment) {}
}

export class CreatePoolPermissionFailure {
  public static readonly type = '[PoolPermissions] Create Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdatePoolPermission {
  public static readonly type = '[PoolPermissions] Update';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly attributes: PermissionInput
  ) {}
}

export class UpdatePoolPermissionSuccess {
  public static readonly type = '[PoolPermissions] Update Success';
  constructor(public readonly permission: PoolPermissionsFragment) {}
}

export class UpdatePoolPermissionFailure {
  public static readonly type = '[PoolPermissions] Update Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeletePoolPermission {
  public static readonly type = '[PoolPermissions] Delete';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeletePoolPermissionSuccess {
  public static readonly type = '[PoolPermissions] Delete Success';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeletePoolPermissionFailure {
  public static readonly type = '[PoolPermissions] Delete Failure';
  constructor(public readonly error: ManagerError) {}
}
