import * as Types from '../../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateCommentReadStatusMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  commentId: Types.Scalars['ID'];
  attributes: Types.CommentInput;
}>;


export type UpdateCommentReadStatusMutation = { __typename?: 'Mutation', updateComment?: { __typename?: 'UpdateCommentPayload', successful: boolean, comment?: { __typename?: 'Comment', read: boolean } | null } | null };

export const UpdateCommentReadStatusDocument = gql`
    mutation UpdateCommentReadStatus($poolId: ID!, $commentId: ID!, $attributes: CommentInput!) {
  updateComment(poolId: $poolId, id: $commentId, attributes: $attributes) {
    successful
    comment {
      read
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCommentReadStatusGQL extends Apollo.Mutation<UpdateCommentReadStatusMutation, UpdateCommentReadStatusMutationVariables> {
    document = UpdateCommentReadStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }