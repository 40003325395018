import { RequestState } from '@common/utils/remote-data';
import { QuestionGroupPreview } from '@generated/base-types';
import { Selector } from '@ngxs/store';
import { QuestionGroupMessageFragment } from '../../services/question-details/question-group-messages.generated';
import { QuestionGroupStatisticsFragment } from '../../services/question-details/question-group-statistics.generated';
import {
  NewQuestionDetailsState,
  QuestionDetailsStateModel,
  QuestionDetailsTabs
} from './question-details.state';

export class QuestionDetailsSelectors {
  @Selector([NewQuestionDetailsState])
  public static statisticsLoadingState(
    state: QuestionDetailsStateModel
  ): RequestState {
    return state.statistics.requestState;
  }

  @Selector([NewQuestionDetailsState])
  public static statistics(
    state: QuestionDetailsStateModel
  ): QuestionGroupStatisticsFragment[] {
    return state.statistics.data || [];
  }

  @Selector([NewQuestionDetailsState])
  public static previewLoadingState(
    state: QuestionDetailsStateModel
  ): RequestState {
    return state.preview.requestState;
  }

  @Selector([NewQuestionDetailsState])
  public static preview(
    state: QuestionDetailsStateModel
  ): QuestionGroupPreview | undefined {
    return state.preview.data;
  }

  @Selector([NewQuestionDetailsState])
  public static messagesLoadingState(
    state: QuestionDetailsStateModel
  ): RequestState {
    return state.messages.requestState;
  }

  @Selector([NewQuestionDetailsState])
  public static messages(
    state: QuestionDetailsStateModel
  ): QuestionGroupMessageFragment[] {
    return state.messages.data || [];
  }

  @Selector([NewQuestionDetailsState])
  public static activeTab(
    state: QuestionDetailsStateModel
  ): QuestionDetailsTabs {
    return state.activeTab;
  }
}
