import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  iif,
  map,
  Observable,
  of,
  Subject,
  switchMap
} from 'rxjs';
import { FormState } from '../../../common/types/form-types';
import { RemoteData } from '../../../common/utils/remote-data';
import { requestStateSuccess } from '../../../common/utils/remote-data-utils';
import { ActionMenuOption } from '../../common/action-menu/action-menu.component';
import { FuzzySearchService } from '../../common/fuzzy-search.service';
import { ModalService } from '../../common/modal/modal.service';
import { LoadUsersFieldsFragment } from '../services/load-users.fragments.generated';
import { Reset } from '../state/user-form.actions';
import { UserFormState } from '../state/user-form.state';
import { DeleteUser } from '../state/users.actions';
import { UsersState } from '../state/users.state';

@Component({
  templateUrl: './users-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container content' }
})
export class UsersListComponent implements OnDestroy {
  public readonly filterFields: (keyof LoadUsersFieldsFragment)[] = [
    'lastName',
    'firstName',
    'organisation',
    'email'
  ];
  public sortBy = 'lastName';
  public readonly users$: Observable<RemoteData<LoadUsersFieldsFragment[]>>;
  public readonly formState$: Observable<FormState<LoadUsersFieldsFragment>>;
  public readonly selection$: Observable<LoadUsersFieldsFragment | undefined>;
  public readonly usersMenuOptions: ActionMenuOption[] = [
    {
      label: 'Edit',
      danger: false,
      callback: (data: LoadUsersFieldsFragment) => this.onEdit(data)
    },
    {
      label: 'Delete',
      danger: true,
      callback: (data: LoadUsersFieldsFragment) => this.onDelete(data)
    }
  ];

  public totalUserCount: string;
  public filteredUserCount: string;

  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private fuzzySearchService: FuzzySearchService<LoadUsersFieldsFragment>
  ) {
    this.users$ = combineLatest([
      this.store.select(UsersState.users),
      this.searchString$.pipe(debounceTime(300))
    ]).pipe(
      map(([users, searchString]) => {
        const result = this.fuzzySearchService.search(
          users.data || [],
          searchString,
          this.filterFields,
          'lastName'
        );

        this.totalUserCount = (
          users.data ? users.data.length : 0
        ).toLocaleString();
        this.filteredUserCount = result.length.toLocaleString();

        return { ...users, data: result };
      })
    );
    this.selection$ = this.store.select(UserFormState.user).pipe(
      map(({ data }) => data?.id),
      switchMap(id =>
        iif(
          () => id === undefined,
          // NOTE: return an empty object is a workaround because if a list element is clicked/selected
          // but then the data cannot be loaded, the list item is selected but the selection stream
          // has never emitted anything else than undefined so that the list item is not deselected
          of({} as LoadUsersFieldsFragment),
          this.store.select(UsersState.users).pipe(
            filter(requestStateSuccess),
            map(({ data }) => data?.find(user => user.id === id))
          )
        )
      )
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
  }

  public onSearchClear(): void {
    this.searchStringSource.next('');
  }

  public onCreate(): void {
    this.store.dispatch(new Reset());
    this.router.navigate(['create'], {
      relativeTo: this.route
    });
  }

  public onEdit(data: LoadUsersFieldsFragment): void {
    this.router.navigate([data.id], {
      relativeTo: this.route
    });
  }

  public async onDelete({ id }: LoadUsersFieldsFragment): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();

    if (confirmDelete) this.store.dispatch(new DeleteUser(id));
  }
}
