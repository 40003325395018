import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { PoolPermissionsFragmentDoc } from './load-pool-permissions.fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoadPoolPermissionsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type LoadPoolPermissionsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', permissions: Array<{ __typename?: 'Permission', id: string, role: Types.PermissionRole, user: { __typename?: 'User', id: string, email?: string | null, firstName: string, lastName: string }, subject?: { __typename?: 'Subject', id: string, name: string } | null, pool: { __typename?: 'Pool', id: string, name: string } }> } };

export const LoadPoolPermissionsDocument = gql`
    query LoadPoolPermissions($poolId: ID!) {
  pool(id: $poolId) {
    permissions {
      ...PoolPermissions
    }
  }
}
    ${PoolPermissionsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadPoolPermissionsGQL extends Apollo.Query<LoadPoolPermissionsQuery, LoadPoolPermissionsQueryVariables> {
    document = LoadPoolPermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }