import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { ValidationsMessageFieldsFragmentDoc } from '../../new/admin/services/create-update-user.fragments.generated';
export type ValidationsCurrentUserFieldsFragment = { __typename?: 'ValidationsCurrentUser', firstName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, lastName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, language?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, password?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null };

export const ValidationsCurrentUserFieldsFragmentDoc = gql`
    fragment ValidationsCurrentUserFields on ValidationsCurrentUser {
  firstName {
    ...ValidationsMessageFields
  }
  lastName {
    ...ValidationsMessageFields
  }
  language {
    ...ValidationsMessageFields
  }
  password {
    ...ValidationsMessageFields
  }
}
    ${ValidationsMessageFieldsFragmentDoc}`;