<div class="tasks-container">
  <div class="mb-2">
    <a
      [routerLink]="['../../']"
      [title]="'admin.navigation.pools' | translate"
      class="btn btn-light d-block d-md-inline-flex align-items-md-center gap-2"
    >
      <i class="fa-solid fa-sm fa-arrow-left" aria-hidden="true"></i>
      <span> {{ 'admin.navigation.pools' | translate }}</span></a
    >
  </div>
  <man-panel-layout-wrapper #wrapper pageName="tasks">
    <man-tasks-list
      leftPanel
      [tasks]="tasks$"
      (hideTaskPanel)="wrapper.togglePanel('left')"
    ></man-tasks-list>

    <man-task-question-groups
      middlePanel
      [taskQuestionGroups]="taskQuestionGroups$"
    ></man-task-question-groups>

    <man-question-group-details
      rightPanel
      *ngIf="
        selectedTaskQuestionGroupIds | async as selectedTaskQuestionGroupIds
      "
      [questionGroupId]="selectedTaskQuestionGroupIds"
      (hideDetailsPanel)="wrapper.togglePanel('right')"
    ></man-question-group-details>
  </man-panel-layout-wrapper>
</div>
