import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PoolsGuard } from '../../admin/pools/routing/pools.guard';
import { ExamEditComponent } from '../exams/exam-edit.component';
import { ImportExcelStatisticsComponent } from '../exams/import-excel-statistics/import-excel-statistics.component';
import { PoolListComponent } from '../pool-list.component';
import { PoolComponent } from '../pool.component';
import { PoolGuard } from '../pool.guard';
import { PoolsComponent } from '../pools.component';
import { TasksComponent } from '../tasks/tasks.component';
import { ExamGuard } from './exam/exam.guard';
import { TasksQuestionGroupsGuard } from './tasks/task-question-groups.guard';
import { TasksGuard } from './tasks/tasks.guard';

const routes: Routes = [
  {
    path: '',
    component: PoolsComponent,
    children: [
      {
        path: 'newpools',
        component: PoolListComponent,
        canActivate: [PoolsGuard]
      },
      {
        path: 'newpools/:id',
        component: PoolComponent,
        canActivate: [PoolGuard],
        children: [
          {
            path: 'exams/:id',
            canActivate: [ExamGuard],
            component: ExamEditComponent,
            children: [
              {
                path: 'import-excel-statistics',
                component: ImportExcelStatisticsComponent
              }
            ]
          },
          {
            path: 'tasks',
            canActivate: [TasksGuard],
            component: TasksComponent,
            children: [
              {
                path: ':ids',
                canActivate: [TasksQuestionGroupsGuard],
                component: TasksComponent
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PoolRoutingModule {}
