import { Injectable } from '@angular/core';
import { takeGraphQLResult } from '@common/operators/take-graphql-response';
import { ToastsService } from '@common/services/toasts.service';
import {
  assertIsDefined,
  isDefined
} from '@common/utils/type-guards/is-defined';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { parseError } from 'src/app/common/utils/error-parser';
import { RemoteData, RequestState } from 'src/app/common/utils/remote-data';
import { requestStateLoading } from 'src/app/common/utils/remote-data-utils';
import { CreateSubjectGQL } from 'src/app/new/admin/services/create-subject.generated';
import { DeleteSubjectGQL } from '../../../services/delete-subject.generated';
import {
  LoadSubjectsGQL,
  SubjectsListElementFragment
} from '../../../services/load-subjects.generated';
import { UpdateSubjectGQL } from '../../../services/update-subject.generated';
import { PoolFormState } from '../form/pool-form.state';
import {
  CreateSubject,
  CreateSubjectFailure,
  CreateSubjectSuccess,
  DeleteSubject,
  DeleteSubjectFailure,
  DeleteSubjectSuccess,
  LoadSubjects,
  LoadSubjectsFailure,
  LoadSubjectsSuccess,
  UpdateSubject,
  UpdateSubjectFailure,
  UpdateSubjectSuccess
} from './subjects.actions';

export interface SubjectsStateModel {
  subjects: RemoteData<SubjectsListElementFragment[]>;
  mutation?: RemoteData<SubjectsListElementFragment>;
}

@State<SubjectsStateModel>({
  name: 'subjects',
  defaults: {
    subjects: {
      requestState: 'initial'
    }
  }
})
@Injectable({
  providedIn: 'root'
})
export class SubjectsState {
  constructor(
    private readonly loadsubjectsService: LoadSubjectsGQL,
    private readonly createSubjectService: CreateSubjectGQL,
    private readonly updateSubjectService: UpdateSubjectGQL,
    private readonly deleteSubjectService: DeleteSubjectGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly store: Store
  ) {}

  @Selector()
  public static subjects(
    state: SubjectsStateModel
  ): RemoteData<SubjectsListElementFragment[]> {
    return { ...state.subjects, actions: { retry: LoadSubjects } };
  }

  @Selector()
  public static subjectsLoading(state: SubjectsStateModel): boolean {
    return requestStateLoading(state.subjects);
  }

  @Selector()
  public static mutationRequestState(
    state: SubjectsStateModel
  ): RequestState | undefined {
    return state.mutation?.requestState;
  }

  @Action(LoadSubjects)
  public loadSubjects(
    ctx: StateContext<SubjectsStateModel>,
    action: LoadSubjects
  ): Observable<void> {
    const poolId =
      action.poolId === undefined
        ? this.store.selectSnapshot(PoolFormState.poolId)
        : action.poolId;

    assertIsDefined(poolId, 'poolId not found');

    ctx.patchState({ subjects: { requestState: 'loading' } });

    return this.loadsubjectsService.fetch({ poolId }).pipe(
      switchMap(res =>
        ctx.dispatch(new LoadSubjectsSuccess(res.data.pool.subjects ?? []))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadSubjectsFailure(parseError(err)))
      )
    );
  }

  @Action(LoadSubjectsSuccess)
  public loadSubjectsSuccess(
    ctx: StateContext<SubjectsStateModel>,
    { subjects }: LoadSubjectsSuccess
  ): void {
    ctx.patchState({
      subjects: {
        data: subjects,
        requestState: 'success'
      }
    });
  }

  @Action(LoadSubjectsFailure)
  public loadSubjectsFailure(
    ctx: StateContext<SubjectsStateModel>,
    { error }: LoadSubjectsFailure
  ): void {
    ctx.patchState({
      subjects: {
        requestState: 'failure',
        error
      }
    });
  }

  @Action(DeleteSubject)
  public deleteSubject(
    ctx: StateContext<SubjectsStateModel>,
    { id }: DeleteSubject
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.deleteSubjectService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteSubjectSuccess(id))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteSubjectFailure(parseError(err)))
      )
    );
  }

  @Action(DeleteSubjectSuccess)
  public deleteSubjectSuccess(
    ctx: StateContext<SubjectsStateModel>,
    { id }: DeleteSubjectSuccess
  ): void {
    ctx.patchState({
      subjects: {
        ...ctx.getState().subjects,
        data: ctx.getState().subjects.data?.filter(item => item.id !== id)
      },
      mutation: { data: undefined, requestState: 'success' }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('activerecord.models.subject')
      })
    );
  }

  @Action(DeleteSubjectFailure)
  public deleteSubjectFailure(
    ctx: StateContext<SubjectsStateModel>,
    { error }: DeleteSubjectFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(
      this.translate.instant('error_modal.title'),
      error.message
    );
  }

  @Action(UpdateSubject)
  public updateSubject(
    ctx: StateContext<SubjectsStateModel>,
    { payload }: UpdateSubject
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.updateSubjectService.mutate(payload).pipe(
      takeGraphQLResult(),
      map(result => result?.updateSubject?.subject),
      filter(isDefined),
      switchMap(subject => ctx.dispatch(new UpdateSubjectSuccess(subject))),
      catchError((err: unknown) =>
        ctx.dispatch(new UpdateSubjectFailure(parseError(err)))
      )
    );
  }

  @Action(UpdateSubjectSuccess)
  public updateSubjectSuccess(
    ctx: StateContext<SubjectsStateModel>,
    { subject }: UpdateSubjectSuccess
  ): void {
    ctx.patchState({
      subjects: {
        ...ctx.getState().subjects,
        data: ctx
          .getState()
          .subjects.data?.map(item => (item.id === subject.id ? subject : item))
      },
      mutation: { data: subject, requestState: 'success' }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('activerecord.models.subject')
      })
    );
  }

  @Action(UpdateSubjectFailure)
  public updateSubjectFailure(
    ctx: StateContext<SubjectsStateModel>,
    { error }: UpdateSubjectFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(CreateSubject)
  public createSubject(
    ctx: StateContext<SubjectsStateModel>,
    { payload }: CreateSubject
  ): Observable<void> {
    const pool = this.store.selectSnapshot(PoolFormState.pool);
    assertIsDefined(pool.data, 'Pool not found');
    const poolId = pool.data.id;

    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.createSubjectService
      .mutate({ poolId, name: payload.name, categoryIds: payload.categoryIds })
      .pipe(
        takeGraphQLResult(),
        map(result => result?.createSubject?.subject),
        filter(isDefined),
        switchMap(subject => ctx.dispatch(new CreateSubjectSuccess(subject))),
        catchError((err: unknown) =>
          ctx.dispatch(new CreateSubjectFailure(parseError(err)))
        )
      );
  }

  @Action(CreateSubjectSuccess)
  public createSubjectSuccess(
    ctx: StateContext<SubjectsStateModel>,
    { subject }: CreateSubjectSuccess
  ): void {
    ctx.patchState({
      subjects: {
        ...ctx.getState().subjects,
        data: [...(ctx.getState().subjects.data || []), subject]
      },
      mutation: { data: subject, requestState: 'success' }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.create', {
        resource: this.translate.instant('activerecord.models.subject')
      })
    );
  }

  @Action(CreateSubjectFailure)
  public createSubjectFailure(
    ctx: StateContext<SubjectsStateModel>,
    { error }: CreateSubjectFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }
}
