import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { RequestState } from '@common/utils/remote-data';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { SettingsState, UpdateCommentFilter } from '@state/settings';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import { isScalarsId } from 'src/app/common/utils/type-guards/is-scalars-id';
import { isComment } from 'src/app/common/utils/type-guards/message';
import { Comment, QuestionGroupPreview } from 'src/generated/base-types';
import { QuestionGroupMessageFragment } from '../../services/question-details/question-group-messages.generated';
import { QuestionGroupStatisticsFragment } from '../../services/question-details/question-group-statistics.generated';
import {
  DeleteComment,
  LoadQuestionGroupMessages,
  LoadQuestionGroupPreview,
  LoadQuestionGroupStatistics,
  UpdateCommentReadState,
  UpdateTabSelection
} from '../../state/question-details/question-details.action';
import { QuestionDetailsSelectors } from '../../state/question-details/question-details.selector';
import { QuestionDetailsTabs } from '../../state/question-details/question-details.state';
import { TaskQuestionGroup } from '../../types/task';
import { DetailsInfoComponent } from './details-info/details-info.component';
import { LoadingDetailsComponent } from './loading-details/loading-details.component';
import { QuestionGroupMessagesComponent } from './question-group-messages/question-group-messages.component';
import { QuestionGroupPreviewComponent } from './question-group-preview/question-group-preview.component';
import { QuestionGroupStatisticsComponent } from './statistics/question-group-statistics/question-group-statistics.component';

@Component({
  selector: 'man-question-group-details',
  templateUrl: './question-group-details.component.html',
  styleUrls: ['./question-group-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    NgbNavModule,
    ManagerCommonModule,
    LoadingDetailsComponent,
    DetailsInfoComponent,
    QuestionGroupPreviewComponent,
    QuestionGroupStatisticsComponent,
    QuestionGroupMessagesComponent
  ]
})
export class QuestionGroupDetailsComponent
  implements OnDestroy, OnChanges, OnInit
{
  @Input()
  public questionGroupId: TaskQuestionGroup['questionGroup']['id'][];
  @Output() public hideDetailsPanel = new EventEmitter<void>();

  public previewLoadingState$: Observable<RequestState>;
  public messagesLoadingState$: Observable<RequestState>;
  public statisticsLoadingState$: Observable<RequestState>;
  public preview$: Observable<QuestionGroupPreview | undefined>;
  public statistics$: Observable<QuestionGroupStatisticsFragment[]>;
  public showReadMessages$: Observable<boolean>;
  public showSystemMessages$: Observable<boolean>;

  public readonly questionDetailsTabs = QuestionDetailsTabs;
  public readonly activeTab$: Observable<QuestionDetailsTabs>;
  public readonly messages$: Observable<QuestionGroupMessageFragment[]>;

  private destroy$ = new Subject<void>();

  constructor(private readonly store: Store) {
    this.showReadMessages$ = this.store.select(
      SettingsState.commentShowReadMessages
    );
    this.showSystemMessages$ = this.store.select(
      SettingsState.commentShowSystemMessages
    );
    this.messages$ = combineLatest([
      this.store.select(QuestionDetailsSelectors.messages),
      this.showReadMessages$,
      this.showSystemMessages$
    ]).pipe(
      map(([messages, showReadMessages, showSystemMessages]) =>
        messages.filter(m =>
          isComment(m) ? !m.read || showReadMessages : showSystemMessages
        )
      )
    );

    this.activeTab$ = this.store
      .select(QuestionDetailsSelectors.activeTab)
      .pipe(startWith(QuestionDetailsTabs.Preview));
  }

  public ngOnInit(): void {
    this.previewLoadingState$ = this.store.select(
      QuestionDetailsSelectors.previewLoadingState
    );
    this.messagesLoadingState$ = this.store.select(
      QuestionDetailsSelectors.messagesLoadingState
    );
    this.statisticsLoadingState$ = this.store.select(
      QuestionDetailsSelectors.statisticsLoadingState
    );
    this.preview$ = this.store.select(QuestionDetailsSelectors.preview);
    this.statistics$ = this.store.select(QuestionDetailsSelectors.statistics);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const questionGroupId = changes.questionGroupId?.currentValue[0];

    if (isScalarsId(questionGroupId)) {
      this.store.dispatch([
        new LoadQuestionGroupPreview(questionGroupId),
        new LoadQuestionGroupMessages(questionGroupId),
        new LoadQuestionGroupStatistics(questionGroupId)
      ]);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCommentReadStateChanged([id, read]: [Comment['id'], boolean]): void {
    this.store.dispatch(new UpdateCommentReadState(id, read));
  }

  public onCommentDeleted(id: Comment['id']): void {
    this.store.dispatch(new DeleteComment(id));
  }

  public onTabSelectionChanged(event: { nextId: QuestionDetailsTabs }): void {
    this.store.dispatch(new UpdateTabSelection(event.nextId));
  }

  public onShowReadMessageChanged(active: boolean): void {
    this.store.dispatch(new UpdateCommentFilter('showReadMessages', active));
  }

  public onShowSystemMessageChanged(active: boolean): void {
    this.store.dispatch(new UpdateCommentFilter('showSystemMessages', active));
  }

  public onHidePanel(): void {
    this.hideDetailsPanel.emit();
  }
}
