import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { ContextState } from '@state/context/context.state';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, first, flatMap, takeUntil } from 'rxjs/operators';
import {
  assertIsDefined,
  isDefined
} from 'src/app/common/utils/type-guards/is-defined';
import {
  Language,
  QuestionA,
  QuestionGroupInput,
  QuestionInput,
  QuestionTypeTransformation,
  ResponseOptionInput,
  Scalars
} from 'src/generated/base-types';
import { LoadExistingQuestionGroup } from '../state/form.actions';
import { FormQuestionGroup, FormState } from '../state/form.state';
import { ChangeQuestionTypeGQL } from './change-question-type.generated';
import { QuestionTypeTransformationsGQL } from './question-type-transformations.generated';

@Component({
  templateUrl: './change-type-modal.component.html',
  styleUrls: ['./change-type-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeTypeModalComponent implements OnInit, OnDestroy {
  @Input() public modalInstance: NgbModalRef;
  @Input() public questionId: Scalars['ID'];
  @Input() public language: Language;
  @Input() public text: string;

  @Select(FormState.questionGroup) private questionGroup$: Observable<
    FormQuestionGroup | undefined
  >;

  @Select(ContextState.currentPoolId) private poolId$: Observable<
    string | undefined
  >;

  @Select(FormState.questionGroupInput)
  private questionGroupInput$: Observable<QuestionGroupInput>;

  public question: QuestionInput | undefined;

  public transformation: QuestionTypeTransformation;

  public transformations: QuestionTypeTransformation[];

  public solutionToDelete: number | undefined;

  public translatedType: string;

  public loading = true;

  public questionGroupId: string | undefined;

  public poolId: string | undefined;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly questionTypeTransformationsGQL: QuestionTypeTransformationsGQL,
    private readonly changeQuestionTypeGQL: ChangeQuestionTypeGQL,
    private readonly translate: TranslateService,
    private readonly cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    combineLatest([this.questionGroup$, this.poolId$])
      .pipe(
        filter(isDefined),
        first(),
        flatMap(([questionGroup, poolId]) => {
          if (questionGroup && poolId !== undefined) {
            this.questionGroupId = questionGroup.id;
            this.poolId = poolId;

            return this.store
              .dispatch(
                new LoadExistingQuestionGroup(this.questionGroupId, this.poolId)
              )
              .pipe(
                filter(isDefined),
                first(),
                flatMap(_ => {
                  return this.questionGroupInput$.pipe(
                    filter(isDefined),
                    first(),
                    flatMap(questionGroupInput => {
                      this.question = questionGroupInput.questions?.find(
                        question => question.id === this.questionId
                      );

                      return this.question?.id === undefined
                        ? of()
                        : this.questionTypeTransformationsGQL
                            .fetch({
                              poolId,
                              questionId: this.question.id as string
                            })
                            .pipe(
                              filter(isDefined),
                              first(),
                              flatMap(transformations => {
                                if (
                                  transformations.data.pool
                                    .questionTypeTransformations
                                ) {
                                  this.transformations = transformations.data
                                    .pool
                                    .questionTypeTransformations as QuestionTypeTransformation[];
                                  this.transformation = {
                                    type: this.transformations[0].type,
                                    necessaryOperations: [
                                      ...this.transformations[0]
                                        .necessaryOperations
                                    ],
                                    deleteSolution:
                                      this.transformations[0].deleteSolution
                                  };
                                  this.questionTypeChanged();
                                }

                                return of(true);
                              })
                            );
                    })
                  );
                })
              );
          } else {
            return of();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(_ => {
        this.loading = false;
        this.cd.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.complete();
  }

  public questionTypeChanged(): void {
    const selectedTransformation = this.transformations.find(
      t => t.type === this.transformation?.type
    );
    if (
      this.transformation !== undefined &&
      selectedTransformation?.necessaryOperations !== undefined
    ) {
      this.transformation.necessaryOperations = [
        ...selectedTransformation.necessaryOperations
      ];
      this.transformation.deleteSolution =
        selectedTransformation.deleteSolution;
      this.solutionToDelete = undefined;
      this.translatedType = this.translate.instant(
        `questions.type.${selectedTransformation.type}`
      );
    }
  }

  public canSubmit(): boolean {
    if (this.transformation !== undefined) {
      const { necessaryOperations } = this.transformation;
      if (necessaryOperations.length === 0) {
        return true;
      }

      if (necessaryOperations.includes('remove_response_option')) {
        return this.solutionToDelete !== undefined;
      }
    }

    return false;
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public transform(): void {
    if (this.transformation?.type && this.question?.id !== undefined) {
      assertIsDefined(this.poolId);

      this.changeQuestionTypeGQL
        .mutate({
          poolId: this.poolId,
          questionId: this.question.id as string,
          type: this.transformation.type,
          removeResponseOption: this.solutionToDelete
        })
        .pipe(
          flatMap(result => {
            const { successful } = result?.data?.changeQuestionType || {};
            if (successful === true) {
              this.modalInstance.close(this.transformation.type);

              return this.questionGroupId !== undefined &&
                this.poolId !== undefined
                ? this.store.dispatch(
                    new LoadExistingQuestionGroup(
                      this.questionGroupId,
                      this.poolId
                    )
                  )
                : of();
            } else {
              return of();
            }
          }),
          takeUntil(this.destroy$)
        )
        /*
          TODO: This is a bandaid fix, dataflow needs to be rethonked
          and this should only do changes in the frontend and then do the
          actual type change upon saving the question.
        */
        .subscribe(_ => window.location.reload());
    } else {
      throw new Error('QuestionType tranformation failed');
    }
  }

  public getResponseOptions(): ResponseOptionInput[] | undefined {
    if (this.question?.type) {
      const question = this.question;
      const type = this.question.type;

      const answers = (question[type] as QuestionA).responseOptions;

      return answers;
    } else {
      return undefined;
    }
  }
}
