import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type Type = 'SUCCESS' | 'WARNING' | 'INFO';

export type DurationMs = number;

export interface Toast {
  message: string;
  duration: DurationMs;
  type: Type;
  errorMessage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToastsService {
  public toasts: Toast[];

  public get toasts$(): Observable<Toast[]> {
    return this.toastsSubject$.asObservable();
  }

  private readonly toastsSubject$: Subject<Toast[]>;

  constructor() {
    this.toastsSubject$ = new Subject<Toast[]>();
    this.toasts = [];
  }

  public addSuccess(message: string): void {
    this.add({ type: 'SUCCESS', message, duration: 3_000 });
  }

  public addWarning(message: string, errorMessage?: string): void {
    this.add({ type: 'WARNING', message, duration: 10_000, errorMessage });
  }

  public addInfo(message: string): void {
    this.add({ type: 'INFO', message, duration: 2_500 });
  }

  public add(toast: Toast): void {
    this.update([...this.toasts, toast]);
  }

  public remove(toast: Toast): void {
    this.update(this.toasts.filter(t => t !== toast));
  }

  private update(toasts: Toast[]): void {
    this.toasts = toasts;
    this.toastsSubject$.next(this.toasts);
  }
}
