import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageVisibility } from '@state/settings';
import type { DndDropEvent } from 'ngx-drag-drop';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Language,
  QuestionGroupType,
  QuestionInput,
  QuestionType
} from 'src/generated/base-types';
import { ChooseQuestionGroupTypeModalComponent } from '../../common/modals/choose-question-group-type-modal/choose-question-group-type-modal.component';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../common/utils/ng-bootstrap-modal';
import { FormDimension } from '../form-types';
import {
  isSecureIndexRange,
  normalizeIndexRange,
  transformDropEventToIndexRange
} from '../services/drag-and-drop';
import { ValidationsQuestionGroupGraphQL } from '../services/graphql-question-group-types';
import { QuestionFactoryService } from '../services/question-factory.service';

/*
 * Change detection for the single question form doesn't work properly. When
 * navigating through the questions with the `question-navigation.component`,
 * the editor will always show the initial question. The solution isn't trivial
 * To work around this the `question-form.component` is wrapped in a `*ngFor`
 * that loops over one question only. This forces the `question-form.component`
 * to be replaced completely on a question change.
 */
@Component({
  selector: 'qf-question-tabs',
  templateUrl: './question-tabs.component.html',
  styleUrls: ['./question-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionTabsComponent),
      multi: true
    }
  ]
})
export class QuestionTabsComponent implements ControlValueAccessor, OnDestroy {
  @Input() public questionGroupType: QuestionGroupType;
  @Input() public validations: ValidationsQuestionGroupGraphQL;
  @Input() public dimensions: FormDimension[];
  @Input() public languageVisibility: LanguageVisibility;
  @Input() public languages: Language[];
  @Input() public sequentialNumber: number;
  @Input() public sourceLanguage: Language;

  public activeQuestionIndex = 0;
  public removalModeActive = false;

  public form: UntypedFormGroup;
  private questionGroup$ = new Subject<QuestionInput[]>();
  private destroy$ = new Subject<void>();

  constructor(
    private builder: UntypedFormBuilder,
    public readonly ngbModal: NgbModal,
    private questionFactory: QuestionFactoryService,
    private changeDetection: ChangeDetectorRef
  ) {
    this.form = builder.group({
      questions: builder.array([])
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.onChange(changes.questions);
      this.onTouch();
    });

    this.questionGroup$.pipe(takeUntil(this.destroy$)).subscribe(questions => {
      this.questionsForm.clear();
      questions.forEach(question => {
        this.questionsForm.push(builder.control(question));
      });
    });
  }

  public get questionsForm(): UntypedFormArray {
    return this.form.get('questions') as UntypedFormArray;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: QuestionInput[]): void {
    this.questionGroup$.next(value);
  }

  public registerOnChange(fn: (value: QuestionInput[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public toggleRemoveQuestions(): void {
    this.removalModeActive = !this.removalModeActive;
  }

  public onAddQuestion(): Promise<void> {
    const ref = this.ngbModal.open(
      ChooseQuestionGroupTypeModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );

    ref.componentInstance.hideQuestionGroupTypes = true;
    ref.componentInstance.modalInstance = ref;

    return ref.result
      .then(async (result: { questionType: QuestionType }) => {
        const newQuestion = await this.questionFactory.createQuestion(
          result.questionType
        );

        this.questionsForm.push(this.builder.control(newQuestion));
        this.changeDetection.markForCheck();
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public onRemoveQuestion(questionIndex: number): void {
    this.questionsForm.removeAt(questionIndex);
  }

  public onMoveTab(event: DndDropEvent): void {
    const indexRange = transformDropEventToIndexRange(event);
    if (!isSecureIndexRange(indexRange)) {
      return;
    }

    const normalizedIndexRange = normalizeIndexRange(indexRange);
    const movedFormGroup = this.questionsForm.at(normalizedIndexRange.from);
    this.questionsForm.removeAt(normalizedIndexRange.from);
    this.questionsForm.insert(normalizedIndexRange.to, movedFormGroup);
  }

  private onChange: (value: QuestionInput[]) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
