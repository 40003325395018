import { Injectable } from '@angular/core';
import { takeGraphQLResult } from '@common/operators/take-graphql-response';
import { ToastsService } from '@common/services/toasts.service';
import { parseError } from '@common/utils/error-parser';
import { RemoteData, RequestState } from '@common/utils/remote-data';
import { isDefined } from '@common/utils/type-guards/is-defined';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ModalService } from '../../../common/modal/modal.service';
import { CreateOauthApplicationGQL } from '../../services/create-oauth-application.generated';
import { DeleteOauthApplicationGQL } from '../../services/delete-oauth-application.generated';
import {
  LoadOAuthApplicationsGQL,
  OAuthApplicationFieldsFragment
} from '../../services/load-oauth-applications.generated';
import { UpdateOauthApplicationGQL } from '../../services/update-oauth-application.generated';
import {
  CreateApplication,
  CreateApplicationFailure,
  CreateApplicationSuccess,
  DeleteApplication,
  DeleteApplicationFailure,
  DeleteApplicationSuccess,
  LoadApplications,
  LoadApplicationsFailure,
  LoadApplicationsSuccess,
  UpdateApplication,
  UpdateApplicationFailure,
  UpdateApplicationSuccess
} from './applications.actions';

export interface ApplicationsStateModel {
  applications: RemoteData<OAuthApplicationFieldsFragment[]>;
  mutation: RemoteData<undefined>;
}

@State<ApplicationsStateModel>({
  name: 'applications',
  defaults: {
    applications: { requestState: 'initial' },
    mutation: { requestState: 'initial' }
  }
})
@Injectable({
  providedIn: 'root'
})
export class ApplicationsState {
  constructor(
    private readonly loadOAuthApplicationsService: LoadOAuthApplicationsGQL,
    private readonly createOauthApplicationGQL: CreateOauthApplicationGQL,
    private readonly updateOauthApplicationGQL: UpdateOauthApplicationGQL,
    private readonly deleteOauthApplicationGQL: DeleteOauthApplicationGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly modalService: ModalService
  ) {}

  @Selector()
  public static applications(
    state: ApplicationsStateModel
  ): RemoteData<OAuthApplicationFieldsFragment[]> {
    return { ...state.applications, actions: { retry: LoadApplications } };
  }

  @Selector()
  public static mutationRequestState(
    state: ApplicationsStateModel
  ): RequestState {
    return state.mutation.requestState;
  }

  @Action(LoadApplications)
  public loadApplications(
    ctx: StateContext<ApplicationsStateModel>
  ): Observable<void> {
    ctx.patchState({ applications: { requestState: 'loading' } });

    return this.loadOAuthApplicationsService.fetch().pipe(
      switchMap(res =>
        ctx.dispatch(new LoadApplicationsSuccess(res.data.oauthApplications))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadApplicationsFailure(parseError(err)))
      )
    );
  }

  @Action(LoadApplicationsSuccess)
  public loadApplicationsSuccess(
    ctx: StateContext<ApplicationsStateModel>,
    { applications }: LoadApplicationsSuccess
  ): void {
    ctx.patchState({
      applications: {
        data: applications,
        requestState: 'success'
      }
    });
  }

  @Action(LoadApplicationsFailure)
  public loadApplicationsFailure(
    ctx: StateContext<ApplicationsStateModel>,
    { error }: LoadApplicationsFailure
  ): void {
    ctx.patchState({
      applications: { requestState: 'failure', error }
    });
  }

  @Action(CreateApplication)
  public createApplication(
    ctx: StateContext<ApplicationsStateModel>,
    action: CreateApplication
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.createOauthApplicationGQL
      .mutate({ attributes: action.attributes })
      .pipe(
        takeGraphQLResult(),
        map(result => result?.createOauthApplication?.oauthApplication),
        filter(isDefined),
        switchMap(application =>
          ctx.dispatch(new CreateApplicationSuccess(application))
        ),
        catchError((error: unknown) => {
          return ctx.dispatch(
            new CreateApplicationFailure(parseError(error), action.attributes)
          );
        })
      );
  }

  @Action(CreateApplicationSuccess)
  public createApplicationSuccess(
    ctx: StateContext<ApplicationsStateModel>,
    action: CreateApplicationSuccess
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'success'
      }
    });

    ctx.setState(
      patch<ApplicationsStateModel>({
        applications: patch<ApplicationsStateModel['applications']>({
          data: append<OAuthApplicationFieldsFragment>([action.application])
        })
      })
    );

    this.toasts.addSuccess(
      this.translate.instant('admin.applications.notification.created')
    );
  }

  @Action(CreateApplicationFailure)
  public createApplicationFailure(
    ctx: StateContext<ApplicationsStateModel>,
    { error, attributes }: CreateApplicationFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (retry) {
        ctx.dispatch(new CreateApplication(attributes));
      }
    });
  }

  @Action(UpdateApplication)
  public updateApplication(
    ctx: StateContext<ApplicationsStateModel>,
    { id, attributes }: UpdateApplication
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.updateOauthApplicationGQL.mutate({ id, attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.updateOauthApplication?.oauthApplication),
      filter(isDefined),
      switchMap(application =>
        ctx.dispatch(new UpdateApplicationSuccess(application))
      ),
      catchError((error: unknown) => {
        return ctx.dispatch(
          new UpdateApplicationFailure(parseError(error), id, attributes)
        );
      })
    );
  }

  @Action(UpdateApplicationSuccess)
  public updateApplicationSuccess(
    ctx: StateContext<ApplicationsStateModel>,
    action: UpdateApplicationSuccess
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'success'
      }
    });

    ctx.setState(
      patch<ApplicationsStateModel>({
        applications: patch<ApplicationsStateModel['applications']>({
          data: updateItem<OAuthApplicationFieldsFragment>(
            application => application?.id === action.application.id,
            patch<OAuthApplicationFieldsFragment>(action.application)
          )
        })
      })
    );

    this.toasts.addSuccess(
      this.translate.instant('admin.applications.notification.updated')
    );
  }

  @Action(UpdateApplicationFailure)
  public updateApplicationFailure(
    ctx: StateContext<ApplicationsStateModel>,
    { error, id, attributes }: UpdateApplicationFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (retry) {
        ctx.dispatch(new UpdateApplication(id, attributes));
      }
    });
  }

  @Action(DeleteApplication)
  public deleteApplication(
    ctx: StateContext<ApplicationsStateModel>,
    { id }: DeleteApplication
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.deleteOauthApplicationGQL.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteApplicationSuccess(id))),
      catchError((error: unknown) =>
        ctx.dispatch(new DeleteApplicationFailure(parseError(error), id))
      )
    );
  }

  @Action(DeleteApplicationSuccess)
  public DeleteApplicationSuccess(
    ctx: StateContext<ApplicationsStateModel>,
    { id }: DeleteApplicationSuccess
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'success'
      }
    });

    ctx.setState(
      patch<ApplicationsStateModel>({
        applications: patch<ApplicationsStateModel['applications']>({
          data: removeItem<OAuthApplicationFieldsFragment>(
            application => application?.id === id
          )
        })
      })
    );

    this.toasts.addSuccess(
      this.translate.instant('newadmin.applications.notification.updated')
    );
  }

  @Action(DeleteApplicationFailure)
  public DeleteApplicationFailure(
    ctx: StateContext<ApplicationsStateModel>,
    { error }: DeleteApplicationFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });
  }
}
