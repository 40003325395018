import { ManagerError } from '@common/utils/error-parser';
import { QuestionGroupPreview, Scalars } from '@generated/base-types';
import { QuestionGroupMessageFragment } from '../../services/question-details/question-group-messages.generated';
import { QuestionGroupStatisticsFragment } from '../../services/question-details/question-group-statistics.generated';
import { QuestionDetailsTabs } from './question-details.state';

export class UpdateTabSelection {
  public static readonly type = '[Question Details] Update Tab Selection';
  constructor(public readonly activeTab: QuestionDetailsTabs) {}
}

export class LoadQuestionGroupPreview {
  public static readonly type =
    '[Question Details] Load Question Group Preview';
  constructor(public readonly questionGroupId: Scalars['ID']) {}
}

export class LoadQuestionGroupPreviewSuccess {
  public static readonly type =
    '[Question Details] Load Question Group Preview Success';
  constructor(public readonly preview: QuestionGroupPreview) {}
}

export class LoadQuestionGroupPreviewFailure {
  public static readonly type =
    '[Question Details] Load Question Group Preview Failure';
  constructor(public readonly error: ManagerError) {}
}

export class LoadQuestionGroupMessages {
  public static readonly type =
    '[Question Details] Load Question Group Messages';
  constructor(public readonly questionGroupId: Scalars['ID']) {}
}

export class LoadQuestionGroupMessagesSuccess {
  public static readonly type =
    '[Question Details] Load Question Group Messages Success';
  constructor(public readonly messages: QuestionGroupMessageFragment[]) {}
}

export class LoadQuestionGroupMessagesFailure {
  public static readonly type =
    '[Question Details] Load Question Group Messages Failure';
  constructor(public readonly error: ManagerError) {}
}

export class LoadQuestionGroupStatistics {
  public static readonly type =
    '[Question Details] Load Question Group Statistics';
  constructor(public readonly questionGroupId: Scalars['ID']) {}
}

export class LoadQuestionGroupStatisticsSuccess {
  public static readonly type =
    '[Question Details] Load Question Group Statistics Success';
  constructor(public readonly statistics: QuestionGroupStatisticsFragment[]) {}
}

export class LoadQuestionGroupStatisticsFailure {
  public static readonly type =
    '[Question Details] Load Question Group Statistics Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateComment {
  public static readonly type = '[Question Details] Create Comment';
  constructor(
    public readonly questionGroupIds: Scalars['ID'][],
    public readonly content: Scalars['String']
  ) {}
}

export class CreateCommentSuccess {
  public static readonly type = '[Question Details] Create Comment Success';
  constructor(public readonly messages: QuestionGroupMessageFragment[]) {}
}

export class CreateCommentFailure {
  public static readonly type = '[Question Details] Create Comment Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateCommentReadState {
  public static readonly type = '[Question Details] Update Comment Read State';
  constructor(
    public readonly commentId: Scalars['ID'],
    public readonly read: Scalars['Boolean']
  ) {}
}

export class UpdateCommentReadStateSuccess {
  public static readonly type =
    '[Question Details] Update Comment Read State Success';
  constructor(
    public readonly commentId: Scalars['ID'],
    public readonly read: Scalars['Boolean']
  ) {}
}

export class UpdateCommentReadStateFailure {
  public static readonly type =
    '[Question Details] Update Comment Read State Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteComment {
  public static readonly type = '[Question Details] Delete Comment';
  constructor(public readonly commentId: Scalars['ID']) {}
}

export class DeleteCommentSuccess {
  public static readonly type = '[Question Details] Delete Comment Success';
  constructor(public readonly commentId: Scalars['ID']) {}
}

export class DeleteCommentFailure {
  public static readonly type = '[Question Details] Delete Comment Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteStatistics {
  public static readonly type = '[Question Details] Delete statistics';
  constructor(public readonly questionGroupIds: Scalars['ID'][]) {}
}

export class DeleteStatisticsSuccess {
  public static readonly type = '[Question Details] Delete statistics success';
}

export class DeleteStatisticsFailure {
  public static readonly type = '[Question Details] Delete statistics failure';
  constructor(public readonly error: ManagerError) {}
}
