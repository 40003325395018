<form class="page form" [formGroup]="form!" (submit)="onSave()" noValidate>
  <div class="page-content">
    <man-field
      *ngIf="selectionMode === 'pool'"
      [label]="'common.models.pool' | translate"
      [errors]="form! | fieldErrors : 'poolId'"
    >
      <man-pool-dropdown formControlName="poolId"></man-pool-dropdown>
    </man-field>

    <man-field
      *ngIf="selectionMode === 'user'"
      [label]="'admin.pools.permissions.form.label.user' | translate"
      [errors]="form! | fieldErrors : 'userId'"
    >
      <man-user-dropdown formControlName="userId"></man-user-dropdown>
    </man-field>

    <man-field
      [label]="'admin.pools.permissions.form.label.role' | translate"
      [errors]="form! | fieldErrors : 'role'"
      *ngIf="
        (form!.get('userId')?.value && form!.get('poolId')?.value) ||
        (form!.get('poolId')?.value && selectionMode === 'pool')
      "
    >
      <man-role-dropdown
        [poolId]="this.form.get('poolId')?.value ?? ''"
        formControlName="role"
      ></man-role-dropdown>
    </man-field>

    <man-field
      [label]="'admin.pools.permissions.form.label.subject' | translate"
      [errors]="form! | fieldErrors : 'subjectId'"
      *ngIf="
        (form!.get('role')?.value ?? '') === 'coordinator' &&
        (subjects$ | async)
      "
    >
      <man-subject-dropdown
        formControlName="subjectId"
        [subjects$]="subjects$"
      ></man-subject-dropdown>
    </man-field>
  </div>

  <div class="page-footer">
    <button
      type="submit"
      class="btn btn-primary order-2"
      [disabled]="disabled || !form!.dirty || !form!.valid"
    >
      <ng-container *ngIf="!disabled; else loading">
        {{
          (edit ? 'common.actions.save' : 'common.actions.create') | translate
        }}
      </ng-container>
    </button>
    <button
      type="button"
      [disabled]="disabled"
      class="btn btn-light order-1"
      (click)="onClose()"
    >
      {{ 'common.actions.close' | translate }}
    </button>
  </div>
</form>

<ng-template #loading>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.actions.loading' | translate }}
</ng-template>
