import { ManagerError } from '@common/utils/error-parser';
import { Scalars } from 'src/generated/base-types';
import { ExamDetailsFragment } from '../../services/exam/load-exam.generated';

export class LoadExam {
  public static readonly type = '[Exam Form] Load Exam';
  constructor(public readonly id: Scalars['ID']) {}
}

export class LoadExamSuccess {
  public static readonly type = '[Exam Form] Load Exam Success';
  constructor(public readonly exam: ExamDetailsFragment) {}
}

export class LoadExamFailure {
  public static readonly type = '[Exam Form] Load Exam Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID']
  ) {}
}
