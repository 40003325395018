import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadTaskQuestionGroups } from '../../state/tasks/tasks.actions';

@Injectable({ providedIn: 'root' })
export class TasksQuestionGroupsGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    this.store.dispatch(
      new LoadTaskQuestionGroups(route.params.ids.split(','))
    );

    return true;
  }
}
