import { CommonModule as AngularCommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { isDefined } from '@common/utils/type-guards/is-defined';
import { TaskQuestionGroup } from '@generated/base-types';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { map, Observable, of, Subject, takeUntil } from 'rxjs';
import { ModalService } from 'src/app/new/common/modal/modal.service';
import { PoolState } from '../../state/pool/pool.state';
import {
  CreateComment,
  DeleteStatistics
} from '../../state/question-details/question-details.action';
import { TaskSelectors as Selectors } from '../../state/tasks/task.selectors';
import {
  DeleteQuestionGroups,
  ResetSelectedTaskQuestionGroups,
  SetSupervisor
} from '../../state/tasks/tasks.actions';
import { ResizedDirective } from '../directives/resized.directive';

export enum ToolbarManagementType {
  Task = 'Task',
  Question = 'Question'
}

interface ToolbarAction {
  label: string;
  onActionClick: () => void;
  disabled: Observable<boolean>;
  displayOn: ToolbarManagementType[];
}

@Component({
  selector: 'man-question-group-toolbar',
  templateUrl: './question-group-toolbar.component.html',
  styleUrls: ['./question-group-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AngularCommonModule,
    TranslateModule,
    ResizedDirective,
    NgbDropdownModule
  ]
})
export class QuestionGroupToolbarComponent implements OnInit, OnDestroy {
  @Input() public managementType: ToolbarManagementType;

  public visibleButtons: ToolbarAction[] = [];
  public dropdownItems: ToolbarAction[] = [];

  private toolbarActions: ToolbarAction[] = [];
  private destroy$ = new Subject<void>();
  private selectedQuestionGroupIds$: Observable<
    TaskQuestionGroup['questionGroup']['id'][]
  >;
  private selectedQuestionGroups: TaskQuestionGroup['questionGroup']['id'][];
  private supervisors$: Observable<string[]>;

  constructor(
    private readonly translateService: TranslateService,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.selectedQuestionGroupIds$ = this.store.select(
      Selectors.selectedTaskQuestionGroupIds
    );
    this.toolbarActions = this.createToolbarActions();
    this.selectedQuestionGroupIds$
      .pipe(takeUntil(this.destroy$))
      .subscribe(qgs => (this.selectedQuestionGroups = qgs));
    this.supervisors$ = this.store.select(PoolState.supervisors);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async onDeleteQuestion(): Promise<void> {
    const amount = this.selectedQuestionGroups.length;
    if (amount < 1) {
      return;
    }
    const message = this.translateService.instant(
      'pool.question_list.toolbar.delete_modal.content',
      {
        amount
      }
    );
    const confirmDelete = await this.modalService.confirmDeleteQuestion(
      message
    );

    if (confirmDelete) {
      this.store.dispatch(
        new DeleteQuestionGroups(this.selectedQuestionGroups)
      );
      this.store.dispatch(new ResetSelectedTaskQuestionGroups());
    }
  }

  public async onDeleteStatistics(): Promise<void> {
    const confirmDelete = await this.modalService.confirmDeleteStatistics();

    if (confirmDelete) {
      this.store.dispatch(new DeleteStatistics(this.selectedQuestionGroups));
    }
  }

  public async onAddComment(): Promise<void> {
    const addComment = await this.modalService.addComment();

    if (
      isDefined(addComment) &&
      typeof addComment === 'string' &&
      addComment !== ''
    ) {
      this.store.dispatch(
        new CreateComment(this.selectedQuestionGroups, addComment)
      );
    }
  }

  public async onChangeSupervisor(): Promise<void> {
    const setSupervisor = await this.modalService.setSupervisor(
      this.supervisors$
    );

    if (
      isDefined(setSupervisor) &&
      typeof setSupervisor === 'string' &&
      setSupervisor !== ''
    ) {
      this.store.dispatch(
        new SetSupervisor(setSupervisor, this.selectedQuestionGroups)
      );
    }
  }

  public onResized(event: ResizeObserverSize): void {
    const count = this.calculateVisibleButtonCount(event.inlineSize);
    this.visibleButtons = this.getManagementToolbarActions().slice(0, count);
    this.dropdownItems = this.getManagementToolbarActions().slice(count);

    this.cdRef.detectChanges();
  }

  private calculateVisibleButtonCount(availableWidth: number): number {
    const buttonWidth = 150;
    const dropdownButtonWidth = 29;
    const createTaskDropdownWidth = 150;
    const minDistanceBetweenButtons = 250;

    let remainingWidth = availableWidth;
    remainingWidth -= minDistanceBetweenButtons;
    remainingWidth -= createTaskDropdownWidth;
    remainingWidth -= dropdownButtonWidth;

    const buttonCount = Math.floor(remainingWidth / buttonWidth);

    return Math.max(buttonCount, 0);
  }

  private getManagementToolbarActions(): ToolbarAction[] {
    return this.toolbarActions.filter(action =>
      action.displayOn.includes(this.managementType)
    );
  }

  private createToolbarActions(): ToolbarAction[] {
    return [
      {
        label: 'pool.question_list.toolbar.new',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('New question...');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question]
      },
      {
        label: 'pool.question_list.toolbar.delete',
        onActionClick: () => this.onDeleteQuestion(),
        disabled: this.hasNoSelection(),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.duplicate',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Duplicate');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question]
      },
      {
        label: 'pool.question_list.toolbar.for_exam',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('For exam...');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.comment',
        onActionClick: () => this.onAddComment(),
        disabled: this.hasNoSelection(),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.label',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Label');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.revision_status',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Review status');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.supervisor',
        onActionClick: () => this.onChangeSupervisor(),
        disabled: this.hasNoSelection(),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.affiliation',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Change affiliation');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.revision_year',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Change revision year');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.source_language',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Source language');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.blueprint',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Assign blueprint');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.revision_document',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Create revision document');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.create_export',
        onActionClick: () => {
          // eslint-disable-next-line no-console
          console.log('Export list');
        },
        disabled: of(false),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      },
      {
        label: 'pool.question_list.toolbar.delete_statistics',
        onActionClick: () => this.onDeleteStatistics(),
        disabled: this.hasNoSelection(),
        displayOn: [ToolbarManagementType.Question, ToolbarManagementType.Task]
      }
    ];
  }

  private hasNoSelection(): Observable<boolean> {
    return this.selectedQuestionGroupIds$.pipe(
      map(ids => ids === undefined || ids.length === 0)
    );
  }
}
