/* eslint-disable camelcase, unicorn/no-null */
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { LanguageVisibility } from '@state/settings';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  QuestionE,
  QuestionESolution,
  QuestionInput
} from 'src/generated/base-types';
import { Voidable } from '../../common/utils/type-guards/voidable';
import { QuestionGroupFormComponent } from '../question-group-form/question-group-form.component';
import { ValidationsQuestionEGraphQL } from '../services/graphql-question-group-types';

const solutions = [
  QuestionESolution.ABecauseB,
  QuestionESolution.AB,
  QuestionESolution.ANotB,
  QuestionESolution.NotAB,
  QuestionESolution.NotANotB
];

function responseOptionToSolution(
  index: number
): QuestionESolution | undefined {
  return solutions[index];
}

function solutionToResponseOption(
  solution: QuestionESolution | Voidable
): number | null {
  if (solution === null || solution === undefined) {
    return null;
  }

  return solutions.indexOf(solution);
}

@Component({
  selector: 'qf-question-content-type-e-form',
  templateUrl: './question-content-type-e-form.component.html',
  styleUrls: ['./question-content-type-e-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionContentTypeEFormComponent),
      multi: true
    }
  ]
})
export class QuestionContentTypeEFormComponent
  implements ControlValueAccessor, OnDestroy
{
  @Input() public question: QuestionE;
  @Input() public validations: ValidationsQuestionEGraphQL | undefined;
  @Input() public languageVisibility: LanguageVisibility;

  public form: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  constructor(
    public readonly builder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.form = this.builder.group({
      contentDePart1: null,
      contentDePart2: null,
      contentFrPart1: null,
      contentFrPart2: null,
      contentEnPart1: null,
      contentEnPart2: null,
      contentItPart1: null,
      contentItPart2: null,
      videoDe: null,
      videoFr: null,
      videoEn: null,
      videoIt: null,
      solution: null
    });

    setTimeout(() => {
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(changes => {
          this.onChange({
            video: {
              de: changes.videoDe,
              fr: changes.videoFr,
              en: changes.videoEn,
              it: changes.videoIt
            },
            e: {
              statementA: {
                de: changes.contentDePart1,
                fr: changes.contentFrPart1,
                it: changes.contentItPart1,
                en: changes.contentEnPart1
              },
              statementB: {
                de: changes.contentDePart2,
                fr: changes.contentFrPart2,
                it: changes.contentItPart2,
                en: changes.contentEnPart2
              },
              solution: responseOptionToSolution(changes.solution)
            }
          });
          this.onTouch();
        });
    }, QuestionGroupFormComponent.CHANGE_DETECTION_TIMEOUT);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(question: QuestionInput): void {
    this.form.setValue({
      contentDePart1: question.e?.statementA.de ?? null,
      contentDePart2: question.e?.statementB.de ?? null,
      contentFrPart1: question.e?.statementA.fr ?? null,
      contentFrPart2: question.e?.statementB.fr ?? null,
      contentItPart1: question.e?.statementA.it ?? null,
      contentItPart2: question.e?.statementB.it ?? null,
      contentEnPart1: question.e?.statementA.en ?? null,
      contentEnPart2: question.e?.statementB.en ?? null,
      videoDe: question.video?.de ?? null,
      videoFr: question.video?.fr ?? null,
      videoEn: question.video?.en ?? null,
      videoIt: question.video?.it ?? null,
      solution: solutionToResponseOption(question.e?.solution)
    });
  }

  public registerOnChange(fn: (value: QuestionInput) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public solutionChanged(value: number): void {
    this.form.get('solution')?.setValue(value);
    this.changeDetector.detectChanges();
  }

  private onChange: (value: QuestionInput) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
