import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
export type ValidationsMessageFieldsFragment = { __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity };

export type ValidationsUserFieldsFragment = { __typename?: 'ValidationsUser', email?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, alternativeEmail?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, firstName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, lastName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, language?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, password?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, passwordConfirmation?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null };

export const ValidationsMessageFieldsFragmentDoc = gql`
    fragment ValidationsMessageFields on ValidationsMessage {
  message
  severity
}
    `;
export const ValidationsUserFieldsFragmentDoc = gql`
    fragment ValidationsUserFields on ValidationsUser {
  email {
    ...ValidationsMessageFields
  }
  alternativeEmail {
    ...ValidationsMessageFields
  }
  firstName {
    ...ValidationsMessageFields
  }
  lastName {
    ...ValidationsMessageFields
  }
  language {
    ...ValidationsMessageFields
  }
  password {
    ...ValidationsMessageFields
  }
  passwordConfirmation {
    ...ValidationsMessageFields
  }
}
    ${ValidationsMessageFieldsFragmentDoc}`;