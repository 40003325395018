import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  template: ` <router-outlet></router-outlet> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'content',
    style: 'display:flex; margin: 1rem;'
  }
})
export class PoolComponent {}
