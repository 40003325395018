import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { AffiliationListElementFragmentDoc } from './load-affiliations.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetPositionAffiliationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  direction: Types.MoveDirection;
  times?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetPositionAffiliationMutation = { __typename?: 'Mutation', setPositionAffiliation?: { __typename?: 'SetPositionAffiliationPayload', successful: boolean, affiliation?: { __typename?: 'Affiliation', id: string, position: number, name: string } | null, affiliations?: Array<{ __typename?: 'Affiliation', id: string, position: number, name: string }> | null } | null };

export const SetPositionAffiliationDocument = gql`
    mutation SetPositionAffiliation($id: ID!, $direction: MoveDirection!, $times: Int) {
  setPositionAffiliation(id: $id, direction: $direction, times: $times) {
    affiliation {
      ...AffiliationListElement
    }
    affiliations {
      ...AffiliationListElement
    }
    successful
  }
}
    ${AffiliationListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetPositionAffiliationGQL extends Apollo.Mutation<SetPositionAffiliationMutation, SetPositionAffiliationMutationVariables> {
    document = SetPositionAffiliationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }