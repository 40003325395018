import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OauthApplicationInput, Scalars } from '@generated/base-types';
import { ModalService } from '../../../common/modal/modal.service';
import { uriValidator } from '../../form/utils/uri.validator';
import { OAuthApplicationFieldsFragment } from '../../services/load-oauth-applications.generated';

@Component({
  selector: 'man-application-form',
  templateUrl: './application-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationFormComponent implements OnInit {
  @Input() public application?: OAuthApplicationFieldsFragment;
  @Input() public canDismissWhileDirty?: boolean;
  @Input()
  public set disabled(value: boolean | undefined) {
    value === true ? this.form?.disable() : this.form?.enable();
  }
  public get disabled(): boolean {
    return this.form.disabled;
  }

  @Output() public create = new EventEmitter<OauthApplicationInput>();
  @Output() public update = new EventEmitter<
    OauthApplicationInput & { id: Scalars['ID'] }
  >();
  @Output() public closed = new EventEmitter<void>();

  public form: FormGroup;

  public get edit(): boolean {
    return this.application !== undefined;
  }

  constructor(public modalService: ModalService) {}

  public async onClose(): Promise<void> {
    if (this.disabled) {
      return;
    }

    await this.safeClose();
  }

  public ngOnInit(): void {
    this.initialiseForm();
  }

  public onSave(): void {
    if (!this.form.valid || !this.form.dirty) {
      return;
    }

    this.application === undefined
      ? this.create.emit(this.form.value)
      : this.update.emit({ id: this.application.id, ...this.form.value });
  }

  private initialiseForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      redirectUri: new FormControl('', [Validators.required, uriValidator])
    });

    if (this.application !== undefined) {
      this.form.patchValue(this.application);
    }
  }

  private async safeClose(): Promise<void> {
    if (this.canDismissWhileDirty === true || !this.form.dirty) {
      this.closed.emit();

      return;
    }

    const discardChanges = await this.modalService.confirmUnsavedChanges();
    if (discardChanges) {
      this.closed.emit();
    }
  }
}
