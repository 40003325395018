import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { isVoid } from '@common/utils/type-guards/voidable';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LegacyProvider } from 'src/app/ajs-upgraded-providers';
import { AssignBlueprintModalComponent } from 'src/app/common/modals/assign-blueprint-modal/assign-blueprint-modal.component';
import { AssignExamModalComponent } from 'src/app/common/modals/assign-exam-modal/assign-exam-modal.component';
import { AssignLabelModalComponent } from 'src/app/common/modals/assign-label-modal/assign-label-modal.component';
import { ChooseQuestionGroupTypeModalComponent } from 'src/app/common/modals/choose-question-group-type-modal/choose-question-group-type-modal.component';
import { ConfirmModalComponent } from 'src/app/common/modals/confirm-modal/confirm-modal.component';
import { DuplicateQuestionGroupModalComponent } from 'src/app/common/modals/duplicate-question-group-modal/duplicate-question-group-modal.component';
import { RevisionDocumentModalComponent } from 'src/app/common/modals/revision-document-modal/revision-document-modal.component';
import { SetAffiliationModalComponent } from 'src/app/common/modals/set-affiliation-modal/set-affiliation-modal.component';
import { SetRevisionModalComponent } from 'src/app/common/modals/set-revision-modal/set-revision-modal.component';
import { SetRevisionYearModalComponent } from 'src/app/common/modals/set-revision-year-modal/set-revision-year-modal.component';
import { SetSourceLanguageModalComponent } from 'src/app/common/modals/set-source-language-modal/set-source-language-modal.component';
import { SetSupervisorModalComponent } from 'src/app/common/modals/set-supervisor-modal/set-supervisor-modal.component';
import { WriteCommentModalComponent } from 'src/app/common/modals/write-comment-modal/write-comment-modal.component';
import {
  QuestionGroupType,
  QuestionType,
  Scalars
} from '../../../../generated/base-types';
import { LegacyStore, OriginReference } from '../../../common/legacy.types';
import { QuestionGroup } from '../../../common/question-group';
import { UiRouterService } from '../../../common/services/ui-router.service';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../../common/utils/ng-bootstrap-modal';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { ContextState } from '../../../state/context/context.state';
import { ReloadPool } from '../../../state/pool/pool.actions';
import { TaskListState } from '../../../task-management/state/task-list.state';
import { ReloadAllQuestionDetails } from '../../state/question-details.actions';
import {
  ReloadQuestionGroupList,
  ReloadSelectedQuestionGroups
} from '../../state/question-list.actions';
import { QuestionListState } from '../../state/question-list.state';
import { QuestionGroupExportModalComponent } from '../question-group-export-modal/question-group-export-modal.component';
import { DeleteQuestionGroupsGQL } from './delete-question-groups.generated';
import { DeleteStatisticsGQL } from './delete-statistics.generated';

export interface TaskSelectorType {
  getSelectedQuestionGroups: QuestionGroup[];
  getOrderAsParameter: (state: unknown) => string[];
}
export interface TaskActionType {
  fetchItemsRequest: () => void;
  fetchItemsSuccess: (items: QuestionGroup[]) => void;
}

export interface TaskListSelector {
  getSelection: (state: unknown) => number[];
}

export interface TaskQuestionGroupRepository {
  query(taskIds: number[], order: string[]): Promise<QuestionGroup[]>;
}

export enum ToolbarManagementType {
  Task = 'Task',
  Question = 'Question',
  Exam = 'Exam'
}

@Component({
  selector: 'qm-question-group-toolbar',
  templateUrl: './question-group-toolbar.component.html',
  styleUrls: ['./question-group-toolbar.component.scss']
})
export class QuestionGroupToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public managementType: ToolbarManagementType = ToolbarManagementType.Question;

  @Select(ContextState.currentPoolId)
  public currentPoolId$: Observable<Scalars['ID'] | undefined>;

  @Select(QuestionListState.selectedQuestionGroups)
  public selectedQuestionGroupIds$: Observable<
    QuestionGroupListElementFragment[]
  >;

  @Select(TaskListState.selectedQuestionGroups)
  public selectedTaskQuestionGroupIds$: Observable<
    QuestionGroupListElementFragment[]
  >;

  public get hasSelection(): boolean {
    return (
      this.selectedQuestionGroups !== undefined &&
      this.selectedQuestionGroups.length > 0
    );
  }

  public get hasExactlyOneSelection(): boolean {
    return (
      this.selectedQuestionGroups !== undefined &&
      this.selectedQuestionGroups.length === 1
    );
  }

  public selectedQuestionGroups: QuestionGroupListElementFragment[];
  public poolId: Scalars['ID'];
  private destroy$ = new Subject<void>();

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly translateService: TranslateService,
    private readonly deleteStatisticsGQL: DeleteStatisticsGQL,
    private readonly deleteQuestionGroupsGQL: DeleteQuestionGroupsGQL,
    private readonly store: Store,
    private readonly uiRouterService: UiRouterService,
    @Inject(LegacyProvider.Store) private readonly legacyStore: LegacyStore,
    @Inject(LegacyProvider.SelectorTaskQuestionGroupList)
    private readonly legacyTaskQuestionGroupListSelectors: TaskSelectorType,
    @Inject(LegacyProvider.ActionTaskQuestionGroupList)
    private readonly legacyTaskQuestionGroupListActions: TaskActionType,
    @Inject(LegacyProvider.SelectorTaskList)
    private readonly legacyTaskListSelectors: TaskListSelector,
    @Inject(LegacyProvider.RepositoryTaskQuestionGroup)
    private readonly legacyTaskQuestionGroupRepository: TaskQuestionGroupRepository
  ) {}

  public ngOnInit(): void {
    const questionGroups$ =
      this.managementType === ToolbarManagementType.Task
        ? this.selectedTaskQuestionGroupIds$
        : this.selectedQuestionGroupIds$;

    questionGroups$
      .pipe(takeUntil(this.destroy$))
      .subscribe(qgs => (this.selectedQuestionGroups = qgs));

    this.currentPoolId$.pipe(takeUntil(this.destroy$)).subscribe(id => {
      if (!isVoid(id)) {
        this.poolId = id;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createQuestion(): Promise<void> {
    const ref = this.prepareModal(ChooseQuestionGroupTypeModalComponent);

    return ref.result.then(
      (result: {
        questionGroupType: QuestionGroupType;
        questionType: QuestionType;
      }) => {
        this.uiRouterService.go('newQuestionForm', {
          questionType: result.questionType,
          questionGroupType: result.questionGroupType,
          ref: OriginReference.QuestionManagement
        });
      }
    );
  }

  public deleteQuestion(): Promise<void> {
    const title = this.translateService.instant(
      'question_management.toolbar.delete_modal.title'
    );
    const message = this.translateService.instant(
      'question_management.toolbar.delete_modal.content',
      {
        amount: this.selectedQuestionGroups.length ?? 0
      }
    );

    const ref = this.prepareModal(ConfirmModalComponent);

    ref.componentInstance.title = title;
    ref.componentInstance.message = message;

    return ref.result
      .then(async _result => {
        const questionGroupIds = this.selectedQuestionGroups.map(qg => qg.id);
        const result = await this.deleteQuestionGroupsGQL
          .mutate({
            poolId: this.poolId,
            questionGroupIds
          })
          .toPromise();

        if (result?.data?.deleteQuestionGroups?.successful !== true) {
          throw `Unable to delete question groups ${questionGroupIds}`;
        }

        this.reloadQuestionGroupList();
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openDuplicateModal(): Promise<void> {
    const ref = this.prepareModal(DuplicateQuestionGroupModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openExamsModal(): Promise<void> {
    const ref = this.prepareModal(AssignExamModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openCommentModal(): Promise<void> {
    const ref = this.prepareModal(WriteCommentModalComponent);

    return ref.result
      .then(_result => {
        this.store.dispatch(new ReloadAllQuestionDetails());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openLabelModal(): Promise<void> {
    const ref = this.prepareModal(AssignLabelModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
        this.store.dispatch(new ReloadPool());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openRevisionStatusModal(): Promise<void> {
    const ref = this.prepareModal(SetRevisionModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
        this.store.dispatch(new ReloadPool());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openSupervisorModal(): Promise<void> {
    const ref = this.prepareModal(SetSupervisorModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
        this.store.dispatch(new ReloadPool());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openAffiliationModal(): Promise<void> {
    const ref = this.prepareModal(SetAffiliationModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
        this.store.dispatch(new ReloadPool());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openRevisionYearModal(): Promise<void> {
    const ref = this.prepareModal(SetRevisionYearModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
        this.store.dispatch(new ReloadPool());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openSourceLanguageModal(): Promise<void> {
    const ref = this.prepareModal(SetSourceLanguageModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openBlueprintModal(): Promise<void> {
    const ref = this.prepareModal(AssignBlueprintModalComponent);

    return ref.result
      .then(_result => {
        this.reloadQuestionGroupList();
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openRevisionDocumentModal(): Promise<void> {
    const ref = this.prepareModal(RevisionDocumentModalComponent);

    return ref.result;
  }

  public openExportModal(): Promise<void> {
    const ref = this.prepareModal(QuestionGroupExportModalComponent);

    return ref.result;
  }

  public openDeleteStatisticsModal(): Promise<void> {
    const title = this.translateService.instant(
      'common.delete_statistics_modal.confirmation.title'
    );
    const message = this.translateService.instant(
      'common.delete_statistics_modal.confirmation.content'
    );

    const ref = this.prepareModal(ConfirmModalComponent);

    ref.componentInstance.title = title;
    ref.componentInstance.message = message;

    return ref.result
      .then(async _result => {
        const questionGroupIds = this.selectedQuestionGroups.map(qg =>
          qg.id.toString()
        );
        const result = await this.deleteStatisticsGQL
          .mutate({
            poolId: this.poolId,
            questionGroupIds
          })
          .toPromise();

        if (result?.data?.deleteStatistics?.successful !== true) {
          throw `Unable to delete statistics for question groups ${questionGroupIds}`;
        }

        this.reloadQuestionGroupList();
        this.store.dispatch(new ReloadAllQuestionDetails());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public reloadQuestionGroupList(): void {
    if (this.managementType === ToolbarManagementType.Question) {
      this.store.dispatch(new ReloadQuestionGroupList());
      this.store.dispatch(new ReloadSelectedQuestionGroups());
    } else {
      this.reloadTaskQuestionGroupList();
    }
  }

  private prepareModal(symbol: unknown): NgbModalRef {
    const ref = this.ngbModal.open(symbol, NG_MODAL_DEFAULT_OPTIONS);
    ref.componentInstance.modalInstance = ref;
    ref.componentInstance.questionGroups = this.selectedQuestionGroups;
    ref.componentInstance.currentPoolId = this.poolId;

    return ref;
  }

  private reloadTaskQuestionGroupList(): void {
    this.legacyStore.dispatch(
      this.legacyTaskQuestionGroupListActions.fetchItemsRequest()
    );
    this.legacyTaskQuestionGroupRepository
      .query(
        this.legacyTaskListSelectors.getSelection(this.legacyStore.getState()),
        this.legacyTaskQuestionGroupListSelectors.getOrderAsParameter(
          this.legacyStore.getState()
        )
      )
      .then(items => {
        this.legacyStore.dispatch(
          this.legacyTaskQuestionGroupListActions.fetchItemsSuccess(items)
        );
      });
  }
}
