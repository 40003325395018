import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { takeGraphQLResult } from '../../../common/operators/take-graphql-response';
import { ToastsService } from '../../../common/services/toasts.service';
import { parseError } from '../../../common/utils/error-parser';
import { RemoteData } from '../../../common/utils/remote-data';
import { isDefined } from '../../../common/utils/type-guards/is-defined';
import { ModalService } from '../../common/modal/modal.service';
import { DeleteUserGQL } from '../services/delete-user.generated';
import { LoadUsersFieldsFragment } from '../services/load-users.fragments.generated';
import { LoadUsersGQL } from '../services/load-users.generated';
import {
  DeleteUser,
  DeleteUserFailure,
  DeleteUserSuccess,
  LoadUsers,
  LoadUsersFailure,
  LoadUsersSuccess
} from './users.actions';

export interface UsersStateModel {
  users: RemoteData<LoadUsersFieldsFragment[]>;
  mutation: RemoteData<LoadUsersFieldsFragment>;
}

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: {
      requestState: 'initial'
    },
    mutation: {
      requestState: 'initial'
    }
  }
})
@Injectable({
  providedIn: 'root'
})
export class UsersState {
  constructor(
    private readonly loadUsersService: LoadUsersGQL,
    private readonly deleteUserService: DeleteUserGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly modalService: ModalService
  ) {}

  @Selector()
  public static user(
    state: UsersStateModel
  ): (id: string) => LoadUsersFieldsFragment | undefined {
    return (id: string) => state.users.data?.find(user => user?.id === id);
  }

  @Selector()
  public static users(
    state: UsersStateModel
  ): RemoteData<LoadUsersFieldsFragment[]> {
    return { ...state.users, actions: { retry: LoadUsers } };
  }

  @Action(LoadUsers)
  public loadUsers(ctx: StateContext<UsersStateModel>): Observable<void> {
    ctx.patchState({
      users: {
        requestState: 'loading'
      }
    });

    return this.loadUsersService.fetch().pipe(
      takeGraphQLResult(),
      switchMap(res => ctx.dispatch(new LoadUsersSuccess(res.users))),
      catchError((err: unknown) => {
        return ctx.dispatch(new LoadUsersFailure(parseError(err)));
      })
    );
  }

  @Action(LoadUsersSuccess)
  public loadUsersSuccess(
    ctx: StateContext<UsersStateModel>,
    { users }: LoadUsersSuccess
  ): void {
    ctx.patchState({
      users: {
        data: users,
        requestState: 'success'
      }
    });
  }

  @Action(LoadUsersFailure)
  public loadUsersFailure(
    ctx: StateContext<UsersStateModel>,
    { error }: LoadUsersFailure
  ): void {
    ctx.patchState({
      users: {
        error,
        requestState: 'failure'
      }
    });
  }

  @Action(DeleteUser)
  public deleteUser(
    ctx: StateContext<UsersStateModel>,
    { id }: DeleteUser
  ): Observable<void> {
    ctx.patchState({
      mutation: {
        requestState: 'loading'
      }
    });

    return this.deleteUserService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteUserSuccess(id))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteUserFailure(parseError(err), id))
      )
    );
  }

  @Action(DeleteUserSuccess)
  public deleteUserSuccess(
    ctx: StateContext<UsersStateModel>,
    { id }: DeleteUserSuccess
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'success'
      },
      users: {
        data: ctx.getState().users.data?.filter(u => u.id !== id),
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('activerecord.models.user.one')
      })
    );
  }

  @Action(DeleteUserFailure)
  public deleteUserFailure(
    ctx: StateContext<UsersStateModel>,
    { error, id }: DeleteUserFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (retry) {
        ctx.dispatch(new DeleteUser(id));
      }
    });
  }
}
