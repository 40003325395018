<ng-container *ngIf="loading">
  <loading-details
    loadingMessage="common.question_group_details.preview.in_work"
  ></loading-details>
</ng-container>
<ng-container *ngIf="!loading">
  <ng-container *ngIf="(questionsByLanguage | json) !== '{}'; else noPreview">
    <p-accordion
      [multiple]="true"
      (onOpen)="onTabOpen($event)"
      (onClose)="onTabClose($event)"
      [activeIndex]="activeIds | async"
    >
      <ng-container *ngFor="let question of questionsByLanguage | keyvalue">
        <p-accordionTab
          header="{{ 'scrudu.languages.long.' + question.key | translate }}"
        >
          <img [src]="question.value" />
        </p-accordionTab>
      </ng-container>
    </p-accordion>
  </ng-container>
  <ng-template #noPreview>
    <details-info
      message="pool.question_group_details.preview.no_preview"
    ></details-info>
  </ng-template>
</ng-container>
