/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { takeGraphQLResult } from '@common/operators/take-graphql-response';
import { parseError } from '@common/utils/error-parser';
import { RemoteData } from '@common/utils/remote-data';
import type { NgxsOnInit, StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Language, Pool, Scalars } from 'src/generated/base-types';
import {
  CurrentUserDetailsGQL,
  CurrentUserFragment
} from '../../services/current-user.generated';
import {
  LoadCurrentUser,
  LoadCurrentUserFailure,
  LoadCurrentUserSuccess,
  SetCurrentLanguage,
  SetCurrentPool
} from './context.actions';

type CurrentPoolModel = Pick<Pool, 'id' | 'languages'>;

export interface ContextStateModel {
  currentPool?: CurrentPoolModel;
  currentUser: RemoteData<CurrentUserFragment>;
  currentLanguage?: Language;
}

@State<ContextStateModel>({
  name: 'main',
  defaults: {
    currentUser: {
      requestState: 'initial'
    }
  }
})
@Injectable()
export class ContextState implements NgxsOnInit {
  constructor(private readonly currentUserDetailsGQL: CurrentUserDetailsGQL) {}

  @Selector()
  public static currentPoolId(
    state: ContextStateModel
  ): Scalars['ID'] | undefined {
    return state.currentPool?.id;
  }

  @Selector()
  public static currentPoolLanguages(state: ContextStateModel): Language[] {
    return state.currentPool?.languages || [];
  }

  @Selector()
  public static currentUser(
    state: ContextStateModel
  ): CurrentUserFragment | undefined {
    return state.currentUser.data;
  }

  @Selector()
  public static currentUserState(
    state: ContextStateModel
  ): RemoteData<CurrentUserFragment> {
    return state.currentUser;
  }

  @Selector()
  public static currentLanguage(state: ContextStateModel): Language {
    return state.currentLanguage ?? Language.De;
  }

  @Action(SetCurrentPool)
  public setCurrentPoolId(
    ctx: StateContext<ContextStateModel>,
    action: SetCurrentPool
  ): void {
    ctx.patchState({
      currentPool: { id: action.id, languages: action.languages }
    });
  }

  @Action(SetCurrentLanguage)
  public setCurrentLanguage(
    ctx: StateContext<ContextStateModel>,
    action: SetCurrentLanguage
  ): void {
    ctx.patchState({
      currentLanguage: action.language
    });
  }

  @Action(LoadCurrentUser)
  public loadCurrentUser(
    ctx: StateContext<ContextStateModel>,
    _action: LoadCurrentUser
  ): Observable<void> {
    ctx.patchState({
      currentUser: {
        requestState: 'loading'
      }
    });

    return this.currentUserDetailsGQL.fetch().pipe(
      takeGraphQLResult(),
      switchMap(res =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ctx.dispatch(new LoadCurrentUserSuccess(res.currentUser!))
      ),
      catchError((err: unknown) => {
        return ctx.dispatch(new LoadCurrentUserFailure(parseError(err)));
      })
    );
  }

  @Action(LoadCurrentUserSuccess)
  public loadUsersSuccess(
    ctx: StateContext<ContextStateModel>,
    { currentUser }: LoadCurrentUserSuccess
  ): void {
    ctx.patchState({
      currentUser: {
        requestState: 'success',
        data: currentUser
      }
    });

    // TODO: can be removed once the legacy store has been removed
    // and the new store is the single source of truth
    if (!ctx.getState().currentLanguage) {
      ctx.dispatch(new SetCurrentLanguage(currentUser.language ?? Language.De));
    }
  }

  @Action(LoadCurrentUserFailure)
  public loadUsersFailure(
    ctx: StateContext<ContextStateModel>,
    { error }: LoadCurrentUserFailure
  ): void {
    ctx.patchState({
      currentUser: {
        error,
        requestState: 'failure'
      }
    });
  }

  public ngxsOnInit(ctx: StateContext<ContextStateModel>): void {
    ctx.dispatch(new LoadCurrentUser());
  }
}
