import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule as ManagerCommonModule } from '../../common/common.module';
import { LOCATION_TOKEN } from './location';
import { AddCommentModalComponent } from './modal/add-comment-modal/add-comment-modal.component';
import { ConfirmModalComponent } from './modal/confirm-modal.component';
import { ErrorModalComponent } from './modal/error-modal.component';
import { SetSupervisorModalComponent } from './modal/set-supervisor-modal/set-supervisor-modal.component';

const components = [
  ConfirmModalComponent,
  ErrorModalComponent,
  AddCommentModalComponent,
  SetSupervisorModalComponent
];

@NgModule({
  imports: [AngularCommonModule, ManagerCommonModule, TranslateModule],
  declarations: components,
  exports: components,
  providers: [
    {
      provide: LOCATION_TOKEN,
      useValue: window.location
    }
  ]
})
export class CommonModule {}
