import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import { AngularSplitModule } from 'angular-split';

// TODO: import these types from 'angular-split' module when Angular is updated to v17
export interface SplitGutterInteractionEvent {
  gutterNum: number;
  sizes: Array<number | '*'>;
}

type PanelConfig = {
  size: number;
  minSize: number;
  maxSize: number;
  collapsed: boolean;
};

type LayoutConfig = {
  left: PanelConfig;
  right: PanelConfig;
};

const DEFAULT_PANEL_CONFIG: PanelConfig = {
  size: 450,
  collapsed: false,
  maxSize: 750,
  minSize: 450
};

const DEFAULT_LAYOUT_CONFIG: LayoutConfig = {
  left: { ...DEFAULT_PANEL_CONFIG },
  right: { ...DEFAULT_PANEL_CONFIG }
};

@Component({
  selector: 'man-panel-layout-wrapper',
  templateUrl: './panel-layout-wrapper.component.html',
  styleUrls: ['./panel-layout-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularSplitModule],
  host: { style: 'flex: 1 1 0; min-width: 0;' }
})
export class PanelLayoutWrapperComponent implements OnInit, AfterViewInit {
  @Input() public set leftPanelSize(value: number) {
    this.config.left.size = value;
  }
  @Input() public set leftPanelMaxSize(value: number) {
    this.config.left.maxSize = value;
  }
  @Input() public set leftPanelMinSize(value: number) {
    this.config.left.minSize = value;
  }
  @Input() public set rightPanelSize(value: number) {
    this.config.right.size = value;
  }
  @Input() public set rightPanelMaxSize(value: number) {
    this.config.right.maxSize = value;
  }
  @Input() public set rightPanelMinSize(value: number) {
    this.config.right.minSize = value;
  }
  @Input() public pageName: string;

  public collapsedPanelSize = 30;
  public config: LayoutConfig = DEFAULT_LAYOUT_CONFIG;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.config = this.readFromLocalStorage();
  }

  public ngAfterViewInit(): void {
    this.setCollapsedGutterStyle('left');
    this.setCollapsedGutterStyle('right');
  }

  public togglePanel(panel: 'left' | 'right'): void {
    this.config[panel].collapsed = !this.config[panel].collapsed;
    this.saveToLocalStorage();
    this.changeDetector.detectChanges();
    this.setCollapsedGutterStyle(panel);
  }

  public onDragEnd({ sizes, gutterNum }: SplitGutterInteractionEvent): void {
    if (gutterNum === 1) {
      this.config.left.size = sizes[0] as number;
    } else if (gutterNum === 2) {
      this.config.right.size = sizes[2] as number;
    }
    this.saveToLocalStorage();
  }

  private get localStorageKey(): string {
    return `panel-layout-config-${this.pageName}`;
  }

  private saveToLocalStorage(): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.config));
  }

  private readFromLocalStorage(): LayoutConfig {
    const config = localStorage.getItem(this.localStorageKey);

    return config === null ? this.config : JSON.parse(config);
  }

  private setCollapsedGutterStyle(panel: 'left' | 'right'): void {
    const gutterList =
      document.querySelectorAll<HTMLElement>('.as-split-gutter');
    const gutterMap = {
      left: 0,
      right: 1
    };
    const gutterTarget = gutterList[gutterMap[panel]];

    if (this.config[panel].collapsed) {
      this.renderer.addClass(gutterTarget, 'collapsed');
    } else {
      this.renderer.removeClass(gutterTarget, 'collapsed');
    }
  }
}
