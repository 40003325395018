import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { RemoteData } from '@common/utils/remote-data';
import { Store } from '@ngxs/store';
import { PoolsState } from '@state/pools';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { FuzzySearchService } from '../../common/fuzzy-search.service';
import { PoolListElementFragment } from '../services/load-pools.generated';

@Component({
  templateUrl: './pools.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container content' }
})
export class PoolsComponent {
  public readonly filterFields: (keyof PoolListElementFragment)[] = [
    'id',
    'name'
  ];
  public readonly pools$: Observable<RemoteData<PoolListElementFragment[]>>;
  public readonly selection$: Observable<PoolListElementFragment | undefined>;

  public totalPoolCount: string;
  public filteredPoolCount: string;

  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private fuzzySearchService: FuzzySearchService<PoolListElementFragment>
  ) {
    this.pools$ = combineLatest([
      this.store.select(PoolsState.pools),
      this.searchString$
    ]).pipe(
      map(([pools, searchString]) => {
        const result = this.fuzzySearchService.search(
          pools.data || [],
          searchString,
          this.filterFields,
          'name'
        );

        this.totalPoolCount = (
          pools.data ? pools.data.length : 0
        ).toLocaleString();
        this.filteredPoolCount = result.length.toLocaleString();

        return { ...pools, data: result };
      })
    );
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
  }

  public onSearchClear(): void {
    this.searchStringSource.next('');
  }

  public onCreate(): void {
    this.router.navigate(['create'], {
      relativeTo: this.route
    });
  }

  public onEdit(pool: PoolListElementFragment): void {
    this.router.navigate([pool.id], {
      relativeTo: this.route
    });
  }
}
