import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Language, Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { PoolState } from '../../../state/pool/pool.state';
import { SetSourceLanguageGQL } from './set-source-language.generated';

@Component({
  selector: 'co-set-source-language-modal',
  templateUrl: './set-source-language-modal.component.html',
  styleUrls: ['./set-source-language-modal.component.scss']
})
export class SetSourceLanguageModalComponent implements OnInit, OnDestroy {
  @Input()
  public modalInstance: NgbModalRef;
  @Input()
  public questionGroups: QuestionGroupListElementFragment[];
  @Select(PoolState.languages)
  public languages$: Observable<Language[]>;
  @Input()
  public currentPoolId: Scalars['ID'];

  public isSaving = false;
  public selectedLanguage: string;
  public languages: Language[];
  private destroy$ = new Subject<void>();

  constructor(private readonly setSourceLanguageGQL: SetSourceLanguageGQL) {}

  public ngOnInit(): void {
    this.languages$
      .pipe(takeUntil(this.destroy$))
      .subscribe(languages => (this.languages = languages));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async save(): Promise<void> {
    this.isSaving = true;
    const result = await this.setSourceLanguageGQL
      .mutate({
        poolId: this.currentPoolId,
        questionGroupIds: this.questionGroups.map(qg => qg.id),
        language: this.selectedLanguage as Language
      })
      .toPromise();

    if (result?.data?.setSourceLanguage?.successful !== true) {
      throw `Unable to set source language on question groups ${this.questionGroups.map(
        qg => qg.id
      )}`;
    }

    this.isSaving = false;
    this.modalInstance.close();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }
}
