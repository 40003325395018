import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import { TaskStatus } from 'src/generated/base-types';

@Component({
  selector: 'man-task-status',
  standalone: true,
  imports: [CommonModule, ManagerCommonModule],
  templateUrl: './task-status.component.html'
})
export class TaskStatusComponent {
  @Input() public status: TaskStatus;
}
