import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { FormState } from 'src/app/common/types/form-types';
import { RemoteData } from 'src/app/common/utils/remote-data';
import {
  assertIsDefined,
  isDefined
} from 'src/app/common/utils/type-guards/is-defined';
import { PoolFormState } from 'src/app/new/admin/state/pool-details/form/pool-form.state';
import { ActionMenuOption } from 'src/app/new/common/action-menu/action-menu.component';
import { FuzzySearchService } from 'src/app/new/common/fuzzy-search.service';
import { ModalService } from 'src/app/new/common/modal/modal.service';
import { PermissionInput, PermissionRole } from 'src/generated/base-types';
import { PoolPermissionsFragment } from '../../../services/load-pool-permissions.fragments.generated';
import {
  CreatePoolPermission,
  DeletePoolPermission,
  UpdatePoolPermission
} from '../../../state/pool-details/permissions/permissions.actions';
import { PermissionsState } from '../../../state/pool-details/permissions/permissions.state';
import { PermissionFormComponent } from './permission-form/permission-form.component';

@Component({
  templateUrl: './pool-permissions.component.html',
  host: { class: 'page' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoolPermissionsComponent implements OnDestroy {
  @ViewChild(PermissionFormComponent)
  public formComponent: PermissionFormComponent;

  public readonly poolId$: Observable<string | undefined>;
  public readonly filterFields = [
    'user.firstName',
    'user.lastName',
    'user.email'
  ];
  public readonly poolPermissions$: Observable<
    RemoteData<PoolPermissionsFragment[]>
  >;
  public readonly formState$: Observable<
    FormState<Partial<PoolPermissionsFragment>>
  >;
  public readonly menuOptions: ActionMenuOption[] = [
    {
      label: 'Edit',
      callback: (data: PoolPermissionsFragment) => this.onEdit(data)
    },
    {
      label: 'Delete',
      danger: true,
      callback: (data: PoolPermissionsFragment) => this.onDelete(data)
    }
  ];
  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private fuzzySearchService: FuzzySearchService<PoolPermissionsFragment>
  ) {
    this.formState$ = this.formState();
    this.dismissSidebarOnSuccessfulMutation();
    this.poolPermissions$ = this.store.select(PermissionsState.permissions);
    this.poolId$ = this.store.select(PoolFormState.poolId);

    this.poolPermissions$ = combineLatest([
      this.store.select(PermissionsState.permissions),
      this.searchString$
    ]).pipe(
      map(([permissions, searchString]) => {
        const result = this.fuzzySearchService.search(
          permissions.data || [],
          searchString,
          this.filterFields
        );

        return { ...permissions, data: result };
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
  }

  public onSearchClear(): void {
    this.searchStringSource.next('');
  }

  public onNew(): void {
    this.routeToForm('new');
  }

  public onEdit({ id }: PoolPermissionsFragment): void {
    this.routeToForm(id);
  }

  public onCreate(permissionInput: PermissionInput): void {
    assertIsDefined(permissionInput.poolId, 'Pool ID is required');
    assertIsDefined(permissionInput.userId, 'User ID is required');
    assertIsDefined(permissionInput.role, 'Role is required');

    this.store.dispatch(new CreatePoolPermission(permissionInput));
  }

  public onUpdate(permissionInput: PermissionInput): void {
    const poolPermissionInput: PermissionInput = {
      role: permissionInput.role as PermissionRole,
      subjectId: permissionInput.subjectId,
      userId: permissionInput.userId
    };

    this.route.queryParams
      .pipe(
        map<{ editing?: string }, string | undefined>(
          params => params?.editing
        ),
        filter(isDefined),
        take(1),
        switchMap(editing => {
          return this.store.dispatch(
            new UpdatePoolPermission(editing, poolPermissionInput)
          );
        })
      )
      .subscribe();
  }

  public onClose(): void {
    this.formComponent.onClose();
  }

  public onDismiss(): void {
    this.routeToForm();
  }

  public async onDelete(permission: PoolPermissionsFragment): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();
    if (confirmDelete) {
      this.store.dispatch(new DeletePoolPermission(permission.id));
    }
  }

  private formState(): Observable<FormState<Partial<PoolPermissionsFragment>>> {
    const editingParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editing') ?? undefined)
    );
    const disabled$ = this.store
      .select(PermissionsState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    return combineLatest([editingParam$, disabled$]).pipe(
      switchMap(([editingParam, disabled]) => {
        const show: boolean = editingParam !== undefined;
        const id = show && editingParam !== 'new' ? editingParam : undefined;

        return this.poolPermissions$.pipe(
          filter(({ requestState }) => requestState === 'success'),
          take(1),
          map(({ data }) =>
            id === undefined ? undefined : data?.find(a => a.id === id)
          ),
          map(data => ({ show, disabled, data }))
        );
      })
    );
  }

  private dismissSidebarOnSuccessfulMutation(): void {
    this.store
      .select(PermissionsState.mutationRequestState)
      .pipe(
        pairwise(),
        filter(
          ([lastState, currentState]) =>
            lastState === 'loading' && currentState === 'success'
        ),
        tap({ next: () => this.onDismiss() }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private routeToForm(editing?: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editing },
      queryParamsHandling: 'merge'
    });
  }
}
