/* eslint-disable unicorn/no-null */
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { takeGraphQLResult } from '@common/operators/take-graphql-response';
import { isVoid } from '@common/utils/type-guards/voidable';
import { map, Observable, of, timer, switchMap, first } from 'rxjs';
import { CheckEmailGQL } from '../../services/check-email.generated';

@Injectable({
  providedIn: 'root'
})
export class EmailValidatorService {
  constructor(private readonly checkEmailGQL: CheckEmailGQL) {}

  // using RFC 2822 compliant regex
  // https://stackoverflow.com/a/1373724
  public isEmailValid = (control: AbstractControl): ValidationErrors | null => {
    if (isVoid(control.value) || control.value === '') return null;

    const railsEmailRegex =
      /[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*@(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z][\da-z-]*[\da-z]/;

    return railsEmailRegex.test(control.value) ? null : { email: true };
  };

  public isEmailInUse = (
    currentUserEmail?: string | null
  ): AsyncValidatorFn => {
    return (control: AbstractControl) => {
      if (isVoid(control.value) || control.value === '') return of(null);
      if (!isVoid(currentUserEmail) && currentUserEmail === control.value)
        return of(null);

      return timer(500).pipe(
        switchMap(() =>
          this.checkIfEmailExists(control.value).pipe(
            map(res => (res.userExists ? { emailInUse: true } : null))
          )
        ),
        first()
      );
    };
  };

  private checkIfEmailExists(
    email: string
  ): Observable<{ userExists: boolean }> {
    return this.checkEmailGQL
      .fetch({
        email
      })
      .pipe(takeGraphQLResult());
  }
}
