import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoadDimensionsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type LoadDimensionsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', dimensions: Array<{ __typename?: 'Dimension', id: string, position: number, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, categories: Array<{ __typename?: 'Category', id: string, position: number, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, customIdentifier?: string | null }> }> } };

export const LoadDimensionsDocument = gql`
    query LoadDimensions($poolId: ID!) {
  pool(id: $poolId) {
    dimensions {
      id
      position
      nameDe
      nameFr
      nameEn
      nameIt
      categories {
        id
        position
        nameDe
        nameFr
        nameEn
        nameIt
        customIdentifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadDimensionsGQL extends Apollo.Query<LoadDimensionsQuery, LoadDimensionsQueryVariables> {
    document = LoadDimensionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }