import { Injectable } from '@angular/core';
import { takeGraphQLResult } from '@common/operators/take-graphql-response';
import { parseError } from '@common/utils/error-parser';
import { RemoteData } from '@common/utils/remote-data';
import {
  requestStateFailure,
  requestStateLoading
} from '@common/utils/remote-data-utils';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ModalService } from '../../../common/modal/modal.service';
import {
  LoadPoolDetailsGQL,
  PoolMetadataFragment
} from '../../services/pool/load-pool.generated';
import { LoadPool, LoadPoolFailure, LoadPoolSuccess } from './pool.actions';

export interface PoolStateModel {
  pool: RemoteData<PoolMetadataFragment>;
  mutation: RemoteData<void>;
}

@State<PoolStateModel>({
  name: 'newpool',
  defaults: {
    pool: { requestState: 'initial' },
    mutation: { requestState: 'initial' }
  }
})
@Injectable({
  providedIn: 'root'
})
export class PoolState {
  constructor(
    private readonly loadPoolDetailsService: LoadPoolDetailsGQL,
    private readonly modalService: ModalService
  ) {}

  @Selector()
  public static pool(state: PoolStateModel): RemoteData<PoolMetadataFragment> {
    return state.pool;
  }

  @Selector()
  public static poolLoading(state: PoolStateModel): boolean {
    return requestStateLoading(state.pool);
  }

  @Selector()
  public static poolFailure(state: PoolStateModel): boolean {
    return requestStateFailure(state.pool);
  }

  @Selector()
  public static poolId(state: PoolStateModel): string | undefined {
    return state.pool.data?.id;
  }

  @Selector()
  public static supervisors(state: PoolStateModel): string[] {
    return state.pool.data?.supervisors || [];
  }

  @Action(LoadPool, { cancelUncompleted: true })
  public loadPool(
    ctx: StateContext<PoolStateModel>,
    { id }: LoadPool
  ): Observable<void> {
    ctx.patchState({ pool: { requestState: 'loading' } });

    return this.loadPoolDetailsService.fetch({ poolId: id }).pipe(
      takeGraphQLResult(),
      switchMap(res => ctx.dispatch(new LoadPoolSuccess(res.pool))),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadPoolFailure(parseError(err), id))
      )
    );
  }

  @Action(LoadPoolSuccess)
  public loadPoolSuccess(
    ctx: StateContext<PoolStateModel>,
    { pool }: LoadPoolSuccess
  ): void {
    ctx.patchState({
      pool: {
        data: pool,
        requestState: 'success'
      }
    });
  }

  @Action(LoadPoolFailure)
  public loadPoolFailure(
    ctx: StateContext<PoolStateModel>,
    { error, id }: LoadPoolFailure
  ): void {
    ctx.patchState({
      pool: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (!retry) return;

      ctx.dispatch(new LoadPool(id));
    });
  }
}
