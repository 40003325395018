import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CheckEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type CheckEmailQuery = { __typename?: 'Query', userExists: boolean };

export const CheckEmailDocument = gql`
    query CheckEmail($email: String!) {
  userExists(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckEmailGQL extends Apollo.Query<CheckEmailQuery, CheckEmailQueryVariables> {
    document = CheckEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }