import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { isDefined } from 'angular';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { takeGraphQLResult } from 'src/app/common/operators/take-graphql-response';
import { ToastsService } from 'src/app/common/services/toasts.service';
import { parseError } from 'src/app/common/utils/error-parser';
import { RemoteData, RequestState } from 'src/app/common/utils/remote-data';
import { requestStateLoading } from 'src/app/common/utils/remote-data-utils';
import { assertIsDefined } from 'src/app/common/utils/type-guards/is-defined';
import { CreateCategoryGQL } from 'src/app/new/admin/services/create-category.generated';
import { CreateDimensionGQL } from 'src/app/new/admin/services/create-dimension.generated';
import { DeleteCategoryGQL } from 'src/app/new/admin/services/delete-category.generated';
import { LoadDimensionsGQL } from 'src/app/new/admin/services/load-dimensions.generated';
import { SetCategoryPositionGQL } from 'src/app/new/admin/services/set-category-position.generated';
import { SetDimensionPositionGQL } from 'src/app/new/admin/services/set-dimension-position.generated';
import { UpdateCategoryGQL } from 'src/app/new/admin/services/update-category.generated';
import { UpdateDimensionGQL } from 'src/app/new/admin/services/update-dimension.generated';
import { Category, Dimension, Scalars } from 'src/generated/base-types';
import { DeleteDimensionGQL } from '../../../services/delete-dimension.generated';
import { PoolFormState } from '../form/pool-form.state';
import {
  CreateCategory,
  CreateCategoryFailure,
  CreateCategorySuccess,
  CreateDimension,
  CreateDimensionFailure,
  CreateDimensionSuccess,
  DeleteCategory,
  DeleteCategoryFailure,
  DeleteCategorySuccess,
  DeleteDimension,
  DeleteDimensionFailure,
  DeleteDimensionSuccess,
  LoadDimensions,
  LoadDimensionsFailure,
  LoadDimensionsSuccess,
  SetCategoryPosition,
  SetCategoryPositionFailure,
  SetCategoryPositionSuccess,
  SetDimensionPosition,
  SetDimensionPositionFailure,
  SetDimensionPositionSuccess,
  UpdateCategory,
  UpdateCategoryFailure,
  UpdateCategorySuccess,
  UpdateDimension,
  UpdateDimensionFailure,
  UpdateDimensionSuccess
} from './dimensions.actions';

export type CategoryData = Omit<Category, 'dimension' | 'name'>;

export type DimensionData = Omit<
  Omit<Dimension, 'truncated' | 'name'>,
  keyof { categories: CategoryData[] }
> & { categories: CategoryData[] };

export interface DimensionsStateModel {
  poolId?: Scalars['ID'];
  dimensions: RemoteData<DimensionData[]>;
  mutation?: RemoteData<DimensionData | CategoryData>;
}

export const initialDimensionsState: DimensionsStateModel = {
  dimensions: {
    requestState: 'initial'
  },
  mutation: { requestState: 'initial' }
};

@State<DimensionsStateModel>({
  name: 'dimensions',
  defaults: {
    ...initialDimensionsState
  }
})
@Injectable({
  providedIn: 'root'
})
export class DimensionsState {
  constructor(
    private readonly toasts: ToastsService,
    private readonly loadDimensionsService: LoadDimensionsGQL,
    private readonly createDimensionService: CreateDimensionGQL,
    private readonly createCategoryService: CreateCategoryGQL,
    private readonly updateDimensionService: UpdateDimensionGQL,
    private readonly updateCategoryService: UpdateCategoryGQL,
    private readonly deleteDimensionService: DeleteDimensionGQL,
    private readonly deleteCategoryService: DeleteCategoryGQL,
    private readonly toast: ToastsService,
    private readonly translate: TranslateService,
    private readonly setDimensionPositionService: SetDimensionPositionGQL,
    private readonly setCategoryPositionService: SetCategoryPositionGQL,
    private readonly store: Store
  ) {}

  @Selector()
  public static findDimensionAtIndex(
    state: DimensionsStateModel
  ): (index: number) => DimensionData | undefined {
    return (index: number) => {
      return state.dimensions.data?.at(index);
    };
  }

  @Selector()
  public static findCategoryAtIndex(
    state: DimensionsStateModel
  ): (
    dimensionId: Scalars['ID'],
    categoryIndex: number
  ) => CategoryData | undefined {
    return (dimensionId: Scalars['ID'], categoryIndex: number) =>
      state.dimensions.data
        ?.find(d => d.id === dimensionId)
        ?.categories.at(categoryIndex);
  }

  @Selector()
  public static dimensions(
    state: DimensionsStateModel
  ): RemoteData<DimensionData[]> {
    return { ...state.dimensions, actions: { retry: LoadDimensions } };
  }

  @Selector()
  public static dimensionsLoading(state: DimensionsStateModel): boolean {
    return requestStateLoading(state.dimensions);
  }

  @Selector()
  public static mutationRequestState(
    state: DimensionsStateModel
  ): RequestState | undefined {
    return state.mutation?.requestState;
  }

  @Action(LoadDimensions)
  public loadDimensions(
    ctx: StateContext<DimensionsStateModel>
  ): Observable<void> {
    const pool = this.store.selectSnapshot(PoolFormState.pool);
    assertIsDefined(pool.data, 'Pool not found');
    const poolId = pool.data.id;

    ctx.patchState({ poolId, dimensions: { requestState: 'loading' } });

    return this.loadDimensionsService.fetch({ poolId }).pipe(
      switchMap(res =>
        ctx.dispatch(new LoadDimensionsSuccess(res.data.pool.dimensions))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadDimensionsFailure(parseError(err)))
      )
    );
  }

  @Action(LoadDimensionsSuccess)
  public loadDimensionsSuccess(
    ctx: StateContext<DimensionsStateModel>,
    { dimensions }: LoadDimensionsSuccess
  ): void {
    ctx.patchState({
      dimensions: {
        data: dimensions as Dimension[],
        requestState: 'success'
      }
    });
  }

  @Action(LoadDimensionsFailure)
  public loadDimensionsFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: LoadDimensionsFailure
  ): void {
    ctx.patchState({
      dimensions: {
        requestState: 'failure',
        error
      }
    });
  }

  @Action(CreateDimension)
  public createDimension(
    ctx: StateContext<DimensionsStateModel>,
    { attributes }: CreateDimension
  ): Observable<void> {
    const pool = this.store.selectSnapshot(PoolFormState.pool);
    assertIsDefined(pool.data, 'Pool not found');
    const poolId = pool.data.id;

    ctx.patchState({
      mutation: {
        requestState: 'loading'
      }
    });

    return this.createDimensionService.mutate({ poolId, attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.createDimension?.dimension),
      filter(isDefined),
      switchMap(dimension =>
        ctx.dispatch(new CreateDimensionSuccess(dimension))
      ),
      catchError((err: unknown) => {
        return ctx.dispatch(new CreateDimensionFailure(parseError(err)));
      })
    );
  }

  @Action(CreateDimensionSuccess)
  public createDimensionSuccess(
    ctx: StateContext<DimensionsStateModel>,
    { dimension }: CreateDimensionSuccess
  ): void {
    ctx.patchState({
      dimensions: {
        ...ctx.getState().dimensions,
        data: [...(ctx.getState().dimensions.data || []), dimension]
      },
      mutation: {
        data: dimension,
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.create', {
        resource: this.translate.instant('common.models.dimension')
      })
    );
  }

  @Action(CreateDimensionFailure)
  public createDimensionFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: CreateDimensionFailure
  ): void {
    ctx.patchState({
      mutation: {
        error: error,
        requestState: 'failure'
      }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(UpdateDimension)
  public updateDimension(
    ctx: StateContext<DimensionsStateModel>,
    { id, attributes }: UpdateDimension
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.updateDimensionService
      .mutate({
        id,
        attributes
      })
      .pipe(
        takeGraphQLResult(),
        map(result => result?.updateDimension?.dimension),
        filter(isDefined),
        switchMap(dimension =>
          ctx.dispatch(new UpdateDimensionSuccess(dimension))
        ),
        catchError((err: unknown) =>
          ctx.dispatch(new UpdateDimensionFailure(parseError(err)))
        )
      );
  }

  @Action(UpdateDimensionSuccess)
  public updateDimensionSuccess(
    ctx: StateContext<DimensionsStateModel>,
    { dimension }: UpdateDimensionSuccess
  ): void {
    ctx.patchState({
      dimensions: {
        ...ctx.getState().dimensions,
        data: ctx
          .getState()
          .dimensions.data?.map(item =>
            item.id === dimension.id ? dimension : item
          )
      },
      mutation: { data: dimension, requestState: 'success' }
    });

    this.toast.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('activerecord.models.dimension')
      })
    );
  }

  @Action(UpdateDimensionFailure)
  public updateDimensionFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: UpdateDimensionFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toast.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(DeleteDimension)
  public deleteDimension(
    ctx: StateContext<DimensionsStateModel>,
    { id }: DeleteDimension
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.deleteDimensionService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteDimensionSuccess(id))),
      catchError((error: unknown) =>
        ctx.dispatch(new DeleteDimensionFailure(parseError(error), id))
      )
    );
  }

  @Action(DeleteDimensionSuccess)
  public deleteDimensionSuccess(
    ctx: StateContext<DimensionsStateModel>,
    { id }: DeleteDimensionSuccess
  ): void {
    ctx.patchState({
      dimensions: {
        ...ctx.getState().dimensions,
        data: ctx.getState().dimensions.data?.filter(d => d.id !== id)
      },
      mutation: { data: undefined, requestState: 'success' }
    });

    this.toast.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('common.models.dimension')
      })
    );
  }

  @Action(DeleteDimensionFailure)
  public deleteDimensionFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: DeleteDimensionFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toast.addWarning(
      this.translate.instant('error_modal.title'),
      error.message
    );
  }

  @Action(CreateCategory)
  public createCategory(
    ctx: StateContext<DimensionsStateModel>,
    { dimensionId, attributes }: CreateCategory
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.createCategoryService
      .mutate({
        dimensionId,
        attributes
      })
      .pipe(
        takeGraphQLResult(),
        map(result => result?.createCategory?.category),
        filter(isDefined),
        switchMap(category =>
          ctx.dispatch(new CreateCategorySuccess(dimensionId, category))
        ),
        catchError((err: unknown) =>
          ctx.dispatch(new CreateCategoryFailure(parseError(err)))
        )
      );
  }

  @Action(CreateCategorySuccess)
  public createCategorySuccess(
    ctx: StateContext<DimensionsStateModel>,
    { dimensionId, category }: CreateCategorySuccess
  ): void {
    const dimensions = ctx.getState().dimensions.data ?? [];
    const dimensionOfCategory = dimensions?.find(
      dimension => dimension.id === dimensionId
    );

    ctx.patchState({
      dimensions: {
        ...ctx.getState().dimensions,
        data: dimensions?.map(item =>
          item.id === dimensionOfCategory?.id
            ? {
                ...item,
                categories: [...item.categories, category]
              }
            : item
        )
      },
      mutation: { data: category, requestState: 'success' }
    });

    this.toast.addSuccess(
      this.translate.instant('toast_success_messages.create', {
        resource: this.translate.instant('activerecord.models.category')
      })
    );
  }

  @Action(CreateCategoryFailure)
  public createCategoryFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: CreateCategoryFailure
  ): void {
    ctx.patchState({ mutation: { error, requestState: 'failure' } });

    this.toast.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(UpdateCategory)
  public updateCategory(
    ctx: StateContext<DimensionsStateModel>,
    { id, attributes }: UpdateCategory
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.updateCategoryService
      .mutate({
        id,
        attributes
      })
      .pipe(
        takeGraphQLResult(),
        map(result => result?.updateCategory?.category),
        filter(isDefined),
        switchMap(category =>
          ctx.dispatch(new UpdateCategorySuccess(category))
        ),
        catchError((err: unknown) =>
          ctx.dispatch(new UpdateCategoryFailure(parseError(err)))
        )
      );
  }

  @Action(UpdateCategorySuccess)
  public updateCategorySuccess(
    ctx: StateContext<DimensionsStateModel>,
    { category }: UpdateCategorySuccess
  ): void {
    const dimensions = ctx.getState().dimensions.data ?? [];

    const dimensionOfCategoryByCategoryId = dimensions?.find(dimension =>
      dimension.categories.some(
        dimensionCategory => dimensionCategory.id === category.id
      )
    );

    ctx.patchState({
      dimensions: {
        ...ctx.getState().dimensions,
        data: dimensions?.map(item =>
          item.id === dimensionOfCategoryByCategoryId?.id
            ? {
                ...item,
                categories: item.categories.map(c =>
                  c.id === category.id ? category : c
                )
              }
            : item
        )
      },
      mutation: { data: category, requestState: 'success' }
    });

    this.toast.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('activerecord.models.category')
      })
    );
  }

  @Action(UpdateCategoryFailure)
  public updateCategoryFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: UpdateCategoryFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toast.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(SetDimensionPosition)
  public setDimensionPosition(
    ctx: StateContext<DimensionsStateModel>,
    { id, direction, times }: SetDimensionPosition
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.setDimensionPositionService
      .mutate({ id, direction, times })
      .pipe(
        takeGraphQLResult(),
        map(result => ({
          dimension: result?.setDimensionPosition?.dimension,
          dimensions: result?.setDimensionPosition?.dimensions
        })),
        filter(
          result => isDefined(result.dimension) && isDefined(result.dimensions)
        ),
        switchMap(({ dimension, dimensions }) => {
          assertIsDefined(dimension);
          assertIsDefined(dimensions);

          return ctx.dispatch(
            new SetDimensionPositionSuccess(
              dimension as Dimension,
              dimensions as Dimension[]
            )
          );
        }),
        catchError((err: unknown) =>
          ctx.dispatch(new SetDimensionPositionFailure(parseError(err)))
        )
      );
  }

  @Action(SetDimensionPositionSuccess)
  public setDimensionPositionSuccess(
    ctx: StateContext<DimensionsStateModel>,
    { dimension, dimensions }: SetDimensionPositionSuccess
  ): void {
    ctx.patchState({
      mutation: {
        data: dimension as Dimension,
        requestState: 'success'
      },
      dimensions: {
        data: dimensions as Dimension[],
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('activerecord.models.dimension')
      })
    );
  }

  @Action(SetDimensionPositionFailure)
  public setDimensionPositionFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: SetDimensionPositionFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(SetCategoryPosition)
  public setCategoryPosition(
    ctx: StateContext<DimensionsStateModel>,
    { id, direction, times }: SetCategoryPosition
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.setCategoryPositionService
      .mutate({ id, direction, times })
      .pipe(
        takeGraphQLResult(),
        map(result => ({
          category: result?.setCategoryPosition?.category,
          categories: result?.setCategoryPosition?.categories
        })),
        filter(
          result => isDefined(result.category) && isDefined(result.categories)
        ),
        switchMap(({ category, categories }) => {
          assertIsDefined(category);
          assertIsDefined(categories);

          return ctx.dispatch(
            new SetCategoryPositionSuccess(
              category as Category,
              categories as Category[]
            )
          );
        }),
        catchError((err: unknown) =>
          ctx.dispatch(new SetCategoryPositionFailure(parseError(err)))
        )
      );
  }

  @Action(SetCategoryPositionSuccess)
  public setCategoryPositionSuccess(
    ctx: StateContext<DimensionsStateModel>,
    { category, categories }: SetCategoryPositionSuccess
  ): void {
    const dimensions = ctx.getState().dimensions.data ?? [];

    const dimensionOfCategoryByCategoryId = dimensions?.find(dimension =>
      dimension.categories.some(
        dimensionCategory => dimensionCategory.id === category?.id
      )
    );

    ctx.patchState({
      mutation: {
        data: category as Category,
        requestState: 'success'
      },
      dimensions: {
        ...ctx.getState().dimensions,
        data: dimensions?.map(item =>
          item.id === dimensionOfCategoryByCategoryId?.id
            ? {
                ...item,
                categories: categories as Category[]
              }
            : item
        ),
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('activerecord.models.category')
      })
    );
  }

  @Action(SetCategoryPositionFailure)
  public setCategoryPositionFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: SetCategoryPositionFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(DeleteCategory)
  public deleteCategory(
    ctx: StateContext<DimensionsStateModel>,
    { id }: DeleteCategory
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.deleteCategoryService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteCategorySuccess(id))),
      catchError((error: unknown) =>
        ctx.dispatch(new DeleteCategoryFailure(parseError(error), id))
      )
    );
  }

  @Action(DeleteCategorySuccess)
  public deleteCategorySuccess(
    ctx: StateContext<DimensionsStateModel>,
    { id }: DeleteCategorySuccess
  ): void {
    const dimensions = ctx.getState().dimensions.data ?? [];

    const dimensionOfCategoryByCategoryId = dimensions?.find(dimension =>
      dimension.categories.some(
        dimensionCategory => dimensionCategory.id === id
      )
    );

    ctx.patchState({
      dimensions: {
        ...ctx.getState().dimensions,
        data: dimensions?.map(item =>
          item.id === dimensionOfCategoryByCategoryId?.id
            ? {
                ...item,
                categories: item.categories.filter(c => c.id !== id)
              }
            : item
        )
      },
      mutation: {
        requestState: 'success'
      }
    });

    this.toast.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('activerecord.models.category')
      })
    );
  }

  @Action(DeleteCategoryFailure)
  public deleteCategoryFailure(
    ctx: StateContext<DimensionsStateModel>,
    { error }: DeleteCategoryFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toast.addWarning(
      this.translate.instant('error_modal.title'),
      error.message
    );
  }
}
