import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AnnouncementListElementFragment = { __typename?: 'Announcement', id: string, startsAt: string, endsAt: string, severity: Types.AnnouncementSeverity, enabled: boolean, message: { __typename?: 'TranslatedContent', de?: string | null, fr?: string | null, en?: string | null, it?: string | null } };

export type LoadAnnouncementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoadAnnouncementsQuery = { __typename?: 'Query', announcements: Array<{ __typename?: 'Announcement', id: string, startsAt: string, endsAt: string, severity: Types.AnnouncementSeverity, enabled: boolean, message: { __typename?: 'TranslatedContent', de?: string | null, fr?: string | null, en?: string | null, it?: string | null } }> };

export const AnnouncementListElementFragmentDoc = gql`
    fragment AnnouncementListElement on Announcement {
  id
  message {
    de
    fr
    en
    it
  }
  startsAt
  endsAt
  severity
  enabled
}
    `;
export const LoadAnnouncementsDocument = gql`
    query LoadAnnouncements {
  announcements {
    ...AnnouncementListElement
  }
}
    ${AnnouncementListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadAnnouncementsGQL extends Apollo.Query<LoadAnnouncementsQuery, LoadAnnouncementsQueryVariables> {
    document = LoadAnnouncementsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }