import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import { QuestionStatisticsFragment } from 'src/app/question-management/services/question-group-statistics.generated';
import { DetailsInfoComponent } from '../../details-info/details-info.component';

@Component({
  selector: 'subgroup-statistics',
  templateUrl: './subgroup-statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ManagerCommonModule,
    TooltipModule,
    TableModule,
    DetailsInfoComponent
  ],
  host: { style: 'margin: 0.5rem;' }
})
export class SubgroupStatisticsComponent {
  @Input()
  public statistics: QuestionStatisticsFragment;
  public i18n = (s: string): string =>
    'pool.question_group_details.statistics.' + s;
}
