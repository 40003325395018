import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from '@generated/base-types';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  Subject as rxjsSubject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { FormState } from 'src/app/common/types/form-types';
import { RemoteData } from 'src/app/common/utils/remote-data';
import { ActionMenuOption } from 'src/app/new/common/action-menu/action-menu.component';
import { FuzzySearchService } from 'src/app/new/common/fuzzy-search.service';
import { ModalService } from 'src/app/new/common/modal/modal.service';
import { CreateSubjectMutationVariables } from '../../../services/create-subject.generated';
import { UpdateSubjectMutationVariables } from '../../../services/update-subject.generated';
import {
  MetaSelectors,
  SubjectWithNamedDimensions
} from '../../../state/meta.selectors';
import {
  CreateSubject,
  DeleteSubject,
  UpdateSubject
} from '../../../state/pool-details/subjects/subjects.actions';
import { SubjectsState } from '../../../state/pool-details/subjects/subjects.state';

@Component({
  templateUrl: './subjects-list.component.html',
  host: { class: 'page' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectsListComponent implements OnDestroy {
  public readonly filterFields: (keyof Subject)[] = ['name'];
  public sortBy = 'id';
  public readonly subjects$: Observable<
    RemoteData<SubjectWithNamedDimensions[]>
  >;
  public readonly formState$: Observable<FormState<Subject>>;
  public readonly menuOptions: ActionMenuOption[] = [
    {
      label: this.translate.instant('actions.edit'),
      callback: (data: Subject) => this.onEdit(data)
    },
    {
      label: this.translate.instant('actions.delete'),
      danger: true,
      callback: (data: Subject) => this.onDelete(data)
    }
  ];
  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();
  private destroy$ = new rxjsSubject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private modalService: ModalService,
    private translate: TranslateService,
    private fuzzySearchService: FuzzySearchService<SubjectWithNamedDimensions>
  ) {
    this.formState$ = this.formState();
    this.dismissSidebarOnSuccessfulMutation();
    this.subjects$ = combineLatest([
      this.store.select(MetaSelectors.subjectCategoriesByDimension),
      this.searchString$
    ]).pipe(
      map(([subjects, searchString]) => {
        searchString.trim() === '' ? (this.sortBy = 'id') : (this.sortBy = '');

        const result = this.fuzzySearchService.search(
          subjects.data || [],
          searchString,
          this.filterFields
        );

        return { ...subjects, data: result };
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onNew(): void {
    this.routeToForm('new');
  }

  public onEdit({ id }: Subject): void {
    this.routeToForm(id);
  }

  public onDismiss(): void {
    this.routeToForm();
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
  }

  public onSearchClear(): void {
    this.searchStringSource.next('');
  }

  public onCreate(
    payload: Pick<CreateSubjectMutationVariables, 'name' | 'categoryIds'>
  ): void {
    this.store.dispatch(new CreateSubject(payload));
  }

  public onUpdate(payload: UpdateSubjectMutationVariables): void {
    this.store.dispatch(new UpdateSubject(payload));
  }

  public async onDelete(subject: Subject): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();

    if (confirmDelete) this.store.dispatch(new DeleteSubject(subject.id));
  }

  private routeToForm(editing?: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editing },
      queryParamsHandling: 'merge'
    });
  }

  private formState(): Observable<FormState<Subject>> {
    const editingParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editing') ?? undefined)
    );
    const disabled$ = this.store
      .select(SubjectsState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    return combineLatest([editingParam$, disabled$]).pipe(
      switchMap(([editingParam, disabled]) => {
        const show: boolean = editingParam !== undefined;
        const id = show && editingParam !== 'new' ? editingParam : undefined;

        return this.store.select(SubjectsState.subjects).pipe(
          filter(({ requestState }) => requestState === 'success'),
          take(1),
          map(({ data }) =>
            id === undefined
              ? undefined
              : (data as Subject[])?.find(a => a.id === id)
          ),
          map(data => ({ show, disabled, data }))
        );
      })
    );
  }

  private dismissSidebarOnSuccessfulMutation(): void {
    this.store
      .select(SubjectsState.mutationRequestState)
      .pipe(
        pairwise(),
        filter(
          ([lastState, currentState]) =>
            lastState === 'loading' && currentState === 'success'
        ),
        tap({ next: () => this.onDismiss() }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
