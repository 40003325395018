<div class="modal-header">
  <h5 class="modal-title">{{ data.titleTranslationKey | translate }}</h5>
</div>
<div class="modal-body">
  <textarea
    [autofocus]="true"
    [(ngModel)]="comment"
    class="form-control"
    placeholder="{{ data.placeholderTranslationKey | translate }}"
  ></textarea>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="onCancel()">
    {{ 'common.actions.cancel' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="onConfirm(comment)"
    [disabled]="!comment"
  >
    {{ 'common.actions.save' | translate }}
  </button>
</div>
