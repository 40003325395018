/* eslint-disable camelcase, unicorn/no-null */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { LanguageVisibility } from '@state/settings';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuestionInput } from 'src/generated/base-types';
import { QuestionGroupFormComponent } from '../question-group-form/question-group-form.component';
import { ValidationsQuestionFreeTextGraphQL } from '../services/graphql-question-group-types';

@Component({
  selector: 'qf-question-content-type-free-text-form',
  templateUrl: './question-content-type-free-text-form.component.html',
  styleUrls: ['./question-content-type-free-text-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionContentTypeFreeTextFormComponent),
      multi: true
    }
  ]
})
export class QuestionContentTypeFreeTextFormComponent
  implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() public validations: ValidationsQuestionFreeTextGraphQL | undefined;
  @Input() public languageVisibility: LanguageVisibility;
  @ViewChild('freeTextContainerLines') private container: ElementRef;

  public form: UntypedFormGroup;
  private destroy$ = new Subject<void>();

  constructor(public readonly builder: UntypedFormBuilder) {
    this.form = this.builder.group({
      contentDe: null,
      contentFr: null,
      contentEn: null,
      contentIt: null,
      videoDe: null,
      videoFr: null,
      videoEn: null,
      videoIt: null,
      solution: this.builder.group({
        contentDe: null,
        contentFr: null,
        contentEn: null,
        contentIt: null,
        suggestedNumberOfLines: 0
      })
    });

    setTimeout(() => {
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(changes => {
          this.onChange({
            video: {
              de: changes.videoDe,
              fr: changes.videoFr,
              en: changes.videoEn,
              it: changes.videoIt
            },
            freeText: {
              content: {
                de: changes.contentDe,
                fr: changes.contentFr,
                en: changes.contentEn,
                it: changes.contentIt
              },
              solution: {
                de: changes.solution.contentDe,
                fr: changes.solution.contentFr,
                en: changes.solution.contentEn,
                it: changes.solution.contentIt
              },
              suggestedNumberOfLines: changes.solution.suggestedNumberOfLines
            }
          });
          this.onTouch();
          this.onTouch();
        });
    }, QuestionGroupFormComponent.CHANGE_DETECTION_TIMEOUT);
  }

  public ngAfterViewInit(): void {
    this.updateGridLayout();
  }

  public ngOnChanges(): void {
    this.updateGridLayout();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(question: QuestionInput): void {
    this.form.setValue({
      contentDe: question.freeText?.content.de ?? null,
      contentFr: question.freeText?.content.fr ?? null,
      contentEn: question.freeText?.content.en ?? null,
      contentIt: question.freeText?.content.it ?? null,
      videoDe: question.video?.de ?? null,
      videoFr: question.video?.fr ?? null,
      videoEn: question.video?.en ?? null,
      videoIt: question.video?.it ?? null,
      solution: {
        contentDe: question.freeText?.solution.de ?? null,
        contentFr: question.freeText?.solution.fr ?? null,
        contentEn: question.freeText?.solution.en ?? null,
        contentIt: question.freeText?.solution.it ?? null,
        suggestedNumberOfLines: question.freeText?.suggestedNumberOfLines ?? 0
      }
    });
  }

  public registerOnChange(fn: (value: QuestionInput) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  private updateGridLayout(): void {
    if (this.container !== undefined && this.container !== null) {
      const amountColumns = Object.values(this.languageVisibility).filter(
        Boolean
      ).length;
      this.container.nativeElement.style.gridColumn = `1 / ${
        amountColumns + 1
      }`;
    }
  }

  private onChange: (value: QuestionInput) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
