<div
  class="toolbar-container d-flex align-items-center"
  (resized)="onResized($event)"
>
  <div class="button-container">
    <button
      *ngFor="let button of visibleButtons"
      type="button"
      class="toolbar-button btn btn-light btn-sm"
      [disabled]="button.disabled | async"
      (click)="button.onActionClick()"
    >
      {{ button.label | translate }}
    </button>
  </div>
  <div *ngIf="dropdownItems.length > 0" ngbDropdown class="dropdown-container">
    <button class="btn btn-light btn-sm aspect-square" ngbDropdownToggle>
      <i class="fa-solid fa-ellipsis-vertical"></i>
    </button>
    <div ngbDropdownMenu>
      <button
        *ngFor="let item of dropdownItems"
        ngbDropdownItem
        (click)="item.onActionClick()"
        [disabled]="!!(item.disabled | async)"
        class="dropdown-item"
      >
        {{ item.label | translate }}
      </button>
    </div>
  </div>
</div>
