import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { SubjectsListElementFragmentDoc } from './load-subjects.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateSubjectMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
  categoryIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type UpdateSubjectMutation = { __typename?: 'Mutation', updateSubject?: { __typename?: 'UpdateSubjectPayload', successful: boolean, subject?: { __typename?: 'Subject', id: string, name: string, categories: Array<{ __typename?: 'Category', id: string, position: number, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, dimension: { __typename?: 'Dimension', id: string } }> } | null, validations?: { __typename?: 'ValidationsSubject', name?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const UpdateSubjectDocument = gql`
    mutation UpdateSubject($id: ID!, $name: String, $categoryIds: [ID!]) {
  updateSubject(id: $id, name: $name, categoryIds: $categoryIds) {
    subject {
      ...SubjectsListElement
    }
    successful
    validations {
      name {
        message
        severity
      }
    }
  }
}
    ${SubjectsListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubjectGQL extends Apollo.Mutation<UpdateSubjectMutation, UpdateSubjectMutationVariables> {
    document = UpdateSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }