import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { isDefined } from '@common/utils/type-guards/is-defined';
import { TableModule, Table } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import {
  QuestionGroupStatisticsFragment,
  QuestionStatisticsFragment
} from 'src/app/question-management/services/question-group-statistics.generated';
import { DetailsInfoComponent } from '../../details-info/details-info.component';
import { SubgroupStatisticsComponent } from '../subgroup-statistics/subgroup-statistics.component';
import { TypeAStatisticsComponent } from '../type-a-statistics/type-a-statistics.component';
import { TypeEStatisticsComponent } from '../type-e-statistics/type-e-statistics.component';
import { TypeFreeTextStatisticsComponent } from '../type-free-text-statistics/type-free-text-statistics.component';
import { TypeKprimeStatisticsComponent } from '../type-kprime-statistics/type-kprime-statistics.component';

interface QuestionGroupStatistics {
  exam: QuestionGroupStatisticsFragment['exam'];
  questions: QuestionGroupStatisticsFragment['questions'];
  n: number[];
  p: number[];
  pDichotomous: number[];
  r: number[];
  lne: number[];
  xi1: number[];
  xi2: number[];
  eliminated: boolean;
}

@Component({
  selector: 'question-group-statistics',
  templateUrl: './question-group-statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ManagerCommonModule,
    DetailsInfoComponent,
    TableModule,
    TooltipModule,
    SubgroupStatisticsComponent,
    TypeAStatisticsComponent,
    TypeEStatisticsComponent,
    TypeFreeTextStatisticsComponent,
    TypeKprimeStatisticsComponent
  ],
  host: { style: 'display: flex; flex: 1; flex-direction: column;' }
})
export class QuestionGroupStatisticsComponent {
  @ViewChild('table', { static: false })
  public table?: Table;

  @Input()
  public set statistics(value: QuestionGroupStatisticsFragment[]) {
    this.questionGroupStatistics = value.map(fragment => {
      return {
        exam: fragment.exam,
        questions: fragment.questions,
        n: fragment.questions
          .map(question => question.total.n)
          .filter(isDefined),
        p: fragment.questions
          .map(question => question.total.p)
          .filter(isDefined),
        pDichotomous: fragment.questions
          .map(question => question.total.pDichotomous)
          .filter(isDefined),
        r: fragment.questions
          .map(question => question.total.r)
          .filter(isDefined),
        lne: fragment.questions
          .map(question => question.total.lne)
          .filter(isDefined),
        xi1: fragment.questions
          .map(question => question.total.xi1)
          .filter(isDefined),
        xi2: fragment.questions
          .map(question => question.total.xi2)
          .filter(isDefined),
        eliminated: fragment.questions.reduce(
          (eliminated, question) => eliminated || question.total.eliminated,
          false as boolean
        )
      };
    });

    this.resetSelection();
    if (this.questionGroupStatistics.length > 0) {
      this.selectQuestionGroup(this.questionGroupStatistics, 0);
      const defaultExpanded = this.questionGroupStatistics[0].exam.name;
      this.expandedRows[defaultExpanded] = true;
    }
  }

  public expandedRows: Record<
    QuestionGroupStatisticsFragment['exam']['name'],
    boolean
  > = {};
  public questionGroupStatistics: QuestionGroupStatistics[] = [];
  public currentQuestionStatistics: QuestionStatisticsFragment | undefined;
  public selectedQuestionGroup: number | undefined;
  public selectedQuestion: number | undefined;
  public i18n = (s: string): string =>
    'pool.question_group_details.statistics.' + s;

  public selectQuestionGroup(
    rowData: QuestionGroupStatistics[],
    index?: number
  ): void {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!this.table?.isRowExpanded(rowData)) {
      this.table?.toggleRow(rowData);
      this.selectedQuestionGroup = index;
      this.selectQuestion(0);
    }
  }

  public selectQuestion(index: number): void {
    this.selectedQuestion = index;
    if (this.selectedQuestionGroup !== undefined) {
      this.currentQuestionStatistics =
        this.questionGroupStatistics[this.selectedQuestionGroup].questions[
          this.selectedQuestion
        ];
    }
  }

  private resetSelection(): void {
    this.selectedQuestionGroup = undefined;
    this.selectedQuestion = undefined;
    this.currentQuestionStatistics = undefined;
  }
}
