<ng-container
  *ngIf="statistics.achievedPoints.length > 0; else noFreeTextStatistics"
>
  <p-table
    name="TypeFreeTextStatistics"
    [value]="statistics.achievedPoints"
    styleClass="p-datatable-sm"
  >
    <ng-template pTemplate="caption">
      {{
        'pool.question_group_details.statistics.type_free_text_statistics.title'
          | translate
      }}
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>
          {{
            'pool.question_group_details.statistics.type_free_text_statistics.points'
              | translate
          }}
        </th>
        <th>
          {{ 'pool.question_group_details.statistics.table.p' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-statistics>
      <tr>
        <td>{{ statistics.points | fixedNumber : 1 }}</td>
        <td>{{ statistics.p | percentage | fixedNumber : 1 }}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
<ng-template #noFreeTextStatistics>
  <details-info
    [message]="
      'pool.question_group_details.statistics.type_free_text_statistics.no_statistics'
        | translate
    "
  ></details-info>
</ng-template>
