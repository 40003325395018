import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { LoadUsersFieldsFragmentDoc } from '../../new/admin/services/load-users.fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoadCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoadCurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null, language: Types.Language, organisation?: string | null, alternativeEmail?: string | null, mobilePhone?: string | null, alternativeMobilePhone?: string | null, currentSignInAt?: string | null, superUser: boolean, useEmailForMtan: boolean } | null };

export const LoadCurrentUserDocument = gql`
    query LoadCurrentUser {
  currentUser {
    ...LoadUsersFields
  }
}
    ${LoadUsersFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadCurrentUserGQL extends Apollo.Query<LoadCurrentUserQuery, LoadCurrentUserQueryVariables> {
    document = LoadCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }