import { CommonModule as AngularCommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { SortEvent } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { Observable } from 'rxjs';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import { RemoteData } from 'src/app/common/utils/remote-data';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import { ActionMenuOption } from 'src/app/new/common/action-menu/action-menu.component';
import {
  QuestionGroupTypesGraphQL,
  QuestionSequenceGraphQL,
  QuestionSeriesGraphQL,
  QuestionTypesGraphQL,
  SingleQuestionGraphQL
} from 'src/app/question-form/services/graphql-question-group-types';
import { QuestionGroupType } from 'src/generated/base-types';
import { TableUIModule } from '../../common/table-ui.module';
import {
  QuestionGroupToolbarComponent,
  ToolbarManagementType
} from '../common/question-group-toolbar/question-group-toolbar.component';
import { SetSelectedTaskQuestionGroups } from '../state/tasks/tasks.actions';
import { TaskQuestionGroup } from '../types/task';

function isSingleQG(
  qg: QuestionGroupTypesGraphQL
): qg is SingleQuestionGraphQL {
  return qg.type === QuestionGroupType.Single;
}

function isSequenceQG(
  qg: QuestionGroupTypesGraphQL
): qg is QuestionSequenceGraphQL {
  return qg.type === QuestionGroupType.Sequence;
}

function isSeriesQG(
  qg: QuestionGroupTypesGraphQL
): qg is QuestionSeriesGraphQL {
  return qg.type === QuestionGroupType.Series;
}

export function extractQGQuestions(
  qg: QuestionGroupTypesGraphQL
): QuestionTypesGraphQL[] {
  if (isSingleQG(qg) && isDefined(qg.question)) {
    return [qg.question];
  } else if (isSequenceQG(qg) && isDefined(qg.questions)) {
    return qg.questions;
  } else if (isSeriesQG(qg) && isDefined(qg.questions)) {
    return qg.questions;
  }

  return [];
}

@Component({
  selector: 'man-task-question-groups',
  templateUrl: './task-question-groups.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AngularCommonModule,
    TableModule,
    ManagerCommonModule,
    TableUIModule,
    QuestionGroupToolbarComponent
  ],
  host: { style: 'overflow: scroll;' }
})
export class TaskQuestionGroupsComponent {
  @Input() public taskQuestionGroups: Observable<
    RemoteData<TaskQuestionGroup[]>
  >;

  public ToolbarManagementType = ToolbarManagementType;
  public base = 'task_management.task_question_groups.question_list.columns.';
  public headers = [
    {
      headerName: 'sequential_number',
      sortingField: 'sequentialNumber'
    },
    {
      headerName: 'source_language',
      sortingField: 'sourceLanguage'
    },
    { headerName: 'title', sortingField: 'title' },
    { headerName: 'supervisor', sortingField: 'supervisor' },
    { headerName: 'type', sortingField: 'type' },
    { headerName: 'question_types', sortingField: `question.type` },
    { headerName: 'editor', sortingField: 'editor.lastName' },
    { headerName: 'author', sortingField: 'author' },
    {
      headerName: 'revision_status',
      sortingField: 'revisionStatus.shortName'
    }
  ];

  public readonly questionGroupOptions: ActionMenuOption[] = [
    {
      label: 'task_management.task_question_groups.question_list.tooltips.edit',
      danger: false,
      callback: () =>
        // eslint-disable-next-line no-console
        console.debug('Edit question')
    },
    {
      label: 'task_management.task_question_groups.question_list.tooltips.diff',
      danger: false,
      callback: () =>
        // eslint-disable-next-line no-console
        console.debug('show changes')
    }
  ];

  public selection: TaskQuestionGroup[] = [];

  constructor(private readonly store: Store) {}

  public onSelectionChange(): void {
    this.store.dispatch(new SetSelectedTaskQuestionGroups(this.selection));
  }

  public getQuestionGroupQuestions = (
    questionGroup: QuestionGroupTypesGraphQL
  ): QuestionTypesGraphQL[] => extractQGQuestions(questionGroup);

  public getHeaders(): Array<{ headerName: string; sortingField: string }> {
    let headers = this.headers;
    this.taskQuestionGroups.subscribe(taskQuestionGroups => {
      if (isDefined(taskQuestionGroups.data?.[0]?.questionGroup)) {
        headers = this.getHeadersWithCategories(
          taskQuestionGroups.data?.[0]
            .questionGroup as QuestionGroupTypesGraphQL
        );
      }
    });

    return headers;
  }

  public customSort(event: SortEvent): void {
    if (!event.data || event.data.length === 0) {
      return;
    }

    event.data.sort((data1, data2) => {
      const value1 = _.get(
        data1,
        `questionGroup.${this.getSortingProperty(
          event.field!,
          data1.questionGroup
        )}`
      );
      const value2 = _.get(
        data2,
        `questionGroup.${this.getSortingProperty(
          event.field!,
          data2.questionGroup
        )}`
      );

      let result;
      if (value1 === undefined && value2 !== undefined) result = 1;
      else if (value1 !== undefined && value2 === undefined) result = -1;
      else if (value1 === undefined && value2 === undefined) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order! * result;
    });
  }

  private getHeadersWithCategories(
    questionGroup: QuestionGroupTypesGraphQL
  ): Array<{ headerName: string; sortingField: string }> {
    const categoryIndexInHeaders = 6;
    const question = extractQGQuestions(questionGroup)[0];
    const keys = Object.keys(question) as Array<keyof typeof question>;

    const categories = keys
      .filter(key => key.startsWith('category'))
      .map(category => {
        const index = category.length - 1;
        const headerName = `${category.slice(
          0,
          Math.max(0, index)
        )}_${category.slice(Math.max(0, index))}`;

        return {
          headerName: headerName,
          sortingField: `question.${category}.name`
        };
      });

    const beforeCategories = this.headers.slice(0, categoryIndexInHeaders);
    const afterCategories = this.headers.slice(categoryIndexInHeaders);

    return [...beforeCategories, ...categories, ...afterCategories];
  }

  private getSortingProperty = (
    sortProperty: string,
    questionGroup: QuestionGroupTypesGraphQL
  ): string => {
    if (sortProperty.startsWith('question')) {
      return sortProperty.replace(
        'question',
        this.getQuestionProperty(questionGroup)
      );
    }

    return sortProperty;
  };

  private getQuestionProperty = (
    questionGroup: QuestionGroupTypesGraphQL
  ): string => {
    return isSingleQG(questionGroup) ? 'question' : 'questions[0]';
  };
}
