/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { assertIsDefined } from '@common/utils/type-guards/is-defined';
import { Language } from '@generated/base-types';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ContextState } from '@state/context';
import { PoolState, type PoolStateModel } from '@state/pool';
import {
  ExamListState,
  ExamListStateModel
} from '../../exam-management/state/exam-list.state';
import {
  SetExamColumnConfig,
  SetQuestionColumnConfig,
  SetQuestionColumnVisibility,
  SetTaskColumnConfig,
  SetTaskQuestionColumnConfig,
  UpdateCommentFilter,
  UpdateLanguageVisibility,
  UpdatePreviewsVisibleLanguages
} from './settings.actions';
import {
  defaultExamColumnConfig,
  defaultQuestionColumnConfig,
  defaultTaskColumnConfig,
  defaultTaskQuestionColumnConfig,
  ExamColumnConfig,
  getQuestionListColumns,
  LanguageVisibility,
  ListColumn,
  PoolColumnConfig,
  PoolId,
  QuestionColumnConfig,
  SettingsStateModel,
  TaskColumnConfig,
  TaskQuestionColumnConfig,
  updateExamDimensions,
  updateExamPoolVisibilites,
  updatePoolVisibilities
} from './settings.model';

@State<SettingsStateModel>({
  name: 'settings',
  defaults: {
    version: 1,
    languageVisibility: {},
    preview: {
      visibleLanguages: []
    },
    comment: {
      showReadMessages: true,
      showSystemMessages: true
    },
    columnConfig: {}
  }
})
@Injectable({
  providedIn: 'root'
})
export class SettingsState {
  constructor(private readonly store: Store) {}

  @Selector([ContextState.currentPoolLanguages])
  public static languageVisibility(
    state: SettingsStateModel,
    languages: Language[]
  ): LanguageVisibility {
    return languages.reduce((visibility, language) => {
      const languageVisible = state.languageVisibility[language];
      visibility[language] =
        languageVisible === undefined ? true : languageVisible;

      return visibility;
    }, {} as LanguageVisibility);
  }

  @Selector()
  public static previewVisibleLanguages(state: SettingsStateModel): Language[] {
    return state.preview.visibleLanguages;
  }

  @Selector()
  public static commentShowReadMessages(state: SettingsStateModel): boolean {
    return state.comment.showReadMessages;
  }

  @Selector()
  public static commentShowSystemMessages(state: SettingsStateModel): boolean {
    return state.comment.showSystemMessages;
  }

  @Selector([ContextState.currentPoolId])
  public static questionColumnConfig(
    state: SettingsStateModel,
    poolId: PoolId | undefined
  ): QuestionColumnConfig {
    if (poolId === undefined) {
      return defaultQuestionColumnConfig;
    }

    return (
      state.columnConfig[poolId]?.questionMgmt ?? defaultQuestionColumnConfig
    );
  }

  @Selector([ContextState.currentPoolId])
  public static questionListColumns(
    state: SettingsStateModel,
    poolId: PoolId | undefined
  ): ListColumn[] {
    const config =
      poolId === undefined
        ? defaultQuestionColumnConfig
        : state.columnConfig[poolId]?.questionMgmt ??
          defaultQuestionColumnConfig;

    return getQuestionListColumns(config);
  }

  @Selector([ContextState.currentPoolId])
  public static taskQuestionColumnConfig(
    state: SettingsStateModel,
    poolId: PoolId | undefined
  ): TaskQuestionColumnConfig {
    if (poolId === undefined) {
      return defaultTaskQuestionColumnConfig;
    }

    return (
      state.columnConfig[poolId]?.taskQuestionMgmt ??
      defaultTaskQuestionColumnConfig
    );
  }

  @Selector([ContextState.currentPoolId])
  public static taskColumnConfig(
    state: SettingsStateModel,
    poolId: PoolId | undefined
  ): TaskColumnConfig {
    if (poolId === undefined) {
      return defaultTaskColumnConfig;
    }

    return state.columnConfig[poolId]?.taskMgmt ?? defaultTaskColumnConfig;
  }

  @Selector([ContextState.currentPoolId])
  public static examColumnConfig(
    state: SettingsStateModel,
    poolId: PoolId | undefined
  ): ExamColumnConfig {
    if (poolId === undefined) {
      return defaultExamColumnConfig;
    }

    return state.columnConfig[poolId]?.examMgmt ?? defaultExamColumnConfig;
  }

  @Action(UpdateLanguageVisibility)
  public updateLanguageVisibility(
    ctx: StateContext<SettingsStateModel>,
    action: UpdateLanguageVisibility
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      languageVisibility: {
        ...state.languageVisibility,
        [action.language]: action.visible
      }
    });
  }

  @Action(UpdatePreviewsVisibleLanguages)
  public updatePreviewsVisibleLanguages(
    ctx: StateContext<SettingsStateModel>,
    action: UpdatePreviewsVisibleLanguages
  ): void {
    ctx.patchState({
      preview: { visibleLanguages: action.languages }
    });
  }

  @Action(UpdateCommentFilter)
  public updateCommentFilter(
    ctx: StateContext<SettingsStateModel>,
    action: UpdateCommentFilter
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      comment: {
        ...state.comment,
        [action.kind]: action.active
      }
    });
  }

  @Action(SetQuestionColumnConfig)
  public setQuestionColumnConfig(ctx: StateContext<SettingsStateModel>): void {
    const state = ctx.getState();
    const pool = this.store.selectSnapshot<PoolStateModel>(PoolState).data;

    assertIsDefined(
      pool,
      'Cannot set question column definition without a pool'
    );

    const columns: QuestionColumnConfig = {
      ...updatePoolVisibilities(
        pool,
        state.columnConfig[pool.id]?.questionMgmt ?? defaultQuestionColumnConfig
      )
    };

    ctx.patchState({
      columnConfig: {
        ...state.columnConfig,
        [pool.id]: {
          ...state.columnConfig[pool.id],
          questionMgmt: columns
        }
      }
    });
  }

  @Action(SetQuestionColumnVisibility)
  public setQuestionColumnVisibility(
    ctx: StateContext<SettingsStateModel>,
    action: SetQuestionColumnVisibility
  ): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);

    assertIsDefined(
      poolId,
      'Cannot set question column visibility without a pool id'
    );

    ctx.setState(
      patch<SettingsStateModel>({
        columnConfig: patch({
          [poolId]: patch<PoolColumnConfig>({
            questionMgmt: patch<QuestionColumnConfig>({
              [action.column]: patch<ListColumn>({
                visible: action.visible
              })
            })
          })
        })
      })
    );
  }

  @Action(SetTaskQuestionColumnConfig)
  public setTaskQuestionColumnConfig(
    ctx: StateContext<SettingsStateModel>
  ): void {
    const state = ctx.getState();
    const pool = this.store.selectSnapshot<PoolStateModel>(PoolState).data;

    assertIsDefined(
      pool,
      'Cannot set task question column definition without a pool'
    );

    const columns: TaskQuestionColumnConfig = {
      ...updatePoolVisibilities(
        pool,
        state.columnConfig[pool.id]?.taskQuestionMgmt ??
          defaultTaskQuestionColumnConfig
      )
    };

    ctx.patchState({
      columnConfig: {
        ...state.columnConfig,
        [pool.id]: {
          ...state.columnConfig[pool.id],
          taskQuestionMgmt: columns
        }
      }
    });
  }

  @Action(SetTaskColumnConfig)
  public setTaskColumnConfig(ctx: StateContext<SettingsStateModel>): void {
    const state = ctx.getState();
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);

    assertIsDefined(poolId, 'Cannot set task column config without a pool id');

    ctx.patchState({
      columnConfig: {
        ...state.columnConfig,
        [poolId]: {
          ...state.columnConfig[poolId],
          taskMgmt: defaultTaskColumnConfig
        }
      }
    });
  }

  @Action(SetExamColumnConfig)
  public setExamColumnConfig(ctx: StateContext<SettingsStateModel>): void {
    const state = ctx.getState();
    const pool = this.store.selectSnapshot<PoolStateModel>(PoolState)?.data;
    const exam =
      this.store.selectSnapshot<ExamListStateModel>(ExamListState)?.exam?.data;

    assertIsDefined(pool, 'Cannot set exam column definition without a pool');

    const columns: ExamColumnConfig = {
      ...(state.columnConfig[pool.id]?.examMgmt ?? defaultExamColumnConfig),
      ...updateExamPoolVisibilites(pool),
      ...updateExamDimensions(exam)
    };

    ctx.patchState({
      columnConfig: {
        ...state.columnConfig,
        [pool.id]: {
          ...state.columnConfig[pool.id],
          examMgmt: columns
        }
      }
    });
  }
}
