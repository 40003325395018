import { Injectable } from '@angular/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { SetExamColumnConfig } from '@state/settings';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Scalars } from '../../../generated/base-types';
import { takeGraphQLResult } from '../../common/operators/take-graphql-response';
import { parseError } from '../../common/utils/error-parser';
import { RemoteData } from '../../common/utils/remote-data';
import {
  ExamMetadataFragment,
  LoadExamGQL
} from '../../services/load-exam.generated';
import {
  LoadExam,
  LoadExamFailure,
  LoadExamSuccess,
  SetLoadedExamIds,
  SetLoadedExamQuestionGroupIds,
  SetSelectedExamId,
  SetSelectedExamQuestionGroupId
} from './exam-list.actions';

export interface ExamListStateModel {
  exam?: RemoteData<ExamMetadataFragment>;
  selectedExamId?: Scalars['ID'];
  loadedExamIds: Scalars['ID'][];
  selectedExamQuestionGroupId?: Scalars['ID'];
  loadedQuestionGroupIds: Scalars['ID'][];
}

@State<ExamListStateModel>({
  name: 'examList',
  defaults: {
    exam: {
      requestState: 'initial'
    },
    selectedExamId: undefined,
    loadedExamIds: [],
    selectedExamQuestionGroupId: undefined,
    loadedQuestionGroupIds: []
  }
})
@Injectable()
export class ExamListState {
  constructor(private readonly loadExamService: LoadExamGQL) {}

  @Selector()
  public static selectedExamId(
    state: ExamListStateModel
  ): Scalars['ID'] | undefined {
    return state.selectedExamId;
  }

  @Selector()
  public static loadedExamIds(state: ExamListStateModel): Scalars['ID'][] {
    return state.loadedExamIds;
  }

  @Selector()
  public static selectedExamQuestionGroupId(
    state: ExamListStateModel
  ): Scalars['ID'] | undefined {
    return state.selectedExamQuestionGroupId;
  }

  @Selector()
  public static loadedQuestionGroupIds(
    state: ExamListStateModel
  ): Scalars['ID'][] {
    return state.loadedQuestionGroupIds;
  }

  @Action(LoadExam)
  public loadExam(
    ctx: StateContext<ExamListStateModel>,
    action: LoadExam
  ): Observable<void> {
    ctx.patchState({ exam: { requestState: 'loading' } });

    return this.loadExamService.fetch({ examId: action.id }).pipe(
      takeGraphQLResult(),
      switchMap(({ exam }) => ctx.dispatch(new LoadExamSuccess(exam))),
      catchError((error: unknown) =>
        ctx.dispatch(new LoadExamFailure(parseError(error)))
      )
    );
  }

  @Action(LoadExamSuccess)
  public loadExamSuccess(
    ctx: StateContext<ExamListStateModel>,
    action: LoadExamSuccess
  ): void {
    ctx.patchState({
      exam: {
        data: action.exam,
        requestState: 'success'
      }
    });

    ctx.dispatch(new SetExamColumnConfig());
  }

  @Action(LoadExamFailure)
  public loadExamFailure(
    ctx: StateContext<ExamListStateModel>,
    action: LoadExamFailure
  ): void {
    ctx.patchState({
      exam: {
        requestState: 'failure',
        error: action.error,
        data: undefined
      }
    });
  }

  @Action(SetSelectedExamId)
  public setSelectedExamId(
    ctx: StateContext<ExamListStateModel>,
    action: SetSelectedExamId
  ): void {
    ctx.patchState({
      selectedExamId: action.id
    });
  }

  @Action(SetLoadedExamIds)
  public setLoadedExamIds(
    ctx: StateContext<ExamListStateModel>,
    action: SetLoadedExamIds
  ): void {
    ctx.patchState({
      loadedExamIds: action.ids
    });
  }

  @Action(SetSelectedExamQuestionGroupId)
  public setSelectedExamQuestionGroupId(
    ctx: StateContext<ExamListStateModel>,
    action: SetSelectedExamQuestionGroupId
  ): void {
    ctx.patchState({
      selectedExamQuestionGroupId: action.id
    });
  }

  @Action(SetLoadedExamQuestionGroupIds)
  public setLoadedExamQuestionGroupIds(
    ctx: StateContext<ExamListStateModel>,
    action: SetLoadedExamQuestionGroupIds
  ): void {
    ctx.patchState({
      loadedQuestionGroupIds: action.ids
    });
  }
}
