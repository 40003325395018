<div
  *ngIf="users$ | async as users"
  class="grid h-100"
  style="grid-template-rows: auto 1fr"
>
  <div class="g-col-6 g-col-xl-8 ms-auto order-2">
    <button (click)="onCreate()" type="button" class="btn btn-dark">
      {{ 'admin.users.actions.new' | translate }}
    </button>
  </div>
  <div class="g-col-6 g-col-xl-4 order-1">
    <man-search-input
      [shortcutActive]="true"
      placeholder="admin.users.filter.placeholder"
      [disabled]="users.requestState !== 'success'"
      (search)="onSearch($event)"
      (clear)="onSearchClear()"
    >
    </man-search-input>
  </div>
  <man-table-wrapper class="g-col-12 order-3" [request]="users">
    <p-table
      name="Users"
      selectionMode="single"
      [sortField]="sortBy"
      [sortOrder]="1"
      [globalFilterFields]="filterFields"
      [value]="users.data | copyArray"
      [loading]="users.requestState === 'loading'"
      [scrollable]="true"
      scrollHeight="flex"
      [selection]="selection$ | async"
      (onRowSelect)="onEdit($event.data)"
      styleClass="p-datatable-striped p-datatable-sm"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'admin.users.table.name' | translate }}</th>
          <th>{{ 'admin.users.table.organisation' | translate }}</th>
          <th>{{ 'admin.users.table.email' | translate }}</th>
          <th class="justify-content-center p-col-fixed p-col--icon">
            {{ 'admin.users.table.admin' | translate }}
          </th>
          <th>{{ 'admin.users.table.last_login' | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr
          class="p-row-fixed"
          [pSelectableRow]="user"
          interactiveRow
          (delete)="onDelete(user)"
        >
          <td>{{ user.lastName }} {{ user.firstName }}</td>
          <td>{{ user.organisation }}</td>
          <td class="overflow-hidden">
            <div [title]="user.email" class="d-inline text-truncate">
              {{ user.email }}
            </div>
          </td>
          <td class="justify-content-center p-col-fixed p-col--icon">
            <ng-container *ngIf="user.superUser">
              <i
                title="Admin"
                aria-hidden="true"
                class="fa-regular fa-check"
              ></i>
              <span class="visually-hidden">Admin</span>
            </ng-container>
          </td>
          <td>{{ user.currentSignInAt | date : 'dd.MM.yyyy' }}</td>
          <td>
            <div>
              <man-action-menu [data]="user" [options]="usersMenuOptions">
              </man-action-menu>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <small class="text-end fw-normal text-muted font-monospace">
          {{ filteredUserCount }} of {{ totalUserCount }} users
        </small>
      </ng-template>
    </p-table>
  </man-table-wrapper>
</div>
