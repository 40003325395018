/* eslint-disable camelcase, unicorn/no-null */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { LanguageVisibility } from '@state/settings';
import { BehaviorSubject, Subject } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';
import {
  Affiliation,
  Language,
  QuestionGroupInput,
  QuestionGroupType,
  QuestionInput,
  ValidationsQuestionGroup
} from 'src/generated/base-types';
import {
  FilterHelperService,
  FilterLabelType
} from '../../question-management/services/filter/filter-helper.service';
import { LoadNewQuestionGroupFromExistingFormData } from '../state/form.actions';

@Component({
  selector: 'qf-question-group-metadata',
  templateUrl: './question-group-metadata.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionGroupMetadataComponent),
      multi: true
    }
  ]
})
export class QuestionGroupMetadataComponent
  implements ControlValueAccessor, OnDestroy, OnInit
{
  @Input() public questionsSubject: BehaviorSubject<QuestionInput[]>;
  @Input() public questionGroupType: QuestionGroupType;
  @Input() public affiliations: Affiliation[];
  @Input() public supervisors: string[];
  @Input() public authors: string[];
  @Input() public languageVisibility: LanguageVisibility;
  @Output() public languageVisibilityChanged = new EventEmitter<{
    language: Language;
    visible: boolean;
  }>();
  @Input()
  public set languages(languages: Language[]) {
    this._languages = languages;
    this.languageOptions = languages.map(language => ({
      label: this.translate.instant(
        `scrudu.languages.long.${language.toLowerCase()}`
      ),
      value: language
    }));
  }
  public get languages(): Language[] {
    return this._languages;
  }
  @Input() public validations: ValidationsQuestionGroup | undefined;

  public questions: QuestionInput[];
  public form: UntypedFormGroup;
  public revisionYears: number[];
  public languageOptions: { label: string; value: string }[];
  public totalScore: number;
  public numberOfQuestions: number;
  public questionGroupTypes: FilterLabelType[];
  private _languages: Language[];

  private questionGroup$ = new Subject<QuestionGroupInput>();
  private destroy$ = new Subject<void>();

  constructor(
    public filterHelper: FilterHelperService,
    public readonly builder: UntypedFormBuilder,
    private translate: TranslateService,
    private store: Store
  ) {}
  public ngOnInit(): void {
    this.revisionYears = Array.from({
      length: new Date().getFullYear() - 1980 + 1
    })
      .fill(0)
      .map((_, index) => 1980 + index)
      .reverse();

    this.form = this.builder.group({
      title: '',
      revisionYear: null,
      sourceLanguage: null,
      affiliationId: null,
      supervisor: '',
      author: '',
      type: ''
    });

    this.form.valueChanges
      .pipe(pairwise(), takeUntil(this.destroy$))
      .subscribe(([prev, changes]) => {
        if (prev.type !== changes.type) {
          this.store.dispatch(
            new LoadNewQuestionGroupFromExistingFormData({
              ...this.form.value,
              questions: this.questions,
              type: changes.type
            })
          );
        }

        this.questionGroupTypes = this.generateQuestionGroupTypeOptions(
          changes.type,
          this.questions.length
        );
        this.onChange({
          title: changes.title,
          revisionYear: changes.revisionYear,
          sourceLanguage: changes.sourceLanguage ?? null,
          affiliationId: changes.affiliationId,
          supervisor: changes.supervisor,
          author: changes.author,
          type: changes.type
        });
        this.onTouch();
      });

    this.questionGroup$
      .pipe(takeUntil(this.destroy$))
      .subscribe(questionGroup => {
        this.form.setValue({
          title: questionGroup.title ?? '',
          revisionYear: questionGroup.revisionYear ?? null,
          sourceLanguage: questionGroup.sourceLanguage ?? null,
          affiliationId: questionGroup.affiliationId ?? null,
          supervisor: questionGroup.supervisor ?? '',
          author: questionGroup.author ?? '',
          type: questionGroup.type ?? ''
        });
      });

    this.questionGroup$
      .pipe(takeUntil(this.destroy$))
      .subscribe(questionGroup => {
        this.totalScore = (questionGroup.questions || []).reduce(
          (sum, questionInput) => {
            return sum + (questionInput.score ?? 0);
          },
          0
        );
        this.numberOfQuestions = (questionGroup?.questions ?? []).length;
      });

    this.questionsSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe(questions => {
        this.questions = questions;
        this.questionGroupTypes = this.generateQuestionGroupTypeOptions(
          this.questionGroupType,
          this.questions.length
        );
      });

    this.questionGroupTypes = this.generateQuestionGroupTypeOptions(
      this.questionGroupType,
      this.questions.length
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(questionGroup: QuestionGroupInput): void {
    this.questionGroup$.next(questionGroup);
  }

  public registerOnChange(fn: (value: QuestionGroupInput) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public languageVisibilityClicked(language: Language): void {
    const visible =
      this.languageVisibility[language] === undefined ||
      this.languageVisibility[language] === false;
    this.languageVisibilityChanged.emit({
      language,
      visible
    });
  }

  private generateQuestionGroupTypeOptions(
    currentType: QuestionGroupType,
    numberOfQuestions: number
  ): FilterLabelType[] {
    const questionGroupTypes = this.filterHelper.questionGroupTypeOptions();

    return (currentType === QuestionGroupType.Sequence ||
      currentType === QuestionGroupType.Series) &&
      numberOfQuestions > 1
      ? questionGroupTypes.filter(obj => obj.value !== QuestionGroupType.Single)
      : questionGroupTypes;
  }

  private onChange: (value: QuestionGroupInput) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
