import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { debounce } from 'lodash';

@Directive({
  selector: '[resized]',
  standalone: true
})
export class ResizedDirective implements OnInit, OnDestroy {
  @Output()
  public resized = new EventEmitter<ResizeObserverSize>();

  private observer: ResizeObserver;

  constructor(private readonly element: ElementRef) {
    // debounce needed to avoid "ResizeObserver loop completed with undelivered notifications" console error
    this.observer = new ResizeObserver(
      debounce(entries => this.observe(entries), 0)
    );
  }

  public ngOnInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    const domSize = entries[0];
    const resizedEvent = domSize.contentBoxSize[0];
    this.resized.emit(resizedEvent);
  }
}
