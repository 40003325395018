import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PermissionRole } from 'src/generated/base-types';
import { PoolPermissionsFragment } from '../../../../services/load-pool-permissions.fragments.generated';
import { UserPermissionsState } from '../../../../state/user-permissions.state';

type RoleOption = { label: string; value: string; exists: boolean };

@Component({
  selector: 'man-role-dropdown',
  templateUrl: './role-dropdown.component.html',
  styleUrls: ['./role-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RoleDropdownComponent)
    }
  ]
})
export class RoleDropdownComponent {
  @Input() public poolId: string;

  public value?: RoleOption;
  public touched = false;
  public disabled = false;
  public onChange: (value: string) => void;
  public onTouched: () => void;

  public roles$: Observable<RoleOption[]>;

  constructor(private store: Store, private translate: TranslateService) {
    this.loadRoles();
  }

  public writeValue(value: RoleOption): void {
    this.value = value;
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public onRoleChange(event: string): void {
    this.onChange(event);
  }

  public loadRoles(): void {
    this.roles$ = this.store.select(UserPermissionsState.userPermissions).pipe(
      switchMap(userPermissions => {
        const permissions = userPermissions?.data || [];

        return this.translate
          .get(
            Object.values(PermissionRole).map(
              role => `common.permissions.${role}`
            )
          )
          .pipe(
            map(translations => {
              return Object.keys(translations).map(key => ({
                label: translations[key],
                value: key.split('.').pop()!,
                exists: this.hasPermission(permissions, key.split('.').pop()!)
              }));
            })
          );
      })
    );
  }

  private hasPermission(
    permissions: PoolPermissionsFragment[],
    translatedRole: string
  ): boolean {
    const isSamePool = ({ pool }: PoolPermissionsFragment): boolean =>
      pool.id === this.poolId;

    const hasNonCoordinatorRole = ({
      role
    }: PoolPermissionsFragment): boolean =>
      role !== 'coordinator' && role === translatedRole;

    return this.poolId === ''
      ? permissions.some(p => hasNonCoordinatorRole(p))
      : permissions.some(p => hasNonCoordinatorRole(p) && isSamePool(p));
  }
}
