/* eslint-disable unicorn/no-null */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isVoid } from '@common/utils/type-guards/voidable';

export const matchValidator = (
  matchTo: string,
  confirmField = false
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const root = control.root;
    if (isVoid(root)) return null;

    const targetControl = root.get(matchTo);
    if (!targetControl) return null;

    if (confirmField) {
      // used to only trigger the validation of the field its supposed to match
      targetControl.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });

      return null;
    }

    return targetControl.value === control.value ? null : { mismatch: true };
  };
};
