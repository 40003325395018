import { Injectable } from '@angular/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Observable, firstValueFrom } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  LoadQuestionGroupListGQL,
  QuestionGroupListElementFragment
} from 'src/app/question-form/services/load-question-group-list.generated';
import {
  ListMetadata,
  QuestionGroupSortInput,
  Scalars
} from 'src/generated/base-types';
import { RemoteData } from '../../common/utils/remote-data';
import { assertIsDefined } from '../../common/utils/type-guards/is-defined';
import { isVoid } from '../../common/utils/type-guards/voidable';
import { ContextState } from '../../state/context/context.state';
import { LoadQuestionGroupsGQL } from '../../task-management/state/load-question-groups.generated';
import { buildFilterQueryItemFromQuestionFilters } from './question-filter/filter-tree';
import { QuestionFilterState } from './question-filter/question-filter.state';
import {
  LoadQuestionGroupList,
  LoadQuestionGroupListFailure,
  LoadQuestionGroupListSuccess,
  ReloadQuestionGroupList,
  ReloadSelectedQuestionGroups,
  ResetSelectedQuestionGroups,
  SetSelectedQuestionGroups,
  SetSortingQuestionGroupList
} from './question-list.actions';

export interface QuestionListStateModel {
  [poolId: Scalars['ID']]: QuestionList;
}

interface QuestionList {
  selectedQuestionGroups: QuestionGroupListElementFragment[];
  items: RemoteData<QuestionGroupListElementFragment[]>;
  metadata: RemoteData<ListMetadata>;
  sortAttributes: QuestionGroupSortInput[] | undefined;
  rows: number;
}

const defaultQuestionList: QuestionList = {
  selectedQuestionGroups: [],
  items: {
    requestState: 'initial'
  },
  metadata: {
    requestState: 'initial'
  },
  sortAttributes: undefined,
  rows: 25
};

@State<QuestionListStateModel>({
  name: 'questionList'
})
@Injectable()
export class QuestionListState {
  constructor(
    private readonly loadQuestionGroupListGQL: LoadQuestionGroupListGQL,
    private readonly store: Store,
    private readonly loadQuestionGroupsGQL: LoadQuestionGroupsGQL
  ) {}

  /*
   * -----------------------------------------------------------------------
   * SELECTORS
   * -----------------------------------------------------------------------
   */

  @Selector([ContextState.currentPoolId])
  public static idOfLastSelectedQuestionGroup(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): Scalars['ID'] | undefined {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    if (
      isVoid(state[poolId]) ||
      state[poolId].selectedQuestionGroups.length === 0
    ) {
      return;
    }

    return state[poolId].selectedQuestionGroups[
      state[poolId].selectedQuestionGroups.length - 1
    ].id.toString();
  }

  @Selector([ContextState.currentPoolId])
  public static selectedQuestionGroups(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): QuestionGroupListElementFragment[] {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (state[poolId] ?? defaultQuestionList).selectedQuestionGroups;
  }

  @Selector([ContextState.currentPoolId])
  public static selectedQuestionGroupsIds(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): Scalars['ID'][] {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (state[poolId] ?? defaultQuestionList).selectedQuestionGroups.map(
      qg => qg.id
    );
  }

  @Selector([ContextState.currentPoolId])
  public static filteredQuestionGroupIds(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): Scalars['ID'][] {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (
      (state[poolId] ?? defaultQuestionList).metadata.data?.allFilteredIds || []
    );
  }

  @Selector([ContextState.currentPoolId])
  public static loadedQuestionGroupIds(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): Scalars['ID'][] {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (
      (state[poolId] ?? defaultQuestionList).items.data?.map(qg => qg.id) || []
    );
  }

  @Selector([ContextState.currentPoolId])
  public static allQuestionGroupIds(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): Scalars['ID'][] {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (state[poolId] ?? defaultQuestionList).metadata.data?.allIds || [];
  }

  @Selector([ContextState.currentPoolId])
  public static questionGroups(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): QuestionGroupListElementFragment[] {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (state[poolId] ?? defaultQuestionList).items.data || [];
  }

  @Selector([ContextState.currentPoolId])
  public static loading(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): boolean {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (
      (state[poolId] ?? defaultQuestionList).items.requestState !== 'success'
    );
  }

  @Selector([ContextState.currentPoolId])
  public static metadata(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): ListMetadata {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (
      (state[poolId] ?? defaultQuestionList).metadata.data || {
        allFilteredIds: [],
        allIds: [],
        filteredNumberOfItems: 0,
        from: 0,
        to: 0,
        totalNumberOfItems: 0
      }
    );
  }

  @Selector([ContextState.currentPoolId])
  public static sortAttributes(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): QuestionGroupSortInput[] | undefined {
    assertIsDefined(
      poolId,
      'Cannot return sorting attributes without a pool ID'
    );

    return (state[poolId] ?? defaultQuestionList).sortAttributes;
  }

  @Selector([ContextState.currentPoolId])
  public static rows(
    state: QuestionListStateModel,
    poolId: Scalars['ID'] | undefined
  ): number {
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    return (state[poolId] ?? defaultQuestionList).rows || 25;
  }

  /*
   * -----------------------------------------------------------------------
   * ACTIONS
   * -----------------------------------------------------------------------
   */

  @Action(LoadQuestionGroupList)
  public loadQuestionGroupList(
    ctx: StateContext<QuestionListStateModel>,
    action: LoadQuestionGroupList
  ): Observable<void> {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    const currentState = ctx.getState()[poolId];

    ctx.patchState({
      [poolId]: {
        ...(currentState ?? defaultQuestionList),
        items: {
          data: [],
          requestState: 'loading'
        },
        rows: action.rows ?? currentState?.rows,
        sortAttributes: action.sort ?? currentState?.sortAttributes
      }
    });

    const filter = buildFilterQueryItemFromQuestionFilters(
      this.store.selectSnapshot(QuestionFilterState.currentPoolFilters)
    );

    const rows = this.store.selectSnapshot(QuestionListState.rows);
    const sort = this.store.selectSnapshot(QuestionListState.sortAttributes);
    const includingIds: string[] = [];
    const minNumberOfItems = 150;
    const to = action.from + rows - 1;

    try {
      return this.loadQuestionGroupListGQL
        .fetch({
          poolID: poolId,
          filter,
          sort,
          includingIds,
          minNumberOfItems,
          from: action.from,
          to
        })
        .pipe(
          switchMap(result => {
            return ctx.dispatch(
              new LoadQuestionGroupListSuccess(
                result.data.pool.questionGroupList.items,
                result.data.pool.questionGroupList.metadata
              )
            );
          }),
          catchError(() => {
            return ctx.dispatch(new LoadQuestionGroupListFailure());
          })
        );
    } catch (e: unknown) {
      return ctx.dispatch(new LoadQuestionGroupListFailure());
    }
  }

  @Action(LoadQuestionGroupListSuccess)
  public loadQuestionGroupListSuccess(
    ctx: StateContext<QuestionListStateModel>,
    action: LoadQuestionGroupListSuccess
  ): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    ctx.patchState({
      [poolId]: {
        ...(ctx.getState()[poolId] ?? defaultQuestionList),
        items: {
          requestState: 'success',
          data: [...action.questionGroupList]
        },
        metadata: {
          requestState: 'success',
          data: { ...action.questionGroupListMetadata }
        }
      }
    });
  }

  @Action(LoadQuestionGroupListFailure)
  public LoadQuestionGroupListFailure(
    ctx: StateContext<QuestionListStateModel>
  ): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    ctx.patchState({
      [poolId]: {
        ...ctx.getState()[poolId],
        items: {
          requestState: 'failure',
          data: undefined
        },
        metadata: {
          requestState: 'failure',
          data: undefined
        }
      }
    });
  }

  @Action(ReloadQuestionGroupList)
  public reloadQuestionGroupList(
    ctx: StateContext<QuestionListStateModel>,
    _action: ReloadQuestionGroupList
  ): Observable<void> {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    ctx.patchState({
      [poolId]: {
        ...ctx.getState()[poolId],
        items: {
          data: [],
          requestState: 'loading'
        }
      }
    });

    const filter = buildFilterQueryItemFromQuestionFilters(
      this.store.selectSnapshot(QuestionFilterState.currentPoolFilters)
    );

    const sortAttributes = ctx.getState()[poolId].sortAttributes || [];
    const includingIds: string[] = [];
    const minNumberOfItems = 150;
    const from = ctx.getState()[poolId].metadata.data?.from ?? 0;
    const to = ctx.getState()[poolId].metadata.data?.to ?? 19;

    try {
      return this.loadQuestionGroupListGQL
        .fetch({
          poolID: poolId,
          filter,
          sort: sortAttributes,
          includingIds,
          minNumberOfItems,
          from,
          to
        })
        .pipe(
          switchMap(result => {
            return ctx.dispatch(
              new LoadQuestionGroupListSuccess(
                result.data.pool.questionGroupList.items,
                result.data.pool.questionGroupList.metadata
              )
            );
          }),
          catchError(() => {
            return ctx.dispatch(new LoadQuestionGroupListFailure());
          })
        );
    } catch (e: unknown) {
      return ctx.dispatch(new LoadQuestionGroupListFailure());
    }
  }

  @Action(SetSelectedQuestionGroups)
  public setSelectedQuestionGroupIds(
    ctx: StateContext<QuestionListStateModel>,
    action: SetSelectedQuestionGroups
  ): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    ctx.patchState({
      [poolId]: {
        ...ctx.getState()[poolId],
        selectedQuestionGroups: action.questionGroups
      }
    });
  }

  // makes sure that when question groups are deleted, they are removed from the selection
  // also makes sure that attributes are updated if selected question groups are updated
  @Action(ReloadSelectedQuestionGroups)
  public reloadSelectedQuestionGroups(
    ctx: StateContext<QuestionListStateModel>,
    _action: ReloadSelectedQuestionGroups
  ): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    const currentSelectedQGs = ctx.getState()[poolId].selectedQuestionGroups;

    firstValueFrom(
      this.loadQuestionGroupsGQL.fetch({
        poolId,
        questionGroupIds: currentSelectedQGs.map(qg => qg.id)
      })
    ).then(result =>
      ctx.patchState({
        [poolId]: {
          ...ctx.getState()[poolId],
          selectedQuestionGroups: result.data.pool.questionGroups
        }
      })
    );
  }

  @Action(ResetSelectedQuestionGroups)
  public resetSelectedQuestionGroups(
    ctx: StateContext<QuestionListStateModel>,
    _action: ResetSelectedQuestionGroups
  ): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    ctx.patchState({
      [poolId]: {
        ...ctx.getState()[poolId],
        selectedQuestionGroups: []
      }
    });
  }

  @Action(SetSortingQuestionGroupList)
  public setSortingQuestionGroupList(
    ctx: StateContext<QuestionListStateModel>,
    action: SetSortingQuestionGroupList
  ): void {
    const poolId = this.store.selectSnapshot(ContextState.currentPoolId);
    assertIsDefined(poolId, 'QuestionGroupList requires a pool ID');

    ctx.patchState({
      [poolId]: {
        ...(ctx.getState()[poolId] ?? defaultQuestionList),
        sortAttributes: action.sortAttributes
      }
    });
  }
}
