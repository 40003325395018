<ng-container *ngIf="pools$ | async as pools">
  <div class="grid h-100" style="grid-template-rows: auto 1fr">
    <div class="g-col-6 g-col-xl-4">
      <man-search-input
        [shortcutActive]="true"
        placeholder="admin.pools.filter.placeholder"
        [disabled]="pools.requestState !== 'success'"
        (search)="onSearch($event)"
        (clear)="onSearchClear()"
      >
      </man-search-input>
    </div>
    <man-table-wrapper class="g-col-12" [request]="pools">
      <p-table
        name="Pools"
        [value]="pools.data | copyArray"
        [loading]="pools.requestState === 'loading'"
        selectionMode="single"
        [scrollable]="true"
        scrollHeight="flex"
        styleClass="p-datatable-striped p-datatable-sm"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="pools-id-column">
              {{ 'pool.pools.table.id' | translate }}
            </th>
            <th>{{ 'pool.pools.table.description' | translate }}</th>
            <th>{{ 'pool.pools.table.self_assessment.title' | translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pool>
          <tr interactiveRow (click)="onSelectPool(pool.id)">
            <td>
              {{ pool.id }}
            </td>
            <td>{{ pool.name }}</td>
            <td>
              {{
                'pool.pools.table.self_assessment.' + pool.selfAssessment
                  | translate
              }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <small class="text-end fw-normal text-muted font-monospace">
            {{ filteredPoolCount }} of {{ totalPoolCount }} pools
          </small>
        </ng-template>
      </p-table>
    </man-table-wrapper>
  </div>
</ng-container>
