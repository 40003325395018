import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type OAuthApplicationFieldsFragment = { __typename?: 'OAuthApplication', id: string, uid: string, name: string, secret: string, redirectUri: string };

export type LoadOAuthApplicationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoadOAuthApplicationsQuery = { __typename?: 'Query', oauthApplications: Array<{ __typename?: 'OAuthApplication', id: string, uid: string, name: string, secret: string, redirectUri: string }> };

export const OAuthApplicationFieldsFragmentDoc = gql`
    fragment OAuthApplicationFields on OAuthApplication {
  id
  uid
  name
  secret
  redirectUri
}
    `;
export const LoadOAuthApplicationsDocument = gql`
    query LoadOAuthApplications {
  oauthApplications {
    ...OAuthApplicationFields
  }
}
    ${OAuthApplicationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadOAuthApplicationsGQL extends Apollo.Query<LoadOAuthApplicationsQuery, LoadOAuthApplicationsQueryVariables> {
    document = LoadOAuthApplicationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }