import { Injectable } from '@angular/core';
import { Language } from '@generated/base-types';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { SetCurrentLanguage } from '@state/context';

// loads together with the app
// listens to language changes and sets the language in ngx-translate
// not triggering this directly in SetCurrentLanguage because we'd have to use ngZone.run()
// https://stackoverflow.com/questions/58352949/use-ngxs-actions-to-trigger-ngx-translate/58509995#58509995
@Injectable({ providedIn: 'root' })
export class NgxTranslateHandler {
  constructor(private actions$: Actions, translate: TranslateService) {
    this.actions$
      .pipe(ofActionDispatched(SetCurrentLanguage))
      .subscribe(({ language }: { language: Language }) => {
        translate.use(language);
      });
  }
}
