import {
  ChangeDetectionStrategy,
  Component,
  HostListener
} from '@angular/core';
import { requestStateSuccess } from '@common/utils/remote-data-utils';
import { isDefined } from '@common/utils/type-guards/is-defined';
import { Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PoolFormFragment } from '../../../../../services/load-pool-form.generated';
import { UnsavedChanges } from '../../../../common/guard/unsaved-changes.guard';
import { UpdatePool } from '../../../state/pool-details/form/pool-form.actions';
import { PoolFormState } from '../../../state/pool-details/form/pool-form.state';

@Component({
  templateUrl: './pool-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoolDetailComponent implements UnsavedChanges {
  public readonly pool$: Observable<PoolFormFragment>;
  public readonly loading$: Observable<boolean>;
  public readonly saving$: Observable<boolean>;
  public readonly disabled$: Observable<boolean>;

  public unsavedChanges = false;

  constructor(private store: Store) {
    this.pool$ = this.store.select(PoolFormState.pool).pipe(
      filter(requestStateSuccess),
      map(({ data }) => data),
      filter(isDefined)
    );
    this.saving$ = this.store.select(PoolFormState.mutationLoading);
    this.loading$ = this.store.select(PoolFormState.poolLoading);
    this.disabled$ = combineLatest([
      this.saving$,
      this.loading$,
      this.store.select(PoolFormState.poolFailure)
    ]).pipe(
      map(([pool, loading, failure]) => pool || loading || failure),
      distinctUntilChanged()
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  public onUnloadListener(event: BeforeUnloadEvent): void {
    if (!this.unsavedChanges) return;

    event.preventDefault();
  }

  public onSave({ id, ...pool }: PoolFormFragment): void {
    this.store.dispatch(new UpdatePool(id, pool));
  }

  public onStateChange(unsavedChanges: boolean): void {
    this.unsavedChanges = unsavedChanges;
  }
}
