<as-split direction="horizontal" unit="pixel" (dragEnd)="onDragEnd($event)">
  <as-split-area
    *ngIf="config.left.collapsed"
    [size]="collapsedPanelSize"
    [lockSize]="true"
    [order]="0"
  >
    <button
      class="border-0 bg-transparent pt-2 px-0 panel-toggle-btn"
      (click)="togglePanel('left')"
      aria-label="toggle-button"
    >
      <i class="fa-regular fa-sidebar"></i>
    </button>
  </as-split-area>

  <as-split-area
    *ngIf="!config.left.collapsed"
    [size]="config.left.size"
    [maxSize]="config.left.maxSize"
    [minSize]="config.left.minSize"
    [order]="0"
  >
    <ng-content select="[leftPanel]"></ng-content>
  </as-split-area>

  <as-split-area [order]="2">
    <ng-content select="[middlePanel]"></ng-content>
  </as-split-area>

  <as-split-area
    *ngIf="!config.right.collapsed"
    [size]="config.right.size"
    [maxSize]="config.right.maxSize"
    [minSize]="config.right.minSize"
    [order]="4"
  >
    <ng-content select="[rightPanel]"></ng-content>
  </as-split-area>

  <as-split-area
    *ngIf="config.right.collapsed"
    [size]="collapsedPanelSize"
    [lockSize]="true"
    [order]="4"
  >
    <button
      class="border-0 bg-transparent pt-2 px-0 panel-toggle-btn"
      (click)="togglePanel('right')"
      aria-label="toggle-button"
    >
      <i class="fa-regular fa-sidebar-flip"></i>
    </button>
  </as-split-area>
</as-split>
