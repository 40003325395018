import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { CommonModule as ManagerCommonModule } from '../../common/common.module';
import { InteractiveRowDirective } from '../../common/directives/row-interaction.directive';
import { FormatNamePipe } from '../../common/pipes/format-name.pipe';
import { CommonModule } from '../common/common.module';
import { LOCATION_TOKEN } from '../common/location';
import { TableUIModule } from '../common/table-ui.module';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { AnnouncementFormComponent } from './announcements/announcement-form/announcement-form.component';
import { AnnouncementSeverityComponent } from './announcements/announcement-severity.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { ApplicationFormComponent } from './applications/application-form/application-form.component';
import { ApplicationsComponent } from './applications/applications.component';
import { FormModule } from './form/form.module';
import { AffiliationFormComponent } from './pools/pool/affiliations-list/affiliation-form/affiliation-form.component';
import { AffiliationsListComponent } from './pools/pool/affiliations-list/affiliations-list.component';
import { CategoryFormComponent } from './pools/pool/dimensions-list/category-form/category-form.component';
import { DimensionFormComponent } from './pools/pool/dimensions-list/dimension-form/dimension-form.component';
import { DimensionsListComponent } from './pools/pool/dimensions-list/dimensions-list.component';
import { PoolDetailComponent } from './pools/pool/pool-detail/pool-detail.component';
import { PermissionFormComponent } from './pools/pool/pool-permissions/permission-form/permission-form.component';
import { PoolPermissionsComponent } from './pools/pool/pool-permissions/pool-permissions.component';
import { RoleDropdownComponent } from './pools/pool/pool-permissions/role-dropdown/role-dropdown.component';
import { SubjectDropdownComponent } from './pools/pool/pool-permissions/subject-dropdown/subject-dropdown.component';
import { UserDropdownComponent } from './pools/pool/pool-permissions/user-dropdown/user-dropdown.component';
import { RevisionStatusFormComponent } from './pools/pool/revision-status-list/revision-status-form/revision-status-form.component';
import { RevisionStatusListComponent } from './pools/pool/revision-status-list/revision-status-list.component';
import { CategoryTableComponent } from './pools/pool/subjects-list/category-table/category-table.component';
import { DimensionTableComponent } from './pools/pool/subjects-list/dimension-categories-table/dimension-categories-table.component';
import { SubjectFormComponent } from './pools/pool/subjects-list/subject-form/subject-form.component';
import { SubjectsListComponent } from './pools/pool/subjects-list/subjects-list.component';
import { PoolCreateComponent } from './pools/pool-create/pool-create.component';
import { PoolEditComponent } from './pools/pool-edit/pool-edit.component';
import { PoolFormComponent } from './pools/pool-form/pool-form.component';
import { PoolDropdownComponent } from './pools/pool-permissions/pool-dropdown/pool-dropdown.component';
import { PoolsComponent } from './pools/pools.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserDetailComponent } from './users/user-edit/user-detail.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserPermissionsComponent } from './users/user-permissions/user-permissions.component';
import { UserFormComponent } from './users-list/user-form/user-form.component';
import { UsersListComponent } from './users-list/users-list.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminHeaderComponent,
    AffiliationsListComponent,
    AffiliationFormComponent,
    AnnouncementsComponent,
    AnnouncementFormComponent,
    AnnouncementSeverityComponent,
    ApplicationsComponent,
    ApplicationFormComponent,
    PoolDetailComponent,
    PoolCreateComponent,
    PoolEditComponent,
    PoolFormComponent,
    PoolsComponent,
    RevisionStatusListComponent,
    PoolPermissionsComponent,
    PermissionFormComponent,
    SidePanelComponent,
    UsersListComponent,
    UserFormComponent,
    UserCreateComponent,
    UserEditComponent,
    UserPermissionsComponent,
    DimensionsListComponent,
    SubjectsListComponent,
    RoleDropdownComponent,
    PoolDropdownComponent,
    UserDropdownComponent,
    SubjectDropdownComponent,
    RevisionStatusFormComponent,
    CategoryTableComponent,
    SubjectFormComponent,
    DimensionTableComponent,
    FormatNamePipe,
    InteractiveRowDirective,
    DimensionFormComponent,
    CategoryFormComponent,
    UserDetailComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    A11yModule,
    FormModule,
    ManagerCommonModule,
    NgbModule,
    TableModule,
    TranslateModule,
    SidebarModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    TableUIModule
  ],
  providers: [
    {
      provide: LOCATION_TOKEN,
      useValue: window.location
    }
  ]
})
export class AdminModule {}
