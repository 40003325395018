import { Injectable } from '@angular/core';
import { takeGraphQLResult } from '@common/operators/take-graphql-response';
import { ToastsService } from '@common/services/toasts.service';
import { parseError } from '@common/utils/error-parser';
import { RemoteData, RequestState } from '@common/utils/remote-data';
import {
  requestStateFailure,
  requestStateLoading
} from '@common/utils/remote-data-utils';
import { isDefined } from '@common/utils/type-guards/is-defined';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Actions, ofAction, Selector, State } from '@ngxs/store';
import { LoadPools } from '@state/pools';
import { Observable } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { ModalService } from 'src/app/new/common/modal/modal.service';
import {
  LoadPoolFormGQL,
  PoolFormFragment
} from '../../../../../services/load-pool-form.generated';
import { CreatePoolGQL } from '../../../services/create-pool.generated';
import { UpdatePoolGQL } from '../../../services/update-pool.generated';
import {
  CreatePool,
  CreatePoolFailure,
  CreatePoolSuccess,
  LoadPool,
  LoadPoolFailure,
  LoadPoolSuccess,
  Reset,
  ResetMutation,
  UpdatePool,
  UpdatePoolFailure,
  UpdatePoolSuccess
} from './pool-form.actions';

export interface PoolFormStateModel {
  pool: RemoteData<PoolFormFragment>;
  mutation: RemoteData<void>;
}

@State<PoolFormStateModel>({
  name: 'poolform',
  defaults: {
    pool: { requestState: 'initial' },
    mutation: { requestState: 'initial' }
  }
})
@Injectable({
  providedIn: 'root'
})
export class PoolFormState {
  constructor(
    private readonly actions$: Actions,
    private readonly loadPoolFormService: LoadPoolFormGQL,
    private readonly createPoolService: CreatePoolGQL,
    private readonly updatePoolService: UpdatePoolGQL,
    private readonly toastsService: ToastsService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService
  ) {}

  @Selector()
  public static state(state: PoolFormStateModel): PoolFormStateModel {
    return state;
  }

  @Selector()
  public static requestState(state: PoolFormStateModel): RequestState {
    return state.pool.requestState;
  }

  @Selector()
  public static pool(state: PoolFormStateModel): RemoteData<PoolFormFragment> {
    return state.pool;
  }

  @Selector()
  public static poolId(state: PoolFormStateModel): string | undefined {
    return state.pool.data?.id;
  }

  @Selector()
  public static poolLoading(state: PoolFormStateModel): boolean {
    return requestStateLoading(state.pool);
  }

  @Selector()
  public static poolFailure(state: PoolFormStateModel): boolean {
    return requestStateFailure(state.pool);
  }

  @Selector()
  public static mutation(state: PoolFormStateModel): RemoteData<void> {
    return state.mutation;
  }

  @Selector()
  public static mutationLoading(state: PoolFormStateModel): boolean {
    return requestStateLoading(state.mutation);
  }

  @Action(LoadPool, { cancelUncompleted: true })
  public loadPool(
    ctx: StateContext<PoolFormStateModel>,
    { id }: LoadPool
  ): Observable<void> {
    ctx.patchState({ pool: { requestState: 'loading' } });

    return this.loadPoolFormService.fetch({ poolId: id }).pipe(
      takeGraphQLResult(),
      switchMap(res => ctx.dispatch(new LoadPoolSuccess(res.pool))),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadPoolFailure(parseError(err), id))
      ),
      takeUntil(this.actions$.pipe(ofAction(Reset)))
    );
  }

  @Action(LoadPoolSuccess)
  public loadPoolSuccess(
    ctx: StateContext<PoolFormStateModel>,
    { pool }: LoadPoolSuccess
  ): void {
    ctx.patchState({
      pool: {
        data: pool,
        requestState: 'success'
      }
    });
  }

  @Action(LoadPoolFailure)
  public loadPoolFailure(
    ctx: StateContext<PoolFormStateModel>,
    { error, id }: LoadPoolFailure
  ): void {
    ctx.patchState({
      pool: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (!retry) return;

      ctx.dispatch(new LoadPool(id));
    });
  }

  @Action(CreatePool)
  public createPool(
    ctx: StateContext<PoolFormStateModel>,
    { attributes }: CreatePool
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.createPoolService.mutate({ attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.createPool?.pool),
      filter(isDefined),
      mergeMap(pool => ctx.dispatch(new CreatePoolSuccess(pool))),
      catchError((err: unknown) =>
        ctx.dispatch(new CreatePoolFailure(parseError(err), attributes))
      )
    );
  }

  @Action(CreatePoolSuccess)
  public createPoolSuccess(
    ctx: StateContext<PoolFormStateModel>,
    { pool }: CreatePoolSuccess
  ): Observable<void> {
    ctx.patchState({
      pool: { requestState: 'success', data: pool },
      mutation: {
        requestState: 'success'
      }
    });

    this.toastsService.addSuccess(
      this.translateService.instant('admin.pools.notification.created')
    );

    return ctx.dispatch(new LoadPools());
  }

  @Action(CreatePoolFailure)
  public createPoolFailure(
    ctx: StateContext<PoolFormStateModel>,
    { error, attributes }: CreatePoolFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      ctx.dispatch(retry ? new CreatePool(attributes) : new ResetMutation());
    });
  }

  @Action(UpdatePool)
  public updatePool(
    ctx: StateContext<PoolFormStateModel>,
    { id, attributes }: UpdatePool
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.updatePoolService.mutate({ id, attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.updatePool?.pool),
      filter(isDefined),
      mergeMap(pool => ctx.dispatch(new UpdatePoolSuccess(pool))),
      catchError((err: unknown) =>
        ctx.dispatch(new UpdatePoolFailure(parseError(err), id, attributes))
      )
    );
  }

  @Action(UpdatePoolSuccess)
  public updatePoolSuccess(
    ctx: StateContext<PoolFormStateModel>,
    { pool }: UpdatePoolSuccess
  ): Observable<void> {
    ctx.patchState({
      pool: { requestState: 'success', data: pool },
      mutation: { requestState: 'success' }
    });

    this.toastsService.addSuccess(
      this.translateService.instant('admin.pools.notification.updated')
    );

    return ctx.dispatch(new LoadPools());
  }

  @Action(UpdatePoolFailure)
  public updatePoolFailure(
    ctx: StateContext<PoolFormStateModel>,
    { error, id, attributes }: UpdatePoolFailure
  ): void {
    ctx.patchState({
      mutation: { requestState: 'failure', error }
    });

    this.modalService
      .error({ errorCode: error.code, message: error.message })
      .then(retry => {
        ctx.dispatch(
          retry ? new UpdatePool(id, attributes) : new ResetMutation()
        );
      });
  }

  @Action(Reset)
  public reset(ctx: StateContext<PoolFormStateModel>): void {
    ctx.setState({
      pool: { requestState: 'initial' },
      mutation: { requestState: 'initial' }
    });
  }

  @Action(ResetMutation)
  public resetMutation(ctx: StateContext<PoolFormStateModel>): void {
    ctx.patchState({
      mutation: {
        requestState: 'initial'
      }
    });
  }
}
