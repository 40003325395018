import { CommonModule as AngularCommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TableModule } from 'primeng/table';
import {
  firstValueFrom,
  Observable,
  take,
  map,
  combineLatest,
  EMPTY
} from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import { RemoteData } from 'src/app/common/utils/remote-data';
import { ActionMenuOption } from 'src/app/new/common/action-menu/action-menu.component';
import { TableUIModule } from 'src/app/new/common/table-ui.module';
import { ModalService } from '../../../common/modal/modal.service';
import { GetTaskMagicLinkGQL } from '../../services/tasks/get-task-magic-link.generated';
import {
  DeleteTasks,
  ResetSelectedTaskQuestionGroups
} from '../../state/tasks/tasks.actions';
import { Task } from '../../types/task';
import { TaskStatusComponent } from './task-status/task-status.component';

@Component({
  selector: 'man-tasks-list',
  templateUrl: './tasks-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AngularCommonModule,
    TableModule,
    ManagerCommonModule,
    TaskStatusComponent,
    TableUIModule
  ],
  host: { style: 'overflow: scroll;' }
})
export class TasksListComponent implements OnInit {
  @Input() public tasks: Observable<RemoteData<Task[]>>;
  @Output() public hideTaskPanel = new EventEmitter<void>();

  public selection: Task[] = [];
  public tasksMenuOptionsList: ActionMenuOption[][] = [];
  private selectedTaskIds: Task['id'][] = [];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private readonly loadTaskMagicLinkService: GetTaskMagicLinkGQL
  ) {}

  public ngOnInit(): void {
    this.tasks
      .pipe(
        filter(({ requestState }) => requestState === 'success'),
        take(1)
      )
      .subscribe({
        next: tasksData => {
          if (tasksData?.data) {
            for (const task of tasksData.data) {
              this.tasksMenuOptionsList.push([
                {
                  label:
                    'task_management.task_list.tooltips.open_external_view',
                  danger: false,
                  disabled: !(task.magicLinkAvailable ?? false),
                  callback: async () => {
                    try {
                      const response = await firstValueFrom(
                        this.loadTaskMagicLinkService.fetch({
                          taskId: task.id.toString()
                        })
                      );

                      const externalUrl = `${response.data.taskMagicLink.url}&details=true`;
                      window.open(externalUrl, '_blank');
                    } catch (error) {
                      // eslint-disable-next-line no-console
                      console.error('Error fetching magic link URL:', error);
                    }
                  }
                },
                {
                  label: 'common.actions.delete',
                  danger: true,
                  callback: () => this.onDelete([task])
                }
              ]);
            }
          }
        },
        error: (err: unknown) => {
          // eslint-disable-next-line no-console
          console.error('Error initializing tasks menu options:', err);
        }
      });

    combineLatest([this.route.firstChild?.params || EMPTY, this.tasks])
      .pipe(
        map(([params, tasksData]) => {
          if (tasksData.requestState === 'success') {
            const ids = params.ids?.split(',') ?? [];

            return tasksData.data?.filter(task =>
              ids.includes(task.id.toString())
            );
          }

          return [];
        }),
        tap(tasks => {
          if (tasks) {
            this.selection = tasks;
          }
        })
      )
      .subscribe();
  }

  public onSelectionChange(): void {
    this.store.dispatch(new ResetSelectedTaskQuestionGroups());
    this.selectedTaskIds = this.selection.map(task => task.id);
    this.router.navigate(['./', this.selectedTaskIds.join(',')], {
      relativeTo: this.route
    });
  }

  public onHidePanel(): void {
    this.hideTaskPanel.emit();
  }

  public async onDelete(tasks: Task[]): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();
    if (confirmDelete) {
      const taskIds = tasks.map(task => task.id.toString());
      this.store.dispatch(new DeleteTasks(taskIds));
      this.filterDeletedTasks(taskIds);
    }
  }

  private filterDeletedTasks(taskIds: string[]): void {
    this.selectedTaskIds = this.selectedTaskIds.filter(
      id => !taskIds.includes(id.toString())
    );
    this.router.navigate(['./', this.selectedTaskIds.join(',')], {
      relativeTo: this.route
    });
    this.selection = this.selection.filter(
      task => !taskIds.includes(task.id.toString())
    );
  }
}
