import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PoolFormFragment } from '../../../../services/load-pool-form.generated';
import { languageOptions } from '../../../common/language-options';
import { EmailValidatorService } from '../../form/utils/email-validator-service';
import { requireOneOfValidatorFactory } from '../../form/utils/require-one-of.validator';

@Component({
  selector: 'man-pool-form',
  templateUrl: './pool-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoolFormComponent {
  @Input()
  public set pool(value: PoolFormFragment | undefined) {
    if (value !== undefined) {
      this.form.reset(value);
    }
  }
  @Input()
  public saving?: boolean;
  @Input()
  public loading?: boolean;
  @Input()
  public set disabled(value: boolean | undefined) {
    value === true ? this.form.disable() : this.form.enable();
  }

  @Output()
  public readonly save = new EventEmitter<PoolFormFragment>();
  @Output()
  public readonly exit = new EventEmitter<void>();
  @Output()
  public readonly stateChange: Observable<boolean>;

  public readonly languageOptions = languageOptions;
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private emailValidatorService: EmailValidatorService
  ) {
    this.form = this.createForm();
    this.stateChange = this.form.valueChanges.pipe(
      map(() => this.form.dirty),
      distinctUntilChanged()
    );
  }

  public onSave(): void {
    if (
      this.loading === true ||
      this.saving === true ||
      !this.form.valid ||
      !this.form.dirty
    ) {
      return;
    }

    this.save.emit(this.form.value);
  }

  public onClose(): void {
    if (this.saving === true) return;

    this.exit.emit();
  }

  private createForm(): FormGroup {
    return this.formBuilder.group(
      {
        id: [''],
        name: ['', [Validators.required]],
        selfAssessment: [false],
        deEnabled: [false],
        frEnabled: [false],
        itEnabled: [false],
        enEnabled: [false],
        responsibleFirstName: ['', [Validators.required]],
        responsibleLastName: ['', [Validators.required]],
        responsibleEmail: [
          '',
          [Validators.required, this.emailValidatorService.isEmailValid]
        ],
        responsibleLanguage: ['', [Validators.required]]
      },
      {
        validators: requireOneOfValidatorFactory(
          'deEnabled',
          'frEnabled',
          'itEnabled',
          'enEnabled'
        )
      }
    );
  }
}
