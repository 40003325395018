<ng-container *ngIf="validStatistics; else noTypeKprimeStatistics">
  <p-table
    name="TypeKprimmeStatistics"
    [value]="typeKprimeStatistics"
    styleClass="p-datatable-sm"
  >
    <ng-template pTemplate="caption">
      {{
        'pool.question_group_details.statistics.type_kprime_statistics.title'
          | translate
      }}
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th [translate]="i18n('response_options.response')"></th>
        <th [translate]="i18n('table.p')"></th>
        <th [translate]="i18n('response_options.solution')"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-statistics>
      <tr
        [ngClass]="{
          'p-highlight': statistics.solution
        }"
      >
        <td
          [translate]="
            i18n('type_kprime_statistics.statistics.' + statistics.name)
          "
        ></td>
        <td>{{ statistics.p | percentage | fixedNumber : 1 }}</td>
        <td>
          {{
            statistics.solution === undefined || statistics.solution === null
              ? ''
              : ('pool.question_group_details.statistics.response_options.' +
                  statistics.solution | translate)
          }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
<ng-template #noTypeKprimeStatistics>
  <details-info
    [message]="
      'pool.question_group_details.statistics.type_kprime_statistics.no_statistics'
        | translate
    "
  ></details-info>
</ng-template>
