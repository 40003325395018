<ng-container *ngIf="subjects$ | async as subjects">
  <p-dropdown
    [placeholder]="'common.messages.no_selection' | translate"
    [options]="subjects"
    [optionLabel]="'name'"
    [optionValue]="'id'"
    [filter]="true"
    [filterBy]="'name'"
    [(ngModel)]="selectedSubjectId"
    (ngModelChange)="onSubjectChange($event)"
    [showClear]="true"
    [optionDisabled]="'exists'"
  >
    <ng-template let-subject pTemplate="item" disabled="true">
      <div class="subject-item">
        <div class="label" [title]="subject.name">
          {{ subject.name }}
        </div>
        <div *ngIf="subject.exists" class="availability" [title]="subject.name">
          {{ 'admin.pools.permissions.exists' | translate }}
        </div>
      </div>
    </ng-template>
  </p-dropdown>
</ng-container>
