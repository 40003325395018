import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule as ManagerCommonModule } from '../common/common.module';
import { FormModule } from '../new/admin/form/form.module';
import { UnsavedChangesGuard } from '../new/common/guard/unsaved-changes.guard';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';

@NgModule({
  declarations: [ProfileComponent, ProfileFormComponent],
  providers: [UnsavedChangesGuard],
  imports: [
    ProfileRoutingModule,
    A11yModule,
    FormModule,
    ManagerCommonModule,
    NgbModule,
    TranslateModule,
    RouterModule
  ]
})
export class ProfileModule {}
