import { ManagerError } from 'src/app/common/utils/error-parser';
import { Scalars } from 'src/generated/base-types';
import { Task, TaskQuestionGroup } from '../../types/task';

export class LoadTasks {
  public static readonly type = '[Tasks] Load tasks';
}

export class LoadTasksSuccess {
  public static readonly type = '[Tasks] Load tasks success';
  constructor(public readonly tasks: Task[]) {}
}

export class LoadTasksFailure {
  public static readonly type = '[Tasks] Load tasks failure';
  constructor(public readonly error: ManagerError) {}
}

export class LoadTaskQuestionGroups {
  public static readonly type = '[Tasks] Load task question groups';
  constructor(public readonly taskIds: string[]) {}
}

export class LoadTaskQuestionGroupsSuccess {
  public static readonly type = '[Tasks] Load task question groups success';
  constructor(public readonly taskQuestionGroups: TaskQuestionGroup[]) {}
}

export class LoadTaskQuestionGroupsFailure {
  public static readonly type = '[Tasks] Load task question groups failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteTasks {
  public static readonly type = '[Tasks] Delete tasks';
  constructor(public readonly taskIds: Scalars['ID'][]) {}
}

export class DeleteTasksSuccess {
  public static readonly type = '[Tasks] Delete tasks success';
  constructor(public readonly taskIds: Scalars['ID'][]) {}
}

export class DeleteTasksFailure {
  public static readonly type = '[Tasks] Delete tasks failure';
  constructor(public readonly error: ManagerError) {}
}

export class SetSelectedTaskQuestionGroups {
  public static readonly type = '[Tasks] Set selected task question group IDs';
  constructor(public readonly taskQuestionGroups: TaskQuestionGroup[]) {}
}

export class ResetSelectedTaskQuestionGroups {
  public static readonly type =
    '[Tasks] Reset selected task question group IDs';
}

export class DeleteQuestionGroups {
  public static readonly type = '[Tasks] Delete question groups';
  constructor(public readonly questionGroupIds: Scalars['ID'][]) {}
}

export class DeleteQuestionGroupsSuccess {
  public static readonly type = '[Tasks] Delete question groups success';
  constructor(public readonly questionGroupIds: Scalars['ID'][]) {}
}

export class DeleteQuestionGroupsFailure {
  public static readonly type = '[Tasks] Delete question groups failure';
  constructor(public readonly error: ManagerError) {}
}

export class SetSupervisor {
  public static readonly type = '[Tasks] Set supervisor';
  constructor(
    public readonly supervisor: string,
    public readonly questionGroupIds: Scalars['ID'][]
  ) {}
}

export class SetSupervisorSuccess {
  public static readonly type = '[Tasks] Set supervisor success';
  constructor(
    public readonly supervisor: string,
    public readonly questionGroupIds: Scalars['ID'][]
  ) {}
}

export class SetSupervisorFailure {
  public static readonly type = '[Tasks] Set supervisor failure';
  constructor(public readonly error: ManagerError) {}
}
