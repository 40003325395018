<div
  [title]="'task_management.task.status.' + status.toLowerCase() | translate"
  [ngSwitch]="status"
  class="d-flex justify-content-center"
>
  <i *ngSwitchCase="'GIVEN'" class="fa-regular fa-circle icon-warning"></i>
  <i
    *ngSwitchCase="'IN_PROGRESS'"
    class="fa-solid fa-circle-half-stroke icon-warning"
  ></i>
  <i
    *ngSwitchCase="'FINISHED'"
    class="fa-solid fa-circle-check icon-success"
  ></i>
</div>
