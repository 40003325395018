import { Injectable } from '@angular/core';
import { ToastsService } from '@common/services/toasts.service';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, Store, type StateContext } from '@ngxs/store';
import { catchError, filter, Observable, switchMap } from 'rxjs';
import { takeGraphQLResult } from 'src/app/common/operators/take-graphql-response';
import { parseError } from 'src/app/common/utils/error-parser';
import { RemoteData } from 'src/app/common/utils/remote-data';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import { SetSupervisorGQL } from '../../services/pool/set-supervisor.generated';
import { DeleteQuestionGroupsGQL } from '../../services/tasks/delete-question-groups.generated';
import { DeleteTasksGQL } from '../../services/tasks/delete-tasks.generated';
import { LoadTaskQuestionGroupsGQL } from '../../services/tasks/load-question-groups.generated';
import { LoadTasksGQL } from '../../services/tasks/load-tasks.generated';
import { Task, TaskQuestionGroup } from '../../types/task';
import { PoolState } from '../pool/pool.state';
import {
  DeleteQuestionGroups,
  DeleteQuestionGroupsFailure,
  DeleteQuestionGroupsSuccess,
  DeleteTasks,
  DeleteTasksFailure,
  DeleteTasksSuccess,
  LoadTaskQuestionGroups,
  LoadTaskQuestionGroupsFailure,
  LoadTaskQuestionGroupsSuccess,
  LoadTasks,
  LoadTasksFailure,
  LoadTasksSuccess,
  ResetSelectedTaskQuestionGroups,
  SetSelectedTaskQuestionGroups,
  SetSupervisor,
  SetSupervisorFailure,
  SetSupervisorSuccess
} from './tasks.actions';

export interface TasksStateModel {
  tasks: RemoteData<Task[]>;
  taskQuestionGroups: RemoteData<TaskQuestionGroup[]>;
  selectedTaskQuestionGroupIds: TaskQuestionGroup['questionGroup']['id'][];
  mutation?: RemoteData<Task>;
}

@State<TasksStateModel>({
  name: 'tasks',
  defaults: {
    tasks: { requestState: 'initial' },
    taskQuestionGroups: { requestState: 'initial' },
    selectedTaskQuestionGroupIds: []
  }
})
@Injectable()
export class TasksState {
  constructor(
    private readonly loadTasksService: LoadTasksGQL,
    private readonly loadTaskQuestionGroupsService: LoadTaskQuestionGroupsGQL,
    private readonly deleteTasksService: DeleteTasksGQL,
    private readonly deleteQuestionGroupsService: DeleteQuestionGroupsGQL,
    private readonly setSupervisorService: SetSupervisorGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly store: Store
  ) {}

  @Action(LoadTasks)
  public loadTasks(ctx: StateContext<TasksStateModel>): Observable<void> {
    ctx.patchState({ tasks: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId => this.loadTasksService.fetch({ poolId })),
      takeGraphQLResult(),
      switchMap(res => ctx.dispatch(new LoadTasksSuccess(res.pool.tasks))),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadTasksFailure(parseError(err)))
      )
    );
  }

  @Action(LoadTasksSuccess)
  public loadTasksSuccess(
    ctx: StateContext<TasksStateModel>,
    { tasks }: LoadTasksSuccess
  ): void {
    ctx.patchState({ tasks: { requestState: 'success', data: tasks } });
  }

  @Action(LoadTasksFailure)
  public loadTasksFailure(
    ctx: StateContext<TasksStateModel>,
    { error }: LoadTasksFailure
  ): void {
    ctx.patchState({ tasks: { requestState: 'failure', error } });
  }

  @Action(LoadTaskQuestionGroups)
  public loadTaskQuestionGroups(
    ctx: StateContext<TasksStateModel>,
    { taskIds }: LoadTaskQuestionGroups
  ): Observable<void> {
    ctx.patchState({ taskQuestionGroups: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.loadTaskQuestionGroupsService.fetch({ poolId, taskIds })
      ),
      takeGraphQLResult(),
      switchMap(res => {
        const sorted = res.pool.taskQuestionGroups.sort((a, b) =>
          a.questionGroup.id > b.questionGroup.id ? 1 : -1
        );

        return ctx.dispatch(new LoadTaskQuestionGroupsSuccess(sorted));
      }),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadTaskQuestionGroupsFailure(parseError(err)))
      )
    );
  }

  @Action(LoadTaskQuestionGroupsSuccess)
  public loadTaskQuestionGroupsSuccess(
    ctx: StateContext<TasksStateModel>,
    { taskQuestionGroups }: LoadTaskQuestionGroupsSuccess
  ): void {
    ctx.patchState({
      taskQuestionGroups: { requestState: 'success', data: taskQuestionGroups }
    });
  }

  @Action(LoadTaskQuestionGroupsFailure)
  public loadTaskQuestionGroupsFailure(
    ctx: StateContext<TasksStateModel>,
    { error }: LoadTaskQuestionGroupsFailure
  ): void {
    ctx.patchState({ taskQuestionGroups: { requestState: 'failure', error } });
  }

  @Action(DeleteTasks)
  public deleteTasks(
    ctx: StateContext<TasksStateModel>,
    { taskIds }: DeleteTasks
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId => this.deleteTasksService.mutate({ poolId, taskIds })),
      takeGraphQLResult(),
      switchMap(() => ctx.dispatch(new DeleteTasksSuccess(taskIds))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteTasksFailure(parseError(err)))
      )
    );
  }

  @Action(DeleteTasksSuccess)
  public deleteTasksSuccess(
    ctx: StateContext<TasksStateModel>,
    { taskIds }: DeleteTasksSuccess
  ): void {
    ctx.patchState({
      tasks: {
        ...ctx.getState().tasks,
        data: ctx
          .getState()
          .tasks.data?.filter(task => !taskIds.map(Number).includes(task.id))
      },
      mutation: { requestState: 'success', data: undefined }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('common.models.task')
      })
    );
  }

  @Action(DeleteTasksFailure)
  public deleteTasksFailure(
    ctx: StateContext<TasksStateModel>,
    { error }: DeleteTasksFailure
  ): void {
    ctx.patchState({ mutation: { error, requestState: 'failure' } });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(SetSelectedTaskQuestionGroups)
  public setSelectedTaskQuestionGroups(
    ctx: StateContext<TasksStateModel>,
    { taskQuestionGroups }: SetSelectedTaskQuestionGroups
  ): void {
    ctx.patchState({
      selectedTaskQuestionGroupIds: taskQuestionGroups.map(
        tqg => tqg.questionGroup.id
      )
    });
  }

  @Action(ResetSelectedTaskQuestionGroups)
  public resetSelectedTaskQuestionGroups(
    ctx: StateContext<TasksStateModel>
  ): void {
    ctx.patchState({ selectedTaskQuestionGroupIds: [] });
  }

  @Action(DeleteQuestionGroups)
  public DeleteQuestionGroups(
    ctx: StateContext<TasksStateModel>,
    { questionGroupIds }: DeleteQuestionGroups
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.deleteQuestionGroupsService.mutate({ poolId, questionGroupIds })
      ),
      takeGraphQLResult(),
      switchMap(() =>
        ctx.dispatch(new DeleteQuestionGroupsSuccess(questionGroupIds))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteQuestionGroupsFailure(parseError(err)))
      )
    );
  }

  @Action(DeleteQuestionGroupsSuccess)
  public deleteQuestionGroupsSuccess(
    ctx: StateContext<TasksStateModel>,
    { questionGroupIds }: DeleteQuestionGroupsSuccess
  ): void {
    ctx.patchState({
      taskQuestionGroups: {
        ...ctx.getState().taskQuestionGroups,
        data: ctx
          .getState()
          .taskQuestionGroups.data?.filter(
            tqg => !questionGroupIds.includes(tqg.questionGroup.id)
          )
      },
      mutation: { requestState: 'success', data: undefined }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('common.models.question')
      })
    );
  }

  @Action(DeleteQuestionGroupsFailure)
  public deleteQuestionGroupsFailure(
    ctx: StateContext<TasksStateModel>,
    { error }: DeleteQuestionGroupsFailure
  ): void {
    ctx.patchState({ mutation: { error, requestState: 'failure' } });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(SetSupervisor)
  public setSupervisor(
    ctx: StateContext<TasksStateModel>,
    { supervisor, questionGroupIds }: SetSupervisor
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.setSupervisorService.mutate({
          poolId,
          questionGroupIds,
          user: supervisor
        })
      ),
      takeGraphQLResult(),
      switchMap(() =>
        ctx.dispatch(new SetSupervisorSuccess(supervisor, questionGroupIds))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new SetSupervisorFailure(parseError(err)))
      )
    );
  }

  @Action(SetSupervisorSuccess)
  public setSupervisorSuccess(
    ctx: StateContext<TasksStateModel>,
    { supervisor, questionGroupIds }: SetSupervisorSuccess
  ): void {
    ctx.patchState({
      taskQuestionGroups: {
        ...ctx.getState().taskQuestionGroups,
        data: ctx
          .getState()
          .taskQuestionGroups.data?.map(tqg =>
            questionGroupIds.includes(tqg.questionGroup.id)
              ? { ...tqg, questionGroup: { ...tqg.questionGroup, supervisor } }
              : tqg
          )
      },
      mutation: { requestState: 'success', data: undefined }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('common.models.supervisor')
      })
    );
  }

  @Action(SetSupervisorFailure)
  public setSupervisorFailure(
    ctx: StateContext<TasksStateModel>,
    { error }: SetSupervisorFailure
  ): void {
    ctx.patchState({ mutation: { error, requestState: 'failure' } });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }
}
