import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { isVoid } from '../../../common/utils/type-guards/voidable';
import { ContextState } from '../../../state/context/context.state';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    const user = this.store.selectSnapshot(ContextState.currentUser);
    if (isVoid(user)) return false;

    return user.superUser;
  }
}
