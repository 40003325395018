/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { DimensionsState } from 'src/app/new/admin/state/pool-details/dimensions/dimensions.state';
import { Category, Dimension, Subject } from 'src/generated/base-types';

@Component({
  selector: 'app-dimension-categories-table',
  templateUrl: './dimension-categories-table.component.html',
  host: { class: 'd-flex flex-column h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DimensionTableComponent)
    }
  ]
})
export class DimensionTableComponent {
  @Input() public subject: Subject | undefined;

  public selectedCategories: string[] = [];
  public value?: string[];
  public touched = false;
  public disabled = false;
  public onChange: (value: string[]) => void;
  public onTouched: () => void;

  public dimensions$: Observable<Dimension[]>;
  public categories$: Observable<Category[]>;
  public filteredCategories$: Observable<Category[]>;

  public searchStringSource = new BehaviorSubject<string>('');
  public searchString$ = this.searchStringSource.asObservable();

  constructor(private store: Store) {
    this.searchStringSource.next('');
    this.dimensions$ = this.store
      .select(DimensionsState.dimensions)
      .pipe(map(dimensionsReq => (dimensionsReq.data || []) as Dimension[]));

    this.categories$ = this.dimensions$.pipe(
      map(dimensions => {
        const categories: Set<Category> = new Set();
        dimensions.forEach(dimension => {
          dimension.categories.forEach(category => {
            const { categories: _, ...rest } = dimension;
            categories.add({
              ...category,
              dimension: { ...rest } as Omit<Dimension, 'catagories'>
            });
          });
        });

        return [...categories];
      })
    );

    this.filteredCategories$ = combineLatest([
      this.categories$,
      this.searchString$
    ]).pipe(
      map(([categories, searchString]) => {
        if (!searchString) {
          return categories;
        }

        return categories.filter(category => {
          const { nameEn, nameDe, nameFr, nameIt } = category;

          return (
            (nameEn || '').toLowerCase().includes(searchString.toLowerCase()) ||
            (nameDe || '').toLowerCase().includes(searchString.toLowerCase()) ||
            (nameFr || '').toLowerCase().includes(searchString.toLowerCase()) ||
            (nameIt || '').toLowerCase().includes(searchString.toLowerCase())
          );
        });
      })
    );
  }

  public writeValue(value?: string[]): void {
    this.value = value;
    this.selectedCategories = value || [];
  }

  public registerOnChange(onChange: (value: string[]) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public onSelectionChange(): void {
    this.onChange(this.selectedCategories);
  }

  public onSearch(event: string): void {
    this.searchStringSource.next(event);
  }

  public onSearchClear(): void {
    this.searchStringSource.next('');
  }
}
