import { ManagerError } from 'src/app/common/utils/error-parser';
import { CreateCategoryMutationVariables } from 'src/app/new/admin/services/create-category.generated';
import { UpdateCategoryMutationVariables } from 'src/app/new/admin/services/update-category.generated';
import { UpdateDimensionMutationVariables } from 'src/app/new/admin/services/update-dimension.generated';
import {
  DimensionInput,
  MoveDirection,
  Scalars,
  SetCategoryPositionPayload,
  SetDimensionPositionPayload
} from 'src/generated/base-types';
import { CategoryData, DimensionData } from './dimensions.state';

export class LoadDimensions {
  public static readonly type = '[Dimensions] Load Dimensions';
}

export class LoadDimensionsSuccess {
  public static readonly type = '[Dimensions] Load Dimensions Success';
  constructor(public readonly dimensions: DimensionData[]) {}
}

export class LoadDimensionsFailure {
  public static readonly type = '[Dimensions] Load Dimensions Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateDimension {
  public static readonly type = '[Dimensions] Create';
  constructor(public readonly attributes: DimensionInput) {}
}

export class CreateDimensionSuccess {
  public static readonly type = '[Dimensions] Create Success';
  constructor(public readonly dimension: DimensionData) {}
}

export class CreateDimensionFailure {
  public static readonly type = '[Dimensions] Create Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateDimension {
  public static readonly type = '[Dimensions] Update Dimension';
  constructor(
    public readonly id: UpdateDimensionMutationVariables['id'],
    public readonly attributes: UpdateDimensionMutationVariables['attributes']
  ) {}
}

export class UpdateDimensionSuccess {
  public static readonly type = '[Dimensions] Update Dimension Success';
  constructor(public readonly dimension: DimensionData) {}
}

export class UpdateDimensionFailure {
  public static readonly type = '[Dimensions] Update Dimension Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateCategory {
  public static readonly type = '[Dimensions] Update Category';
  constructor(
    public readonly id: UpdateCategoryMutationVariables['id'],
    public readonly attributes: UpdateCategoryMutationVariables['attributes']
  ) {}
}

export class UpdateCategorySuccess {
  public static readonly type = '[Dimensions] Update Category Success';
  constructor(public readonly category: CategoryData) {}
}

export class UpdateCategoryFailure {
  public static readonly type = '[Dimensions] Update Category Failure';
  constructor(public readonly error: ManagerError) {}
}

export class SetDimensionPosition {
  public static readonly type = '[Dimensions] Set Dimension Position';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly direction: MoveDirection,
    public readonly times?: number
  ) {}
}

export class SetDimensionPositionSuccess {
  public static readonly type = '[Dimensions] Set Dimension Position Success';
  constructor(
    public readonly dimension: SetDimensionPositionPayload['dimension'],
    public readonly dimensions: SetDimensionPositionPayload['dimensions']
  ) {}
}

export class SetDimensionPositionFailure {
  public static readonly type = '[Dimensions] Set Dimensions Position Failure';
  constructor(public readonly error: ManagerError) {}
}

export class SetCategoryPosition {
  public static readonly type = '[Dimensions] Set Category Position';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly direction: MoveDirection,
    public readonly times?: number
  ) {}
}

export class SetCategoryPositionSuccess {
  public static readonly type = '[Dimensions] Set Category Position Success';
  constructor(
    public readonly category: SetCategoryPositionPayload['category'],
    public readonly categories: SetCategoryPositionPayload['categories']
  ) {}
}

export class SetCategoryPositionFailure {
  public static readonly type = '[Dimensions] Set Category Position Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateCategory {
  public static readonly type = '[Dimensions] Create Category';
  constructor(
    public readonly dimensionId: CreateCategoryMutationVariables['dimensionId'],
    public readonly attributes: CreateCategoryMutationVariables['attributes']
  ) {}
}

export class CreateCategorySuccess {
  public static readonly type = '[Dimensions] Create Category Success';
  constructor(
    public readonly dimensionId: CreateCategoryMutationVariables['dimensionId'],
    public readonly category: CategoryData
  ) {}
}

export class CreateCategoryFailure {
  public static readonly type = '[Dimensions] Create Category Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteDimension {
  public static readonly type = '[Dimensions] Delete Dimension';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteDimensionSuccess {
  public static readonly type = '[Dimensions] Delete Dimension Success';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteDimensionFailure {
  public static readonly type = '[Dimensions] Delete Dimension Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID']
  ) {}
}

export class DeleteCategory {
  public static readonly type = '[Dimensions] Delete Category';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteCategorySuccess {
  public static readonly type = '[Dimensions] Delete Category Success';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteCategoryFailure {
  public static readonly type = '[Dimensions] Delete Category Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID']
  ) {}
}
