import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { TableErrorComponent } from './table-wrapper/table-error/table-error.component';
import { TableWrapperComponent } from './table-wrapper/table-wrapper.component';

@NgModule({
  imports: [AngularCommonModule, NgbModule, TranslateModule],
  declarations: [
    ActionMenuComponent,
    TableWrapperComponent,
    TableErrorComponent,
    SearchInputComponent
  ],
  exports: [
    ActionMenuComponent,
    TableWrapperComponent,
    TableErrorComponent,
    SearchInputComponent
  ]
})
export class TableUIModule {}
