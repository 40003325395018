<ng-container
  *ngIf="statistics.subgroups.length > 0; else noSubgroupStatistics"
>
  <p-table
    name="SubgroupStatistics"
    [value]="statistics.subgroups"
    styleClass="p-datatable-sm p-datatable-striped"
  >
    <ng-template pTemplate="caption">
      {{ 'pool.question_group_details.statistics.subgroup.title' | translate }}
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th [translate]="i18n('subgroup.name')"></th>
        <th [translate]="i18n('table.n')"></th>
        <th
          [pTooltip]="
            'pool.question_group_details.statistics.overview.hint.p_partial'
              | translate
          "
          tooltipPosition="top"
        >
          {{
            'pool.question_group_details.statistics.table.p_partial' | translate
          }}
          <i class="fa-solid fa-square-info"></i>
        </th>
        <th
          [pTooltip]="
            'pool.question_group_details.statistics.overview.hint.p_dichotomous'
              | translate
          "
          tooltipPosition="top"
        >
          {{
            'pool.question_group_details.statistics.table.p_dichotomous'
              | translate
          }}
          <i class="fa-solid fa-square-info"></i>
        </th>
        <th [translate]="i18n('table.r')"></th>
        <th [translate]="i18n('table.lne')"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-subgroup let-index="rowIndex">
      <tr *ngIf="index === 0">
        <td [translate]="i18n('subgroup.total')"></td>
        <td>
          {{
            statistics.total.n !== null && statistics.total.n !== undefined
              ? (statistics.total.n | fixedNumber : 0)
              : '-'
          }}
        </td>
        <td>
          {{
            statistics.total.p !== null && statistics.total.p !== undefined
              ? (statistics.total.p | percentage | fixedNumber : 1)
              : '-'
          }}
        </td>
        <td>
          {{
            statistics.total.pDichotomous !== null &&
            statistics.total.pDichotomous !== undefined
              ? (statistics.total.pDichotomous | percentage | fixedNumber : 1)
              : '-'
          }}
        </td>
        <td>
          {{
            statistics.total.r !== null && statistics.total.r !== undefined
              ? (statistics.total.r | fixedNumber : 2)
              : '-'
          }}
        </td>
        <td>
          {{
            statistics.total.lne !== null && statistics.total.lne !== undefined
              ? (statistics.total.lne | fixedNumber : 3)
              : '-'
          }}
        </td>
      </tr>
      <tr>
        <td>{{ subgroup.name }}</td>
        <td>
          {{
            subgroup.n !== null && subgroup.n !== undefined
              ? (subgroup.n | fixedNumber : 0)
              : '-'
          }}
        </td>
        <td>
          {{
            subgroup.p !== null && subgroup.p !== undefined
              ? (subgroup.p | percentage | fixedNumber : 1)
              : '-'
          }}
        </td>
        <td>
          {{
            subgroup.pDichotomous !== null &&
            subgroup.pDichotomous !== undefined
              ? (subgroup.pDichotomous | percentage | fixedNumber : 1)
              : '-'
          }}
        </td>
        <td>
          {{
            subgroup.r !== null && subgroup.r !== undefined
              ? (subgroup.r | fixedNumber : 2)
              : '-'
          }}
        </td>
        <td>
          {{
            subgroup.lne !== null && subgroup.lne !== undefined
              ? (subgroup.lne | fixedNumber : 3)
              : '-'
          }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
<ng-template #noSubgroupStatistics>
  <details-info
    [message]="
      'pool.question_group_details.statistics.subgroup.no_statistics'
        | translate
    "
  ></details-info>
</ng-template>
