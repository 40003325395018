import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuestionGroupType, Scalars } from '../../../../generated/base-types';
import {
  QuestionGroupListElementFragment,
  QuestionGroupListElementQuestionSequenceFragment,
  QuestionGroupListElementQuestionSeriesFragment,
  QuestionGroupListElementSingleQuestionFragment,
  QuestionListElementFragment
} from '../../../question-form/services/load-question-group-list.generated';
import { PoolDimensionFragment } from '../../../services/load-pool.generated';
import { PoolState } from '../../../state/pool/pool.state';
import { LabelValuePair } from '../../ng-select-wrapper/ng-select-wrapper.component';
import { isDefined } from '../../utils/type-guards/is-defined';
import {
  SetBlueprintGQL,
  SetBlueprintMutationVariables
} from './set-blueprint.generated';

function isSingleQG(
  qg: QuestionGroupListElementFragment
): qg is QuestionGroupListElementSingleQuestionFragment {
  return qg.type === QuestionGroupType.Single;
}

function isSequenceQG(
  qg: QuestionGroupListElementFragment
): qg is QuestionGroupListElementQuestionSequenceFragment {
  return qg.type === QuestionGroupType.Sequence;
}

function isSeriesQG(
  qg: QuestionGroupListElementFragment
): qg is QuestionGroupListElementQuestionSeriesFragment {
  return qg.type === QuestionGroupType.Series;
}

export function extractQGQuestions(
  qg: QuestionGroupListElementFragment
): QuestionListElementFragment[] {
  if (isSingleQG(qg) && isDefined(qg.question)) {
    return [qg.question];
  } else if (isSequenceQG(qg) && isDefined(qg.questions)) {
    return qg.questions;
  } else if (isSeriesQG(qg) && isDefined(qg.questions)) {
    return qg.questions;
  }

  return [];
}

@Component({
  selector: 'co-assign-blueprint-modal',
  templateUrl: './assign-blueprint-modal.component.html',
  styleUrls: ['./assign-blueprint-modal.component.scss']
})
export class AssignBlueprintModalComponent implements OnInit, OnDestroy {
  @Input()
  public modalInstance: NgbModalRef;
  @Input()
  public questionGroups: QuestionGroupListElementFragment[];
  @Select(PoolState.dimensions)
  public dimensions$: Observable<PoolDimensionFragment[]>;
  @Input()
  public currentPoolId: Scalars['ID'];

  public blueprint: SetBlueprintMutationVariables;
  public dimensions: {
    options: LabelValuePair<string>[];
    label: string;
  }[] = [];
  private destroy$ = new Subject<void>();

  constructor(private readonly setBlueprintGQL: SetBlueprintGQL) {}

  public ngOnInit(): void {
    this.dimensions$.pipe(takeUntil(this.destroy$)).subscribe(dimensions => {
      this.dimensions = dimensions.map(dimension => {
        const categories = dimension.categories.map(category => ({
          label: category.name,
          value: category.id
        }));

        return { options: categories, label: dimension.name };
      });
    });

    this.blueprint = {
      poolId: this.currentPoolId,
      questionGroupIds: this.questionGroups.map(qg => qg.id),
      category0Id: undefined,
      category1Id: undefined,
      category2Id: undefined,
      category3Id: undefined,
      category4Id: undefined,
      category5Id: undefined,
      category6Id: undefined,
      category7Id: undefined
    };

    this.presetMatchingCategories();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public presetMatchingCategories(): void {
    const questionCategories = this.questionGroups.reduce(
      (acc, qg) => {
        extractQGQuestions(qg).forEach(q => {
          acc[0].push(q.category0?.id);
          acc[1].push(q.category1?.id);
          acc[2].push(q.category2?.id);
          acc[3].push(q.category3?.id);
          acc[4].push(q.category4?.id);
          acc[5].push(q.category5?.id);
          acc[6].push(q.category6?.id);
          acc[7].push(q.category7?.id);
        });

        return acc;
      },
      [[], [], [], [], [], [], [], []] as (Scalars['ID'] | undefined)[][]
    );

    questionCategories.forEach((categoryIds, categoryIndex) => {
      if (new Set(categoryIds).size === 1 && isDefined(categoryIds[0])) {
        this.setCategory(categoryIds[0], Number(categoryIndex));
      }
    });
  }

  public setCategory(categoryId: string | null, index: number): void {
    switch (index) {
      case 0: {
        this.blueprint.category0Id = categoryId;

        return;
      }
      case 1: {
        this.blueprint.category1Id = categoryId;

        return;
      }
      case 2: {
        this.blueprint.category2Id = categoryId;

        return;
      }
      case 3: {
        this.blueprint.category3Id = categoryId;

        return;
      }
      case 4: {
        this.blueprint.category4Id = categoryId;

        return;
      }
      case 5: {
        this.blueprint.category5Id = categoryId;

        return;
      }
      case 6: {
        this.blueprint.category6Id = categoryId;

        return;
      }
      case 7: {
        this.blueprint.category7Id = categoryId;
      }
    }
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public async save(): Promise<void> {
    const result = await this.setBlueprintGQL
      .mutate(this.blueprint)
      .toPromise();

    if (result?.data?.setBlueprint?.successful !== true) {
      throw `Unable to set blueprint for ${this.blueprint.questionGroupIds}`;
    }

    this.modalInstance.close();
  }
}
