import { Scalars, UserInput } from 'src/generated/base-types';
import { ManagerError } from '../../../common/utils/error-parser';
import { LoadUsersFieldsFragment } from '../services/load-users.fragments.generated';

export class LoadUser {
  public static readonly type = '[User-Form] Load Users';
  constructor(public readonly id: Scalars['ID']) {}
}

export class LoadUserSuccess {
  public static readonly type = '[User Form] Load Users Success';
  constructor(public readonly user: LoadUsersFieldsFragment) {}
}

export class LoadUserFailure {
  public static readonly type = '[User Form] Load Users Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID']
  ) {}
}

export class CreateUser {
  public static readonly type = '[User Form] Create User';
  constructor(public readonly attributes: UserInput) {}
}

export class CreateUserSuccess {
  public static readonly type = '[User Form] Create User Success';
  constructor(public readonly user: LoadUsersFieldsFragment) {}
}

export class CreateUserFailure {
  public static readonly type = '[User Form] Create User Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly attributes: UserInput
  ) {}
}

export class UpdateUser {
  public static readonly type = '[User Form] Update User';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly attributes: UserInput
  ) {}
}

export class UpdateUserSuccess {
  public static readonly type = '[User Form] Update User Success';
  constructor(public readonly user: LoadUsersFieldsFragment) {}
}

export class UpdateUserFailure {
  public static readonly type = '[User Form] Update User Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID'],
    public readonly attributes: UserInput
  ) {}
}

export class ResetMutation {
  public static readonly type = '[User Form] Reset Mutation';
}

export class Reset {
  public static readonly type = '[User Form] Reset';
}
