<ng-container *ngIf="taskQuestionGroups | async as taskQuestionGroups">
  <man-table-wrapper [request]="taskQuestionGroups">
    <p-table
      name="Question Group"
      styleClass="p-datatable-sm"
      [value]="taskQuestionGroups.data | copyArray"
      [loading]="taskQuestionGroups.requestState === 'loading'"
      selectionMode="multiple"
      [metaKeySelection]="true"
      [(selection)]="selection"
      (selectionChange)="onSelectionChange()"
      [scrollable]="true"
      scrollHeight="flex"
      (sortFunction)="customSort($event)"
      [customSort]="true"
    >
      <ng-template pTemplate="caption">
        <div id="header-container">
          <div class="pb-3">
            {{ 'task_management.task_question_groups.title' | translate }}
          </div>
          <man-question-group-toolbar
            [managementType]="ToolbarManagementType.Task"
          ></man-question-group-toolbar>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th
            *ngFor="let header of getHeaders()"
            pSortableColumn="{{ header.sortingField }}"
          >
            {{ base + header.headerName | translate }}
            <p-sortIcon field="{{ header.sortingField }}"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-taskQuestionGroup>
        <tr [pSelectableRow]="taskQuestionGroup">
          <td>{{ taskQuestionGroup.questionGroup.sequentialNumber }}</td>
          <td>{{ taskQuestionGroup.questionGroup.sourceLanguage }}</td>
          <td>{{ taskQuestionGroup.questionGroup.title }}</td>
          <td class="name-cell">
            {{ taskQuestionGroup.questionGroup.supervisor }}
          </td>
          <td>
            {{
              'task_management.task_question_groups.question_group.type.' +
                taskQuestionGroup.questionGroup.type | translate
            }}
          </td>
          <td>
            <ng-container
              *ngFor="
                let question of getQuestionGroupQuestions(
                  taskQuestionGroup.questionGroup
                );
                let isLast = last
              "
              >{{
                'task_management.task_question_groups.questions.type.' +
                  question.type | translate
              }}{{ !isLast ? ', ' : '' }}
            </ng-container>
          </td>
          <td>
            {{
              getQuestionGroupQuestions(taskQuestionGroup.questionGroup)[0]
                .category0?.name || ' - '
            }}
          </td>
          <td>
            {{
              getQuestionGroupQuestions(taskQuestionGroup.questionGroup)[0]
                .category1?.name || ' - '
            }}
          </td>
          <td>
            {{
              getQuestionGroupQuestions(taskQuestionGroup.questionGroup)[0]
                .category2?.name || ' - '
            }}
          </td>
          <td>
            {{
              getQuestionGroupQuestions(taskQuestionGroup.questionGroup)[0]
                .category3?.name || ' - '
            }}
          </td>
          <td>
            {{
              getQuestionGroupQuestions(taskQuestionGroup.questionGroup)[0]
                .category4?.name || ' - '
            }}
          </td>
          <td>
            {{
              getQuestionGroupQuestions(taskQuestionGroup.questionGroup)[0]
                .category5?.name || ' - '
            }}
          </td>
          <td>
            {{
              getQuestionGroupQuestions(taskQuestionGroup.questionGroup)[0]
                .category6?.name || ' - '
            }}
          </td>
          <td>
            {{
              getQuestionGroupQuestions(taskQuestionGroup.questionGroup)[0]
                .category7?.name || ' - '
            }}
          </td>
          <td class="name-cell">
            {{ taskQuestionGroup.questionGroup.editor.lastName }}
            {{ taskQuestionGroup.questionGroup.editor.firstName }}
          </td>
          <td class="name-cell">
            {{ taskQuestionGroup.questionGroup.author }}
          </td>
          <td>
            {{ taskQuestionGroup.questionGroup.revisionStatus?.shortName }}
          </td>
          <td>
            <div>
              <man-action-menu
                [data]="taskQuestionGroup.questionGroup"
                [options]="questionGroupOptions"
              ></man-action-menu>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <ng-template
          [ngIf]="!!taskQuestionGroups.data?.length"
          [ngIfElse]="noEntries"
        >
          <tr>
            <td colspan="100%" style="text-align: center">
              {{ 'task_management.task_question_groups.amount' | translate }}:
              {{ taskQuestionGroups.data?.length }}
            </td>
          </tr>
        </ng-template>

        <ng-template #noEntries>
          <tr>
            <td colspan="100%" style="text-align: center">
              {{
                'task_management.task_question_groups.question_list.no_entries'
                  | translate
              }}
            </td>
          </tr>
        </ng-template>
      </ng-template>
    </p-table>
  </man-table-wrapper>
</ng-container>
