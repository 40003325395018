import { Selector } from '@ngxs/store';
import { RemoteData, RequestState } from 'src/app/common/utils/remote-data';
import { Task, TaskQuestionGroup } from '../../types/task';
import { TasksState, TasksStateModel } from './tasks.state';

export class TaskSelectors {
  @Selector([TasksState])
  public static tasks(state: TasksStateModel): RemoteData<Task[]> {
    return state.tasks;
  }

  @Selector([TasksState])
  public static tasksList(state: TasksStateModel): Task[] {
    return state.tasks.data ?? [];
  }

  @Selector([TasksState])
  public static taskQuestionGroups(
    state: TasksStateModel
  ): RemoteData<TaskQuestionGroup[]> {
    return state.taskQuestionGroups;
  }

  @Selector([TasksState])
  public static taskQuestionGroupsRequestState(
    state: TasksStateModel
  ): RequestState {
    return state.taskQuestionGroups.requestState;
  }

  @Selector([TasksState])
  public static selectedTaskQuestionGroupIds(
    state: TasksStateModel
  ): TaskQuestionGroup['questionGroup']['id'][] {
    return state.selectedTaskQuestionGroupIds;
  }
}
