import type { ServerError } from '@apollo/client/core';
import { ApolloError } from '@apollo/client/core';
import type { ServerParseError } from '@apollo/client/link/http';
import { GraphQLError } from 'graphql';
import * as Honeybadger from 'honeybadger-js';
import { environment } from '../../../environments/environment';
import {
  GenericGraphQLValidations,
  GraphQLValidationError
} from '../types/validation-error-types';
import { isOfTypeByProperty } from './type-guards/is-of-type-by-property';

export enum GraphQLErrorCode {
  AuthErrorNotAllowed = '[AUTH ERROR] NOT ALLOWED',
  GeneralErrorNotFound = '[GENERAL ERROR] NOT FOUND'
}

export enum ManagerErrorCode {
  NotAllowedError = 'NOT_ALLOWED',
  NotFoundError = 'NOT_FOUND',
  GeneralGraphQLError = 'GENERAL_GRAPHQL',
  ServerError = 'SERVER_ERROR',
  ServerParseError = 'SERVER_PARSE_ERROR',
  GeneralError = 'GENERAL_ERROR',
  ValidationError = 'VALIDATION_ERROR'
}

export function parseError<T = undefined>(error: unknown): ManagerError<T> {
  const parsedError = getManagerError<T>(error);

  // no need to raise errors when there's a validation error
  // as it will appear in the form
  if (parsedError.code !== ManagerErrorCode.ValidationError) {
    environment.production
      ? // eslint-disable-next-line import/namespace
        Honeybadger.notify({
          errorName: parsedError.code,
          error
        })
      : // eslint-disable-next-line no-console
        console.error(parsedError.code, error);
  }

  return parsedError;
}

export interface ManagerError<T = unknown> {
  code: ManagerErrorCode;
  validations?: GenericGraphQLValidations<T>;
  message?: string;
}

function getManagerError<T>(error: unknown): ManagerError<T> {
  if (error instanceof GraphQLError) {
    return {
      code: mapGraphQLError(error),
      message: error.message
    };
  }

  if (error instanceof ApolloError) {
    return { code: mapApolloError(error) };
  }

  if (error instanceof GraphQLValidationError) {
    return {
      code: ManagerErrorCode.ValidationError,
      validations: error.validations
    };
  }

  return { code: ManagerErrorCode.GeneralError };
}

const mapGraphQLError = ({ extensions }: GraphQLError): ManagerErrorCode => {
  switch (extensions?.code) {
    case GraphQLErrorCode.AuthErrorNotAllowed: {
      return ManagerErrorCode.NotAllowedError;
    }
    case GraphQLErrorCode.GeneralErrorNotFound: {
      return ManagerErrorCode.NotFoundError;
    }
  }

  return ManagerErrorCode.GeneralGraphQLError;
};

const mapApolloError = ({ networkError }: ApolloError): ManagerErrorCode => {
  if (
    isOfTypeByProperty<ServerParseError>(
      networkError,
      'response',
      'statusCode',
      'bodyText'
    )
  ) {
    return ManagerErrorCode.ServerParseError;
  }

  if (
    isOfTypeByProperty<ServerError>(
      networkError,
      'response',
      'statusCode',
      'result'
    )
  ) {
    return ManagerErrorCode.ServerError;
  }

  return ManagerErrorCode.GeneralError;
};
