import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { CommonModule as ManagerCommonModule } from '../../common/common.module';
import { FormModule } from '../admin/form/form.module';
import { CommonModule } from '../common/common.module';
import { UnsavedChangesGuard } from '../common/guard/unsaved-changes.guard';
import { TableUIModule } from '../common/table-ui.module';
import { ExamEditComponent } from './exams/exam-edit.component';
import { ImportExcelStatisticsComponent } from './exams/import-excel-statistics/import-excel-statistics.component';
import { PoolListComponent } from './pool-list.component';
import { PoolComponent } from './pool.component';
import { PoolGuard } from './pool.guard';
import { PoolsComponent } from './pools.component';
import { PoolRoutingModule } from './routing/pool-routing.module';

@NgModule({
  declarations: [
    PoolsComponent,
    PoolComponent,
    PoolListComponent,
    ExamEditComponent,
    ImportExcelStatisticsComponent
  ],
  providers: [PoolGuard, UnsavedChangesGuard],
  imports: [
    RouterModule,
    PoolRoutingModule,
    ManagerCommonModule,
    A11yModule,
    FormModule,
    NgbModule,
    TranslateModule,
    TableModule,
    CommonModule,
    TableUIModule
  ]
})
export class PoolModule {}
