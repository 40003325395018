import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateAffiliationMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;


export type CreateAffiliationMutation = { __typename?: 'Mutation', createAffiliation?: { __typename?: 'CreateAffiliationPayload', successful: boolean, affiliation?: { __typename?: 'Affiliation', id: string, name: string, position: number } | null, validations?: { __typename?: 'ValidationsAffiliation', name?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const CreateAffiliationDocument = gql`
    mutation CreateAffiliation($poolId: ID!, $name: String!) {
  createAffiliation(poolId: $poolId, name: $name) {
    affiliation {
      id
      name
      position
    }
    successful
    validations {
      name {
        message
        severity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAffiliationGQL extends Apollo.Mutation<CreateAffiliationMutation, CreateAffiliationMutationVariables> {
    document = CreateAffiliationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }