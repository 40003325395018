import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  ExcelStatisticsInput as Statistics,
  ExcelStatisticsValidation as Validation,
  ValidationError
} from '@generated/base-types';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { isDefined } from 'angular';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  takeUntil
} from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import {
  createXlsxFile,
  parseXlsxFile,
  ParseXlsxFileOptions
} from 'src/app/common/utils/xlsx';
import { UnsavedChanges } from '../../../common/guard/unsaved-changes.guard';
import {
  ImportExcelStatistics,
  ResetMutation
} from '../../state/excel-statistics/excel-statistics.actions';
import { ExcelStatisticsState as State } from '../../state/excel-statistics/excel-statistics.state';
import {
  columns,
  exampleImportData,
  parseValidationData,
  ValidatedStatistics
} from './utils';

@Component({
  templateUrl: './import-excel-statistics.component.html',
  styleUrls: ['./import-excel-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportExcelStatisticsComponent
  implements UnsavedChanges, OnDestroy
{
  @ViewChild('dataInput', { static: true }) public dataInput?: ElementRef;

  public unsavedChanges = false;

  public readonly columns = columns;
  public readonly saving$: Observable<boolean>;
  public readonly failure$: Observable<boolean>;
  public readonly saved$: Observable<boolean>;
  public readonly disabled$: Observable<boolean>;
  public readonly validation$: Observable<Validation | undefined>;

  public statistics = new BehaviorSubject([] as Statistics[]);
  public statistics$ = this.statistics.asObservable();
  public validatedStatistics$: Observable<ValidatedStatistics[]>;
  public examErrors$: Observable<ValidationError[]>;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private ref: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.saving$ = this.store.select(State.mutationLoading);
    this.failure$ = this.store.select(State.mutationFailure);
    this.saved$ = this.store.select(State.mutationSuccess);
    this.validation$ = this.store.select(State.mutationValidations);

    this.disabled$ = combineLatest([
      this.saving$,
      this.failure$,
      this.statistics$
    ]).pipe(
      map(
        ([saving, failure, stats]) => saving || failure || stats.length === 0
      ),
      distinctUntilChanged()
    );

    this.validatedStatistics$ = combineLatest([
      this.statistics$,
      this.validation$
    ]).pipe(map(([stats, val]) => parseValidationData(stats, val)));

    this.examErrors$ = this.validation$.pipe(
      map(validation => validation?.exam || [])
    );

    // reset form after successful import
    this.saved$
      .pipe(
        filter(v => v),
        tap(_ => {
          this.statistics.next([]);
          if (isDefined(this.dataInput)) {
            // eslint-disable-next-line unicorn/no-null
            this.dataInput!.nativeElement.value = null;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public onSave(): void {
    const statistics = this.statistics.getValue();
    if (statistics.length === 0) return;
    this.store.dispatch(new ImportExcelStatistics(statistics));
  }

  public async onChange(event: InputEvent): Promise<void> {
    const opts: ParseXlsxFileOptions = {
      indexRows: true,
      i18nBoolColumns: ['eliminated']
    };
    const xlsxData = (await parseXlsxFile<Statistics>(event, opts)) || [];

    this.store.dispatch(new ResetMutation());
    this.statistics.next(xlsxData);
    this.ref.detectChanges();
  }

  public exampleXlsxDownload(): void {
    createXlsxFile(exampleImportData, 'Data', 'example-statistics-import.xlsx');
  }

  public getTooltip(
    statistic: ValidatedStatistics,
    key: string
  ): string | undefined {
    const property = statistic[key as keyof ValidatedStatistics];
    if (!property) return;
    const { value, error } = property;

    return error === undefined
      ? undefined
      : this.translate.instant('common.form.errors.' + error, { value });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
