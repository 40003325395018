import { PermissionInput, Scalars } from '@generated/base-types';
import { ManagerError } from 'src/app/common/utils/error-parser';
import { PoolPermissionsFragment } from '../services/load-pool-permissions.fragments.generated';

export class LoadUserPermissions {
  public static readonly type = '[UserPermissions] Load UserPermissions';
  constructor(public readonly userId: string) {}
}

export class LoadUserPermissionsSuccess {
  public static readonly type =
    '[UserPermissions] Load UserPermissions Success';
  constructor(public readonly userPermissions: PoolPermissionsFragment[]) {}
}

export class LoadUserPermissionsFailure {
  public static readonly type =
    '[UserPermissions] Load UserPermissions Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateUserPermission {
  public static readonly type = '[UserPermissions] Update UserPermission';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly attributes: PermissionInput
  ) {}
}

export class UpdateUserPermissionSuccess {
  public static readonly type =
    '[UserPermissions] Update UserPermission Success';
  constructor(public readonly permission: PoolPermissionsFragment) {}
}

export class UpdateUserPermissionFailure {
  public static readonly type =
    '[UserPermissions] Update UserPermission Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteUserPermission {
  public static readonly type = '[UserPermissions] Delete Permission';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteUserPermissionSuccess {
  public static readonly type = '[UserPermissions] Delete Permission Success';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteUserPermissionFailure {
  public static readonly type = '[UserPermissions] Delete Permission Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateUserPermission {
  public static readonly type = '[UserPermissions] Create Permission';
  constructor(public readonly attributes: PermissionInput) {}
}

export class CreateUserPermissionSuccess {
  public static readonly type = '[UserPermissions] Create Permission Success';
  constructor(public readonly permission: PoolPermissionsFragment) {}
}

export class CreateUserPermissionFailure {
  public static readonly type = '[UserPermissions] Create Permission Failure';
  constructor(public readonly error: ManagerError) {}
}
