import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { ContextState } from '@state/context';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommentTaskModalComponent } from 'src/app/common/modals/comment-task-modal/comment-task-modal.component';
import { CreateTaskModalComponent } from 'src/app/common/modals/create-task-modal/create-task-modal.component';
import { ReviseTaskModalComponent } from 'src/app/common/modals/revise-task-modal/revise-task-modal.component';
import { TranslateTaskModalComponent } from 'src/app/common/modals/translate-task-modal/translate-task-modal.component';
import { Language, Scalars } from 'src/generated/base-types';
import { NG_MODAL_LG_OPTIONS } from '../../../common/utils/ng-bootstrap-modal';
import { isDefined } from '../../../common/utils/type-guards/is-defined';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { PoolState } from '../../../state/pool/pool.state';
import { TaskListState } from '../../../task-management/state/task-list.state';
import { QuestionListState } from '../../state/question-list.state';
import { ToolbarManagementType } from './question-group-toolbar.component';

@Component({
  selector: 'qm-question-group-task-toolbar',
  templateUrl: './question-group-task-toolbar.component.html',
  styleUrls: [
    './question-group-toolbar.component.scss',
    './question-group-task-toolbar.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionGroupTaskToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public managementType: ToolbarManagementType = ToolbarManagementType.Question; // this is because of AngularJS!

  @Select(QuestionListState.selectedQuestionGroups)
  public selectedQuestionGroups$: Observable<
    QuestionGroupListElementFragment[]
  >;

  @Select(TaskListState.selectedQuestionGroups)
  public selectedTaskQuestionGroups$: Observable<
    QuestionGroupListElementFragment[]
  >;

  @Select(PoolState.languages)
  public languages$: Observable<Language[]>;

  @Select(ContextState.currentPoolId)
  private currentPoolId$: Observable<Scalars['ID'] | undefined>;

  public get hasSelection(): boolean {
    return (
      this.selectedQuestionGroups !== undefined &&
      this.selectedQuestionGroups.length > 0
    );
  }

  public selectedQuestionGroups: QuestionGroupListElementFragment[];
  public poolId: Scalars['ID'];
  public languages: Language[] = [];

  private destroy$ = new Subject<void>();

  constructor(private readonly ngbModal: NgbModal) {}

  public ngOnInit(): void {
    this.currentPoolId$
      .pipe(filter(isDefined))
      .pipe(takeUntil(this.destroy$))
      .subscribe(poolId => {
        this.poolId = poolId;
      });

    const questionGroups$ =
      this.managementType === ToolbarManagementType.Task
        ? this.selectedTaskQuestionGroups$
        : this.selectedQuestionGroups$;

    questionGroups$
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(isDefined))
      .subscribe(qgs => {
        this.selectedQuestionGroups = qgs;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createTask(): void {
    this.prepareModal(CreateTaskModalComponent);
  }

  public reviseTask(): void {
    this.prepareModal(ReviseTaskModalComponent);
  }

  public commentTask(): void {
    this.prepareModal(CommentTaskModalComponent);
  }

  public translateTask(): void {
    this.prepareModal(TranslateTaskModalComponent);
  }

  private prepareModal(symbol: unknown): NgbModalRef {
    const ref = this.ngbModal.open(symbol, NG_MODAL_LG_OPTIONS);
    ref.componentInstance.modalInstance = ref;
    ref.componentInstance.poolId = this.poolId;
    ref.componentInstance.questionGroupIds = this.selectedQuestionGroups.map(
      qg => qg.id
    );

    return ref;
  }
}
