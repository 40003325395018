angular.module('main').controller('footerCtrl', [
  '$scope',
  '$location',
  'Repositories::Version',
  'Store',
  'Selectors::Pool',
  'Selectors::User',
  function (
    $scope,
    $location,
    VersionRepository,
    Store,
    PoolSelector,
    UserSelector
  ) {
    VersionRepository.getFullVersion().then(function (version) {
      $scope.appVersion = version.name;
      $scope.appVersionDate = version.date;
    });

    $scope.isTaskManagementRoute = function () {
      return $location.path() === '/task_management';
    };

    $scope.I18n = I18n;

    const userSubscription = Store.subscribeOn(
      UserSelector.getCurrentUser,
      function (user) {
        if (!user) return;
        $scope.isSuperUser = user.super_user;
      }
    );

    const poolSubscription = Store.subscribeOn(
      PoolSelector.getCurrentPool,
      function (pool) {
        if (!pool) return;
        $scope.newTaskManagementPath = `newpools/${pool.id}/tasks`;
      }
    );

    $scope.$on('destroy', function () {
      userSubscription();
      poolSubscription();
    });
  }
]);
