import * as Types from '../../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetTaskMagicLinkQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
}>;


export type GetTaskMagicLinkQuery = { __typename?: 'Query', taskMagicLink: { __typename?: 'TaskMagicLink', url: string } };

export const GetTaskMagicLinkDocument = gql`
    query GetTaskMagicLink($taskId: ID!) {
  taskMagicLink(taskId: $taskId) {
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaskMagicLinkGQL extends Apollo.Query<GetTaskMagicLinkQuery, GetTaskMagicLinkQueryVariables> {
    document = GetTaskMagicLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }