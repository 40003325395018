import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SubjectsListElementCategoryFragment = { __typename?: 'Category', id: string, position: number, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, dimension: { __typename?: 'Dimension', id: string } };

export type SubjectsListElementFragment = { __typename?: 'Subject', id: string, name: string, categories: Array<{ __typename?: 'Category', id: string, position: number, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, dimension: { __typename?: 'Dimension', id: string } }> };

export type LoadSubjectsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type LoadSubjectsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', subjects?: Array<{ __typename?: 'Subject', id: string, name: string, categories: Array<{ __typename?: 'Category', id: string, position: number, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, dimension: { __typename?: 'Dimension', id: string } }> }> | null } };

export const SubjectsListElementCategoryFragmentDoc = gql`
    fragment SubjectsListElementCategory on Category {
  id
  position
  name
  nameDe
  nameFr
  nameEn
  nameIt
  dimension {
    id
  }
}
    `;
export const SubjectsListElementFragmentDoc = gql`
    fragment SubjectsListElement on Subject {
  id
  name
  categories {
    ...SubjectsListElementCategory
  }
}
    ${SubjectsListElementCategoryFragmentDoc}`;
export const LoadSubjectsDocument = gql`
    query LoadSubjects($poolId: ID!) {
  pool(id: $poolId) {
    subjects {
      ...SubjectsListElement
    }
  }
}
    ${SubjectsListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadSubjectsGQL extends Apollo.Query<LoadSubjectsQuery, LoadSubjectsQueryVariables> {
    document = LoadSubjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }