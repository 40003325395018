import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SettingsState, UpdateCommentFilter } from '@state/settings';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isScalarsId } from 'src/app/common/utils/type-guards/is-scalars-id';
import { isComment } from 'src/app/common/utils/type-guards/message';
import {
  Comment,
  QuestionGroupPreview,
  Scalars
} from 'src/generated/base-types';
import { QuestionGroupMessageFragment } from '../../services/question-group-messages.generated';
import { QuestionGroupStatisticsFragment } from '../../services/question-group-statistics.generated';
import {
  DeleteComment,
  LoadQuestionGroupMessages,
  LoadQuestionGroupPreview,
  LoadQuestionGroupStatistics,
  UpdateCommentReadState,
  UpdateTabSelection
} from '../../state/question-details.actions';
import {
  QuestionDetailsState,
  QuestionDetailsTabs
} from '../../state/question-details.state';

@Component({
  selector: 'qm-question-group-details',
  templateUrl: './question-group-details.component.html',
  styleUrls: ['./question-group-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionGroupDetailsComponent implements OnDestroy, OnChanges {
  @Input()
  public questionGroupId: Scalars['ID'] | undefined;

  @Select(QuestionDetailsState.isLoading)
  public loading$: Observable<boolean>;

  @Select(QuestionDetailsState.preview)
  public preview$: Observable<QuestionGroupPreview>;

  @Select(QuestionDetailsState.statistics)
  public statistics$: Observable<QuestionGroupStatisticsFragment[]>;

  @Select(SettingsState.commentShowReadMessages)
  public showReadMessages$: Observable<boolean>;

  @Select(SettingsState.commentShowSystemMessages)
  public showSystemMessages$: Observable<boolean>;

  public preview: QuestionGroupPreview;
  public statistics: QuestionGroupStatisticsFragment[];
  public readonly questionDetailsTabs = QuestionDetailsTabs;
  public readonly activeTab$: Observable<QuestionDetailsTabs>;
  public readonly messages$: Observable<QuestionGroupMessageFragment[]>;

  private destroy$ = new Subject<void>();

  constructor(private readonly store: Store) {
    this.messages$ = combineLatest([
      this.store.select(QuestionDetailsState.messages),
      this.showReadMessages$,
      this.showSystemMessages$
    ]).pipe(
      map(([messages, showReadMessages, showSystemMessages]) =>
        messages.filter(m =>
          isComment(m) ? !m.read || showReadMessages : showSystemMessages
        )
      )
    );

    this.activeTab$ = this.store
      .select(QuestionDetailsState.activeTab)
      .pipe(startWith(QuestionDetailsTabs.Preview));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const questionGroupId = changes.questionGroupId?.currentValue;

    if (isScalarsId(questionGroupId)) {
      this.store.dispatch([
        new LoadQuestionGroupPreview(questionGroupId),
        new LoadQuestionGroupMessages(questionGroupId),
        new LoadQuestionGroupStatistics(questionGroupId)
      ]);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCommentReadStateChanged([id, read]: [Comment['id'], boolean]): void {
    this.store.dispatch(new UpdateCommentReadState(id, read));
  }

  public onCommentDeleted(id: Comment['id']): void {
    this.store.dispatch(new DeleteComment(id));
  }

  public onTabSelectionChanged(event: { nextId: QuestionDetailsTabs }): void {
    this.store.dispatch(new UpdateTabSelection(event.nextId));
  }

  public onShowReadMessageChanged(active: boolean): void {
    this.store.dispatch(new UpdateCommentFilter('showReadMessages', active));
  }

  public onShowSystemMessageChanged(active: boolean): void {
    this.store.dispatch(new UpdateCommentFilter('showSystemMessages', active));
  }
}
