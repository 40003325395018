import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { LoadUsersFieldsFragmentDoc } from './load-users.fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoadUsersQueryVariables = Types.Exact<{
  from?: Types.InputMaybe<Types.Scalars['Int']>;
  to?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type LoadUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null, language: Types.Language, organisation?: string | null, alternativeEmail?: string | null, mobilePhone?: string | null, alternativeMobilePhone?: string | null, currentSignInAt?: string | null, superUser: boolean, useEmailForMtan: boolean }> };

export const LoadUsersDocument = gql`
    query LoadUsers($from: Int, $to: Int) {
  users(from: $from, to: $to) {
    ...LoadUsersFields
  }
}
    ${LoadUsersFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadUsersGQL extends Apollo.Query<LoadUsersQuery, LoadUsersQueryVariables> {
    document = LoadUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }