import { ManagerError } from '@common/utils/error-parser';
import { PoolListElementFragment } from '../../new/admin/services/load-pools.generated';

export class LoadPools {
  public static readonly type = '[Pools] Load Pools';
}

export class LoadPoolsSuccess {
  public static readonly type = '[Pools] Load Pools Success';
  constructor(public readonly pools: PoolListElementFragment[]) {}
}

export class LoadPoolsFailure {
  public static readonly type = '[Pools] Load Pools Failure';
  constructor(public readonly error: ManagerError) {}
}
