import { Injectable } from '@angular/core';
import { takeGraphQLResult } from '@common/operators/take-graphql-response';
import { ToastsService } from '@common/services/toasts.service';
import { parseError } from '@common/utils/error-parser';
import { RemoteData } from '@common/utils/remote-data';
import { isDefined } from '@common/utils/type-guards/is-defined';
import {
  PreviewGenerationStatus,
  QuestionGroupPreview,
  Scalars
} from '@generated/base-types';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, Store } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  catchError,
  delay,
  filter,
  map,
  mapTo,
  Observable,
  of,
  retryWhen,
  switchMap,
  takeWhile,
  tap
} from 'rxjs';
import type { MonoTypeOperatorFunction } from 'rxjs';
import { CreateCommentNewGQL } from '../../services/question-details/create-comment.generated';
import { DeleteCommentGQL } from '../../services/question-details/delete-comment.generated';
import { DeleteStatisticsGQL } from '../../services/question-details/delete-statistics.generated';
import {
  QuestionGroupMessageFragment,
  QuestionGroupMessagesGQL
} from '../../services/question-details/question-group-messages.generated';
import { QuestionGroupPreviewGQL } from '../../services/question-details/question-group-preview.generated';
import {
  QuestionGroupStatisticsFragment,
  QuestionGroupStatisticsGQL
} from '../../services/question-details/question-group-statistics.generated';
import { UpdateCommentReadStatusGQL } from '../../services/question-details/update-comment-read-status.generated';
import { PoolState } from '../pool/pool.state';
import {
  CreateComment,
  CreateCommentFailure,
  CreateCommentSuccess,
  DeleteComment,
  DeleteCommentFailure,
  DeleteCommentSuccess,
  DeleteStatistics,
  DeleteStatisticsFailure,
  DeleteStatisticsSuccess,
  LoadQuestionGroupMessages,
  LoadQuestionGroupMessagesFailure,
  LoadQuestionGroupMessagesSuccess,
  LoadQuestionGroupPreview,
  LoadQuestionGroupPreviewFailure,
  LoadQuestionGroupPreviewSuccess,
  LoadQuestionGroupStatistics,
  LoadQuestionGroupStatisticsFailure,
  LoadQuestionGroupStatisticsSuccess,
  UpdateCommentReadState,
  UpdateCommentReadStateFailure,
  UpdateCommentReadStateSuccess,
  UpdateTabSelection
} from './question-details.action';

export enum QuestionDetailsTabs {
  Preview = 'preview',
  Comments = 'comments',
  Statistics = 'statistics'
}

export interface QuestionDetailsStateModel {
  questionGroupIdToLoad: Scalars['ID'] | undefined;
  preview: RemoteData<QuestionGroupPreview>;
  messages: RemoteData<QuestionGroupMessageFragment[]>;
  statistics: RemoteData<QuestionGroupStatisticsFragment[]>;
  activeTab: QuestionDetailsTabs;
  mutation?: RemoteData<
    QuestionGroupMessageFragment | QuestionGroupStatisticsFragment[]
  >;
}

/*
 * -------------------------------
 * CUSTOM OPERATORS FOR POLLING
 * -------------------------------
 */
function isPreviewGenerationInProgress(
  action: LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure
): boolean {
  return (
    action instanceof LoadQuestionGroupPreviewSuccess &&
    action.preview.status === PreviewGenerationStatus.InProgress
  );
}

function ignoreNonUpToDateQuestionGroups<T>(
  action: LoadQuestionGroupPreview,
  ctx: StateContext<QuestionDetailsStateModel>
): MonoTypeOperatorFunction<T> {
  return takeWhile<T>(
    // eslint-disable-next-line rxjs/no-ignored-takewhile-value
    _value => action.questionGroupId === ctx.getState().questionGroupIdToLoad
  );
}

function retryIfPreviewGenerationIsInProgress(): [
  MonoTypeOperatorFunction<
    LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure
  >,
  MonoTypeOperatorFunction<
    LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure
  >
] {
  return [
    tap<LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure>(
      action => {
        if (isPreviewGenerationInProgress(action)) {
          throw new PreviewGenerationInProgressError();
        }
      }
    ),
    retryWhen<
      LoadQuestionGroupPreviewSuccess | LoadQuestionGroupPreviewFailure
    >(errors =>
      errors.pipe(
        filter(error => error instanceof PreviewGenerationInProgressError),
        delay(2000)
      )
    )
  ];
}

class PreviewGenerationInProgressError extends Error {}

@State<QuestionDetailsStateModel>({
  name: 'questionDetails',
  defaults: {
    questionGroupIdToLoad: undefined,
    preview: {
      requestState: 'initial'
    },
    messages: {
      requestState: 'initial'
    },
    statistics: {
      requestState: 'initial'
    },
    activeTab: QuestionDetailsTabs.Preview
  }
})
@Injectable()
export class NewQuestionDetailsState {
  constructor(
    private readonly store: Store,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly loadQuestionPreviewService: QuestionGroupPreviewGQL,
    private readonly loadQuestionMessagesService: QuestionGroupMessagesGQL,
    private readonly loadQuestionStatisticsService: QuestionGroupStatisticsGQL,
    private readonly createCommentService: CreateCommentNewGQL,
    private readonly updateCommentReadStateService: UpdateCommentReadStatusGQL,
    private readonly deleteCommentService: DeleteCommentGQL,
    private readonly deleteStatisticsService: DeleteStatisticsGQL
  ) {}

  @Action(UpdateTabSelection)
  public updateTabSelection(
    ctx: StateContext<QuestionDetailsStateModel>,
    { activeTab }: UpdateTabSelection
  ): void {
    ctx.setState(patch({ activeTab }));
  }

  @Action(LoadQuestionGroupPreview)
  public loadQuestionGroupPreview(
    ctx: StateContext<QuestionDetailsStateModel>,
    action: LoadQuestionGroupPreview
  ): Observable<unknown> {
    ctx.patchState({
      questionGroupIdToLoad: action.questionGroupId,
      preview: { requestState: 'loading', data: undefined }
    });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.loadQuestionPreviewService.fetch({
          poolId,
          questionGroupId: action.questionGroupId
        })
      ),
      ignoreNonUpToDateQuestionGroups(action, ctx),
      takeGraphQLResult(),
      map(
        ({ pool }) =>
          new LoadQuestionGroupPreviewSuccess(pool.questionGroup!.preview)
      ),
      catchError((err: unknown) =>
        of(new LoadQuestionGroupPreviewFailure(parseError(err)))
      ),
      tap(action => ctx.dispatch(action)),
      ...retryIfPreviewGenerationIsInProgress(),
      mapTo(void 0)
    );
  }

  @Action(LoadQuestionGroupPreviewSuccess)
  public loadQuestionGroupPreviewSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    { preview }: LoadQuestionGroupPreviewSuccess
  ): void {
    ctx.patchState({ preview: { requestState: 'success', data: preview } });
  }

  @Action(LoadQuestionGroupPreviewFailure)
  public loadQuestionGroupPreviewFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    { error }: LoadQuestionGroupPreviewFailure
  ): void {
    ctx.patchState({
      preview: { requestState: 'failure', error, data: undefined }
    });
  }

  @Action(LoadQuestionGroupMessages)
  public loadQuestionGroupMessages(
    ctx: StateContext<QuestionDetailsStateModel>,
    { questionGroupId }: LoadQuestionGroupMessages
  ): Observable<void> {
    ctx.patchState({
      messages: { requestState: 'loading', data: undefined }
    });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.loadQuestionMessagesService.fetch({ poolId, questionGroupId })
      ),
      takeGraphQLResult(),
      switchMap(({ pool }) =>
        ctx.dispatch(
          new LoadQuestionGroupMessagesSuccess(pool.questionGroup!.messages)
        )
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadQuestionGroupMessagesFailure(parseError(err)))
      )
    );
  }

  @Action(LoadQuestionGroupMessagesSuccess)
  public loadQuestionGroupMessagesSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    { messages }: LoadQuestionGroupMessagesSuccess
  ): void {
    ctx.patchState({
      messages: {
        requestState: 'success',
        data: messages
      }
    });
  }

  @Action(LoadQuestionGroupMessagesFailure)
  public loadQuestionGroupMessagesFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    { error }: LoadQuestionGroupMessagesFailure
  ): void {
    ctx.patchState({
      messages: {
        requestState: 'failure',
        data: [],
        error
      }
    });
  }

  @Action(LoadQuestionGroupStatistics)
  public loadQuestionGroupStatistics(
    ctx: StateContext<QuestionDetailsStateModel>,
    { questionGroupId }: LoadQuestionGroupStatistics
  ): Observable<void> {
    ctx.patchState({
      statistics: { requestState: 'loading', data: undefined }
    });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.loadQuestionStatisticsService.fetch({ poolId, questionGroupId })
      ),
      takeGraphQLResult(),
      switchMap(({ pool }) =>
        ctx.dispatch(
          new LoadQuestionGroupStatisticsSuccess(pool.questionGroup!.statistics)
        )
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadQuestionGroupStatisticsFailure(parseError(err)))
      )
    );
  }

  @Action(LoadQuestionGroupStatisticsSuccess)
  public loadQuestionGroupStatisticsSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    { statistics }: LoadQuestionGroupStatisticsSuccess
  ): void {
    ctx.patchState({
      statistics: { requestState: 'success', data: statistics }
    });
  }

  @Action(LoadQuestionGroupStatisticsFailure)
  public loadQuestionGroupStatisticsFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    { error }: LoadQuestionGroupStatisticsFailure
  ): void {
    ctx.patchState({
      statistics: { requestState: 'failure', error, data: undefined }
    });
  }

  @Action(CreateComment)
  public createComment(
    ctx: StateContext<QuestionDetailsStateModel>,
    { questionGroupIds, content }: CreateComment
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.createCommentService.mutate({
          poolId,
          questionGroupIds,
          content
        })
      ),
      takeGraphQLResult(),
      map(result =>
        result?.createComment?.questionGroups?.map(qg => qg.messages)
      ),
      filter(isDefined),
      switchMap(messages =>
        ctx.dispatch(new CreateCommentSuccess(messages[0]))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new CreateCommentFailure(parseError(err)))
      )
    );
  }

  @Action(CreateCommentSuccess)
  public createCommentSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    { messages }: CreateCommentSuccess
  ): void {
    ctx.patchState({
      messages: {
        ...ctx.getState().messages,
        data: messages
      },
      mutation: { requestState: 'success', data: undefined }
    });
  }

  @Action(CreateCommentFailure)
  public createCommentFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    { error }: CreateCommentFailure
  ): void {
    ctx.patchState({ mutation: { requestState: 'failure', error } });
  }

  @Action(UpdateCommentReadState)
  public updateCommentReadState(
    ctx: StateContext<QuestionDetailsStateModel>,
    { commentId, read }: UpdateCommentReadState
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.updateCommentReadStateService.mutate({
          poolId,
          commentId,
          attributes: { read }
        })
      ),
      takeGraphQLResult(),
      switchMap(() =>
        ctx.dispatch(new UpdateCommentReadStateSuccess(commentId, read))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new UpdateCommentReadStateFailure(parseError(err)))
      )
    );
  }

  @Action(UpdateCommentReadStateSuccess)
  public updateCommentReadStateSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    { commentId, read }: UpdateCommentReadStateSuccess
  ): void {
    ctx.patchState({
      messages: {
        ...ctx.getState().messages,
        data: ctx
          .getState()
          .messages.data?.map(message =>
            message.id === commentId && message.__typename === 'Comment'
              ? { ...message, read }
              : message
          )
      },
      mutation: { requestState: 'success', data: undefined }
    });
  }

  @Action(UpdateCommentReadStateFailure)
  public updateCommentReadStateFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    { error }: UpdateCommentReadStateFailure
  ): void {
    ctx.patchState({
      mutation: { requestState: 'failure', error }
    });
  }

  @Action(DeleteComment)
  public deleteComment(
    ctx: StateContext<QuestionDetailsStateModel>,
    { commentId }: DeleteComment
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.deleteCommentService.mutate({
          poolId,
          commentId
        })
      ),
      takeGraphQLResult(),
      switchMap(() => ctx.dispatch(new DeleteCommentSuccess(commentId))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteCommentFailure(parseError(err)))
      )
    );
  }

  @Action(DeleteCommentSuccess)
  public deleteCommentSuccess(
    ctx: StateContext<QuestionDetailsStateModel>,
    { commentId }: DeleteCommentSuccess
  ): void {
    ctx.patchState({
      messages: {
        ...ctx.getState().messages,
        data: ctx
          .getState()
          .messages.data?.filter(message => message.id !== commentId)
      },
      mutation: { requestState: 'success', data: undefined }
    });
  }

  @Action(DeleteCommentFailure)
  public deleteCommentFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    { error }: DeleteCommentFailure
  ): void {
    ctx.patchState({ mutation: { requestState: 'failure', error } });
  }

  @Action(DeleteStatistics)
  public deleteStatistics(
    ctx: StateContext<QuestionDetailsStateModel>,
    { questionGroupIds }: DeleteStatistics
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.store.select(PoolState.poolId).pipe(
      filter(isDefined),
      switchMap(poolId =>
        this.deleteStatisticsService.mutate({ poolId, questionGroupIds })
      ),
      takeGraphQLResult(),
      switchMap(() => ctx.dispatch(new DeleteStatisticsSuccess())),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteStatisticsFailure(parseError(err)))
      )
    );
  }

  @Action(DeleteStatisticsSuccess)
  public deleteStatisticsSuccess(
    ctx: StateContext<QuestionDetailsStateModel>
  ): void {
    ctx.patchState({
      statistics: {
        requestState: 'success',
        data: []
      },
      mutation: { requestState: 'success', data: undefined }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('common.models.statistics')
      })
    );
  }

  @Action(DeleteStatisticsFailure)
  public deleteStatisticsFailure(
    ctx: StateContext<QuestionDetailsStateModel>,
    { error }: DeleteStatisticsFailure
  ): void {
    ctx.patchState({ mutation: { error, requestState: 'failure' } });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }
}
