import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';

export const coordinatorRequiresSubjectValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const role = control.get('role')?.value;
    const subjectId = control.get('subjectId')?.value;
    const valid =
      (role === 'coordinator' && subjectId) || role !== 'coordinator';

    if (role === 'coordinator') {
      control.get('subjectId')?.addValidators(Validators.required);
    } else {
      control.get('subjectId')?.removeValidators(Validators.required);
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return valid ? null : { coordinatorMissingSubject: true }; // eslint-disable-line unicorn/no-null
  };
};
