<aside
  class="small-side-panel sheet sheet--default"
  [class.sheet--hidden]="filterHidden$ | async"
>
  <div class="sheet-heading">
    <div class="sheet-heading-item sheet-title">
      {{ 'question_management.index.left_bar_title' | translate }}
    </div>
    <button
      class="sheet-heading-item sheet-heading-item--right sheet-heading-btn"
      toggle-panel="left"
      (click)="onToggleFilterPanel()"
    >
      <i class="fa fa-chevron-circle-left fa-lg"></i>
    </button>
  </div>
  <div class="sheet-body">
    <qm-group-filter></qm-group-filter>
  </div>
</aside>
<div class="panel-separator__filter"></div>
<article
  class="layout-cell--resizable sheet sheet--default pv-center"
  panel="center"
>
  <div class="sheet-heading">
    <div class="sheet-heading-item sheet-title">
      {{ 'question_management.index.title' | translate }}
    </div>
    <qm-question-group-column-visibility class="sheet-heading-item">
      <i class="fa fa-gears"></i>
    </qm-question-group-column-visibility>
  </div>
  <div class="sheet-body layout--vertical">
    <ng-container *ngIf="poolId$ | async as poolId">
      <div class="toolbar-index-container">
        <div class="question-list-toolbar">
          <qm-question-group-toolbar></qm-question-group-toolbar>
        </div>
        <div>
          <qm-question-group-task-toolbar></qm-question-group-task-toolbar>
        </div>
      </div>
      <qm-question-group-list
        [poolId]="poolId"
        class="layout-cell--resizable layout--vertical"
      ></qm-question-group-list>
    </ng-container>
  </div>
</article>
<div class="panel-separator" (mousedown)="onMouseDown()">
  <i class="fa fa-ellipsis-v fa-lg"></i>
</div>
<aside
  #detailsContainer
  [class.sheet--hidden]="questionDetailsHidden$ | async"
  class="side-panel sheet sheet--default"
>
  <div class="sheet-heading">
    <button
      class="sheet-heading-item sheet-heading-btn"
      toggle-panel="right"
      (click)="onToggleDetailsPanel()"
    >
      <i class="fa fa-chevron-circle-right fa-lg"></i>
    </button>
    <div class="sheet-heading-item sheet-title">
      {{ 'common.question_group_details.title' | translate }}
    </div>
  </div>
  <div class="sheet-body js-details">
    <qm-question-group-details
      *ngIf="questionGroupId$ | async as questionGroupId"
      [questionGroupId]="questionGroupId"
      class="bs4"
    ></qm-question-group-details>
  </div>
</aside>
