/* eslint-disable camelcase */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { isEqual } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { QuestionListState } from 'src/app/question-management/state/question-list.state';
import {
  Language,
  RevisionDocumentInput,
  Scalars
} from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { PoolMetadataFragment } from '../../../services/load-pool.generated';
import { PoolState } from '../../../state/pool/pool.state';
import { FileSaverService } from '../../services/file-saver.service';
import { assertIsDefined, isDefined } from '../../utils/type-guards/is-defined';
import { isVoid } from '../../utils/type-guards/voidable';
import { RevisionDocumentGQL } from './revision-document.generated';

// generic input object
export type PartialRevisionDocumentInput = Omit<
  RevisionDocumentInput,
  | 'languages'
  | 'orderBy'
  | 'questionGroupIds'
  | 'statisticsAmount'
  | 'includePageBreaks'
  | 'pageBreakBetweenQuestionGroups'
>;

const defaultCompleteOptions: PartialRevisionDocumentInput = {
  title: true,
  questionGroupNumber: true,
  questionGroupType: true,
  questionGroupSupervisor: true,
  questionGroupAffiliation: true,
  questionGroupAuthor: true,
  questionGroupCreatedAt: true,
  questionGroupNumberOfUsages: true,
  questionGroupLastUsageYear: true,
  questionGroupUpdatedAt: true,
  questionGroupRevisionYear: true,
  questionGroupSourceLanguage: true,
  questionGroupRevisionStatus: true,
  questionGroupScore: true,
  questionLearningTarget: true,
  questionReference: true,
  questionRemarks: true,
  blueprint: true,
  statistics: true,
  comments: true,
  text: true,
  preview: true,
  solution: true
};

const defaultShortOptions: PartialRevisionDocumentInput = {
  title: true,
  questionGroupNumber: true,
  questionGroupType: true,
  questionGroupSupervisor: true,
  questionGroupAffiliation: false,
  questionGroupAuthor: false,
  questionGroupCreatedAt: false,
  questionGroupNumberOfUsages: false,
  questionGroupLastUsageYear: false,
  questionGroupUpdatedAt: false,
  questionGroupRevisionYear: false,
  questionGroupSourceLanguage: false,
  questionGroupRevisionStatus: false,
  questionGroupScore: false,
  questionLearningTarget: false,
  questionReference: false,
  questionRemarks: false,
  blueprint: true,
  statistics: false,
  comments: false,
  text: true,
  preview: false,
  solution: true
};

export enum RevisionModalMode {
  Complete = 'COMPLETE',
  Short = 'SHORT'
}

type LanguageToggle = { [language: string]: boolean };
type OldRevisionDocumentInput = {
  general: { page_break_between_question_groups: boolean };
};

@Component({
  selector: 'co-revision-document-modal',
  templateUrl: './revision-document-modal.component.html',
  styleUrls: ['./revision-document-modal.component.scss']
})
export class RevisionDocumentModalComponent implements OnInit, OnDestroy {
  @Input() public modalInstance: NgbModalRef;
  @Input()
  public currentPoolId: Scalars['ID'];
  @Input()
  public questionGroups: QuestionGroupListElementFragment[];

  @Select(PoolState.languages)
  public languages$: Observable<Language[]>;

  public form: UntypedFormGroup;
  public mode: string | undefined = undefined;
  public modes = RevisionModalMode;
  public showMoreOptions = false;
  public isGenerating = false;
  public languageToggle: LanguageToggle;
  public languages: Language[] = [];
  public pool: PoolMetadataFragment;

  private orderBy: string[];
  private destroy$ = new Subject<void>();

  constructor(
    private revisionDocumentGQL: RevisionDocumentGQL,
    private builder: UntypedFormBuilder,
    private fileSaver: FileSaverService,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {
    this.languageToggle = this.store
      .selectSnapshot(PoolState.languages)
      .reduce((acc, language) => {
        acc[language] = true;

        return acc;
      }, {} as LanguageToggle);

    this.orderBy = (
      this.store.selectSnapshot(QuestionListState.sortAttributes) || []
    ).map(
      sortSpec =>
        `${sortSpec.attribute.toLowerCase()}.${sortSpec.direction.toLowerCase()}`
    );

    const options = this.getCachedOptions();
    this.form = this.builder.group(options);
    this.form.valueChanges.subscribe(changes => this.setFormMode(changes));
    this.setFormMode(options);
    this.updateLanguages();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async save(): Promise<void> {
    this.isGenerating = true;

    const displayOptions = {
      ...this.form.getRawValue(),
      questionGroupIds: this.questionGroups.map(qg => qg.id),
      orderBy: this.orderBy,
      languages: this.languages
    };
    const query = await this.revisionDocumentGQL
      .fetch({ poolId: this.currentPoolId, displayOptions })
      .toPromise();

    assertIsDefined(query);
    this.fileSaver.saveAs(query.data.pool.revisionDocument.url);
    this.setCachedOptions(displayOptions);
    this.isGenerating = false;
    this.modalInstance.close();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public toggleMode(mode: RevisionModalMode): void {
    const modeOptions =
      mode === RevisionModalMode.Complete
        ? defaultCompleteOptions
        : defaultShortOptions;
    this.form.patchValue(modeOptions);
  }

  public updateLanguages(): void {
    this.languages = Object.keys(this.languageToggle).reduce(
      (acc, language) => {
        if (this.languageToggle[language]) {
          acc.push(language as Language);
        }

        return acc;
      },
      [] as Language[]
    );
  }

  private setFormMode(options: RevisionDocumentInput): void {
    const omittedKeys = new Set([
      'languages',
      'orderBy',
      'questionGroupIds',
      'statisticsAmount',
      'includePageBreaks',
      'pageBreakBetweenQuestionGroups'
    ]);
    const fittedOptions = Object.keys(options).reduce((acc, key) => {
      if (!omittedKeys.has(key)) {
        acc[key as keyof PartialRevisionDocumentInput] = <boolean>(
          options[key as keyof RevisionDocumentInput]
        );
      }

      return acc;
    }, {} as PartialRevisionDocumentInput);

    if (isEqual(fittedOptions, defaultShortOptions) as boolean) {
      this.mode = RevisionModalMode.Short;
    } else if (isEqual(fittedOptions, defaultCompleteOptions) as boolean) {
      this.mode = RevisionModalMode.Complete;
    } else {
      this.mode = undefined;
    }
  }

  private getCachedOptions(): RevisionDocumentInput {
    const stringifiedOptions = localStorage.getItem(
      `revisionDocument-pool-${this.currentPoolId}-options`
    );
    const defaultInput = {
      statisticsAmount: 1,
      includePageBreaks: false,
      pageBreakBetweenQuestionGroups: false,
      languages: [],
      orderBy: [],
      questionGroupIds: [],
      ...defaultCompleteOptions
    };

    if (isVoid(stringifiedOptions)) {
      return defaultInput;
    }

    const parsedOptions = JSON.parse(stringifiedOptions);

    return isDefined(
      (parsedOptions as OldRevisionDocumentInput).general
        ?.page_break_between_question_groups
    )
      ? defaultInput
      : {
          ...defaultInput,
          ...parsedOptions
        };
  }

  private setCachedOptions(options: RevisionDocumentInput): void {
    const ignoredKeys = new Set(['languages', 'orderBy', 'questionGroupIds']);
    const genericOptions = Object.entries(options).reduce(
      (acc, [key, value]) => {
        if (!ignoredKeys.has(key)) {
          acc[key] = value;
        }

        return acc;
      },
      {} as { [key: string]: unknown }
    );
    localStorage.setItem(
      `revisionDocument-pool-${this.currentPoolId}-options`,
      JSON.stringify(genericOptions)
    );
  }
}
