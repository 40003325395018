import { Injectable } from '@angular/core';
import { parseError } from '@common/utils/error-parser';
import { RemoteData, RequestState } from '@common/utils/remote-data';
import { requestStateLoading } from '@common/utils/remote-data-utils';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { takeGraphQLResult } from 'src/app/common/operators/take-graphql-response';
import { ToastsService } from 'src/app/common/services/toasts.service';
import {
  assertIsDefined,
  isDefined
} from 'src/app/common/utils/type-guards/is-defined';
import { SetPositionAffiliationGQL } from 'src/app/new/admin/services/set-position-affiliation.generated';
import { Affiliation } from 'src/generated/base-types';
import { CreateAffiliationGQL } from '../../../services/create-affiliation.generated';
import { DeleteAffiliationGQL } from '../../../services/delete-affiliation.generated';
import {
  AffiliationListElementFragment,
  LoadAffiliationsGQL
} from '../../../services/load-affiliations.generated';
import { UpdateAffiliationGQL } from '../../../services/update-affiliation.generated';
import { PoolFormState } from '../form/pool-form.state';
import {
  CreateAffiliation,
  CreateAffiliationFailure,
  CreateAffiliationSuccess,
  DeleteAffiliation,
  DeleteAffiliationFailure,
  DeleteAffiliationSuccess,
  LoadAffiliations,
  LoadAffiliationsFailure,
  LoadAffiliationsSuccess,
  SetAffiliationPosition,
  SetAffiliationPositionFailure,
  SetAffiliationPositionSuccess,
  UpdateAffiliation,
  UpdateAffiliationFailure,
  UpdateAffiliationSuccess
} from './affiliations.actions';

export interface AffiliationsStateModel {
  affiliations: RemoteData<AffiliationListElementFragment[]>;
  mutation?: RemoteData<AffiliationListElementFragment>;
}

@State<AffiliationsStateModel>({
  name: 'affiliations',
  defaults: {
    affiliations: { requestState: 'initial' },
    mutation: { requestState: 'initial' }
  }
})
@Injectable({
  providedIn: 'root'
})
export class AffiliationsState {
  constructor(
    private readonly loadAffiliationsService: LoadAffiliationsGQL,
    private readonly createAffiliationService: CreateAffiliationGQL,
    private readonly updateAffiliationService: UpdateAffiliationGQL,
    private readonly deleteAffiliationService: DeleteAffiliationGQL,
    private readonly setAffiliationPositionService: SetPositionAffiliationGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly store: Store
  ) {}

  @Selector()
  public static findAffiliationAtIndex(
    state: AffiliationsStateModel
  ): (index: number) => AffiliationListElementFragment | undefined {
    return (index: number) => {
      return state.affiliations.data?.find(el => el.position === index);
    };
  }

  @Selector()
  public static affiliations(
    state: AffiliationsStateModel
  ): RemoteData<AffiliationListElementFragment[]> {
    return { ...state.affiliations, actions: { retry: LoadAffiliations } };
  }

  @Selector()
  public static affiliationsLoading(state: AffiliationsStateModel): boolean {
    return requestStateLoading(state.affiliations);
  }

  @Selector()
  public static mutationRequestState(
    state: AffiliationsStateModel
  ): RequestState | undefined {
    return state.mutation?.requestState;
  }

  @Action(LoadAffiliations, { cancelUncompleted: true })
  public loadAffiliations(
    ctx: StateContext<AffiliationsStateModel>
  ): Observable<void> {
    const pool = this.store.selectSnapshot(PoolFormState.pool);
    assertIsDefined(pool.data, 'Pool not found');
    const poolId = pool.data.id;

    ctx.patchState({ affiliations: { requestState: 'loading' } });

    return this.loadAffiliationsService.fetch({ poolId }).pipe(
      switchMap(res =>
        ctx.dispatch(new LoadAffiliationsSuccess(res.data.pool.affiliations))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadAffiliationsFailure(parseError(err)))
      )
    );
  }

  @Action(LoadAffiliationsSuccess)
  public loadAffiliationsSuccess(
    ctx: StateContext<AffiliationsStateModel>,
    { affiliations }: LoadAffiliationsSuccess
  ): void {
    ctx.patchState({
      affiliations: {
        data: affiliations,
        requestState: 'success'
      }
    });
  }

  @Action(LoadAffiliationsFailure)
  public loadAffiliationsFailure(
    ctx: StateContext<AffiliationsStateModel>,
    { error }: LoadAffiliationsFailure
  ): void {
    ctx.patchState({
      affiliations: { requestState: 'failure', error }
    });
  }

  @Action(CreateAffiliation)
  public createAffiliation(
    ctx: StateContext<AffiliationsStateModel>,
    { name }: CreateAffiliation
  ): Observable<void> {
    const pool = this.store.selectSnapshot(PoolFormState.pool);
    if (!pool.data) throw new Error('Pool not found');
    const poolId = pool.data.id;

    ctx.patchState({
      mutation: {
        requestState: 'loading'
      }
    });

    return this.createAffiliationService.mutate({ poolId, name }).pipe(
      takeGraphQLResult(),
      map(result => result?.createAffiliation?.affiliation),
      filter(isDefined),
      switchMap(affiliation =>
        ctx.dispatch(new CreateAffiliationSuccess(affiliation))
      ),
      catchError((err: unknown) => {
        return ctx.dispatch(new CreateAffiliationFailure(parseError(err)));
      })
    );
  }

  @Action(CreateAffiliationSuccess)
  public createAffiliationSuccess(
    ctx: StateContext<AffiliationsStateModel>,
    { affiliation }: CreateAffiliationSuccess
  ): void {
    ctx.patchState({
      affiliations: {
        ...ctx.getState().affiliations,
        data: [...(ctx.getState().affiliations.data || []), affiliation]
      },
      mutation: {
        data: affiliation,
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.create', {
        resource: this.translate.instant('common.models.affiliation')
      })
    );
  }

  @Action(CreateAffiliationFailure)
  public createAffiliationFailure(
    ctx: StateContext<AffiliationsStateModel>,
    { error }: CreateAffiliationFailure
  ): void {
    ctx.patchState({
      mutation: {
        error: error,
        requestState: 'failure'
      }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(UpdateAffiliation)
  public updateAffiliation(
    ctx: StateContext<AffiliationsStateModel>,
    { id, name }: UpdateAffiliation
  ): Observable<void> {
    ctx.patchState({
      mutation: {
        requestState: 'loading'
      }
    });

    return this.updateAffiliationService.mutate({ id, name }).pipe(
      takeGraphQLResult(),
      map(result => result?.updateAffiliation?.affiliation),
      filter(isDefined),
      switchMap(affiliation =>
        ctx.dispatch(new UpdateAffiliationSuccess(affiliation))
      ),
      catchError((err: unknown) => {
        return ctx.dispatch(new UpdateAffiliationFailure(parseError(err)));
      })
    );
  }

  @Action(UpdateAffiliationSuccess)
  public updateAffiliationSuccess(
    ctx: StateContext<AffiliationsStateModel>,
    { affiliation }: UpdateAffiliationSuccess
  ): void {
    ctx.patchState({
      affiliations: {
        ...ctx.getState().affiliations,
        data: ctx
          .getState()
          .affiliations.data?.map(item =>
            item.id === affiliation.id ? affiliation : item
          )
      },
      mutation: {
        data: affiliation,
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('common.models.affiliation')
      })
    );
  }

  @Action(UpdateAffiliationFailure)
  public updateAffiliationFailure(
    ctx: StateContext<AffiliationsStateModel>,
    { error }: UpdateAffiliationFailure
  ): void {
    ctx.patchState({
      mutation: {
        error: error,
        requestState: 'failure'
      }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(DeleteAffiliation)
  public deleteAffiliation(
    ctx: StateContext<AffiliationsStateModel>,
    { id }: DeleteAffiliation
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.deleteAffiliationService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteAffiliationSuccess(id))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteAffiliationFailure(parseError(err)))
      )
    );
  }

  @Action(DeleteAffiliationSuccess)
  public deleteAffiliationSuccess(
    ctx: StateContext<AffiliationsStateModel>,
    { id }: DeleteAffiliationSuccess
  ): void {
    ctx.patchState({
      affiliations: {
        ...ctx.getState().affiliations,
        data: ctx.getState().affiliations.data?.filter(item => item.id !== id)
      },
      mutation: { data: undefined, requestState: 'success' }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('common.models.affiliation')
      })
    );
  }

  @Action(DeleteAffiliationFailure)
  public deleteAffiliationFailure(
    ctx: StateContext<AffiliationsStateModel>,
    { error }: DeleteAffiliationFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(SetAffiliationPosition)
  public setAffiliationPosition(
    ctx: StateContext<AffiliationsStateModel>,
    { id, direction, times }: SetAffiliationPosition
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.setAffiliationPositionService
      .mutate({ id, direction, times })
      .pipe(
        takeGraphQLResult(),
        map(result => ({
          affiliation: result?.setPositionAffiliation?.affiliation,
          affiliations: result?.setPositionAffiliation?.affiliations
        })),
        filter(
          result =>
            isDefined(result.affiliation) && isDefined(result.affiliations)
        ),
        switchMap(({ affiliation, affiliations }) => {
          assertIsDefined(affiliation);
          assertIsDefined(affiliations);

          return ctx.dispatch(
            new SetAffiliationPositionSuccess(
              affiliation as Affiliation,
              affiliations as Affiliation[]
            )
          );
        }),
        catchError((err: unknown) =>
          ctx.dispatch(new SetAffiliationPositionFailure(parseError(err)))
        )
      );
  }

  @Action(SetAffiliationPositionSuccess)
  public setAffiliationPositionSuccess(
    ctx: StateContext<AffiliationsStateModel>,
    { affiliation, affiliations }: SetAffiliationPositionSuccess
  ): void {
    ctx.patchState({
      mutation: {
        data: affiliation as Affiliation,
        requestState: 'success'
      },
      affiliations: {
        data: affiliations as Affiliation[],
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('activerecord.models.revision_status')
      })
    );
  }

  @Action(SetAffiliationPositionFailure)
  public setAffiliationPositionFailure(
    ctx: StateContext<AffiliationsStateModel>,
    { error }: SetAffiliationPositionFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }
}
