require('angular-ui-bootstrap/dist/ui-bootstrap-tpls');
const Redux = require('redux');
const Reselect = require('reselect');
require('@uirouter/angularjs/release/angular-ui-router');

window.Redux = Redux;
window.Reselect = Reselect;

angular.module('main', [
  'common',
  'questionManagement',
  'questionForm',
  'taskManagement',
  'examManagement',
  'ui.router',
]);

require('./router');
require('./config');

require('./controllers/footer_ctrl');
require('./controllers/header_ctrl');
require('./controllers/main_ctrl');

require('./reducers/root');
