import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateAffiliationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;


export type UpdateAffiliationMutation = { __typename?: 'Mutation', updateAffiliation?: { __typename?: 'UpdateAffiliationPayload', successful: boolean, affiliation?: { __typename?: 'Affiliation', id: string, name: string, position: number } | null, validations?: { __typename?: 'ValidationsAffiliation', name?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const UpdateAffiliationDocument = gql`
    mutation UpdateAffiliation($id: ID!, $name: String!) {
  updateAffiliation(id: $id, name: $name) {
    affiliation {
      id
      name
      position
    }
    successful
    validations {
      name {
        message
        severity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAffiliationGQL extends Apollo.Mutation<UpdateAffiliationMutation, UpdateAffiliationMutationVariables> {
    document = UpdateAffiliationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }