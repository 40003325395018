import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { PoolAffiliationFragment } from '../../../services/load-pool.generated';
import { PoolState } from '../../../state/pool/pool.state';
import { LabelValuePair } from '../../ng-select-wrapper/ng-select-wrapper.component';
import { SetAffiliationGQL } from './set-affiliation.generated';

@Component({
  selector: 'co-set-affiliation-modal',
  templateUrl: './set-affiliation-modal.component.html',
  styleUrls: ['./set-affiliation-modal.component.scss']
})
export class SetAffiliationModalComponent implements OnInit, OnDestroy {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public questionGroups: QuestionGroupListElementFragment[];

  @Select(PoolState.affiliations)
  public affiliations$: Observable<PoolAffiliationFragment[]>;

  @Input()
  public currentPoolId: Scalars['ID'];

  public affiliations: LabelValuePair<string>[];
  public affiliationId: Scalars['ID'];
  private destroy$ = new Subject<void>();

  constructor(private readonly setAffiliationGQL: SetAffiliationGQL) {}

  public ngOnInit(): void {
    this.affiliations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(affiliations => {
        this.affiliations = affiliations.map(affiliation => ({
          label: affiliation.name,
          value: affiliation.id
        }));
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async save(): Promise<void> {
    const result = await this.setAffiliationGQL
      .mutate({
        poolId: this.currentPoolId,
        questionGroupIds: this.questionGroups.map(qg => qg.id),
        affiliationId: this.affiliationId
      })
      .toPromise();

    if (result?.data?.setAffiliation?.successful !== true) {
      throw `Unable to assign affiliation to ${this.questionGroups.map(
        qg => qg.id
      )}`;
    }

    this.modalInstance.close();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }
}
