import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnDestroy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { ContextState } from '@state/context/context.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { uuid } from 'src/app/common/utils/uuid';
import { Language } from 'src/generated/base-types';
import { EditorOptions } from 'tinymce';
import { handleImageUploadWrapper, TINY_MCE_CONFIG } from './tinymce.config';

type OnChange = (value: string) => void;
type OnTouched = () => void;

@Component({
  selector: 'qf-rich-editor',
  templateUrl: './rich-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichEditorComponent),
      multi: true
    }
  ],
  exportAs: 'richEditor'
})
export class RichEditorComponent implements ControlValueAccessor, OnDestroy {
  @Select(ContextState.currentLanguage)
  private currentLanguage$: Observable<Language>;

  public nativeElement: HTMLElement;
  public value: string;
  public id = 'editor_' + uuid();

  public config: Partial<EditorOptions> = TINY_MCE_CONFIG;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly store: Store
  ) {
    // eslint-disable-next-line camelcase
    this.config.images_upload_handler = handleImageUploadWrapper(
      this.store
      // TinyMCE does not provide solid types and uses any for the upload handler!
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any;

    this.currentLanguage$.pipe(takeUntil(this.destroy$)).subscribe(language => {
      switch (language) {
        case Language.De: {
          this.config.language = 'de';
          break;
        }
        case Language.Fr: {
          // This is correct for france, renaming files to fr.js does not work!
          this.config.language = 'fr_FR';
          break;
        }
        default: {
          this.config.language = 'en';
          break;
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: string): void {
    this.value = value;
    this.changeDetector.detectChanges();
  }

  public setValue(value: string): void {
    this.writeValue(value);
    this.onChange(this.value);
  }

  public registerOnChange(fn: OnChange): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: OnTouched): void {
    this.onTouched = fn;
  }

  public onTouched: OnTouched = () => void 0;
  private onChange: OnChange = () => void 0;
}
