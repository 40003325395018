import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AffiliationListElementFragment = { __typename?: 'Affiliation', id: string, position: number, name: string };

export type LoadAffiliationsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type LoadAffiliationsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', affiliations: Array<{ __typename?: 'Affiliation', id: string, position: number, name: string }> } };

export const AffiliationListElementFragmentDoc = gql`
    fragment AffiliationListElement on Affiliation {
  id
  position
  name
}
    `;
export const LoadAffiliationsDocument = gql`
    query LoadAffiliations($poolId: ID!) {
  pool(id: $poolId) {
    affiliations {
      ...AffiliationListElement
    }
  }
}
    ${AffiliationListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadAffiliationsGQL extends Apollo.Query<LoadAffiliationsQuery, LoadAffiliationsQueryVariables> {
    document = LoadAffiliationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }