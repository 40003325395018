<div
  class="bg-stripes-light h-100 border rounded m-2 d-flex flex-column align-items-center justify-content-center"
>
  <div class="bg-white text-center p-2 border rounded d-inline-block">
    <p class="m-0">
      <i class="fa-regular fa-circle-notch fa-spin"></i>
      <span *ngIf="!loadingMessage">
        {{ 'common.actions.loading' | translate | uppercase }}</span
      >
      <span *ngIf="loadingMessage"> {{ loadingMessage | translate }}</span>
    </p>
  </div>
</div>
