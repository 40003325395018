import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ToastsService } from '@common/services/toasts.service';
import { Language } from '@generated/base-types';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { languageOptions } from '../../../common/language-options';
import { EmailValidatorService } from '../../form/utils/email-validator-service';
import { matchValidator } from '../../form/utils/match.validator';
import { requiredWhenOthersEmptyValidator } from '../../form/utils/required-when-others-empty.validator';
import { LoadUsersFieldsFragment } from '../../services/load-users.fragments.generated';

@Component({
  selector: 'man-user-form',
  templateUrl: './user-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent implements OnInit, OnDestroy {
  // this is only called in the UserDetail component
  // in UserCreate the input is not even used and so this setter is not called
  @Input() public set user(value: LoadUsersFieldsFragment) {
    this.form.reset(value);
    this.form.get('password')!.removeValidators([Validators.required]);
    this.passwordFieldVisible = false;
    this.form
      .get('email')
      ?.setAsyncValidators(
        this.emailValidatorService.isEmailInUse(value?.email)
      );
    this.form
      .get('alternativeEmail')
      ?.setAsyncValidators(
        this.emailValidatorService.isEmailInUse(value?.alternativeEmail)
      );
  }
  @Input()
  public saving?: boolean;
  @Input()
  public loading?: boolean;
  @Input()
  public set disabled(value: boolean | undefined) {
    value === true ? this.form.disable() : this.form.enable();
  }

  @Output()
  public readonly save = new EventEmitter<LoadUsersFieldsFragment>();
  @Output()
  public readonly exit = new EventEmitter<void>();
  @Output()
  public readonly stateChange: Observable<boolean>;

  @ViewChild('passwordInput')
  public passwordInput: ElementRef<HTMLInputElement>;

  public readonly languageOptions = languageOptions;
  public form: FormGroup;
  public passwordFieldVisible = true;
  public showPassword = false;

  private statSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private toast: ToastsService,
    private translate: TranslateService,
    private emailValidatorService: EmailValidatorService,
    private cdRef: ChangeDetectorRef
  ) {
    this.form = this.createForm();
    this.stateChange = this.form.valueChanges.pipe(
      map(() => this.form.dirty),
      distinctUntilChanged()
    );
  }

  public ngOnInit(): void {
    this.statSub = this.form.statusChanges.subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.statSub.unsubscribe();
  }

  public onSave(): void {
    if (
      this.loading === true ||
      this.saving === true ||
      !this.form.valid ||
      !this.form.dirty
    ) {
      return;
    }

    this.save.emit(this.form.value);
  }

  public onClose(): void {
    if (this.saving === true) return;

    this.exit.emit();
  }

  public showPasswordField(): void {
    this.passwordFieldVisible = true;
    setTimeout(() => {
      this.passwordInput.nativeElement.focus();
    }, 0);
  }

  public togglePasswordObfuscation(): void {
    this.showPassword = !this.showPassword;
  }

  public generatePassword(): void {
    const password = Math.random().toString(36).slice(-8);

    this.form.markAsDirty();
    this.form.get('password')?.setValue(password);
    this.form.get('passwordConfirmation')?.setValue(password);

    navigator.clipboard.writeText(password).then(() => {
      this.toast.addInfo(
        this.translate.instant(
          'admin.users.form.toast.password_copied_to_clipboard'
        )
      );
    });
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      id: [undefined],
      email: new FormControl('', {
        validators: [
          this.emailValidatorService.isEmailValid,
          Validators.required
        ],
        asyncValidators: this.emailValidatorService.isEmailInUse()
      }),
      alternativeEmail: new FormControl('', {
        validators: [this.emailValidatorService.isEmailValid],
        asyncValidators: this.emailValidatorService.isEmailInUse()
      }),
      useEmailForMtan: new FormControl(true, [
        requiredWhenOthersEmptyValidator(
          'mobilePhone',
          'alternativeMobilePhone'
        )
      ]),
      password: new FormControl('', [
        Validators.minLength(8),
        Validators.maxLength(128),
        matchValidator('passwordConfirmation', true),
        Validators.required
      ]),
      passwordConfirmation: new FormControl('', [
        Validators.minLength(8),
        Validators.maxLength(128),
        matchValidator('password')
      ]),
      language: new FormControl(Language.De, [Validators.required]),
      superUser: new FormControl(false),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      mobilePhone: new FormControl(''),
      alternativeMobilePhone: new FormControl(''),
      organisation: new FormControl('')
    });
  }
}
