<form class="page form" [formGroup]="form" (submit)="onSave()" novalidate>
  <div class="page-content">
    <fieldset formGroupName="message" class="form-fieldset grid">
      <legend class="form-legend g-col-4">
        {{ 'admin.announcements.form.label.message' | translate }}
      </legend>
      <man-field
        class="g-col-4"
        labelFor="message-de"
        [label]="'admin.announcements.form.label.message_de' | translate"
        [errors]="form | fieldErrors : 'message.de'"
      >
        <textarea
          id="message-de"
          formControlName="de"
          class="form-control"
          cdkFocusInitial
          rows="3"
          [placeholder]="
            'admin.announcements.form.placeholder.message' | translate
          "
        ></textarea>
      </man-field>
      <man-field
        class="g-col-4"
        labelFor="message-fr"
        [label]="'admin.announcements.form.label.message_fr' | translate"
        [errors]="form | fieldErrors : 'message.fr'"
      >
        <textarea
          id="message-fr"
          formControlName="fr"
          class="form-control"
          rows="3"
          [placeholder]="
            'admin.announcements.form.placeholder.message' | translate
          "
        ></textarea>
      </man-field>
      <man-field
        class="g-col-4"
        labelFor="message-en"
        [label]="'admin.announcements.form.label.message_en' | translate"
        [errors]="form | fieldErrors : 'message.en'"
      >
        <textarea
          id="message-en"
          formControlName="en"
          class="form-control"
          rows="3"
          [placeholder]="
            'admin.announcements.form.placeholder.message' | translate
          "
        ></textarea>
      </man-field>
    </fieldset>
    <fieldset class="form-fieldset grid">
      <legend class="form-legend g-col-4">
        {{ 'admin.announcements.form.label.settings' | translate }}
      </legend>
      <man-field
        class="g-col-2"
        labelFor="starts-at"
        [label]="'admin.announcements.form.label.starts_at' | translate"
        [errors]="form | fieldErrors : 'startsAt'"
      >
        <input
          #startsAt
          id="starts-at"
          type="date"
          formControlName="startsAt"
          class="form-control"
          [min]="dateInputToday"
      /></man-field>
      <man-field
        class="g-col-2 g-start-1"
        labelFor="ends-at"
        [label]="'admin.announcements.form.label.ends_at' | translate"
        [errors]="form | fieldErrors : 'endsAt'"
      >
        <input
          id="ends-at"
          type="date"
          formControlName="endsAt"
          class="form-control"
          [min]="startsAt.value"
        />
        <ng-container *ngIf="form.touched || form.dirty">
          <div
            class="d-block invalid-feedback"
            error-message
            *ngIf="form.errors?.mustBeAtOrAfterDate as validatorError"
          >
            {{
              'common.form.errors.mustBeAtOrAfterDate'
                | translate
                  : {
                      date: (validatorError.date | date : 'dd.MM.yyyy')
                    }
            }}
          </div>
        </ng-container>
      </man-field>
      <man-field
        class="g-col-2 g-start-1"
        labelFor="severity"
        [label]="'admin.announcements.form.label.severity' | translate"
        [errors]="form | fieldErrors : 'severity'"
      >
        <select id="severity" formControlName="severity" class="form-select">
          <option selected disabled value="">
            {{ 'common.messages.please_select' | translate }}
          </option>
          <option *ngFor="let option of severityOptions" [value]="option.value">
            {{ option.label | translate }}
          </option>
        </select>
      </man-field>
      <man-field class="g-start-1">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="enabled"
            id="enabled"
          />
          <label for="enabled" class="form-check-label">{{
            'admin.announcements.form.label.enabled' | translate
          }}</label>
        </div>
      </man-field>
    </fieldset>
  </div>
  <div class="page-footer">
    <button
      type="button"
      [disabled]="disabled"
      class="btn btn-light order-1"
      (click)="onClose()"
    >
      {{ 'common.actions.close' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-primary order-2"
      [disabled]="disabled || !form.dirty || !form.valid"
      (click)="onSave()"
    >
      <ng-container *ngIf="!disabled; else loading">
        {{
          (edit ? 'common.actions.save_changes' : 'common.actions.create')
            | translate
        }}
      </ng-container>
    </button>
  </div>
</form>
<ng-template #loading>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.actions.loading' | translate }}
</ng-template>
