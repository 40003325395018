import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import { TotalStatisticTypeEFragment } from 'src/app/question-management/services/question-group-statistics.generated';
import { DetailsInfoComponent } from '../../details-info/details-info.component';

type Answer = 'aBecauseB' | 'aB' | 'aNotB' | 'notAB' | 'notANotB' | 'empty';

type Statistic = {
  name: Answer;
  p: number;
  solution: boolean | null;
};

const answerOptions = new Set([
  'aBecauseB',
  'aB',
  'aNotB',
  'notAB',
  'notANotB',
  'empty'
]);

@Component({
  selector: 'type-e-statistics',
  templateUrl: './type-e-statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ManagerCommonModule, TableModule, DetailsInfoComponent],
  host: { style: 'margin: 0.5rem;' }
})
export class TypeEStatisticsComponent {
  @Input()
  public set statistics(value: TotalStatisticTypeEFragment) {
    const values = Object.entries(value) as [string, any][]; // eslint-disable-line @typescript-eslint/no-explicit-any
    this.typeEStatistics = values
      .filter(([key, value]) => answerOptions.has(key) && value !== undefined)
      .map(([key, value]) => {
        return { name: key, ...value };
      });

    this.validStatistics = this.typeEStatistics.some(
      stat => stat.name !== undefined
    );
  }

  public validStatistics = false;
  public typeEStatistics: Statistic[];
  public i18n = (s: string): string =>
    'pool.question_group_details.statistics.' + s;
}
