import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteRevisionStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteRevisionStatusMutation = { __typename?: 'Mutation', deleteRevisionStatus?: { __typename?: 'DeleteRevisionStatusPayload', successful: boolean } | null };

export const DeleteRevisionStatusDocument = gql`
    mutation DeleteRevisionStatus($id: ID!) {
  deleteRevisionStatus(id: $id) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRevisionStatusGQL extends Apollo.Mutation<DeleteRevisionStatusMutation, DeleteRevisionStatusMutationVariables> {
    document = DeleteRevisionStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }