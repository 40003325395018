import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { OriginReference } from '@common/legacy.types';
import { UiRouterService } from '@common/services/ui-router.service';
import {
  primeNGSelectToSortInput,
  sortInputToPrimeNGSelect
} from '@common/utils/primeng-sort';
import { isDefined } from '@common/utils/type-guards/is-defined';
import {
  ListMetadata,
  QuestionGroupSortAttribute,
  Scalars
} from '@generated/base-types';
import { Store } from '@ngxs/store';
import { SettingsState, type QuestionColumnConfig } from '@state/settings';
import { SortMeta } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, of, Subject } from 'rxjs';
import { concatWith, filter, map, takeUntil, tap } from 'rxjs/operators';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { QuestionFilterState as FilterState } from '../../state/question-filter/question-filter.state';
import { QuestionFilters } from '../../state/question-filter/question-filter.state.model';
import {
  LoadQuestionGroupList,
  SetSelectedQuestionGroups
} from '../../state/question-list.actions';
import { QuestionListState as ListState } from '../../state/question-list.state';
import * as util from './utils';

@Component({
  selector: 'qm-question-group-list',
  templateUrl: './question-group-list.component.html',
  styleUrls: ['./question-group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'bs4 pv-fixed-table-header__scroll-area' }
})
export class QuestionGroupListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() public poolId: string;

  @ViewChild('table') public table: Table;

  public rows$: Observable<number>;
  public filter$: Observable<QuestionFilters>;
  public columns$: Observable<QuestionColumnConfig>;
  public loading$: Observable<boolean>;
  public selected$: Observable<QuestionGroupListElementFragment[]>;
  public metadata$: Observable<ListMetadata>;
  public questionGroups$: Observable<QuestionGroupListElementFragment[]>;
  public totalRecords$: Observable<number>;
  public pagePositionSummary$: Observable<string>;
  public first$: Observable<number>;

  public selection: QuestionGroupListElementFragment[] = [];
  public sortAttributes: SortMeta[] = [];
  public visibleColumnsCount = 0;
  public QuestionGroupSortAttribute = QuestionGroupSortAttribute;

  public i18nQGKey = util.i18nQGKey;
  public i18nColKey = util.i18nColKey;
  public isSeriesOrSequence = util.isSeriesOrSequence;
  public isSingle = util.isSingle;
  public validationIcon = util.validationIcon;
  public validationText = util.validationText;
  public isColumnVisible = util.isColumnVisible;

  private get tableScrollBody(): HTMLElement {
    return this.table.el.nativeElement.querySelector('.p-datatable-wrapper');
  }

  private destroy$ = new Subject<void>();

  constructor(private store: Store, private uiRouterService: UiRouterService) {}

  public ngOnInit(): void {
    this.rows$ = this.store.select(ListState.rows);
    this.filter$ = this.store.select(FilterState.currentPoolFilters);
    this.columns$ = this.store.select(SettingsState.questionColumnConfig);
    this.loading$ = this.store.select(ListState.loading);
    this.selected$ = this.store.select(ListState.selectedQuestionGroups);
    this.metadata$ = this.store.select(ListState.metadata);
    this.questionGroups$ = this.store.select(ListState.questionGroups);

    this.totalRecords$ = this.metadata$.pipe(util.countTotalRecords);
    this.pagePositionSummary$ = this.metadata$.pipe(util.pagePositionSummary);

    this.store
      .select(ListState.selectedQuestionGroups)
      .pipe(filter(isDefined), takeUntil(this.destroy$))
      .subscribe(selection => (this.selection = selection));

    const _sortAtrributes = this.store.selectSnapshot(ListState.sortAttributes);
    this.sortAttributes = sortInputToPrimeNGSelect(_sortAtrributes || []);

    this.columns$.pipe(filter(isDefined)).subscribe(columns => {
      this.visibleColumnsCount = util.countVisibleColumns(columns);
    });

    this.first$ = of(util.readPagingDataFromSessionStorage(this.poolId)).pipe(
      map(pagingData => (pagingData && pagingData.first) || 0),
      concatWith(this.filter$.pipe(util.resetFirstValueOnFilterChange())),
      tap(first => this.handleOnPage({ first }))
    );
  }

  public ngAfterViewInit(): void {
    const pagingData = util.readPagingDataFromSessionStorage(this.poolId);
    this.tableScrollBody.scrollTop = pagingData?.scrollTop ?? 0;
    this.attachScrollListener();
  }

  public ngOnDestroy(): void {
    this.destroy$.complete();
    this.detachScrollListener();
  }

  public loadMore(data: {
    first: number;
    rows: number;
    multiSortMeta?: { field: QuestionGroupSortAttribute; order: number }[];
  }): void {
    // remove last sorting attribute if there are more than 3
    if (
      isDefined(data.multiSortMeta) &&
      data.multiSortMeta?.length > util.MAX_SORTING_ATTRIBUTES
    ) {
      this.sortAttributes = data.multiSortMeta.slice(
        0,
        util.MAX_SORTING_ATTRIBUTES
      );

      return;
    }

    const sortAttributes = primeNGSelectToSortInput<QuestionGroupSortAttribute>(
      isDefined(data.multiSortMeta) ? data.multiSortMeta : []
    );

    this.store.dispatch(
      new LoadQuestionGroupList(data.first ?? 0, data.rows, sortAttributes)
    );
  }

  public openQuestionGroup(qgID: Scalars['ID']): void {
    this.uiRouterService.go('editQuestionForm', {
      id: qgID,
      ref: OriginReference.QuestionManagement
    });
  }

  public onSelectionChange(): void {
    this.store.dispatch(new SetSelectedQuestionGroups(this.selection));
  }

  public handleOnPage(pagingData: Partial<util.PagingData>): void {
    util.writePagingDataToSessionStorage(this.poolId, pagingData);
  }

  private attachScrollListener(): void {
    this.handleScroll = this.handleScroll.bind(this);
    const options: AddEventListenerOptions = { passive: true };
    this.tableScrollBody.addEventListener('scroll', this.handleScroll, options);
  }

  private detachScrollListener(): void {
    this.tableScrollBody.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll(event: Event): void {
    const scrollTop = (event.target as HTMLElement).scrollTop;
    util.writePagingDataToSessionStorage(this.poolId, { scrollTop });
  }
}
