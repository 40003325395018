import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RemoteData } from '@common/utils/remote-data';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Table } from 'primeng/table';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  map,
  mergeMap,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap,
  toArray
} from 'rxjs';
import { MultipleFormState } from 'src/app/common/types/form-types';
import {
  getDirectionFromIndices,
  getMoveQuantity
} from 'src/app/common/utils/drag-and-drop';
import {
  assertIsDefined,
  isDefined
} from 'src/app/common/utils/type-guards/is-defined';
import { isVoid } from 'src/app/common/utils/type-guards/voidable';
import { PoolFormState } from 'src/app/new/admin/state/pool-details/form/pool-form.state';
import { ActionMenuOption } from 'src/app/new/common/action-menu/action-menu.component';
import { FuzzySearchService } from 'src/app/new/common/fuzzy-search.service';
import { ModalService } from 'src/app/new/common/modal/modal.service';
import { PoolFormFragment } from 'src/app/services/load-pool-form.generated';
import {
  CategoryInput,
  DimensionInput,
  Language,
  Scalars
} from 'src/generated/base-types';
import { UpdateCategoryMutationVariables } from '../../../services/update-category.generated';
import { UpdateDimensionMutationVariables } from '../../../services/update-dimension.generated';
import {
  CreateCategory,
  CreateDimension,
  DeleteCategory,
  DeleteDimension,
  SetCategoryPosition,
  SetDimensionPosition,
  UpdateCategory,
  UpdateDimension
} from '../../../state/pool-details/dimensions/dimensions.actions';
import {
  CategoryData,
  DimensionData,
  DimensionsState
} from '../../../state/pool-details/dimensions/dimensions.state';

type DimensionOrCategoryData =
  | { dimensionData?: DimensionData; categoryData?: CategoryData }
  | undefined;
type DimensionCategoryFormState = MultipleFormState<DimensionOrCategoryData>;

@Component({
  templateUrl: './dimensions-list.component.html',
  host: { class: 'page' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionsListComponent implements OnDestroy {
  @ViewChild('tbl', { static: false })
  public tbl?: Table;

  @Output()
  public readonly exit = new EventEmitter<void>();

  public readonly filterFields: (keyof CategoryData)[] = [
    'id',
    'nameDe',
    'nameFr',
    'nameEn',
    'nameIt'
  ];
  public inSearchMode = false;
  public readonly dimensions$: Observable<RemoteData<DimensionData[]>>;
  public readonly formState$: Observable<DimensionCategoryFormState>;
  public readonly currentUserLanguage$: Observable<Language>;
  public readonly pool$: Observable<PoolFormFragment>;
  public readonly dimensionMenuOptions: ActionMenuOption[] = [
    {
      label: this.translate.instant('actions.edit'),
      danger: false,
      callback: (data: DimensionData) => this.onEditDimension(data)
    },
    {
      label: this.translate.instant('actions.delete'),
      danger: true,
      callback: (data: DimensionData) => this.onDeleteDimension(data.id)
    }
  ];

  public readonly categoryMenuOptions: ActionMenuOption[] = [
    {
      label: this.translate.instant('actions.edit'),
      danger: false,
      callback: (data: CategoryData) => this.onEditCategory(data)
    },
    {
      label: this.translate.instant('actions.delete'),
      danger: true,
      callback: (data: CategoryData) => this.onDeleteCategory(data.id)
    }
  ];

  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private translate: TranslateService,
    private modalService: ModalService,
    private fuzzySearchService: FuzzySearchService<CategoryData>
  ) {
    this.formState$ = this.formState();
    this.dismissSidebarOnSuccessfulMutation();
    this.dimensions$ = combineLatest([
      this.store.select(DimensionsState.dimensions),
      this.searchString$
    ]).pipe(
      mergeMap(([dimensions, searchString]) => {
        this.inSearchMode = searchString.length > 0;

        return from(dimensions.data || []).pipe(
          map(dimension => {
            const filteredCategories = this.fuzzySearchService.search(
              dimension.categories,
              searchString,
              this.filterFields
            );

            return { ...dimension, categories: filteredCategories };
          }),
          filter(dimension =>
            this.inSearchMode ? dimension.categories.length > 0 : true
          ),
          toArray(),
          map(data => ({ ...dimensions, data }))
        );
      })
    );
    this.pool$ = this.store.select(PoolFormState.pool).pipe(
      map(e => e.data),
      filter(isDefined)
    );
  }

  public onDimensionRowSelect(data: DimensionData): void {
    this.tbl?.toggleRow(data);
  }

  public onCategoryRowSelect(data: CategoryData): void {
    this.onEditCategory(data);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
  }

  public onSearchClear(): void {
    this.searchStringSource.next('');
  }

  public onEditDimension(dimension: DimensionData): void {
    this.routeToForm({ editingDimension: dimension.id });
  }

  public onEditCategory(category: CategoryData): void {
    this.routeToForm({ editingCategory: category.id });
  }

  public async onDeleteDimension(id: Scalars['ID']): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();

    if (confirmDelete) this.store.dispatch(new DeleteDimension(id));
  }

  public async onDeleteCategory(id: Scalars['ID']): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();

    if (confirmDelete) this.store.dispatch(new DeleteCategory(id));
  }

  public onUpdateDimension({
    id,
    attributes
  }: UpdateDimensionMutationVariables): void {
    this.store.dispatch(new UpdateDimension(id, attributes));
  }

  public onUpdateCategory({
    id,
    attributes
  }: UpdateCategoryMutationVariables): void {
    this.store.dispatch(new UpdateCategory(id, attributes));
  }

  public onNewDimension(): void {
    this.routeToForm({ editingDimension: 'new' });
  }

  public onCreateDimension(input: DimensionInput): void {
    this.store.dispatch(new CreateDimension(input));
  }

  public onNewCategory(dimensionId: string): void {
    this.routeToForm({ editingCategory: 'new', dimensionId });
  }

  public onCreateCategory(input: CategoryInput): void {
    this.route.queryParams
      .pipe(
        map<{ dimensionId?: string }, string | undefined>(
          params => params?.dimensionId
        ),
        filter(isDefined),
        take(1),
        switchMap(dimensionId => {
          return this.store.dispatch(new CreateCategory(dimensionId, input));
        })
      )
      .subscribe();
  }

  public onDismiss(): void {
    this.routeToForm();
  }

  public onReorderDimension({
    dragIndex,
    dropIndex
  }: {
    dragIndex: number;
    dropIndex: number;
  }): void {
    if (this.inSearchMode) return;
    if (dragIndex === dropIndex) return;
    const { id } =
      this.store.selectSnapshot(DimensionsState.findDimensionAtIndex)(
        dragIndex
      ) ?? {};
    assertIsDefined(id);

    const moveDirection = getDirectionFromIndices(dragIndex, dropIndex);
    const moveQuantity = getMoveQuantity(dragIndex, dropIndex);

    this.store.dispatch(
      new SetDimensionPosition(id, moveDirection, moveQuantity)
    );
  }

  public onReorderCategory(
    {
      dragIndex,
      dropIndex
    }: {
      dragIndex: number;
      dropIndex: number;
    },
    { id }: DimensionData
  ): void {
    if (this.inSearchMode) return;
    if (dragIndex === dropIndex) return;
    const categoryId = this.store.selectSnapshot(
      DimensionsState.findCategoryAtIndex
    )(id, dragIndex)?.id;
    assertIsDefined(categoryId);

    const moveDirection = getDirectionFromIndices(dragIndex, dropIndex);
    const moveQuantity = getMoveQuantity(dragIndex, dropIndex);

    this.store.dispatch(
      new SetCategoryPosition(categoryId, moveDirection, moveQuantity)
    );
  }

  public generateDimensionNumber(
    dimensions: Observable<RemoteData<DimensionData[]>>,
    dimension?: DimensionData
  ): Observable<string> {
    return dimensions.pipe(
      map(dimensions => {
        const numberOfDimension = dimensions.data?.length ?? 1;

        const positionOfDimensionInDimensions =
          (dimensions.data?.findIndex(d => d.id === dimension?.id) ?? 0) + 1;

        return `${positionOfDimensionInDimensions}/${numberOfDimension}`;
      })
    );
  }

  public generateCategoryNumber(
    dimensions: Observable<RemoteData<DimensionData[]>>,
    category?: CategoryData
  ): Observable<string> {
    return dimensions.pipe(
      map(dimensions => {
        const categories = dimensions?.data?.find(d =>
          d.categories.some(c => c.id === category?.id)
        )?.categories;

        const numberOfCategories = categories?.length ?? 1;

        const positionOfCategoryInCategories =
          (categories?.findIndex(c => c.id === category?.id) ?? 0) + 1;

        return `${positionOfCategoryInCategories}/${numberOfCategories}`;
      })
    );
  }

  private formState(): Observable<DimensionCategoryFormState> {
    const editingDimensionParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editingDimension') ?? undefined)
    );
    const editingCategoryParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editingCategory') ?? undefined)
    );
    const disabled$ = this.store
      .select(DimensionsState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    return combineLatest([
      editingDimensionParam$,
      editingCategoryParam$,
      disabled$
    ]).pipe(
      switchMap(([editingDimension, editingCategory, disabled]) => {
        const show = {
          showDimension: editingDimension !== undefined,
          showCategory: editingCategory !== undefined
        };
        const dimensionId =
          Boolean(show.showDimension) && editingDimension !== 'new'
            ? editingDimension
            : undefined;
        const categoryId =
          Boolean(show.showCategory) && editingCategory !== 'new'
            ? editingCategory
            : undefined;

        return this.dimensions$.pipe(
          filter(({ requestState }) => requestState === 'success'),
          take(1),
          map(({ data }) => {
            let returnValue: DimensionOrCategoryData;

            returnValue = {
              dimensionData: isVoid(dimensionId)
                ? undefined
                : data?.find(dimension => dimension.id === dimensionId)
            };

            if (categoryId !== undefined) {
              returnValue = {
                categoryData: (
                  data
                    ?.map(dimension => dimension.categories)
                    .reduce((acc, cur) => acc.concat(cur)) ?? []
                ).find(category => category.id === categoryId)
              };
            }

            return returnValue;
          }),
          map(data => ({
            show,
            disabled,
            data
          }))
        );
      })
    );
  }

  private dismissSidebarOnSuccessfulMutation(): void {
    this.store
      .select(DimensionsState.mutationRequestState)
      .pipe(
        pairwise(),
        filter(
          ([lastState, currentState]) =>
            lastState === 'loading' && currentState === 'success'
        ),
        tap({ next: () => this.onDismiss() }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private routeToForm({
    editingDimension,
    editingCategory,
    dimensionId
  }: {
    editingDimension?: string;
    editingCategory?: string;
    dimensionId?: string;
  } = {}): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editingDimension, editingCategory, dimensionId },
      queryParamsHandling: 'merge'
    });
  }
}
