import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import { TotalStatisticTypeKprimeFragment } from 'src/app/question-management/services/question-group-statistics.generated';
import { DetailsInfoComponent } from '../../details-info/details-info.component';

type Answer =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'fullPoints'
  | 'halfPoints'
  | 'noPoints'
  | 'empty';

type Statistic = {
  name: Answer;
  p: number;
  solution: boolean | null;
};

const answerOptions = new Set([
  'A',
  'B',
  'C',
  'D',
  'fullPoints',
  'halfPoints',
  'noPoints',
  'empty'
]);

@Component({
  selector: 'type-kprime-statistics',
  templateUrl: './type-kprime-statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ManagerCommonModule, TableModule, DetailsInfoComponent],
  host: { style: 'margin: 0.5rem;' }
})
export class TypeKprimeStatisticsComponent {
  @Input()
  public set statistics(value: TotalStatisticTypeKprimeFragment) {
    const values = Object.entries(value) as [string, any][]; // eslint-disable-line @typescript-eslint/no-explicit-any
    this.typeKprimeStatistics = values
      .filter(([key, value]) => answerOptions.has(key) && value !== undefined)
      .map(([key, value]) => {
        return { name: key, ...value };
      });

    this.validStatistics = this.typeKprimeStatistics.some(
      stat => stat.name !== undefined
    );
  }

  public validStatistics = false;
  public typeKprimeStatistics: Statistic[];
  public i18n = (s: string): string =>
    'pool.question_group_details.statistics.' + s;
}
