import { Injectable, InjectionToken, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import {
  AddCommentModalComponent,
  AddCommentModalData
} from './add-comment-modal/add-comment-modal.component';
import {
  ConfirmModalComponent,
  ConfirmModalData
} from './confirm-modal.component';
import { ErrorModalComponent, ErrorModalData } from './error-modal.component';
import {
  SetSupervisorModalComponent,
  SupervisorModalData
} from './set-supervisor-modal/set-supervisor-modal.component';

export const MODAL_DATA = new InjectionToken('MODAL_DATA');

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private readonly ngbModal: NgbModal) {}

  public confirmDelete(): Promise<boolean> {
    return this.confirm({
      titleTranslationKey: 'admin.common.confirm_modal.delete.title',
      messageTranslationKey: 'admin.common.confirm_modal.delete.message'
    });
  }

  public confirmDeleteQuestion(
    messageTranslationKey: string
  ): Promise<boolean> {
    return this.confirm({
      titleTranslationKey: 'pool.question_list.toolbar.delete_modal.title',
      messageTranslationKey
    });
  }

  public confirmDeleteStatistics(): Promise<boolean> {
    return this.confirm({
      titleTranslationKey:
        'pool.question_list.toolbar.delete_statistics_modal.title',
      messageTranslationKey:
        'pool.question_list.toolbar.delete_statistics_modal.content'
    });
  }

  public addComment(): Promise<boolean | string> {
    return this.confirmComment({
      titleTranslationKey: 'pool.question_list.toolbar.comment_modal.title',
      placeholderTranslationKey:
        'pool.question_list.toolbar.comment_modal.placeholder'
    });
  }

  public setSupervisor(
    supervisors: Observable<string[]>
  ): Promise<boolean | string> {
    return this.confirmSetSupervisor({
      titleTranslationKey:
        'pool.question_list.toolbar.set_supervisor_modal.title',
      labelTranslationKey:
        'pool.question_list.toolbar.set_supervisor_modal.label',
      supervisors
    });
  }

  public confirmUnsavedChanges(): Promise<boolean> {
    return this.confirm({
      titleTranslationKey: 'admin.common.confirm_modal.unsaved_changes.title',
      messageTranslationKey:
        'admin.common.confirm_modal.unsaved_changes.message'
    });
  }

  public confirm(data: ConfirmModalData): Promise<boolean> {
    const modalRef = this.ngbModal.open(ConfirmModalComponent, {
      centered: true,
      keyboard: true,
      injector: createDataInjector(data)
    });

    return modalRef.result.then(
      () => true,
      () => false
    );
  }

  public confirmComment(data: AddCommentModalData): Promise<boolean | string> {
    const modalRef = this.ngbModal.open(AddCommentModalComponent, {
      centered: true,
      keyboard: true,
      injector: createDataInjector(data)
    });

    return modalRef.result.then(
      res => res ?? false,
      () => false
    );
  }

  public confirmSetSupervisor(
    data: SupervisorModalData
  ): Promise<boolean | string> {
    const modalRef = this.ngbModal.open(SetSupervisorModalComponent, {
      centered: true,
      keyboard: true,
      injector: createDataInjector(data)
    });

    return modalRef.result.then(
      res => res ?? false,
      () => false
    );
  }

  public error(data: ErrorModalData): Promise<boolean> {
    const modalRef = this.ngbModal.open(ErrorModalComponent, {
      centered: true,
      backdrop: 'static',
      injector: createDataInjector(data)
    });

    return modalRef.result.then(
      () => true,
      () => false
    );
  }
}

function createDataInjector<T>(data: T): Injector {
  return Injector.create({
    providers: [
      {
        provide: MODAL_DATA,
        useValue: data
      }
    ]
  });
}
