import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';

@Component({
  selector: 'loading-details',
  standalone: true,
  imports: [CommonModule, ManagerCommonModule],
  templateUrl: './loading-details.component.html',
  host: { style: 'display: flex; flex: 1; flex-direction: column;' }
})
export class LoadingDetailsComponent {
  @Input() public loadingMessage: string;
}
