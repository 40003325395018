import { ManagerError } from '@common/utils/error-parser';
import {
  Announcement,
  AnnouncementInput,
  Scalars
} from '@generated/base-types';
import { AnnouncementListElementFragment } from '../../services/load-announcements.generated';

export class LoadAnnouncements {
  public static readonly type = '[Announcements] Load Announcements';
}

export class LoadAnnouncementsSuccess {
  public static readonly type = '[Announcement] Load Announcements Success';
  constructor(public readonly announcements: Announcement[]) {}
}

export class LoadAnnouncementsFailure {
  public static readonly type = '[Announcements] Load Announcements Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateAnnouncement {
  public static readonly type = '[Announcements] Create Announcement';
  constructor(public readonly attributes: AnnouncementInput) {}
}

export class CreateAnnouncementSuccess {
  public static readonly type = '[Announcements] Create Announcement Success';
  constructor(public readonly announcement: AnnouncementListElementFragment) {}
}

export class CreateAnnouncementFailure {
  public static readonly type = '[Announcements] Create Announcement Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly attributes: AnnouncementInput
  ) {}
}

export class UpdateAnnouncement {
  public static readonly type = '[Announcements] Update Announcement';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly attributes: AnnouncementInput
  ) {}
}

export class UpdateAnnouncementSuccess {
  public static readonly type = '[Announcements] Update Announcement Success';
  constructor(public readonly announcement: AnnouncementListElementFragment) {}
}

export class UpdateAnnouncementFailure {
  public static readonly type = '[Announcements] Update Announcement Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID'],
    public readonly attributes: AnnouncementInput
  ) {}
}

export class DeleteAnnouncement {
  public static readonly type = '[Announcements] Delete Announcement';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteAnnouncementSuccess {
  public static readonly type = '[Announcements] Delete Announcement Success';
  constructor(public readonly id: Scalars['ID']) {}
}
export class DeleteAnnouncementFailure {
  public static readonly type = '[Announcements] Delete Announcement Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID']
  ) {}
}
