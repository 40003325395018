import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DATA } from '../modal.service';

export interface AddCommentModalData {
  titleTranslationKey: string;
  placeholderTranslationKey: string;
}

@Component({
  templateUrl: './add-comment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCommentModalComponent {
  public comment = '';

  constructor(
    private ngbActiveModal: NgbActiveModal,
    @Inject(MODAL_DATA) public data: AddCommentModalData
  ) {}

  public onConfirm(comment: string): void {
    this.ngbActiveModal.close(comment.trimEnd());
  }

  public onCancel(): void {
    this.ngbActiveModal.dismiss();
  }
}
