import {
  OAuthApplication,
  OauthApplicationInput,
  Scalars
} from '@generated/base-types';
import { ManagerError } from 'src/app/common/utils/error-parser';
import { OAuthApplicationFieldsFragment } from '../../services/load-oauth-applications.generated';

export class LoadApplications {
  public static readonly type = '[Applications] Load Applications';
}

export class LoadApplicationsSuccess {
  public static readonly type = '[Applications] Load Applications Success';
  constructor(public readonly applications: OAuthApplication[]) {}
}

export class LoadApplicationsFailure {
  public static readonly type = '[Applications] Load Applications Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateApplication {
  public static readonly type = '[Application Form] Create Application';
  constructor(public readonly attributes: OauthApplicationInput) {}
}

export class CreateApplicationSuccess {
  public static readonly type = '[Application Form] Create Application Success';
  constructor(public readonly application: OAuthApplicationFieldsFragment) {}
}

export class CreateApplicationFailure {
  public static readonly type = '[Application Form] Create Application Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly attributes: OauthApplicationInput
  ) {}
}

export class UpdateApplication {
  public static readonly type = '[Application Form] Update Application';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly attributes: OauthApplicationInput
  ) {}
}

export class UpdateApplicationSuccess {
  public static readonly type = '[Application Form] Update Application Success';
  constructor(public readonly application: OAuthApplicationFieldsFragment) {}
}

export class UpdateApplicationFailure {
  public static readonly type = '[Application Form] Update Application Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID'],
    public readonly attributes: OauthApplicationInput
  ) {}
}

export class DeleteApplication {
  public static readonly type = '[Application Form] Delete Application';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteApplicationSuccess {
  public static readonly type = '[Application Form] Delete Application Success';
  constructor(public readonly id: Scalars['ID']) {}
}

export class DeleteApplicationFailure {
  public static readonly type = '[Application Form] Delete Application Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID']
  ) {}
}
