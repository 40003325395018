import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { CommonModule as ManagerCommonModule } from '@common/common.module';
import { Group, GroupedHistory } from '@common/utils/grouped-history';
import {
  isComment,
  isSystemMessageQuestionGroupUpdated,
  isSystemMessageQuestionTypeTransformed,
  isSystemMessageStatisticsDeleted
} from '@common/utils/type-guards/message';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { QuestionGroupMessageFragment } from 'src/app/question-management/services/question-group-messages.generated';
import { Comment, Scalars } from 'src/generated/base-types';

interface GroupViewModel {
  year: number;
  visible: boolean;
}

interface MessageViewModel {
  id: Scalars['ID'];
  author: string;
  date: Date;
  text: string;
  isComment: boolean;
  isRead: boolean;
}

@Component({
  selector: 'question-group-messages',
  templateUrl: './question-group-messages.component.html',
  styleUrls: ['./question-group-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ManagerCommonModule, CheckboxModule, PanelModule]
})
export class QuestionGroupMessagesComponent {
  @Input()
  public set messages(value: QuestionGroupMessageFragment[]) {
    this.messageGroups = this.transformToMessageGroups(
      value,
      this.translateService
    );
  }

  @Input()
  public showReadMessages: boolean;

  @Input()
  public showSystemMessages: boolean;

  @Output()
  public commentReadStateChanged = new EventEmitter<[Comment['id'], boolean]>();

  @Output()
  public showReadMessageChanged = new EventEmitter<boolean>();

  @Output()
  public showSystemMessageChanged = new EventEmitter<boolean>();

  @Output()
  public commentDeleted = new EventEmitter<Comment['id']>();

  public messageGroups: Group<GroupViewModel, MessageViewModel>[] = [];

  constructor(private translateService: TranslateService) {}

  public trackByGroupName(
    _index: number,
    group: Group<GroupViewModel, MessageViewModel>
  ): number {
    return group.name.year;
  }

  public trackByMessageId(_index: number, message: MessageViewModel): string {
    return message.id;
  }

  public onToggleMessageRead({ id, isRead }: MessageViewModel): void {
    this.commentReadStateChanged.emit([id, isRead]);
  }

  public onDeleteComment({ id }: MessageViewModel): void {
    this.commentDeleted.emit(id);
  }

  public onToggleShowReadMessages(): void {
    this.showReadMessageChanged.emit(this.showReadMessages);
  }

  public onToggleShowSystemMessages(): void {
    this.showSystemMessageChanged.emit(this.showSystemMessages);
  }

  private transformToMessageGroups(
    messages: QuestionGroupMessageFragment[],
    translateService: TranslateService
  ): Group<GroupViewModel, MessageViewModel>[] {
    const messageViewModels = messages.map(message =>
      this.messageViewModelFromMessage(message, translateService)
    );
    messageViewModels.sort((a, b) => b.date.getTime() - a.date.getTime());

    const groups = new GroupedHistory(messageViewModels, {
      groupByFn: message => message.date.getFullYear()
    }).getGroups();

    return groups.map(({ name, items }) => {
      return {
        name: {
          year: name,
          visible: true
        },
        items
      };
    });
  }

  private messageViewModelFromMessage(
    message: QuestionGroupMessageFragment,
    translateService: TranslateService
  ): MessageViewModel {
    const comment = isComment(message);

    return {
      id: message.id,
      date: new Date(message.createdAt),
      author: `${message.author.firstName} ${message.author.lastName}`,
      text: comment
        ? message.text
        : this.translateSystemMessage(message, translateService),
      isComment: comment,
      isRead: comment && message.read
    };
  }

  private translateSystemMessage(
    message: QuestionGroupMessageFragment,
    translateService: TranslateService
  ): string {
    if (isSystemMessageQuestionGroupUpdated(message)) {
      return translateService.instant(
        'common.question_group_details.history.system_messages.question_group_updated'
      );
    }

    if (isSystemMessageStatisticsDeleted(message)) {
      return translateService.instant(
        'common.question_group_details.history.system_messages.statistics_deleted'
      );
    }

    if (isSystemMessageQuestionTypeTransformed(message)) {
      return translateService.instant(
        'common.question_group_details.history.system_messages.question_type_transformed',
        { questionNumber: message.questionNumber }
      );
    }

    throw new Error('Unknown message type');
  }
}
