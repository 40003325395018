import { NgModule, importProvidersFrom } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { UnsavedChangesGuard } from '../common/guard/unsaved-changes.guard';
import { AdminComponent } from './admin.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { AnnouncementsGuard } from './announcements/announcements.guard';
import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationsGuard } from './applications/applications.guard';
import { AffiliationsListComponent } from './pools/pool/affiliations-list/affiliations-list.component';
import { AffiliationsListGuard } from './pools/pool/affiliations-list/affiliations-list.guard';
import { DimensionsListComponent } from './pools/pool/dimensions-list/dimensions-list.component';
import { DimensionsListGuard } from './pools/pool/dimensions-list/dimensions-list.guard';
import { PoolDetailComponent } from './pools/pool/pool-detail/pool-detail.component';
import { PoolPermissionsComponent } from './pools/pool/pool-permissions/pool-permissions.component';
import { PoolPermissionsGuard } from './pools/pool/pool-permissions/pool-permissions.guard';
import { RevisionStatusListComponent } from './pools/pool/revision-status-list/revision-status-list.component';
import { RevisionStatusListGuard } from './pools/pool/revision-status-list/revision-status-list.guard';
import { SubjectsListComponent } from './pools/pool/subjects-list/subjects-list.component';
import { SubjectsListGuard } from './pools/pool/subjects-list/subjects-list.guard';
import { PoolCreateComponent } from './pools/pool-create/pool-create.component';
import { PoolEditComponent } from './pools/pool-edit/pool-edit.component';
import { PoolsComponent } from './pools/pools.component';
import { PoolGuard } from './pools/routing/pool.guard';
import { PoolsGuard } from './pools/routing/pools.guard';
import { AnnouncementsState } from './state/announcements/announcements.state';
import { ApplicationsState } from './state/applications/applications.state';
import { AffiliationsState } from './state/pool-details/affiliations/affiliations.state';
import { DimensionsState } from './state/pool-details/dimensions/dimensions.state';
import { PoolFormState } from './state/pool-details/form/pool-form.state';
import { PermissionsState } from './state/pool-details/permissions/permissions.state';
import { PoolDetailsState } from './state/pool-details/pool-details.state';
import { RevisionStatusState } from './state/pool-details/revision-status/revision-status.state';
import { SubjectsState } from './state/pool-details/subjects/subjects.state';
import { UserFormState } from './state/user-form.state';
import { UserPermissionsState } from './state/user-permissions.state';
import { UsersState } from './state/users.state';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserDetailComponent } from './users/user-edit/user-detail.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserGuard } from './users/user-edit/user.guard';
import { UserPermissionsComponent } from './users/user-permissions/user-permissions.component';
import { UserPermissionsGuard } from './users/user-permissions/user-permissions.guard';
import { AdminGuard } from './users-list/admin.guard';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersGuard } from './users-list/users.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AdminGuard],
    providers: [
      importProvidersFrom(
        NgxsModule.forFeature([
          AnnouncementsState,
          ApplicationsState,
          UsersState,
          SubjectsState
        ])
      )
    ],
    children: [
      {
        path: 'users',
        providers: [
          importProvidersFrom(
            NgxsModule.forFeature([UserFormState, UserPermissionsState])
          )
        ],
        children: [
          {
            path: '',
            canActivate: [UsersGuard],
            component: UsersListComponent
          },
          {
            path: 'create',
            component: UserCreateComponent,
            canDeactivate: [UnsavedChangesGuard]
          },
          {
            path: ':id',
            canActivate: [UserGuard],
            component: UserEditComponent,
            children: [
              {
                path: 'details',
                component: UserDetailComponent,
                canDeactivate: [UnsavedChangesGuard]
              },
              {
                path: 'permissions',
                canActivate: [UserPermissionsGuard],
                component: UserPermissionsComponent
              },
              {
                path: '',
                redirectTo: 'details',
                pathMatch: 'full'
              }
            ]
          }
        ]
      },
      {
        path: 'applications',
        canActivate: [ApplicationsGuard],
        component: ApplicationsComponent
      },
      {
        path: 'announcements',
        canActivate: [AnnouncementsGuard],
        component: AnnouncementsComponent
      },
      {
        path: 'pools',
        providers: [
          importProvidersFrom(
            NgxsModule.forFeature([
              PoolDetailsState,
              PoolFormState,
              AffiliationsState,
              DimensionsState,
              PermissionsState,
              RevisionStatusState,
              UserPermissionsState
            ])
          )
        ],
        children: [
          { path: '', canActivate: [PoolsGuard], component: PoolsComponent },
          {
            path: 'create',
            component: PoolCreateComponent,
            canDeactivate: [UnsavedChangesGuard]
          },
          {
            path: ':id',
            canActivate: [PoolGuard],
            component: PoolEditComponent,
            children: [
              {
                path: 'details',
                component: PoolDetailComponent,
                canDeactivate: [UnsavedChangesGuard]
              },
              {
                path: 'permissions',
                canActivate: [PoolPermissionsGuard],
                component: PoolPermissionsComponent
              },
              {
                path: 'affiliations',
                canActivate: [AffiliationsListGuard],
                component: AffiliationsListComponent
              },
              {
                path: 'revision-status',
                canActivate: [RevisionStatusListGuard],
                component: RevisionStatusListComponent
              },
              {
                path: 'blueprints',
                canActivate: [DimensionsListGuard],
                component: DimensionsListComponent
              },
              {
                path: 'subjects',
                canActivate: [SubjectsListGuard],
                component: SubjectsListComponent
              },
              {
                path: '',
                redirectTo: 'details',
                pathMatch: 'full'
              }
            ]
          }
        ]
      },
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
