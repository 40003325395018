<div class="modal-header">
  <h5 class="modal-title">{{ 'error_modal.title' | translate }}</h5>
</div>
<div class="modal-body">
  <p *ngIf="!data.message">
    {{ translationKey | translate }}
  </p>
  <p *ngIf="data.message">
    {{ errorMessage }}
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="onCancel()">
    {{ 'common.actions.ignore' | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onRetry()">
    {{ 'common.actions.retry' | translate }}
  </button>
</div>
