<ng-container *ngIf="tasks | async as tasks">
  <man-table-wrapper [request]="tasks">
    <p-table
      name="Tasks"
      styleClass="p-datatable-sm"
      [value]="tasks.data | copyArray"
      dataKey="id"
      [loading]="tasks.requestState === 'loading'"
      selectionMode="multiple"
      [metaKeySelection]="true"
      [(selection)]="selection"
      (selectionChange)="onSelectionChange()"
      [rowHover]="true"
      [scrollable]="true"
      scrollHeight="flex"
    >
      <ng-template pTemplate="caption">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            {{ 'task_management.tasks.title' | translate }}
          </div>
          <div class="d-flex">
            <div class="px-3">
              <button
                *ngIf="selection.length >= 2"
                class="btn btn-danger btn-sm"
                (click)="onDelete(selection)"
                aria-label="delete-button"
              >
                Delete Selected
              </button>
            </div>
            <button
              class="border-0 bg-transparent panel-toggle-btn"
              (click)="onHidePanel()"
              aria-label="toggle-button"
            >
              <i class="fa-regular fa-sidebar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pFrozenColumn>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="id">
            {{ 'task_management.task_list.short_columns.id' | translate }}
            <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="type">
            {{ 'task_management.task_list.short_columns.type' | translate }}
            <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th pSortableColumn="receiver.lastName">
            {{ 'task_management.task_list.short_columns.receiver' | translate }}
            <p-sortIcon field="receiver.lastName"></p-sortIcon>
          </th>
          <th pSortableColumn="startsAt">
            {{
              'task_management.task_list.short_columns.starts_at' | translate
            }}
            <p-sortIcon field="startsAt"></p-sortIcon>
          </th>
          <th pSortableColumn="endsAt">
            {{ 'task_management.task_list.short_columns.ends_at' | translate }}
            <p-sortIcon field="endsAt"></p-sortIcon>
          </th>
          <th pSortableColumn="creator.lastName">
            {{ 'task_management.task_list.short_columns.creator' | translate }}
            <p-sortIcon field="creator.lastName"></p-sortIcon>
          </th>
          <th pSortableColumn="status">
            {{ 'task_management.task_list.short_columns.status' | translate }}
            <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-task let-rowIndex="rowIndex">
        <tr [pSelectableRow]="task" [pSelectableRowIndex]="rowIndex">
          <td pFrozenColumn>
            <p-tableCheckbox
              [value]="task"
              (click)="$event.stopPropagation()"
            ></p-tableCheckbox>
          </td>
          <td>{{ task.id }}</td>
          <td>
            {{
              'task_management.task.type.' + task.type.toLowerCase() | translate
            }}
          </td>
          <td class="name-cell">
            {{ task.receiver.lastName }} {{ task.receiver.firstName }}
          </td>
          <td>{{ task.startsAt | date : 'dd.MM.yyyy' }}</td>
          <td>{{ task.endsAt | date : 'dd.MM.yyyy' }}</td>
          <td class="name-cell">
            {{ task.creator.lastName }} {{ task.creator.firstName }}
          </td>
          <td>
            <man-task-status [status]="task.status"></man-task-status>
          </td>
          <td>
            <div>
              <man-action-menu
                [data]="task"
                [options]="tasksMenuOptionsList[rowIndex]"
              >
              </man-action-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </man-table-wrapper>
</ng-container>
