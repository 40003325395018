import * as Types from '../../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteQuestionGroupsMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type DeleteQuestionGroupsMutation = { __typename?: 'Mutation', deleteQuestionGroups?: { __typename?: 'DeleteQuestionGroupsPayload', successful: boolean } | null };

export const DeleteQuestionGroupsDocument = gql`
    mutation DeleteQuestionGroups($poolId: ID!, $questionGroupIds: [ID!]!) {
  deleteQuestionGroups(poolId: $poolId, questionGroupIds: $questionGroupIds) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteQuestionGroupsGQL extends Apollo.Mutation<DeleteQuestionGroupsMutation, DeleteQuestionGroupsMutationVariables> {
    document = DeleteQuestionGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }