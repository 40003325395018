import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Scalars } from '@generated/base-types';
import { Store } from '@ngxs/store';
import { LoadPools, PoolsState } from '@state/pools';
import { isDefined } from 'angular';
import { Observable, map, tap } from 'rxjs';
import { PoolListElementFragment } from '../../../services/load-pools.generated';

@Component({
  selector: 'man-pool-dropdown',
  templateUrl: './pool-dropdown.component.html',
  styleUrls: ['./pool-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PoolDropdownComponent)
    }
  ]
})
export class PoolDropdownComponent {
  @Input() public showEmail = true;

  public selectedPoolId?: Scalars['ID'];
  public value?: Scalars['ID'];
  public disabled = false;
  public onChange: (value: string) => void;
  public onTouched: () => void;
  public pools$: Observable<PoolListElementFragment[]>;

  constructor(private store: Store) {
    this.pools$ = this.store.select(PoolsState.pools).pipe(
      tap(pools => {
        if (pools.requestState === 'initial') {
          this.store.dispatch(new LoadPools());
        }
      }),
      map(poolsReq => (isDefined(poolsReq.data) ? [...poolsReq.data!] : [])),
      map(pools => pools.sort((a, b) => a.name.localeCompare(b.name)))
    );
  }

  public writeValue(value?: Scalars['ID']): void {
    this.value = value;
    this.selectedPoolId = isDefined(this.value) ? this.value : undefined;
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public onPoolChange(value: string): void {
    this.onChange(value);
  }
}
