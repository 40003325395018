<div class="actions">
  <div class="action">
    <p-checkbox
      [(ngModel)]="showReadMessages"
      [binary]="true"
      (onChange)="onToggleShowReadMessages()"
      [label]="'common.question_group_details.history.display_read' | translate"
    >
    </p-checkbox>
  </div>
  <div class="action">
    <p-checkbox
      [(ngModel)]="showSystemMessages"
      [binary]="true"
      (onChange)="onToggleShowSystemMessages()"
      [label]="
        'common.question_group_details.history.display_system' | translate
      "
    >
    </p-checkbox>
  </div>
</div>
<ng-container *ngIf="messageGroups?.length">
  <p-panel
    *ngFor="let group of messageGroups; trackBy: trackByGroupName"
    [header]="group.name.year.toString()"
    [toggleable]="true"
    [collapseIcon]="'pi pi-chevron-up'"
    [expandIcon]="'pi pi-chevron-down'"
  >
    <ol class="items" *ngIf="group.name.visible">
      <li
        class="item"
        [ngClass]="{
          'item-comment': message.isComment,
          'item-system-message': !message.isComment
        }"
        *ngFor="let message of group.items; trackBy: trackByMessageId"
      >
        <div class="item-icon">
          <i
            class="fa-solid"
            [ngClass]="{
              'fa-user': message.isComment,
              'fa-desktop': !message.isComment
            }"
          ></i>
        </div>
        <div class="item-author">
          {{ message.author }}
        </div>
        <div class="item-date">
          {{ message.date | date : 'dd.MM.yyyy HH:mm zzzz' }}
        </div>
        <div class="item-text">{{ message.text }}</div>
        <div class="item-actions" *ngIf="message.isComment">
          <p-checkbox
            [(ngModel)]="message.isRead"
            [binary]="true"
            (onChange)="onToggleMessageRead(message)"
          ></p-checkbox>
          <button
            class="btn btn-primary btn-sm"
            (click)="onDeleteComment(message)"
          >
            <i class="fa-solid fa-trash-can"></i>
          </button>
        </div>
      </li>
    </ol>
  </p-panel>
</ng-container>
