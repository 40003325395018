import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { assertIsDefined } from '@common/utils/type-guards/is-defined';
import { Store } from '@ngxs/store';
import { ContextState } from '@state/context/context.state';
import { PoolState } from '@state/pool/pool.state';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import {
  FilterService,
  FilterType
} from '../../services/filter/filter.service';
import {
  QuestionFilterReset,
  QuestionFilterUpdate
} from '../../state/question-filter/question-filter.actions';
import { QuestionFilterState as FilterState } from '../../state/question-filter/question-filter.state';
import {
  AdvancedFilterValue,
  QuestionFilters
} from '../../state/question-filter/question-filter.state.model';
import { QuestionGroupFilterAdvancedComponent } from '../question-group-filter-advanced/question-group-filter-advanced.component';
import { FilterCheckboxComponent } from '../question-group-filter-checkbox/filter-checkbox.component';

@Component({
  selector: 'qm-group-filter',
  templateUrl: './question-group-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionGroupFilterComponent {
  @HostBinding('class.bs4') public bs4 = true;
  @ViewChildren(FilterCheckboxComponent)
  public checkboxFilters: QueryList<FilterCheckboxComponent>;
  @ViewChild(QuestionGroupFilterAdvancedComponent)
  public advancedFilter: QuestionGroupFilterAdvancedComponent;

  public filters$: Observable<QuestionFilters>;
  public openCategories: Record<string, boolean> = {};

  private questionFilters$: Observable<QuestionFilters>;
  private filtertype$: Observable<FilterType>;
  private originalFilters: string;

  constructor(
    private readonly store: Store,
    private readonly filterService: FilterService
  ) {
    this.questionFilters$ = this.store.select(FilterState.currentPoolFilters);
    const currentPoolId = this.store.selectSnapshot(ContextState.currentPoolId);

    assertIsDefined(currentPoolId);

    this.filtertype$ = this.store.select(PoolState.pool).pipe(
      distinctUntilChanged(),
      switchMap(_pool => this.filterService.get(currentPoolId))
    );

    this.filters$ = combineLatest([
      this.questionFilters$,
      this.filtertype$
    ]).pipe(
      map(this.mergeFilterOptions.bind(this)),
      tap(filter => {
        return (this.originalFilters = JSON.stringify(filter));
      })
    );
  }

  public getName(label: string): string {
    label = label === 'contentValidationStates' ? 'languages' : label;
    const regExp = /(?=[A-Z])/;
    const i18nKey = label.split(regExp).join('_').toLowerCase();

    return 'question_groups.filter.all_' + i18nKey;
  }

  public isOpen(category: string): boolean {
    return !!this.openCategories[category];
  }

  public onOpen(category: string, open: boolean): void {
    this.openCategories[category] = open;
  }

  public onChangeAdvanced(
    selection: AdvancedFilterValue[],
    filters: QuestionFilters
  ): void {
    filters.advancedFilter = { ...filters.advancedFilter, selection };
  }

  public onSubmit(filters: QuestionFilters): void {
    this.store.dispatch(new QuestionFilterUpdate(filters));
  }

  public onReset(): void {
    this.store.dispatch(new QuestionFilterReset());
    this.advancedFilter.reset();
  }

  public isDirty(filters: QuestionFilters): boolean {
    return this.originalFilters !== JSON.stringify(filters);
  }

  public isDisabled(): boolean {
    return this.checkboxFilters.some(checkbox => checkbox.isEmptySelection);
  }

  public isFiltered(filters: QuestionFilters): boolean {
    const simple = filters.simpleFilters.some(f => f.selection?.length);
    const advanced = Object.keys(filters.advancedFilter.selection).length > 0;
    const query = !!filters.query;

    return simple || advanced || query;
  }

  // This should be moved into the store selector once the legacy store is removed
  private mergeFilterOptions([filters, type]: [
    QuestionFilters,
    FilterType
  ]): QuestionFilters {
    const simpleFilters = filters.simpleFilters.map(filter => ({
      ...filter,
      options: type.simpleFilter[filter.category]
    }));
    const advancedFilter = {
      ...filters.advancedFilter,
      options: type.advancedFilter
    };

    return { ...filters, simpleFilters, advancedFilter };
  }
}
