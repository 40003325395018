import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { MODAL_DATA } from '../modal.service';

export interface SupervisorModalData {
  titleTranslationKey: string;
  labelTranslationKey: string;
  supervisors: Observable<string[]>;
}

@Component({
  templateUrl: './set-supervisor-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetSupervisorModalComponent {
  public supervisor = '';

  constructor(
    private ngbActiveModal: NgbActiveModal,
    @Inject(MODAL_DATA) public data: SupervisorModalData
  ) {}

  public onConfirm(): void {
    if (this.supervisor) {
      this.ngbActiveModal.close(this.supervisor);
    } else {
      this.ngbActiveModal.close();
    }
  }

  public onCancel(): void {
    this.ngbActiveModal.dismiss();
  }
}
