import {
  IndexedValidationError,
  ExcelStatisticsInput as Statistics,
  ExcelStatisticsValidation as Validation,
  ValidationCode
} from '@generated/base-types';
export const requiredXslxHeaders = ['sequentialNumber', 'questionPosition'];

export type Validated<T> = {
  [K in keyof T]: { error?: ValidationCode; value: T[K] };
};
export type ValidatedStatistics = Validated<Validation>;

export const exampleImportData: Omit<Statistics, 'index'>[] = [
  {
    sequentialNumber: '125498',
    questionPosition: 1,
    n: 120,
    p: 75,
    pDich: 14,
    r: 0.23,
    lne: 3.24,
    xi1: 0.634,
    xi2: 0.042,
    eliminated: false,
    answerOption1: 40.5,
    answerOption2: 25.5,
    answerOption3: 34,
    answerOption4: 0,
    answerOption5: 0
  }
];

export const columns = [
  {
    key: 'sequentialNumber',
    header: 'pool.exams.upload_statistics.table.header.id'
  },
  {
    key: 'questionPosition',
    header: 'pool.exams.upload_statistics.table.header.position',
    tooltip: 'position'
  },
  {
    key: 'n',
    header: 'pool.exams.upload_statistics.table.header.n'
  },
  {
    key: 'p',
    header: 'pool.exams.upload_statistics.table.header.p'
  },
  {
    key: 'pDich',
    header: 'pool.exams.upload_statistics.table.header.p_dich',
    tooltip: 'pool.exams.upload_statistics.table.tooltip.p_dich'
  },
  {
    key: 'r',
    header: 'pool.exams.upload_statistics.table.header.r'
  },
  {
    key: 'lne',
    header: 'pool.exams.upload_statistics.table.header.lne'
  },
  {
    key: 'xi1',
    header: 'pool.exams.upload_statistics.table.header.xi1'
  },
  {
    key: 'xi2',
    header: 'pool.exams.upload_statistics.table.header.xi2'
  },
  {
    key: 'eliminated',
    header: 'pool.exams.upload_statistics.table.header.eliminated',
    tooltip: 'pool.exams.upload_statistics.table.tooltip.eliminated'
  },
  {
    key: 'answerOption1',
    header: 'pool.exams.upload_statistics.table.header.answer_opt_1',
    tooltip: 'pool.exams.upload_statistics.table.tooltip.answer_opt_1'
  },
  {
    key: 'answerOption2',
    header: 'pool.exams.upload_statistics.table.header.answer_opt_2',
    tooltip: 'pool.exams.upload_statistics.table.tooltip.answer_opt_2'
  },
  {
    key: 'answerOption3',
    header: 'pool.exams.upload_statistics.table.header.answer_opt_3',
    tooltip: 'pool.exams.upload_statistics.table.tooltip.answer_opt_3'
  },
  {
    key: 'answerOption4',
    header: 'pool.exams.upload_statistics.table.header.answer_opt_4',
    tooltip: 'pool.exams.upload_statistics.table.tooltip.answer_opt_4'
  },
  {
    key: 'answerOption5',
    header: 'pool.exams.upload_statistics.table.header.answer_opt_5',
    tooltip: 'pool.exams.upload_statistics.table.tooltip.answer_opt_5'
  }
];

export const parseValidationData = (
  statistics: Statistics[],
  validation: Validation | undefined
): ValidatedStatistics[] => {
  return statistics.map(statistic => {
    const { index, ...parameters } = statistic;
    const validated: ValidatedStatistics = Object.entries(parameters).reduce(
      (acc, [key, value]) => {
        const validationErrors =
          validation && validation[key as keyof typeof validation];

        const indexedValidationError = Array.isArray(validationErrors)
          ? (validationErrors as IndexedValidationError[]).find(
              v => v.index === index
            )
          : undefined;

        return {
          ...acc,
          [key]: { error: indexedValidationError?.code, value }
        };
      },
      {} as ValidatedStatistics
    );

    return validated;
  });
};
