/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable unicorn/no-null */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { filter, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import { coordinatorRequiresSubjectValidator } from 'src/app/new/admin/form/utils/coordinator-requires-subject.validator';
import { PoolPermissionsFragment } from 'src/app/new/admin/services/load-pool-permissions.fragments.generated';
import { ModalService } from 'src/app/new/common/modal/modal.service';
import { PermissionInput } from 'src/generated/base-types';
import { LoadSubjects } from '../../../../state/pool-details/subjects/subjects.actions';
import { SubjectsState } from '../../../../state/pool-details/subjects/subjects.state';
import { LoadUserPermissions } from '../../../../state/user-permissions.actions';
import { UserPermissionsState } from '../../../../state/user-permissions.state';
import { RoleDropdownComponent } from '../role-dropdown/role-dropdown.component';

type SubjectOption = { id: string; name: string; exists: boolean };

@Component({
  selector: 'man-permission-form',
  templateUrl: './permission-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionFormComponent implements OnInit, OnDestroy {
  @ViewChild(RoleDropdownComponent)
  public roleComponent: RoleDropdownComponent;

  @Input() public permission?: Partial<PoolPermissionsFragment>;
  @Input() public selectionMode: 'pool' | 'user';
  @Input() public userId: string | undefined;
  @Input() public set poolId(value: string | undefined) {
    this.form.get('poolId')!.setValue(value);
  }
  public get poolId(): string {
    return this.form.get('poolId')?.value ?? '';
  }

  @Input() public set disabled(value: boolean | undefined) {
    value === true ? this.form?.disable() : this.form?.enable();
  }
  public get disabled(): boolean {
    return this.form?.disabled ?? true;
  }

  @Output() public create = new EventEmitter<PermissionInput>();
  @Output() public update = new EventEmitter<PermissionInput>();
  @Output() public closed = new EventEmitter<void>();

  public subjects$: Observable<SubjectOption[]>;

  public form = new FormGroup(
    {
      role: new FormControl<PermissionInput['role']>(null, [
        Validators.required
      ]),
      userId: new FormControl<PermissionInput['userId']>(null, [
        Validators.required
      ]),
      subjectId: new FormControl<PermissionInput['subjectId']>(null),
      poolId: new FormControl<PermissionInput['poolId']>(null)
    },
    {
      validators: coordinatorRequiresSubjectValidator()
    }
  );

  public get edit(): boolean {
    return this.permission !== undefined;
  }

  private destroy$ = new Subject<void>();

  constructor(
    private readonly modalService: ModalService,
    private store: Store
  ) {}

  public ngOnInit(): void {
    // editing existing permission
    if (
      this.permission?.user?.id !== undefined &&
      this.permission?.user?.id !== null &&
      this.selectionMode === 'user'
    ) {
      this.store.dispatch(new LoadUserPermissions(this.permission.user.id));
    }

    if (
      this.permission?.pool?.id !== undefined &&
      this.permission.pool.id !== null
    ) {
      this.loadSubjects(this.permission.pool.id);
    }

    // creating Permission from the pools page, no this.permission
    if (this.poolId) this.loadSubjects(this.poolId);

    if (this.userId !== undefined) {
      this.form.get('userId')?.setValue(this.userId, { emitEvent: false });
    }

    this.handleRoleChange();
    this.handlePoolChange();
    this.handleUserChange();
    this.initialiseForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async onClose(): Promise<void> {
    if (this.disabled) return;
    await this.safeClose();
  }

  public onSave(): void {
    if (!isDefined(this.form) || !this.form.valid || !this.form.dirty) return;

    isDefined(this.permission)
      ? this.update.emit(this.form.value)
      : this.create.emit(this.form.value);
  }

  private async safeClose(): Promise<void> {
    if (this.form?.dirty ?? false) {
      const discardChanges = await this.modalService.confirmUnsavedChanges();
      if (discardChanges) {
        this.closed.emit();
      }
    } else {
      this.closed.emit();
    }
  }

  private initialiseForm(): void {
    if (this.permission === undefined) return;

    this.form.patchValue(
      {
        role: this.permission.role,
        userId: this.permission.user?.id,
        subjectId: this.permission.subject?.id,
        poolId: this.permission.pool?.id
      },
      { emitEvent: false }
    );
  }

  private handleRoleChange(): void {
    this.form.valueChanges
      .pipe(
        // using pairwise and filter in combination instead of distinctUntilChanged
        // because the first value change will trigger distinctUntilChanged as the previous value
        // of the form is always different, triggering patchValue unnecessarily
        pairwise(),
        filter(([prev, curr]) => prev.role !== curr.role),
        tap(() => {
          this.roleComponent.loadRoles();

          this.form.patchValue({ subjectId: null }, { emitEvent: false });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private loadSubjects(poolId: string): void {
    const subjectsAfterLoad$ = this.store
      .dispatch(new LoadSubjects(poolId))
      .pipe(
        switchMap(() =>
          this.store
            .select(SubjectsState.subjects)
            .pipe(map(({ data }) => data || []))
        )
      );

    this.subjects$ = combineLatest([
      subjectsAfterLoad$,
      this.store
        .select(UserPermissionsState.userPermissions)
        .pipe(map(({ data }) => data || []))
    ]).pipe(
      map(([subjects, permissions]) => {
        const existingSubjects = new Set(
          permissions.filter(p => p.subject?.id).map(p => p.subject!.id)
        );

        return subjects
          .map(subject => ({
            ...subject,
            exists: existingSubjects.has(subject.id)
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      })
    );
  }

  private handlePoolChange(): void {
    this.form
      .get('poolId')
      ?.valueChanges.pipe(
        // The pipe above doesn't work on the first change,
        // this one works every change of the poolId, which is exactly what we need.
        filter(poolId => poolId !== undefined && poolId !== null),
        tap(poolId => {
          this.loadSubjects(poolId!);
          this.form.reset(
            {
              poolId: poolId,
              userId: this.form.get('userId')?.value
            },
            { emitEvent: false }
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handleUserChange(): void {
    this.form
      .get('userId')!
      .valueChanges.pipe(
        filter(isDefined),
        tap(userId => {
          this.store.dispatch(new LoadUserPermissions(userId!));

          this.form.reset(
            {
              poolId: this.permission?.pool?.id ?? this.poolId,
              userId: userId,
              role: null
            },
            { emitEvent: false }
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
