import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';

@Component({
  selector: 'details-info',
  standalone: true,
  imports: [CommonModule, ManagerCommonModule],
  templateUrl: './details-info.component.html',
  host: { style: 'display: flex; flex: 1; flex-direction: column;' }
})
export class DetailsInfoComponent {
  @Input() public message: string;
  @Input() public isExclamationMsg: boolean;
}
