import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges
} from '@angular/core';
import { Store } from '@ngxs/store';
import { SettingsState, UpdatePreviewsVisibleLanguages } from '@state/settings';
import { AccordionModule } from 'primeng/accordion';
import { map, Observable } from 'rxjs';
import { CommonModule as ManagerCommonModule } from 'src/app/common/common.module';
import {
  Language,
  LanguageSpecificUrl,
  PreviewGenerationStatus
} from 'src/generated/base-types';
import { DetailsInfoComponent } from '../details-info/details-info.component';
import { LoadingDetailsComponent } from '../loading-details/loading-details.component';

const NAME_TO_LANGUAGE: Record<string, Language> = {
  de: Language.De,
  fr: Language.Fr,
  en: Language.En,
  it: Language.It
};

@Component({
  selector: 'question-group-preview',
  templateUrl: './question-group-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ManagerCommonModule,
    LoadingDetailsComponent,
    AccordionModule,
    DetailsInfoComponent
  ],
  host: { style: 'display: flex; height: 100%' }
})
export class QuestionGroupPreviewComponent implements OnChanges {
  @Input()
  public set status(value: PreviewGenerationStatus) {
    this.loading = value === PreviewGenerationStatus.InProgress;
  }
  @Input()
  public url: LanguageSpecificUrl | undefined | null;

  public questionsByLanguage: Record<Language, string>;
  public loading = false;
  public selectedLanguages: Language[];
  public activeIds: Observable<number[]>;

  private selectedLanguages$: Observable<Language[]>;

  constructor(private store: Store) {
    this.selectedLanguages$ = this.store.select(
      SettingsState.previewVisibleLanguages
    );
    this.activeIds = this.selectedLanguages$.pipe(
      map(languages =>
        languages.map(language => {
          return Object.keys(this.questionsByLanguage).indexOf(language);
        })
      )
    );
    this.selectedLanguages$.subscribe(languages => {
      this.selectedLanguages = [...(languages ?? [])];
    });
  }

  public ngOnChanges(): void {
    if (this.url) {
      this.questionsByLanguage = Object.entries(this.url)
        .filter(([_, value]) => value !== undefined)
        .reduce((obj, [key, value]) => {
          if (value !== null) {
            obj[key as Language] = value;
          }

          return obj;
        }, {} as Record<Language, string>);
    }
  }

  public onTabOpen(event: { index: number }): void {
    const selectedLanguage = Object.keys(this.questionsByLanguage)[event.index];
    this.store.dispatch(
      new UpdatePreviewsVisibleLanguages([
        ...this.selectedLanguages,
        NAME_TO_LANGUAGE[selectedLanguage]
      ])
    );
  }

  public onTabClose(event: { index: number }): void {
    const selectedLanguage = Object.keys(this.questionsByLanguage)[event.index];
    this.store.dispatch(
      new UpdatePreviewsVisibleLanguages(
        this.selectedLanguages.filter(
          language => language !== NAME_TO_LANGUAGE[selectedLanguage]
        )
      )
    );
  }
}
