/* eslint-disable camelcase, unicorn/no-null, @typescript-eslint/no-non-null-assertion */
import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import type { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateAdapter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Language,
  Scalars,
  TaskInput,
  TaskType
} from 'src/generated/base-types';
import { PoolState } from '../../../state/pool/pool.state';
import { LabelValuePair } from '../../ng-select-wrapper/ng-select-wrapper.component';
import { Notifications } from '../../services/notifications';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../utils/ng-bootstrap-modal';
import { assertIsDefined } from '../../utils/type-guards/is-defined';
import { isVoid } from '../../utils/type-guards/voidable';
import { CreateTaskGQL } from '../create-task-modal/create-task.generated';
import {
  LoadTaskOptionsGQL,
  TaskOptionQuestionGroupFragment,
  TaskUserFragment
} from '../create-task-modal/load-task-options.generated';
import {
  buildRecentEmailBodyOptions,
  buildUserOptions
} from '../task-modal-options.tool';
import { ConfirmTranslateTaskModalComponent } from './confirm-translate-task-modal.component';

type UserOption = LabelValuePair<string>;
type LanguageOption = LabelValuePair<Language>;
type RecentEmailBodyOption = LabelValuePair<string>;

const SECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;
const SECONDS_IN_A_YEAR = SECONDS_IN_A_DAY * 365;

@Component({
  selector: 'co-translate-task-modal',
  templateUrl: './translate-task-modal.component.html',
  styleUrls: ['./translate-task-modal.component.scss']
})
export class TranslateTaskModalComponent implements OnInit, OnDestroy {
  @Input() public modalInstance: NgbModalRef;
  @Input()
  public questionGroupIds: string[];

  @Input()
  public poolId: Scalars['ID'];

  @Select(PoolState.languages)
  public languages$: Observable<Language[]>;

  public minDate: NgbDateStruct;
  public maxDate: NgbDateStruct;
  public isLoading = true;
  public isSubmitting = false;
  public showDetails = false;
  public form: UntypedFormGroup;
  public questionGroupsWithSameLang = 0;
  public questionGroups: TaskOptionQuestionGroupFragment[];
  public languageOptions: LanguageOption[];
  public languages: Language[];
  public userOptions: UserOption[];
  public recentEmailBodies: RecentEmailBodyOption[];

  private users: TaskUserFragment[];
  private destroy$ = new Subject<void>();

  constructor(
    private readonly loadTaskOptionsGQL: LoadTaskOptionsGQL,
    private readonly createTaskGQL: CreateTaskGQL,
    private readonly translateService: TranslateService,
    private readonly datePipe: DatePipe,
    private readonly ngbDateAdapter: NgbDateAdapter<Date>,
    private readonly ngbModal: NgbModal,
    private readonly notifications: Notifications
  ) {
    this.minDate = this.ngbDateAdapter.fromModel(new Date(Date.now()))!;
    this.maxDate = this.ngbDateAdapter.fromModel(
      new Date(Date.now() + 7 * SECONDS_IN_A_YEAR)
    )!;
    this.form = new UntypedFormGroup({
      receiverIds: new UntypedFormControl(
        [],
        [Validators.required, Validators.minLength(1)]
      ),
      emailSubject: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      emailBody: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      deadline: new UntypedFormControl(undefined, [Validators.required]),
      targetLanguage: new UntypedFormControl('', [Validators.required])
    });
  }

  public async ngOnInit(): Promise<void> {
    const query = await this.loadTaskOptionsGQL
      .fetch({
        poolId: this.poolId,
        questionGroupIds: this.questionGroupIds.map(id => id.toString()),
        taskType: TaskType.Translate
      })
      .toPromise();

    assertIsDefined(query);

    this.languages$.pipe(takeUntil(this.destroy$)).subscribe(languages => {
      this.languages = languages;
      this.languageOptions = languages.map(language => ({
        label: this.translateService.instant(
          `scrudu.languages.long.${language}`
        ),
        value: language
      }));
    });
    this.users = query.data.pool.users;
    this.questionGroups = query.data.pool.questionGroups;
    this.userOptions = buildUserOptions(this.users);
    this.recentEmailBodies = buildRecentEmailBodyOptions(
      query.data.pool.tasks,
      this.datePipe
    );
    this.isLoading = false;
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async confirm(): Promise<void> {
    const formValues = this.form.getRawValue();
    const translateTaskInput: TaskInput = {
      receiverIds: formValues.receiverIds,
      emailSubject: formValues.emailSubject,
      emailBody: formValues.emailBody,
      questionGroupIds: this.questionGroupIds.map(id => id.toString()),
      deadline: formValues.deadline?.toISOString(),
      type: TaskType.Translate,
      translate: {
        targetLanguage: formValues.targetLanguage
      }
    };
    const users = this.users.filter(user =>
      formValues.receiverIds.includes(user.id)
    );
    const ngbInstance = this.ngbModal.open(
      ConfirmTranslateTaskModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );
    const componentInstance: ConfirmTranslateTaskModalComponent =
      ngbInstance.componentInstance;
    componentInstance.modalInstance = ngbInstance;
    componentInstance.subject = translateTaskInput.emailSubject;
    componentInstance.body = translateTaskInput.emailBody;
    componentInstance.users = users;
    componentInstance.targetLanguage = formValues.targetLanguage;

    try {
      await ngbInstance.result;
      this.isSubmitting = true;
      const result = await this.createTaskGQL
        .mutate({
          poolId: this.poolId,
          attributes: translateTaskInput
        })
        .toPromise();

      if (result?.data?.createTask?.successful !== true) {
        throw `Unable to create translate task on pool ${this.poolId}`;
      }

      this.isSubmitting = false;
      this.modalInstance.close();
      this.notifications.addSuccess(
        this.translateService.instant(
          'question_management.translate_task_modal.notifications.task_created'
        )
      );
    } catch (_rejected: unknown) {
      // do nothing
    }
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public setEmailBody(value: string): void {
    this.form.patchValue({ emailBody: value });
  }

  public setLanguageWarning(option: LabelValuePair<Language>): void {
    const language = option?.value;

    if (isVoid(language)) {
      return; // should never happen
    }

    this.questionGroupsWithSameLang = this.questionGroups.filter(
      qg => qg.sourceLanguage === language
    ).length;
  }
}
