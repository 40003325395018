import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Store } from '@ngxs/store';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Language } from '../generated/base-types';
import { ContextState } from './state/context/context.state';

const uri = '/api/v2/graphql';

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, store: Store) {
        let language = Language.De;
        store
          .select(ContextState.currentLanguage)
          .subscribe(currentLanguage => {
            language = currentLanguage;
          });

        const languageHeader = setContext(() => ({
          headers: {
            'User-Language': language
          }
        }));

        const errorHandler = onError(({ networkError }) => {
          if (
            networkError &&
            (networkError as HttpErrorResponse).status === 401
          ) {
            window.location.replace('/login');
          }
        });

        const link = ApolloLink.from([
          languageHeader,
          errorHandler,
          httpLink.create({ uri })
        ]);

        return {
          // disabling GraphQL to prevent __typename fields from being added when querying
          cache: new InMemoryCache({ addTypename: false }),
          link,
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'ignore'
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
            },
            mutate: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
            },
            subscribe: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
            }
          }
        };
      },
      deps: [HttpLink, Store]
    }
  ]
})
export class GraphQLModule {}
