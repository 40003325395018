import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetDimensionPositionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  direction: Types.MoveDirection;
  times?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetDimensionPositionMutation = { __typename?: 'Mutation', setDimensionPosition?: { __typename?: 'SetDimensionPositionPayload', successful: boolean, dimension?: { __typename?: 'Dimension', id: string, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, position: number, categories: Array<{ __typename?: 'Category', id: string, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, position: number }> } | null, dimensions?: Array<{ __typename?: 'Dimension', id: string, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, position: number, categories: Array<{ __typename?: 'Category', id: string, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, position: number }> }> | null } | null };

export const SetDimensionPositionDocument = gql`
    mutation SetDimensionPosition($id: ID!, $direction: MoveDirection!, $times: Int) {
  setDimensionPosition(id: $id, direction: $direction, times: $times) {
    dimension {
      id
      name
      nameDe
      nameFr
      nameEn
      nameIt
      position
      categories {
        id
        name
        nameDe
        nameFr
        nameEn
        nameIt
        position
      }
    }
    dimensions {
      id
      name
      nameDe
      nameFr
      nameEn
      nameIt
      position
      categories {
        id
        name
        nameDe
        nameFr
        nameEn
        nameIt
        position
      }
    }
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDimensionPositionGQL extends Apollo.Mutation<SetDimensionPositionMutation, SetDimensionPositionMutationVariables> {
    document = SetDimensionPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }