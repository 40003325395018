import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
export type PoolPermissionsFragment = { __typename?: 'Permission', id: string, role: Types.PermissionRole, user: { __typename?: 'User', id: string, email?: string | null, firstName: string, lastName: string }, subject?: { __typename?: 'Subject', id: string, name: string } | null, pool: { __typename?: 'Pool', id: string, name: string } };

export const PoolPermissionsFragmentDoc = gql`
    fragment PoolPermissions on Permission {
  id
  role
  user {
    id
    email
    firstName
    lastName
  }
  subject {
    id
    name
  }
  pool {
    id
    name
  }
}
    `;