<div
  class="bg-stripes-light h-100 border rounded m-2 d-flex flex-column align-items-center justify-content-center"
>
  <div class="bg-white text-center p-2 border rounded d-inline-block">
    <p class="m-0">
      <i class="fa-solid fa-triangle-exclamation" *ngIf="isExclamationMsg"></i>
      {{ message | translate | uppercase }}
    </p>
  </div>
</div>
