import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { requestStateSuccess } from '@common/utils/remote-data-utils';
import { isDefined } from '@common/utils/type-guards/is-defined';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PoolFormFragment } from '../../../../services/load-pool-form.generated';
import { UnsavedChanges } from '../../../common/guard/unsaved-changes.guard';
import { CreatePool } from '../../state/pool-details/form/pool-form.actions';
import { PoolFormState } from '../../state/pool-details/form/pool-form.state';

@Component({
  templateUrl: './pool-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container content' }
})
export class PoolCreateComponent implements OnDestroy, UnsavedChanges {
  public readonly saving$: Observable<boolean>;
  public unsavedChanges = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.saving$ = this.store.select(PoolFormState.mutationLoading);
    this.navigateToEditOnSuccess();
  }

  @HostListener('window:beforeunload', ['$event'])
  public onUnloadListener(event: BeforeUnloadEvent): void {
    if (!this.unsavedChanges) return;

    event.preventDefault();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onStateChange(unsavedChanges: boolean): void {
    this.unsavedChanges = unsavedChanges;
  }

  public onSave({ id: _, ...pool }: PoolFormFragment): void {
    this.store.dispatch(new CreatePool(pool));
  }

  private navigateToEditOnSuccess(): void {
    this.store
      .select(PoolFormState.mutation)
      .pipe(
        filter(requestStateSuccess),
        tap(() => (this.unsavedChanges = false)),
        switchMap(() => this.store.select(PoolFormState.pool)),
        take(1),
        map(({ data }) => data),
        filter(isDefined),
        tap(({ id }) =>
          this.router.navigate(['../', id], { relativeTo: this.route })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
