/* eslint-disable camelcase */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { PoolRevisionStatusFragment } from '../../../services/load-pool.generated';
import { PoolState } from '../../../state/pool/pool.state';
import { isDefined } from '../../utils/type-guards/is-defined';
import { SetRevisionStatusGQL } from './set-revision-status.generated';

enum RevisionOperation {
  SET = 'SET',
  UNSET = 'UNSET'
}

@Component({
  selector: 'co-set-revision-modal',
  templateUrl: './set-revision-modal.component.html',
  styleUrls: ['./set-revision-modal.component.scss']
})
export class SetRevisionModalComponent implements OnInit, OnDestroy {
  @Input()
  public modalInstance: NgbModalRef;
  @Input()
  public questionGroups: QuestionGroupListElementFragment[];
  @Select(PoolState.revisionStatuses)
  public revisionStatuses$: Observable<PoolRevisionStatusFragment[]>;
  @Input()
  public currentPoolId: Scalars['ID'];

  public mode = RevisionOperation.SET;
  public modes = RevisionOperation;
  public revisions: PoolRevisionStatusFragment[];
  public selected?: PoolRevisionStatusFragment;
  public confirmDeletion = false;

  private destroy$ = new Subject<void>();

  constructor(private readonly setRevisionStatusGQL: SetRevisionStatusGQL) {}

  public ngOnInit(): void {
    this.revisionStatuses$
      .pipe(takeUntil(this.destroy$))
      .subscribe(revisionStatuses => (this.revisions = revisionStatuses));

    this.preselectMatchingRevision();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectRevision(
    revision: PoolRevisionStatusFragment | undefined
  ): void {
    this.selected = revision;
  }

  public async save(): Promise<void> {
    if (!this.selected) {
      return;
    }
    const revisionStatusId =
      // eslint-disable-next-line unicorn/no-null
      this.mode === this.modes.SET ? this.selected.id : null;
    const result = await this.setRevisionStatusGQL
      .mutate({
        poolId: this.currentPoolId,
        questionGroupIds: this.questionGroups.map(qg => qg.id),
        revisionStatusId: revisionStatusId
      })
      .toPromise();

    if (result?.data?.setRevisionStatus?.successful !== true) {
      throw `Unable to set revision status for ${this.questionGroups.map(
        qg => qg.id
      )}`;
    }

    this.modalInstance.close();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  private preselectMatchingRevision(): void {
    const revisionStatus = new Map<Scalars['ID'], PoolRevisionStatusFragment>();
    this.questionGroups.forEach(questionGroup => {
      if (isDefined(questionGroup.revisionStatus)) {
        revisionStatus.set(
          questionGroup.revisionStatus.id,
          questionGroup.revisionStatus
        );
      }
    });

    this.selected =
      revisionStatus.size === 1
        ? // get first entry of map
          revisionStatus.entries().next().value[1]
        : // or nothing if revision status are not the same
          undefined;
  }
}
