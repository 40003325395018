<form class="page form" [formGroup]="form" (submit)="onSave()" noValidate>
  <div class="page-content">
    <man-field
      class="g-col-4"
      labelFor="number"
      [label]="
        'admin.pools.dimensions.table.form.label.dimension_number' | translate
      "
    >
      <input
        id="number"
        type="text"
        class="form-control"
        [value]="dimensionNumber"
        [disabled]="true"
      />
    </man-field>
    <man-field
      class="g-col-4"
      labelFor="nameDe"
      [label]="'admin.pools.dimensions.table.form.label.name_de' | translate"
      [errors]="form | fieldErrors : 'nameDe'"
      *ngIf="pool.deEnabled"
    >
      <input
        id="nameDe"
        type="text"
        formControlName="nameDe"
        class="form-control"
        cdkFocusInitial
      />
    </man-field>
    <man-field
      class="g-col-4"
      labelFor="nameEn"
      [label]="'admin.pools.dimensions.table.form.label.name_en' | translate"
      [errors]="form! | fieldErrors : 'nameEn'"
      *ngIf="pool.enEnabled"
    >
      <input
        id="nameEn"
        type="text"
        formControlName="nameEn"
        class="form-control"
        cdkFocusInitial
      />
    </man-field>
    <man-field
      class="g-col-4"
      labelFor="nameFr"
      [label]="'admin.pools.dimensions.table.form.label.name_fr' | translate"
      [errors]="form | fieldErrors : 'nameFr'"
      *ngIf="pool.frEnabled"
    >
      <input
        id="nameFr"
        type="text"
        formControlName="nameFr"
        class="form-control"
        cdkFocusInitial
      />
    </man-field>
    <man-field
      class="g-col-4"
      labelFor="nameIt"
      [label]="'admin.pools.dimensions.table.form.label.name_it' | translate"
      [errors]="form | fieldErrors : 'nameIt'"
      *ngIf="pool.itEnabled"
    >
      <input
        id="nameIt"
        type="text"
        formControlName="nameIt"
        class="form-control"
        cdkFocusInitial
      />
    </man-field>
  </div>

  <div class="page-footer">
    <button
      type="submit"
      class="btn btn-primary order-2"
      [disabled]="disabled || !form?.dirty || !form?.valid"
    >
      <ng-container *ngIf="!disabled; else loading">
        {{ (edit ? 'actions.save' : 'actions.create') | translate }}
      </ng-container>
    </button>
    <button
      type="button"
      [disabled]="disabled"
      class="btn btn-light order-1"
      (click)="onClose()"
    >
      {{ 'actions.close' | translate }}
    </button>
  </div>
</form>

<ng-template #loading>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.loading' | translate }}
</ng-template>
