import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

type SubjectOption = { id: string; name: string };

@Component({
  selector: 'man-subject-dropdown',
  templateUrl: './subject-dropdown.component.html',
  styleUrls: ['./subject-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SubjectDropdownComponent)
    }
  ]
})
export class SubjectDropdownComponent {
  @Input() public subjects$: Observable<SubjectOption[]>;

  public selectedSubjectId: SubjectOption['id'] | undefined;
  public value?: SubjectOption['id'];
  public touched = false;
  public disabled = false;
  public onChange: (value: SubjectOption['id']) => void;
  public onTouched: () => void;

  public writeValue(value: SubjectOption['id']): void {
    this.value = value;
    this.selectedSubjectId = value;
  }

  public registerOnChange(
    onChange: (value: SubjectOption['id']) => void
  ): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public onSubjectChange(event: SubjectOption['id']): void {
    this.onChange(event);
  }
}
