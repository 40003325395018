import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ModalService } from '../../common/modal/modal.service';

export interface UnsavedChanges {
  unsavedChanges: boolean;
}

@Injectable({ providedIn: 'root' })
export class UnsavedChangesGuard implements CanDeactivate<UnsavedChanges> {
  constructor(private modalService: ModalService) {}

  public canDeactivate(component: UnsavedChanges): boolean | Promise<boolean> {
    if (component.unsavedChanges === false) return true;

    return this.modalService.confirmUnsavedChanges();
  }
}
