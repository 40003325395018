import * as Types from '../../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoadTasksQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type LoadTasksQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', tasks: Array<{ __typename?: 'Task', id: number, type: Types.TaskType, startsAt: string, endsAt: string, createdAt: string, finishedAt?: string | null, status: Types.TaskStatus, emailBody: string, emailSubject: string, softDeleted: boolean, magicLinkAvailable?: boolean | null, poolId: number, creator: { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null, organisation?: string | null, superUser: boolean }, receiver: { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null, organisation?: string | null, superUser: boolean } }> } };

export const LoadTasksDocument = gql`
    query LoadTasks($poolId: ID!, $limit: Int = null) {
  pool(id: $poolId) {
    tasks(limit: $limit, sort: [{attribute: CREATED_AT, direction: DESC}]) {
      id
      type
      startsAt
      endsAt
      createdAt
      finishedAt
      status
      emailBody
      emailSubject
      softDeleted
      magicLinkAvailable
      poolId
      creator {
        id
        firstName
        lastName
        email
        organisation
        superUser
      }
      receiver {
        id
        firstName
        lastName
        email
        organisation
        superUser
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadTasksGQL extends Apollo.Query<LoadTasksQuery, LoadTasksQueryVariables> {
    document = LoadTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }