import { CommonModule as AngularCommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbAccordionModule,
  NgbDateAdapter,
  NgbDateNativeUTCAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPopoverModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgbCustomDateParserFormatter } from './adapters/ngb-custom-date-parser-formatter';
import './angularjs/module';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AssetImageComponent } from './asset-image/asset-image.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import './downgraded';
import { TableKeyboardNavigationDirective } from './directives/table-keyboard-navigation.directive';
import { LoaderComponent } from './loader/loader.component';
import { AssignBlueprintModalComponent } from './modals/assign-blueprint-modal/assign-blueprint-modal.component';
import { AssignExamModalComponent } from './modals/assign-exam-modal/assign-exam-modal.component';
import { AssignLabelModalComponent } from './modals/assign-label-modal/assign-label-modal.component';
import { BaseConfirmModalComponent } from './modals/base-confirm-modal/base-confirm-modal.component';
import { ChooseQuestionGroupTypeModalComponent } from './modals/choose-question-group-type-modal/choose-question-group-type-modal.component';
import { CommentTaskModalComponent } from './modals/comment-task-modal/comment-task-modal.component';
import { ConfirmCommentTaskModalComponent } from './modals/comment-task-modal/confirm-comment-task-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { ConfirmCreateTaskModalComponent } from './modals/create-task-modal/confirm-create-task-modal.component';
import { CreateTaskModalComponent } from './modals/create-task-modal/create-task-modal.component';
import { DuplicateQuestionGroupModalComponent } from './modals/duplicate-question-group-modal/duplicate-question-group-modal.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { ConfirmReviseTaskModalComponent } from './modals/revise-task-modal/confirm-revise-task-modal.component';
import { ReviseTaskModalComponent } from './modals/revise-task-modal/revise-task-modal.component';
import { RevisionDocumentModalComponent } from './modals/revision-document-modal/revision-document-modal.component';
import { SetAffiliationModalComponent } from './modals/set-affiliation-modal/set-affiliation-modal.component';
import { SetRevisionModalComponent } from './modals/set-revision-modal/set-revision-modal.component';
import { SetRevisionYearModalComponent } from './modals/set-revision-year-modal/set-revision-year-modal.component';
import { SetSourceLanguageModalComponent } from './modals/set-source-language-modal/set-source-language-modal.component';
import { SetSupervisorModalComponent } from './modals/set-supervisor-modal/set-supervisor-modal.component';
import { TaskQuestionsTableComponent } from './modals/task-modal/task-questions-table/task-questions-table.component';
import { ConfirmTranslateTaskModalComponent } from './modals/translate-task-modal/confirm-translate-task-modal.component';
import { TranslateTaskModalComponent } from './modals/translate-task-modal/translate-task-modal.component';
import { TranslateTaskTableComponent } from './modals/translate-task-modal/translate-task-table/translate-task-table.component';
import { WriteCommentModalComponent } from './modals/write-comment-modal/write-comment-modal.component';
import { NgSelectWrapperComponent } from './ng-select-wrapper/ng-select-wrapper.component';
import { AggregateLocalisationPipe } from './pipes/aggregate-localisation.pipe';
import { AsyncNotNull } from './pipes/async-not-null.pipe';
import { CopyArrayPipe } from './pipes/copy-array.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FixedNumberRangePipe } from './pipes/fixed-number-range.pipe';
import { FixedNumberPipe } from './pipes/fixed-number.pipe';
import { LocalizeContentPipe } from './pipes/localize-content.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { PercentagesPipe } from './pipes/percentages.pipe';
import { RgbPipe } from './pipes/rgb.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TranslateErrorPipe } from './pipes/translate-error.pipe';
import { YesOrBlankPipe } from './pipes/yes-or-blank.pipe';
import { PreviewComponent } from './preview/preview.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { QuestionGroupMessagesComponent } from './question-group-messages/question-group-messages.component';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import { WindowService } from './services/window.service';
import { SinglePanelAccordionComponent } from './single-panel-accordion/single-panel-accordion.component';
import { QuestionGroupStatisticsComponent } from './statistics/question-group-statistics/question-group-statistics.component';
import { SubgroupStatisticsComponent } from './statistics/subgroup-statistics/subgroup-statistics.component';
import { TypeAStatisticsComponent } from './statistics/type-a-statistics/type-a-statistics.component';
import { TypeEStatisticsComponent } from './statistics/type-e-statistics/type-e-statistics.component';
import { TypeFreeTextStatisticsComponent } from './statistics/type-free-text-statistics/type-free-text-statistics.component';
import { TypeKprimeStatisticsComponent } from './statistics/type-kprime-statistics/type-kprime-statistics.component';
import { TabSwitchComponent } from './tab-switch/tab-switch.component';
import { ToastComponent } from './toasts/toast/toast.component';
import { ToastsContainerComponent } from './toasts/toasts-container.component';

@NgModule({
  declarations: [
    AsyncNotNull,
    NgSelectWrapperComponent,
    FilterPipe,
    RadioButtonGroupComponent,
    CheckboxComponent,
    CopyArrayPipe,
    ProgressBarComponent,
    SinglePanelAccordionComponent,
    AssetImageComponent,
    DuplicateQuestionGroupModalComponent,
    AssignExamModalComponent,
    SetSourceLanguageModalComponent,
    SetSupervisorModalComponent,
    ChooseQuestionGroupTypeModalComponent,
    WriteCommentModalComponent,
    BaseConfirmModalComponent,
    ConfirmModalComponent,
    SetRevisionModalComponent,
    AssignLabelModalComponent,
    SetRevisionYearModalComponent,
    AssignBlueprintModalComponent,
    SetAffiliationModalComponent,
    RevisionDocumentModalComponent,
    LoaderComponent,
    LocalizeContentPipe,
    PreviewComponent,
    QuestionGroupMessagesComponent,
    QuestionGroupStatisticsComponent,
    FixedNumberRangePipe,
    FixedNumberPipe,
    PercentagesPipe,
    YesOrBlankPipe,
    PercentagePipe,
    TranslateErrorPipe,
    SafeHtmlPipe,
    SubgroupStatisticsComponent,
    TabSwitchComponent,
    TypeAStatisticsComponent,
    TypeKprimeStatisticsComponent,
    TypeEStatisticsComponent,
    TypeFreeTextStatisticsComponent,
    CreateTaskModalComponent,
    ConfirmCreateTaskModalComponent,
    CommentTaskModalComponent,
    ConfirmCommentTaskModalComponent,
    TaskQuestionsTableComponent,
    ReviseTaskModalComponent,
    ConfirmReviseTaskModalComponent,
    TranslateTaskModalComponent,
    TranslateTaskTableComponent,
    ConfirmTranslateTaskModalComponent,
    RgbPipe,
    TableKeyboardNavigationDirective,
    AppHeaderComponent,
    ToastsContainerComponent,
    ToastComponent,
    ErrorModalComponent,
    AggregateLocalisationPipe
  ],
  imports: [
    AngularCommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgbAccordionModule,
    TranslateModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    RouterModule
  ],
  exports: [
    AngularCommonModule,
    AsyncNotNull,
    LocalizeContentPipe,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonGroupComponent,
    BaseConfirmModalComponent,
    CheckboxComponent,
    CopyArrayPipe,
    ProgressBarComponent,
    SinglePanelAccordionComponent,
    AssetImageComponent,
    NgSelectWrapperComponent,
    LoaderComponent,
    PreviewComponent,
    QuestionGroupMessagesComponent,
    QuestionGroupStatisticsComponent,
    NgSelectModule,
    NgbDatepickerModule,
    SafeHtmlPipe,
    YesOrBlankPipe,
    RgbPipe,
    TableKeyboardNavigationDirective,
    TabSwitchComponent,
    AppHeaderComponent,
    ToastsContainerComponent,
    ToastComponent,
    AggregateLocalisationPipe,
    FixedNumberRangePipe,
    FixedNumberPipe,
    PercentagePipe,
    PercentagesPipe,
    SubgroupStatisticsComponent,
    TypeAStatisticsComponent,
    TypeKprimeStatisticsComponent,
    TypeEStatisticsComponent,
    TypeFreeTextStatisticsComponent
  ],
  providers: [
    DatePipe,
    WindowService,
    { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }, // outputs datepicked date as ISO8601 string
    {
      provide: NgbDateParserFormatter,
      useClass: NgbCustomDateParserFormatter
    }
  ]
})
export class CommonModule {}
