import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OauthApplicationInput, Scalars } from '@generated/base-types';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { FormState } from '../../../common/types/form-types';
import { RemoteData } from '../../../common/utils/remote-data';
import { ActionMenuOption } from '../../common/action-menu/action-menu.component';
import { FuzzySearchService } from '../../common/fuzzy-search.service';
import { ModalService } from '../../common/modal/modal.service';
import { OAuthApplicationFieldsFragment } from '../services/load-oauth-applications.generated';
import {
  CreateApplication,
  DeleteApplication,
  UpdateApplication
} from '../state/applications/applications.actions';
import { ApplicationsState } from '../state/applications/applications.state';
import { ApplicationFormComponent } from './application-form/application-form.component';

@Component({
  templateUrl: './applications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container content' }
})
export class ApplicationsComponent implements OnDestroy {
  @ViewChild(ApplicationFormComponent)
  public formComponent: ApplicationFormComponent;

  public readonly filterFields: (keyof OAuthApplicationFieldsFragment)[] = [
    'name'
  ];
  public readonly applications$: Observable<
    RemoteData<OAuthApplicationFieldsFragment[]>
  >;
  public readonly formState$: Observable<
    FormState<OAuthApplicationFieldsFragment>
  >;

  public applicationsMenuOptions: ActionMenuOption[] = [
    {
      label: 'Edit',
      callback: (data: OAuthApplicationFieldsFragment) => this.onEdit(data)
    },
    {
      label: 'Delete',
      danger: true,
      callback: (data: OAuthApplicationFieldsFragment) => this.onDelete(data.id)
    }
  ];

  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private fuzzySearchService: FuzzySearchService<OAuthApplicationFieldsFragment>
  ) {
    this.applications$ = combineLatest([
      this.store.select(ApplicationsState.applications),
      this.searchString$
    ]).pipe(
      map(([applications, searchString]) => {
        const result = this.fuzzySearchService.search(
          applications.data || [],
          searchString,
          this.filterFields
        );

        return { ...applications, data: result };
      })
    );
    this.formState$ = this.formState();
    this.dismissSidebarOnSuccessfulMutation();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
  }

  public onSearchClear(): void {
    this.searchStringSource.next('');
  }

  public onNew(): void {
    this.routeToForm('new');
  }

  public onEdit(data: OAuthApplicationFieldsFragment): void {
    this.routeToForm(data.id);
  }

  public onDismiss(): void {
    this.routeToForm();
  }

  public onClose(): void {
    this.formComponent.onClose();
  }

  public onCreate(announcement: OauthApplicationInput): void {
    this.store.dispatch(new CreateApplication(announcement));
  }

  public onUpdate({
    id,
    ...input
  }: OauthApplicationInput & { id: Scalars['ID'] }): void {
    this.store.dispatch(new UpdateApplication(id, input));
  }

  public async onDelete(id: Scalars['ID']): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();

    if (confirmDelete) this.store.dispatch(new DeleteApplication(id));
  }

  private formState(): Observable<FormState<OAuthApplicationFieldsFragment>> {
    const editingParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editing') ?? undefined)
    );
    const disabled$ = this.store
      .select(ApplicationsState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    return combineLatest([editingParam$, disabled$]).pipe(
      switchMap(([editingParam, disabled]) => {
        const show: boolean = editingParam !== undefined;
        const id = show && editingParam !== 'new' ? editingParam : undefined;

        // TODO: https://github.com/iml-it/measured-manager/issues/2851
        return this.applications$.pipe(
          filter(({ requestState }) => requestState === 'success'),
          take(1),
          map(({ data }) =>
            id === undefined ? undefined : data?.find(a => a.id === id)
          ),
          map(data => ({ show, disabled, data }))
        );
      })
    );
  }

  private dismissSidebarOnSuccessfulMutation(): void {
    this.store
      .select(ApplicationsState.mutationRequestState)
      .pipe(
        // NOTE:
        // The problem is that if we get to the page and the "mutationState" is "Success", "onDismiss" is called and therefore navigate.
        // If navigate is called durring navigation processing, this navigation is canceled.
        // So we need to wait for the "mutationState" to change from "Loading" to "Success" and only navigate then.
        pairwise(),
        filter(
          ([lastState, currentState]) =>
            lastState === 'loading' && currentState === 'success'
        ),
        tap({ next: () => this.onDismiss() }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private routeToForm(editing?: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editing },
      queryParamsHandling: 'merge'
    });
  }
}
