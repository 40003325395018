/* eslint-disable camelcase, unicorn/no-null, unicorn/no-await-expression-member */
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import type { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateAdapter,
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  Language,
  Scalars,
  TaskInput,
  TaskLanguagesOption,
  TaskType
} from 'src/generated/base-types';
import { PoolState } from '../../../state/pool/pool.state';
import { LabelValuePair } from '../../ng-select-wrapper/ng-select-wrapper.component';
import { Notifications } from '../../services/notifications';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../utils/ng-bootstrap-modal';
import { assertIsDefined } from '../../utils/type-guards/is-defined';
import { CreateTaskGQL } from '../create-task-modal/create-task.generated';
import {
  LoadTaskOptionsGQL,
  TaskDimensionFragment,
  TaskOptionQuestionGroupFragment,
  TaskUserFragment
} from '../create-task-modal/load-task-options.generated';
import {
  buildRecentEmailBodyOptions,
  buildUserOptions
} from '../task-modal-options.tool';
import { ConfirmReviseTaskModalComponent } from './confirm-revise-task-modal.component';

@Component({
  selector: 'co-revise-task-modal',
  templateUrl: './revise-task-modal.component.html',
  styleUrls: ['./revise-task-modal.component.scss']
})
export class ReviseTaskModalComponent implements OnInit {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public questionGroupIds: string[];

  @Input()
  public poolId: Scalars['ID'];

  @Select(PoolState.languages)
  public languages$: Observable<Language[]>;

  public readonly languagesOptions = TaskLanguagesOption;
  public languagesOption: TaskLanguagesOption = TaskLanguagesOption.Source;
  public date: { year: number; month: number };
  public minDate: NgbDateStruct;
  public form: UntypedFormGroup;
  public dimensions: TaskDimensionFragment[];
  public questionGroups: TaskOptionQuestionGroupFragment[];
  public receiverIds: string[] = [];
  public userOptions: LabelValuePair<string>[] = [];
  public recentEmailBodies: LabelValuePair<string>[] = [];
  public showDetails = false;
  public loading = true;
  public isSubmitting = false;

  private users: TaskUserFragment[];

  constructor(
    private readonly loadTaskOptionsGQL: LoadTaskOptionsGQL,
    private datePipe: DatePipe,
    private readonly ngbDateAdapter: NgbDateAdapter<Date>,
    private readonly ngbModal: NgbModal,
    private readonly translateService: TranslateService,
    private readonly createTaskGQL: CreateTaskGQL,
    private readonly notifications: Notifications
  ) {
    this.form = new UntypedFormGroup({
      receiverIds: new UntypedFormControl(
        [],
        [Validators.required, Validators.minLength(1)]
      ),
      emailBody: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      emailSubject: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      deadline: new UntypedFormControl(null, [Validators.required])
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.minDate = this.ngbDateAdapter.fromModel(new Date())!;
  }

  public async ngOnInit(): Promise<void> {
    const query = await this.loadTaskOptionsGQL
      .fetch({
        poolId: this.poolId,
        questionGroupIds: this.questionGroupIds.map(id => id.toString()),
        taskType: TaskType.Revise
      })
      .toPromise();

    assertIsDefined(query);

    this.users = query.data.pool.users;
    this.dimensions = query.data.pool.dimensions;
    this.questionGroups = query.data.pool.questionGroups;
    this.userOptions = buildUserOptions(this.users);
    this.recentEmailBodies = buildRecentEmailBodyOptions(
      query.data.pool.tasks,
      this.datePipe
    );
    this.loading = false;
  }

  public async save(): Promise<void> {
    const formValues = this.form.getRawValue();

    const taskInput: TaskInput = {
      deadline: formValues.deadline?.toISOString(),
      emailBody: formValues.emailBody,
      emailSubject: formValues.emailSubject,
      questionGroupIds: this.questionGroupIds,
      revise: { languageOption: this.languagesOption },
      type: TaskType.Revise,
      receiverIds: formValues.receiverIds
    };

    const users = this.users.filter(user =>
      this.form.getRawValue().receiverIds.includes(user.id)
    );
    const languageOptionTranslationKey =
      this.languagesOption === this.languagesOptions.Source
        ? 'question_management.revise_task_modal.source_language'
        : 'question_management.revise_task_modal.all_languages';

    const ngbInstance = this.ngbModal.open(
      ConfirmReviseTaskModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );
    const componentInstance: ConfirmReviseTaskModalComponent =
      ngbInstance.componentInstance;
    componentInstance.modalInstance = ngbInstance;
    componentInstance.users = users;
    componentInstance.languageOptionTranslationKey =
      languageOptionTranslationKey;
    componentInstance.subject = taskInput.emailSubject;
    componentInstance.body = taskInput.emailBody;

    try {
      await ngbInstance.result;
      this.isSubmitting = true;
      const result = await this.createTaskGQL
        .mutate({
          poolId: this.poolId,
          attributes: taskInput
        })
        .toPromise();

      if (result?.data?.createTask?.successful !== true) {
        throw `Unable to create revise task on pool ${this.poolId}`;
      }

      this.isSubmitting = false;
      this.modalInstance.close();
      this.notifications.addSuccess(
        this.translateService.instant(
          'question_management.revise_task_modal.notifications.task_created'
        )
      );
    } catch (_rejected: unknown) {
      // do nothing
    }
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public setEmailBody(value: string): void {
    this.form.patchValue({ emailBody: value });
  }

  public isControlInvalid(name: string): boolean {
    const control = this.form.get(name);
    if (control === null) {
      return false;
    }

    return control.invalid;
  }
}
