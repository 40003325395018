import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { LoadCurrentUser } from '@state/context';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { takeGraphQLResult } from '../../common/operators/take-graphql-response';
import { ToastsService } from '../../common/services/toasts.service';
import { parseError } from '../../common/utils/error-parser';
import { RemoteData, RequestState } from '../../common/utils/remote-data';
import { isDefined } from '../../common/utils/type-guards/is-defined';
import { LoadUsersFieldsFragment } from '../../new/admin/services/load-users.fragments.generated';
import { ModalService } from '../../new/common/modal/modal.service';
import { LoadCurrentUserGQL } from '../services/load-current-user.generated';
import { UpdateCurrentUserGQL } from '../services/update-current-user.generated';
import {
  LoadProfile,
  LoadProfileFailure,
  LoadProfileSuccess,
  UpdateProfile,
  UpdateProfileFailure,
  UpdateProfileSuccess
} from './profile-form.actions';

export interface ProfileFormStateModel {
  profile: RemoteData<LoadUsersFieldsFragment>;
  mutation: RemoteData<LoadUsersFieldsFragment>;
}

@State<ProfileFormStateModel>({
  name: 'profile',
  defaults: {
    profile: {
      requestState: 'initial'
    },
    mutation: {
      requestState: 'initial'
    }
  }
})
@Injectable({
  providedIn: 'root'
})
export class ProfileFormState {
  constructor(
    private readonly loadCurrentUserService: LoadCurrentUserGQL,
    private readonly updateCurrentUserService: UpdateCurrentUserGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly modalService: ModalService
  ) {}

  @Selector()
  public static profile(
    state: ProfileFormStateModel
  ): LoadUsersFieldsFragment | undefined {
    return state.profile.data;
  }

  @Selector()
  public static mutationRequestState(
    state: ProfileFormStateModel
  ): RequestState {
    return state.mutation.requestState;
  }

  @Action(LoadProfile)
  public loadProfile(
    ctx: StateContext<ProfileFormStateModel>
  ): Observable<void> {
    ctx.patchState({
      profile: {
        requestState: 'loading'
      }
    });

    return this.loadCurrentUserService.fetch().pipe(
      takeGraphQLResult(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      switchMap(res => ctx.dispatch(new LoadProfileSuccess(res.currentUser!))),
      catchError((err: unknown) => {
        return ctx.dispatch(new LoadProfileFailure(parseError(err)));
      })
    );
  }

  @Action(LoadProfileSuccess)
  public loadUsersSuccess(
    ctx: StateContext<ProfileFormStateModel>,
    { profile }: LoadProfileSuccess
  ): void {
    ctx.patchState({
      profile: {
        data: profile,
        requestState: 'success'
      }
    });
  }

  @Action(LoadProfileFailure)
  public loadUsersFailure(
    ctx: StateContext<ProfileFormStateModel>,
    { error }: LoadProfileFailure
  ): void {
    ctx.patchState({
      profile: {
        error,
        requestState: 'failure'
      }
    });
  }

  @Action(UpdateProfile)
  public updateProfile(
    ctx: StateContext<ProfileFormStateModel>,
    { attributes }: UpdateProfile
  ): Observable<void> {
    ctx.patchState({
      mutation: {
        requestState: 'loading'
      }
    });

    return this.updateCurrentUserService.mutate({ attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.updateCurrentUser?.user),
      filter(isDefined),
      switchMap(user => ctx.dispatch(new UpdateProfileSuccess(user))),
      catchError((err: unknown) => {
        return ctx.dispatch(
          new UpdateProfileFailure(parseError(err), attributes)
        );
      })
    );
  }

  @Action(UpdateProfileSuccess)
  public updateProfileSuccess(
    ctx: StateContext<ProfileFormStateModel>,
    { profile }: UpdateProfileSuccess
  ): void {
    ctx.patchState({
      profile: {
        data: profile,
        requestState: 'success'
      },
      mutation: {
        requestState: 'success'
      }
    });

    // update user in app context
    ctx.dispatch(new LoadCurrentUser());

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('profile.form.notification.updated')
      })
    );
  }

  @Action(UpdateProfileFailure)
  public updateProfileFailure(
    ctx: StateContext<ProfileFormStateModel>,
    { error, attributes }: UpdateProfileFailure
  ): void {
    ctx.patchState({
      mutation: {
        error,
        requestState: 'failure'
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (retry) {
        ctx.dispatch(new UpdateProfile(attributes));
      }
    });
  }
}
