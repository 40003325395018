import * as Types from '../../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ExamDetailsFragment = { __typename?: 'Exam', id: string, name: string, date: string };

export type LoadExamDetailsQueryVariables = Types.Exact<{
  examId: Types.Scalars['ID'];
}>;


export type LoadExamDetailsQuery = { __typename?: 'Query', exam: { __typename?: 'Exam', id: string, name: string, date: string } };

export const ExamDetailsFragmentDoc = gql`
    fragment ExamDetails on Exam {
  id
  name
  date
}
    `;
export const LoadExamDetailsDocument = gql`
    query LoadExamDetails($examId: ID!) {
  exam(id: $examId) {
    ...ExamDetails
  }
}
    ${ExamDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadExamDetailsGQL extends Apollo.Query<LoadExamDetailsQuery, LoadExamDetailsQueryVariables> {
    document = LoadExamDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }