import * as Types from '../../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PoolRevisionStatusFragment = { __typename?: 'RevisionStatus', id: string, description: string, shortName: string };

export type PoolAffiliationFragment = { __typename?: 'Affiliation', id: string, name: string };

export type PoolDimensionFragment = { __typename?: 'Dimension', id: string, name: string, position: number, truncated: boolean, categories: Array<{ __typename?: 'Category', id: string, name: string, position: number }> };

export type PoolLabelFragment = { __typename?: 'Label', color: string, usageCount: number, name: string, id: string };

export type PoolMetadataFragment = { __typename?: 'Pool', id: string, languages: Array<Types.Language>, supervisors: Array<string>, dimensions: Array<{ __typename?: 'Dimension', id: string, name: string, position: number, truncated: boolean, categories: Array<{ __typename?: 'Category', id: string, name: string, position: number }> }>, affiliations: Array<{ __typename?: 'Affiliation', id: string, name: string }>, labels: Array<{ __typename?: 'Label', color: string, usageCount: number, name: string, id: string }>, revisionStatus: Array<{ __typename?: 'RevisionStatus', id: string, description: string, shortName: string }> };

export type LoadPoolDetailsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type LoadPoolDetailsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', id: string, languages: Array<Types.Language>, supervisors: Array<string>, dimensions: Array<{ __typename?: 'Dimension', id: string, name: string, position: number, truncated: boolean, categories: Array<{ __typename?: 'Category', id: string, name: string, position: number }> }>, affiliations: Array<{ __typename?: 'Affiliation', id: string, name: string }>, labels: Array<{ __typename?: 'Label', color: string, usageCount: number, name: string, id: string }>, revisionStatus: Array<{ __typename?: 'RevisionStatus', id: string, description: string, shortName: string }> } };

export const PoolDimensionFragmentDoc = gql`
    fragment PoolDimension on Dimension {
  id
  name
  position
  truncated
  categories {
    id
    name
    position
  }
}
    `;
export const PoolAffiliationFragmentDoc = gql`
    fragment PoolAffiliation on Affiliation {
  id
  name
}
    `;
export const PoolLabelFragmentDoc = gql`
    fragment PoolLabel on Label {
  color
  usageCount
  name
  id
}
    `;
export const PoolRevisionStatusFragmentDoc = gql`
    fragment PoolRevisionStatus on RevisionStatus {
  id
  description
  shortName
}
    `;
export const PoolMetadataFragmentDoc = gql`
    fragment PoolMetadata on Pool {
  id
  languages
  supervisors
  dimensions {
    ...PoolDimension
  }
  affiliations {
    ...PoolAffiliation
  }
  labels {
    ...PoolLabel
  }
  revisionStatus {
    ...PoolRevisionStatus
  }
}
    ${PoolDimensionFragmentDoc}
${PoolAffiliationFragmentDoc}
${PoolLabelFragmentDoc}
${PoolRevisionStatusFragmentDoc}`;
export const LoadPoolDetailsDocument = gql`
    query LoadPoolDetails($poolId: ID!) {
  pool(id: $poolId) {
    ...PoolMetadata
  }
}
    ${PoolMetadataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadPoolDetailsGQL extends Apollo.Query<LoadPoolDetailsQuery, LoadPoolDetailsQueryVariables> {
    document = LoadPoolDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }